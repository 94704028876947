import { Injectable } from '@angular/core';
import { Observable, Subject, forkJoin, BehaviorSubject } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';

import { SignInEndpoint } from './sign-in-endpoint.service';
import { AuthService } from './auth.service';
import { CompanyMinimalData } from '../models/company-minimal-data.model';
import { CompanyGeneralData } from '../models/company-general-data.model';
import { ProfileBlockData, ProfileSaveBlockData } from '../models/profile-block-data.model';
import { ProfileEndpoint } from './profile-endpoint.service';
import { ProfileCompanyGeneralData } from '../models/profile-company-general-data.model';
import { UserPeople } from '../models/user-people.model';
import { UserCompanyFollowing } from '../models/user-company-following.model';

@Injectable()
export class ProfileService {

  private companyLogo = new Subject<string>();
  private companyBackground = new Subject<string>();
  private companyImageBackground = new Subject<string>();
  private currentCompany = new BehaviorSubject<string>("");

  // Observable string streams
  companyLogoCanged$ = this.companyLogo.asObservable();
  companyBackgroundCanged$ = this.companyBackground.asObservable();
  companyImageBackgroundCanged$ = this.companyImageBackground.asObservable();
  currentCompany$ = this.currentCompany.asObservable();

  constructor(
    private authService: AuthService,
    private ProfileEndPoint: ProfileEndpoint) {

  }

  changeLogo(newLogo: string) {
    this.companyLogo.next(newLogo);
  }

  changeBackground(newBackground: string) {
    this.companyBackground.next(newBackground);
  }

  changeBackgroundImage(newBackground: string) {
    this.companyImageBackground.next(newBackground);
  }

  setCurrentCompany(currentCompany:string){
    this.currentCompany.next(currentCompany);
  }

  getStartUps(page?: number, pageSize?: number) {
    return this.ProfileEndPoint.getStartUpsEndpoint<CompanyMinimalData[]>(page,pageSize);
  }

  getInvestors(page?: number, pageSize?: number) {
    return this.ProfileEndPoint.getInvestorsEndpoint<CompanyMinimalData[]>(page,pageSize);
  }

  getMostSearched(page?: number, pageSize?: number) {
    return this.ProfileEndPoint.getMostSearchedEndpoint<CompanyMinimalData[]>(page,pageSize);
  }

  getCompanyGeneralData(idEmpresa?: string, isLoggedIn?: boolean) {
    return this.ProfileEndPoint.getCompanyGeneralDataEndpoint<CompanyGeneralData>(idEmpresa, isLoggedIn);
  }

  getCompanyContactsData(idEmpresa?: string) {
    return this.ProfileEndPoint.getCompanyContactsEndpoint<UserPeople[]>(idEmpresa);
  }

  getCompanyUnauthorizedContactsData(idEmpresa?: string) {
    return this.ProfileEndPoint.getCompanyUnauthorizedContactsEndpoint<UserPeople[]>(idEmpresa);
  }

  getProfileBlockData(idEmpresa?: string, isLoggedIn?: boolean) {
    return this.ProfileEndPoint.getProfileBlockDataEndpoint<ProfileBlockData>(idEmpresa, isLoggedIn, 1, 30);
  }

  putProfileGeneralData(idEmpresa: string, comGeneralData: ProfileCompanyGeneralData) {
    return this.ProfileEndPoint.getUpdateProfileGeneralDataCompany<ProfileCompanyGeneralData>(idEmpresa, comGeneralData);
  }

  postProfileGeneralData(idEmpresa: string, comGeneralData: ProfileCompanyGeneralData) {
    return this.ProfileEndPoint.getCreateProfileGeneralDataCompany<ProfileCompanyGeneralData>(idEmpresa, comGeneralData);
  }

  saveProfileBlockData(profileBlockData: ProfileSaveBlockData){
    return this.ProfileEndPoint.getBlockSaveDataEndPoint<ProfileSaveBlockData>(profileBlockData);
  }

  deleteProfileBlockData(idBlock: string) {
    return this.ProfileEndPoint.getBlockDeleteDataEndPoint<ProfileBlockData>(idBlock);
  }

  postUserCompanyFollowData(userCompFollowingData: UserCompanyFollowing) {
    return this.ProfileEndPoint.postFollowCompanyEndPoint<UserCompanyFollowing[]>(userCompFollowingData);
  }

  putUserCompanyUnfollowData(userCompFollowingData: UserCompanyFollowing) {
    return this.ProfileEndPoint.putUnfollowCompanyEndPoint<UserCompanyFollowing[]>(userCompFollowingData);
  }

 }
