
<div class="row tvs-bg-white pb-5  px-2 px-sm-3 px-md-0 px-lg-4" *ngIf="!isBannerLoaded">
  <div class="col-12" style="height:600px;">
    <div class="loader-100">
      <img class="loader-img z-20" src="assets/images/website_general/loader-2.svg" alt="" />
    </div>
  </div>
</div>

<div class="row tvs-bg-white pb-5 tvs-paquetes-top px-2 px-sm-3 px-md-0 px-lg-4" *ngIf="isBannerLoaded">
  <app-custom-breadcrumbs [breadcrumbsData]="breadcrumbsData"></app-custom-breadcrumbs>
  <div class="tvs-paquetes-backlogo">
    <div class="tvs-paquetes-backlogo-up"></div>
    <div class="tvs-paquetes-backlogo-down"></div>
  </div>
  <div class="container-fluid pt-5 px-2 px-md-5 tvs-bg-white">
    <!--Introducción-->
    <div class="row mb-5 pt-5">
      <div class="col-12 text-left">
        <div class="float-left tvs-paquetes-sidelogo mr-3 tvs-paquetes-sidelogo-1">
          <div class="tvs-paquetes-sidelogo-up"></div>
          <div class="tvs-paquetes-sidelogo-down"></div>
        </div>
        <h4 class="tvs-text-upper tvs-paquetes-contenidos-1 mb-0 mt-3">Paquetes</h4>
        <h1 class="tvs-text-orange tvs-paquetes-tituloplat mt-2">{{bannerTitle}}</h1>
      </div>
    </div>
  </div>
</div>
<!--Paquetes actuales-->
<div class="row tvs-bg-white pb-5  px-2 px-sm-3 px-md-0 px-lg-4" *ngIf="!isPaquetesLoaded">
  <div class="col-12" style="height:600px;">
    <div class="loader-100">
      <img class="loader-img z-20" src="assets/images/website_general/loader-2.svg" alt="" />
    </div>
  </div>
</div>
<div class="row tvs-bg-white pb-5  px-2 px-sm-3 px-md-0 px-lg-4" *ngIf="isPaquetesLoaded">
  <div class="col-12 col-xl-10 offset-xl-1 mb-5">
    <div *ngFor="let datosTarjeta of datosPaqueteNuevos; let i = index;" class="container-fluid tvs-paquetes-detalle">
      <ng-container *ngIf="datosTarjeta.tarjetaPaquetes">
        <app-tarjeta class="c-pointer tvs-tarjeta-paquete" [cardData]="datosTarjeta.tarjetaPaquetes" (click)="goToPdf(getCardImg(baseCMSUrl.slice(0, -1) + datosTarjeta.tarjetaPaquetes.linkTarjeta?.urls[0]), datosTarjeta.tarjetaPaquetes.nombreTarjeta, datosTarjeta.paquetePadre )"></app-tarjeta>
      </ng-container>
    </div>
  </div>
</div>
<div class="row tvs-bg-white py-2 py-md-5" *ngIf="datosPaqueteHistoricoCount > 0">
  <div class="col-12">
    <div class="tvs-paquete-lower-div"></div>
  </div>
</div>
<!--Paquetes histórico-->
<div class="row tvs-bg-white pb-5  px-2 px-sm-3 px-md-0 px-lg-4" *ngIf="datosPaqueteHistoricoCount > 0">
  <div class="col-12 mt-5 text-center">
    <h2 class="tvs-text-orange tvs-text-bold">Histórico</h2>
  </div>
  <div class="col-12" style="height:600px;" *ngIf="!isPaquetesLoaded">
    <div class="loader-100">
      <img class="loader-img z-20" src="assets/images/website_general/loader-2.svg" alt="" />
    </div>
  </div>
  <div class="col-12 col-lg-10 offset-lg-1 mb-5" *ngIf="isPaquetesLoaded">
    <div *ngFor="let datosTarjeta of datosPaqueteHistorico | paginate: { itemsPerPage: paquetesPorPagina, currentPage: paginaInicial }" class="container-fluid tvs-paquetes-detalle">
      <div class="row tvs-historico-tarjeta c-pointer" (click)="goToPdf(getCardImg(baseCMSUrl.slice(0, -1) + datosTarjeta.tarjetaPaquetes.linkTarjeta?.urls[0]), datosTarjeta.tarjetaPaquetes.nombreTarjeta, datosTarjeta.paquetePadre )">
        <div class="col-12 px-4 py-2">
          <div class="row">
            <div class="col-12">
              <h3 class="tvs-text-gray tvs-text-bold" *ngIf="datosTarjeta.tarjetaPaquetes.fechaTarjeta && datosTarjeta.tarjetaPaquetes.fechaTarjeta != ''">{{datosTarjeta.tarjetaPaquetes.fechaTarjeta}}</h3>
              <h3 class="tvs-text-orange tvs-text-bold" *ngIf="datosTarjeta.tarjetaPaquetes.nombreTarjeta && datosTarjeta.tarjetaPaquetes.nombreTarjeta != ''">{{datosTarjeta.tarjetaPaquetes.nombreTarjeta}}</h3>
            </div>
            <div class="col-auto tvs-historico-border-right">
              <p class="tvs-text-gray" *ngIf="datosTarjeta.tarjetaPaquetes.textoTarjeta && datosTarjeta.tarjetaPaquetes.textoTarjeta != ''">{{datosTarjeta.tarjetaPaquetes.textoTarjeta}}</p>
            </div>
            <div class="col-auto" *ngIf="datosTarjeta.tarjetaPaquetes.fechaTarjeta && datosTarjeta.tarjetaPaquetes.fechaTarjeta != ''">
              <h3 class="tvs-text-gray d-inline">Vigencia:</h3>
              <p class="tvs-text-gray ml-1 d-inline">{{datosTarjeta.tarjetaPaquetes.fechaTarjeta}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 text-center mt-5">
      <pagination-controls (pageChange)="paginaInicial = $event"
                           maxSize="7"
                           directionLinks="false"
                           autoHide="true"
                           responsive="false"
                           previousLabel=""
                           nextLabel=""
                           class="tvs-paginacion"></pagination-controls>
    </div>
  </div>
</div>
<!--Ver más paquetes-->
<div class="row tvs-bg-white px-0 px-md-3">
  <div class="col-12 px-0 pt-5 tvs-paquetes-vermas">
    <div class="col-12 px-5 mt-5">
      <h2 class="tvs-text-white tvs-text-bold mt-3">Ver más paquetes</h2>
    </div>
    <div class="col-12" style="height:600px;" *ngIf="!isPaquetesOtrosLoaded">
      <div class="loader-100">
        <img class="loader-img z-20" src="assets/images/website_general/loader-2.svg" alt="" />
      </div>
    </div>
    <div class="col-12 px-5 mt-3 mb-5" *ngIf="isPaquetesOtrosLoaded">
      <div class="row">
        <ng-container *ngFor="let datosPaquete of paquetes">
          <div class="col-12 col-sm-6 col-lg-4 col-xl-3 tvs-card-paquete-selector" *ngIf="datosPaquete.displayText.toLowerCase() !== paquete.toLowerCase()">
            <app-tarjeta-simple [attr.id]="dynamicId('tarjetaPaquete-', datosPaquete.nombreTarjeta)" class="tarjetaPaquete c-pointer" [cardData]="datosPaquete" (click)="navigateTo(datosPaquete.linkTarjeta)"></app-tarjeta-simple>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
