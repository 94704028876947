import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { gsap } from "gsap/all";
import { CmsPlanComercialService } from '../../../services/cms/cms.plan.comercial.service';
import { PaquetesPlancomercial, ProductosPlancomercial } from 'src/app/models/cms/cms.plan.comercial.model';
import { AuthService } from 'src/app/services/auth.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { faTimes, faArrowRight, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { GtmService } from 'ngx-gtm';
import { DynamicHelper } from 'src/app/helpers/dynamic-helper';

@Component({
  selector: 'app-plan-comercial',
  templateUrl: './plan-comercial.component.html',
  styleUrls: ['./plan-comercial.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlanComercialComponent implements OnInit {

  title: string = 'Plan comercial';

  faTimes = faTimes;
  faArrowRight = faArrowRight;
  faChevronDown = faChevronDown;

  bannerImage: string;
  bannerImageMobile: string;
  bannerTitle: string;
  productos: ProductosPlancomercial[];
  paquetes: PaquetesPlancomercial[];
  dataGraficas: any[];
  tamanioGrafica: number;
  tamanioBorde: number;
  imagenProducto: string;
  state: string = 'center';
  baseCMSUrl : string;
  webUrl: string;
  linkProducto: string;
  nombreProducto: string;

/*Loaders*/
  isBannerLoaded: boolean;
  isProductosLoaded: boolean;
  isPaquetesLoaded: boolean;

/* Pilares */
  objPilares: any[] = [
    { id: '1', titulo: 'Fuerza', texto: 'Conectamos con tus consumidores llegando a diversas audiencias.' },
    { id: '2', titulo: 'Evaluación', texto: 'Impulsamos tus métricas de marca a lo largo del embudo de marketing.' },
    { id: '3', titulo: 'Innovación', texto: 'Desarrollamos nuevos proyectos y productos.' },
    { id: '4', titulo: 'Confianza', texto: 'Colocamos tu marca en las plataformas correctas, con los formatos adecuados y en el momento preciso.' },
  ];


  constructor(
    public router: Router,
    private cmsPlanComercialService: CmsPlanComercialService,
    private authService: AuthService,
    private configuration: ConfigurationService,
    private gtm: GtmService,
    private cdr: ChangeDetectorRef,
    private titleService: Title,
    private metaService: Meta
  ) {

  /*Loaders*/
    this.isBannerLoaded = false;
    this.isProductosLoaded = false;
    this.isPaquetesLoaded = false;

    this.baseCMSUrl = this.configuration.baseCmsUrl;
    this.webUrl = this.configuration.webUrl;

    if(this.authService.isLoggedIn){
      this.getDataFromServices();
    } else {
      this.authService.loginByApp().subscribe(resp => {//console.log('Resp login:',resp)
        this.getDataFromServices();
      });
    }
  }

  getDataFromServices() {
    this.getProductos();
    this.getPaquetes();
    this.getBannerImage();
  }

  ngOnInit(): void {
    this.gtm.push({ event: 'TelevisaLlega Plan comercial' });

    this.titleService.setTitle(this.title);
    this.metaService.addTags([
      { name: 'keywords', content: 'Plan comercial Televisa, Plan comercial, publicidad en Televisa, mensajes publicitarios, estrategia publicitaria, publicidad, efectividad publicitaria, puntos de contacto Televisa, fortalezas Televisa' },
      { name: 'description', content: 'Conoce las soluciones integrales de comunicación que ofrece Televisa, sus múltiples puntos de contacto y la efectividad publicitaria de sus plataformas.' },
      { name: 'robots', content: 'index, follow' }
    ]);
  }

  ordenaArregloPropiedadOrden(obj1 ,obj2 ){
    if(obj1.orden > obj2.orden) { return 1;}
    if(obj1.orden < obj2.orden) { return -1;}
    return 0;
  }

  public getProductos() {
    this.cmsPlanComercialService.getProductos().subscribe(response => {
      this.productos = response.data.productosPlancomercial;
      this.productos.sort(this.ordenaArregloPropiedadOrden);
      this.isProductosLoaded = true;
      this.cdr.markForCheck();
      //console.log('this.productos', this.productos);
    });
  }

  public getPaquetes() {
    this.cmsPlanComercialService.getPaquetes().subscribe(response => {
      this.paquetes = response.data.paquetesPlancomercial.map((v : PaquetesPlancomercial) => {
        v.linkTarjeta = `/plan-comercial/paquetes;paquete=${v.displayText}`;
        this.isPaquetesLoaded = true;
        this.cdr.markForCheck();
        return v;
      });
      this.paquetes.sort(this.ordenaArregloPropiedadOrden);
    });
  }

  public getBannerImage() {
    this.cmsPlanComercialService.getBannerHero().subscribe(response => {
      for(let i = 0; i < response.data.bannersdePaquetes.length ; i++) {
        if(response.data.bannersdePaquetes[i].displayText === 'Plan-comercial') {
          this.bannerImage = this.baseCMSUrl.slice(0, -1) + response.data.bannersdePaquetes[i].bannerImage.urls[0];
          this.bannerImageMobile = this.baseCMSUrl.slice(0, -1) + response.data.bannersdePaquetes[i].imagenMobile.urls[0];
          this.bannerTitle = response.data.bannersdePaquetes[i].bannerTitle;
          this.isBannerLoaded = true;
          this.cdr.markForCheck();
        }
      }
    });
  }

  public navigateTo(path: string): void {
    this.router.navigateByUrl(path);
    gsap.to(window, {
      scrollTo: 0,
      duration: 0.5
    });
  }

  public dynamicId(objeto: string, nombre: string) {
    return DynamicHelper.dynamicIdHelper(objeto, nombre);
  }


}
