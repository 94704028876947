import { Component, Input, OnInit } from '@angular/core';


@Component({
  selector: 'app-header-image-new',
  templateUrl: './header-image-new.component.html',
  styleUrls: ['./header-image-new.component.scss']
})
export class HeaderImageNewComponent implements OnInit {

  @Input() srcImage: string;
  @Input() srcImageMobile: string;
  @Input() srcTitle: string;
  @Input() srcTipo: string; /*Tipo 1: imagen normal, Tipo 2: imagen corte diagonal con cuadrito izq, Tipo 3: imagen corte diagonal con cuadrito der */

  srcTipoDefault: string = '1';

  constructor() {
    if (!this.srcImage || this.srcImage === '') {
      this.srcImage = '';
    }
    if (!this.srcImageMobile || this.srcImage === '') {
      this.srcImageMobile = '';
    }  
    if (!this.srcTitle) {
      this.srcTitle = 'Título página';
    }
    if (!this.srcTipo) {
      this.srcTipo = this.srcTipoDefault;
    }



  }

  ngOnInit(): void {
  }


}
