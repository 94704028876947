<ng-container *ngIf="!isBannerLoaded">
  <div class="carta-editorial">
    <div class="row tvs-banner-unloaded">
      <img class="loader-img z-20" src="assets/images/website_general/loader-2.svg" alt="" />
    </div>
  </div>
</ng-container>
<ng-container *ngIf="isBannerLoaded">
  <app-header-image-new srcImage="{{bannerImage}}" srcTitle="{{bannerTitle}}" class="carta-editorial"></app-header-image-new>
</ng-container>

<div class="row px-2 px-md-5 py-5 tvs-bg-white">
  <div class="container-fluid">
    <div class="row my-5" *ngIf="isBannerLoaded">
      <div class="col-12 mt-4">
        <h3 class="tvs-text-orange mb-5 tvs-text-bold">Estimados clientes y socios:</h3>
        <p class="tvs-text-black text-justify">
          Hoy más que nunca, el panorama mundial nos exige determinar un rumbo de acción que contribuya al bienestar y progreso.  En Televisa creemos firmemente que para lograrlo necesitamos contribuir e impulsar el crecimiento y desarrollo de nuestros socios comerciales; es momento de arriesgarnos y juntos repensar, cambiar y evolucionar la manera de generar proyectos.
        </p>
        <p class="tvs-text-black text-justify">
          Continuamente nos inspiramos y transformamos para brindar soluciones que permitan afrontar los retos y desafíos actuales.  Nuestro enfoque va más allá de la resiliencia; adoptamos al cambio como una palanca de crecimiento.   Es por lo que, ante este parteaguas en la historia de la humanidad, los invitamos a que juntos reiniciemos, reinventemos y construyamos un mejor mundo de negocios.
        </p>
        <p class="tvs-text-black text-justify">
          Lo más importante para nosotros es colocar a nuestros socios comerciales al centro de la estrategia, por lo que año con año concentramos nuestros esfuerzos en brindar soluciones que detonen y contribuyan al crecimiento de sus marcas.
        </p>
        <p class="tvs-text-black text-justify">
          Este 2022 encontrarás un sinfín de oportunidades para llegar más allá de los límites; sobre todo, en nuestro ecosistema digital que se caracteriza por la constante innovación y que recientemente lanzó al mercado la versión AVOD de BLIM TV. También, continuaremos perfeccionando nuestra estrategia con base en la tecnología, los datos y los contenidos.  Estamos convencidos que la combinación de estos tres elementos es el fundamento que nos permite construir los cuatro pilares que darán forma a la estrategia y que te permitirán lograr efectividad publicitaria y detonar el crecimiento de tu marca. El primero de ellos, es la fuerza para llegar a diversas audiencias y conectar con tus consumidores. El segundo, es la evaluación para impulsar las métricas de marca a lo largo del funnel de marketing. El tercero, la confianza para colocar a tu marca en las plataformas correctas, con los formatos adecuados y en el momento preciso. Finalmente, la innovación para continuar desarrollando soluciones que aporten valor a cada una de tus campañas.
        </p>
        <p class="tvs-text-black text-justify">
          Preparamos la mejor oferta comercial para que logres conectar con tus consumidores. Enfocaremos esfuerzos en ofrecerte lo mejor del Mundial, eventos deportivos, reality shows y diversos programas de entretenimiento. Seguiremos creando nuevas oportunidades, desarrollando mejores productos y poniendo a tu disposición nuevas plataformas en nuestro ecosistema digital. Todo esto para poder brindarte la mejor experiencia y soluciones que contribuyan al logro de tus objetivos.
        </p>
        <p class="tvs-text-black text-justify">
          En Televisa, nuestra razón de ser es el compromiso con cada uno de nuestros socios comerciales. Hoy en día estamos reorientando la forma en que hacemos negocios y continuamos con el compromiso de entregar valor a largo plazo.
        </p>
      </div>
    </div>
    <div class="row mt-5" *ngIf="isBannerLoaded">
      <div class="col-6 offset-3 col-md-2 offset-md-5 text-center">
        <p class="tvs-text-black ">
          Cordialmente
        </p>
        <img class="img-fluid" src="assets/images/website_general/televisa_logotipo.png" alt="Televisa" />
      </div>
    </div>

    <div class="row my-5" *ngIf="isBannerLoaded">
      <div class="col-12 col-md-10 offset-md-1 my-4 text-center">
        <a class="tvs-regresar" href="javascript:history.back()">&nbsp;</a>
      </div>
    </div>
  </div>
</div>
