
<!--No encontrado-->
<div class="row tvs-bg-gray pb-5" *ngIf="!content">
  <div class="container container-80 pb-5 tvs-mt-60 tvs-bg-white tvs-corner-container-2 no-scale">
    <div class="row mt-5">
      <div class="col-12 text-center">
        <h1 class="tvs-text-orange my-5">404 - NO ENCONTRADO</h1>
        <h3 class="tvs-text-black my-5">LA PÁGINA QUE BUSCAS NO EXISTE</h3>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-12 text-center">
        <button class="btn btn-primary btn-orange tvs-btn" (click)="goBack()">REGRESAR</button>
      </div>
    </div>
  </div>
</div>

<div class="row tvs-bg-white pb-5 tvs-futurecast-top px-2 px-sm-3 px-md-0 px-lg-4" *ngIf="content">
  <app-custom-breadcrumbs [breadcrumbsData]="breadcrumbsData"></app-custom-breadcrumbs>
  <div class="tvs-futurecast-backlogo">
    <div class="tvs-futurecast-backlogo-up"></div>
    <div class="tvs-futurecast-backlogo-down"></div>
  </div>
  <div class="container-fluid pt-5 px-2 px-md-5 tvs-bg-white">
    <!--Introducción-->
    <div class="row mb-5 pt-5">
      <div class="col-12 text-left">
        <div class="float-left tvs-futurecast-sidelogo mr-3 tvs-futurecast-sidelogo-1">
          <div class="tvs-futurecast-sidelogo-up"></div>
          <div class="tvs-futurecast-sidelogo-down"></div>
        </div>
        <h4 class="tvs-text-upper tvs-futurecast-contenidos-1 mb-0 mt-3">{{tipoFutureCast}}</h4>
        <h1 class="tvs-text-orange tvs-futurecast-tituloplat mt-3">{{bannerTitle}}</h1>
      </div>
    </div>
  </div>
</div>

<div class="row tvs-bg-white pb-5  px-2 px-sm-3 px-md-0 px-lg-4" *ngIf="!isFuturecastLoaded">
  <div class="col-12" style="height:600px;">
    <div class="loader-100">
      <img class="loader-img z-20" src="assets/images/website_general/loader-2.svg" alt="" />
    </div>
  </div>
</div>

<div class="row tvs-bg-white pb-5  px-2 px-sm-3 px-md-0 px-lg-4" *ngIf="isFuturecastLoaded">
  <div class="col-12" *ngIf="content && futurecastSingleData">
    <ng-container *ngFor="let item of futurecastSingleData[0].bloque.contentItems">
      <app-blocks class="z-20 w-100" [dataBlock]="item"></app-blocks>
    </ng-container>
  </div>
</div>
