<ng-container *ngIf="!isBannerLoaded">
  <div class="plan-comercial">
    <div class="row tvs-banner-unloaded">
      <img class="loader-img z-20" src="assets/images/website_general/loader-2.svg" alt="" />
    </div>
  </div>
</ng-container>
<ng-container *ngIf="isBannerLoaded">
  <app-header-image-new srcImage="{{bannerImage}}" srcImageMobile="{{bannerImageMobile}}" srcTitle="{{bannerTitle}}" srcTipo="3" class="plan-comercial"></app-header-image-new>
</ng-container>
<div class="row px-2 px-md-5 py-5 tvs-bg-white">
  <div class="container-fluid mt-5">
    <div class="row my-5" *ngIf="!isBannerLoaded">
      <ng-container>
        <div class="container-fluid loader-min">
          <img class="loader-img z-20" src="assets/images/website_general/loader-2.svg" alt="" />
        </div>
      </ng-container>
    </div>
    <!--Texto intro-->
    <div class="row mt-5 mb-5 mt-md-0" *ngIf="isBannerLoaded">
      <div class="col-12 col-md-10 col-lg-9 col-xl-7 mt-4">
        <p class="tvs-text-black tvs-text-big">
          En TelevisaUnivision ofrecemos soluciones integrales de comunicación a través de la flexibilidad que nos
          brinda el ser generadores de contenidos así como el poseer una sólida red de audiencias a través de nuestros
          múltiples puntos de contacto; es por eso que impulsamos tu estrategia y garantizamos la efectividad
          publicitaria en la transmisión de tus mensajes.
        </p>
      </div>
      <div class="col-12 mt-4">
        <button id="botonPlanComercial-carta-editorial" class="btn btn-primary tvs-btn-orange tvs-btn"
          (click)="navigateTo('plan-comercial/carta-editorial')">Seguir leyendo<fa-icon [icon]="faArrowRight"
            class="pl-1"></fa-icon></button>
      </div>
    </div>
    <!--Universo Televisa-->
    <div class="row py-5" *ngIf="isBannerLoaded">
      <div class="col-12">
        <h1 class="tvs-text-orange titulos-plan-comercial">Universo TelevisaUnivision</h1>
      </div>
      <div class="col-12 col-lg-8 offset-lg-2 mt-5">
        <img class="img-fluid" src="assets/images/carga_cms/plan-comercial/universo-televisaunivision-2.svg"
          alt="Universo TelevisaUnivision" />
      </div>
    </div>
    <!--Infografia-->
    <div class="row" *ngIf="isBannerLoaded">
      <div class="col-12 d-block d-lg-none text-center">
        <p>¿Necesitas un modelo integral, seguro y con soluciones estratégicas para cada una de tus marcas?</p>
        <br />
        <h3 class="tvs-text-orange tvs-text-x-bold">¡Juntos lograremos el éxito de tus campañas!</h3>
      </div>
      <div class="d-none d-lg-block col-lg-7 mt-5 pt-md-5 pr-md-5">
        <p class="pt-lg-2 pt-xl-5 mt-xl-5">¿Necesitas un modelo integral, seguro y con soluciones estratégicas para cada
          una de tus marcas?</p>
        <br />
        <h2 class="tvs-text-orange tvs-text-bold">¡Juntos lograremos el éxito de tus campañas!</h2>
      </div>
      <div class="col-12 col-md-8 offset-md-2 col-lg-5 offset-lg-0 mt-5">
        <img class="img-fluid tvs-svg-img" src="assets/images/carga_cms/plan-comercial/brandformance-2.svg"
          alt="Brandformance TelevisaUnivision" />
      </div>
    </div>
    <!--Pilares-->
    <div class="row mt-5" *ngIf="isBannerLoaded">
      <div class="col-12 mt-5">
        <h1 class="tvs-text-orange titulos-plan-comercial text-center">Nuestros pilares</h1>
      </div>
      <div class="col-12 my-5">
        <div class="row tvs-pilares-card-container">
          <div class="tvs-pilares-card tvs-bg-white col-md-6 col-lg-3" *ngFor="let pilares of objPilares; let i = index;">
            <div class="text-center tvs-pilares-card-fondo">
              <h3 class="tvs-text-white tvs-text-x-bold">{{pilares.titulo}}</h3>
              <p class="tvs-text-white mt-3">{{pilares.texto}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 text-center">
        <a id="botonPlanComercial-descarga-pilares" class="btn btn-primary btn-orange tvs-btn"
          href="assets/pilares/Televisa-Plan-Comercial-2022_VEjecutiva_02_Publicar[1].pdf" target="_blank">Descarga
          nuestros pilares<fa-icon [icon]="faChevronDown" class="pl-1"></fa-icon></a>
      </div>
    </div>
    <!--Productos por plataforma-->
    <div class="row" id="pc-productos"></div>
    <div class="row tvs-bg-white">
      <div class="col-12 px-3 px-md-5 tvs-plan-productos">
        <div class="col-12 mt-5 pt-5">
          <h2 class="titulos-plan-comercial tvs-text-x-bold tvs-text-white py-3">Productos por plataforma</h2>
        </div>
        <div class="col-12" *ngIf="!isProductosLoaded">
          <div class="row tvs-productos-unloaded">
            <img class="loader-img z-20" src="assets/images/website_general/loader-2.svg" alt="" />
          </div>
        </div>
        <div class="row mb-5 pb-5">
          <ng-container *ngIf="isProductosLoaded">
            <div class="col-12 col-md-6 col-lg-4 col-xl-3 tvs-plan-tarj-productos" *ngFor="let producto of productos">
              <app-tarjeta-producto [attr.id]="dynamicId('tarjetaProducto-', producto.nombreTarjeta)"
              class="tarjetaProducto" [cardData]="producto"></app-tarjeta-producto>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>