import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { gsap } from "gsap/all";
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-custom-breadcrumbs',
  templateUrl: './custom-breadcrumbs.component.html',
  styleUrls: ['./custom-breadcrumbs.component.scss']
})
export class CustomBreadcrumbsComponent implements OnInit {

  @Input() breadcrumbsData: any[];
  @Input() srcTipo: string;
  faChevronRight = faChevronRight;
  srcTipoDefault: string = '1';

  constructor(
    public router: Router,
  ) {
    if (!this.srcTipo) {
      this.srcTipo = this.srcTipoDefault;
    }
  }

  ngOnInit(): void {
  }

  public navigateTo(path: string): void {
    this.router.navigateByUrl(path);
    gsap.to(window, {
      scrollTo: 0,
      duration: 0.5
    });
  }

}
