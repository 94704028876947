import { Component, OnInit, ViewChildren, ChangeDetectionStrategy, ChangeDetectorRef, QueryList } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { gsap } from "gsap/all";
import { MatVideoComponent } from 'mat-video/lib/video.component';
import { GtmService } from 'ngx-gtm';
import { CmsOfertaService } from 'src/app/services/cms/cms.oferta.service';
import { EventosEvento } from 'src/app/models/cms/cms.oferta.model';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { AuthService } from 'src/app/services/auth.service';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { DynamicHelper } from 'src/app/helpers/dynamic-helper';


@Component({
  selector: 'app-detalle-evento',
  templateUrl: './detalle-evento.component.html',
  styleUrls: ['./detalle-evento.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DetalleEventoComponent implements OnInit {

  faArrowRight = faArrowRight;

  title: string = 'Detalles de eventos';

  content: boolean = false;
  evento: string;
  bannerImage: string;
  bannerTitle: string;
  baseCMSUrl: string;
  eventoNombre: string;

  eventoContent: any[];
  eventosService: EventosEvento[];
  elencoContent: boolean = false;
  datosElenco: any[];

  videosContent: boolean = false;
  videoCardData: any[];
  @ViewChildren('video') matVideo: QueryList<MatVideoComponent>;
  video: HTMLVideoElement;

  /*Loaders*/
  isBannerLoaded: boolean = false;
  isEventoLoaded: boolean = false;
  isParticipantesLoaded: boolean = false;
  isVideosLoaded: boolean = false;

  breadcrumbsData: any[];

  constructor(
    private gtm: GtmService,
    public router: Router,
    private route: ActivatedRoute,
    private _location: Location,
    private cdr: ChangeDetectorRef,
    private cmsOfertaService: CmsOfertaService,
    private configuration: ConfigurationService,
    private authService: AuthService,
    private titleService: Title,
    private metaService: Meta
  ) {
    this.route.params.subscribe(routeParams => {
      if (routeParams['id']) {
        this.evento = routeParams['id'];
        this.content = true;
        this.cdr.markForCheck();
      }
      else {
        this.evento = "No encontrado";
        this.content = false;
        this.bannerTitle = '404 - NO ENCONTRADO';
      }
    });
  }

  ngOnInit(): void {
    this.gtm.push({ event: 'TelevisaLlega Evento Detalle' });
    this.baseCMSUrl = this.configuration.baseCmsUrl;
    if(this.authService.isLoggedIn){
      this.getBannerImage();
      if (this.content == true) {
        this.getEvento(this.evento);
        this.getElenco(this.evento);
      }
    }
    else{
      /* Me loggeo y luego voy por los banners */
      this.authService.loginByApp().subscribe(resp => {//console.log('Resp login:',resp)
      this.getBannerImage();
      if (this.content == true) {
        this.getEvento(this.evento);
        this.getElenco(this.evento);
      }
      });
    }

    this.titleService.setTitle(this.title);
    this.metaService.addTags([
      { name: 'keywords', content: 'eventos, eventos deportivos, eventos especiales, programas de televisón, argumentos de programas' },
      { name: 'description', content: 'Conoce a detalle los eventos más importantes a incluir en tu estrategia, con los cuales podrás tener una presencia relevante frente a los consumidores.' },
      { name: 'robots', content: 'index, follow' }
    ]);
  }

  public getBannerImage() {
    /* Invocar servicio que recupera imagen de portada de la página*/
    this.bannerImage = '';
    this.isBannerLoaded = true;
    this.cdr.markForCheck();
  }

  public getEvento(id: string) {
      this.cmsOfertaService.getEventos().subscribe(response => {
        this.eventosService = response?.data.eventosEvento;
        //console.log('Esto traigo en this.eventosService', this.eventosService);
        this.eventoContent = [];
        this.videoCardData = [];
        for(let i = 0; i < this.eventosService.length ; i++) {
          if (this.eventosService[i].contentItemId == id){
            this.eventoContent.push(
              {
                eventoId: this.eventosService[i].contentItemId,
                eventoNombre: this.eventosService[i].eventoNombre,
                eventoNombreCorto: this.eventosService[i].eventoNombre,
                eventoDescripcionCorta: this.eventosService[i].eventoDescripcionCorta,
                eventoDescripcion: this.eventosService[i].eventoDescripcion,
                eventoImagen: this.baseCMSUrl.slice(0, -1) + this.eventosService[i]?.imagenEvento?.urls[0],
                eventoFecha: this.eventosService[i].fechaEvento,
                eventoHora: this.eventosService[i].horarioEvento,
                eventoCanal: this.eventosService[i].eventoCanalLeyenda,
                eventoCanalImagen: this.baseCMSUrl.slice(0, -1) + this.eventosService[i]?.eventoCanal?.contentItems[0]?.platImg?.urls[0],
                eventoWebsite: this.eventosService[i].eventoWebsite.url,
                orden: this.eventosService[i].orden
              }
            );
            this.eventoNombre = this.eventosService[i].eventoNombre;

            if (this.eventosService[i].contentItemId == id) {
              this.breadcrumbsData = [
                { nombre: 'Eventos', link: 'eventos' },
                { nombre: this.eventosService[i].eventoNombre, link: '' }
              ];
            }

  
            if(this.eventosService[i]?.videos?.contentItems[0]?.eventoVideo){
              this.videoCardData.push(
                {
                  videoTitulo: this.eventoNombre,
                  videoVideo: this.baseCMSUrl.slice(0, -1) + this.eventosService[i]?.videos?.contentItems[0]?.eventoVideo?.video?.urls[0],
                  videoThumb: this.baseCMSUrl.slice(0, -1) + this.eventosService[i]?.videos?.contentItems[0]?.eventoVideo?.thumbnail?.urls[0],
                  videoNumSecu: 1
                }
              );  
            }
            else {
              this.videoCardData = [];
              this.videosContent = false;
            }
          }
        }
        if (this.eventoContent.length > 0) {
          for (let obj of this.eventoContent) {
            if (obj.eventoNombreCorto != '') {
              this.bannerTitle = obj.eventoNombreCorto;
            } else {
              this.bannerTitle = obj.eventoNombre;
            }
          }
        }
        if (this.videoCardData.length > 0) {
          this.videosContent = true;
        }
        this.isEventoLoaded = true;
        this.isVideosLoaded = true;
        this.cdr.markForCheck();
      });
  }

  public getCardImg(imgString: string) {
    var urlImagen: string = imgString.replace(/ /g, '%20');
    return urlImagen;
  }

  public goToEvent(path: string, nombre: string) {
    window.open(path, '_self');
    //console.log(path, nombre);
    this.gtm.push({
      event: 'click-evento',
      type: 'Eventos',
      name: nombre
    });
  }

  public getElenco(id: string) {
    this.datosElenco = [];
    if (id == '1') {
      this.datosElenco = [
        //{
        //  tipoTarjeta: 6,
        //  nombreTarjeta: 'Carlos Hurtado',
        //  eventoId: '1'
        //},
      ];
    } else {
      this.datosElenco = [];
    }

    if (this.datosElenco.length > 0) {
      this.elencoContent = true;
      this.cdr.markForCheck();
    }
    this.isParticipantesLoaded = true;
    this.cdr.markForCheck();
  }

  public iOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }

  pauseAllVideos() {
    var vids = document.getElementsByTagName('video');
    for (var i = 0; i < vids.length; i++) {
      this.video = vids.item(i);
      this.video.pause();
    }
  }


  customOptionsMobile: OwlOptions = {
    loop: false,
    rewind: true,
    mouseDrag: false,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 500,
    navText: ['', ''],
    items: 1,
    nav: true,
    autoplay: true,
    autoplayTimeout: 7000,
    autoplaySpeed: 1000,
  }

  customOptionsVideo: OwlOptions = {
    loop: false,
    rewind: true,
    mouseDrag: false,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 500,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1,
      },
    },
    nav: true,
    autoplay: false,
    autoplayTimeout: 7000,
    autoplaySpeed: 1000,
    autoplayHoverPause: true,
    autoplayMouseleaveTimeout: 1000
  }


  public navigateTo(path: string): void {
    this.router.navigateByUrl(path);
    gsap.to(window, {
      scrollTo: 0,
      duration: 0.5
    });

  }
  public goBack() {
    this._location.back();
  }

  public dynamicId(objeto: string, nombre: string) {
    return DynamicHelper.dynamicIdHelper(objeto, nombre);
  }

}
