<!-- Programas, Futurecast, Eventos, Paquetes -->
<a [attr.id]="dynamicId('tarjetaBuscador-', cardData.tituloTarjeta)" class="c-pointer" (click)="goToLink(cardData.linkTarjeta, cardData.tituloTarjeta , cardData.tipoResultado)" *ngIf="cardData.tipoResultado != 5">
  <div class="card tvs-card-common pb-3 mb-4 mx-1" [ngClass]="{'card-img-top' : cardData.tipoResultado == 4}">

    <div *ngIf="cardData.tipoResultado == 1 || cardData.tipoResultado == 2 || cardData.tipoResultado == 3">
      <div class="tvs-card-img-container tvs-o-hidden">
        <div class="tvs-img-container"
             [ngStyle]="{'background-image': 'url('+ getCardImg(baseCMSUrl.slice(0, -1) + cardData.imagenTarjeta) +')'}"
             *ngIf="cardData.imagenTarjeta && cardData.imagenTarjeta != ''">
        </div>
        <div class="tvs-img-container"
             style="background-image: url('assets/images/website_general/paquetes_default.png')"
             *ngIf="!cardData.imagenTarjeta || cardData.imagenTarjeta == ''">
        </div>
      </div>
    </div>
    <div *ngIf="cardData.tipoResultado == 4">
      <div class="tvs-card-img-container card-paquete tvs-o-hidden">
        <div class="tvs-img-container"
             [ngStyle]="{'background-image': 'url('+ getCardImg(baseCMSUrl.slice(0, -1) + cardData.imagenTarjeta) +')'}"
             *ngIf="cardData.imagenTarjeta && cardData.imagenTarjeta != ''">
        </div>
        <div class="tvs-img-container"
             style="background-image: url('assets/images/website_general/paquetes_default.png')"
             *ngIf="!cardData.imagenTarjeta || cardData.imagenTarjeta == ''">
        </div>
      </div>
    </div>

    <div class="card-body px-3 px-md-2 px-xl-4">
      <span class="tvs-cinta-evento tvs-cinta" *ngIf="cardData.nombrePlataformaTarjeta && cardData.nombrePlataformaTarjeta != ''">{{cardData.nombrePlataformaTarjeta}}</span>
      <h2 class="card-title tvs-text-orange mt-4 tvs-limite-titulos">{{cardData.tituloTarjeta}}</h2>

      <p class="tvs-span-texto" *ngIf="cardData.tipoResultado == 2 && cardData.textoTarjeta && cardData.textoTarjeta != ''">{{cardData.textoTarjeta}}</p>

      <p class="tvs-span-horario" *ngIf="cardData.tipoResultado != 4 && cardData.fechaTarjeta && cardData.fechaTarjeta != ''">{{cardData.fechaTarjeta}}&nbsp;{{cardData.horaTarjeta}}</p>

      <h3 *ngIf="cardData.tipoResultado === 4 && cardData.fechaTarjeta && cardData.fechaTarjeta != ''">{{cardData.fechaTarjeta}}</h3>

      <p class="tvs-span-texto" *ngIf="cardData.tipoResultado == 4 && cardData.textoTarjeta && cardData.textoTarjeta != ''">{{cardData.textoTarjeta}}</p>

      <p class="tvs-card-genero tvs-genero" *ngIf="cardData.generoTarjeta && cardData.generoTarjeta != ''">{{cardData.generoTarjeta}}</p>
      <p class="tvs-span-canal" *ngIf="cardData.canalTarjeta && cardData.canalTarjeta != ''">{{cardData.canalTarjeta}}</p>
    </div>

  </div>
</a>

<!-- Secciones (páginas) -->

<a [attr.id]="dynamicId('tarjetaBuscador-', cardData.tituloTarjeta)" class="c-pointer" (click)="goToLink(cardData.linkTarjeta, cardData.tituloTarjeta , cardData.tipoResultado)" *ngIf="cardData.tipoResultado == 5" >
  <h4 class="tvs-text-upper">{{cardData.tituloTarjeta}}</h4>
  <p class="tvs-substring-bold" [innerHTML]="cardData.textoTarjeta"></p>
</a>
