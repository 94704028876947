<div class="card tvs-card-common pb-3 mb-4 mx-1 tvs-o-hidden">
  <div>
    <div class="tvs-card-img-container tvs-o-hidden">
      <div class="tvs-img-container"
           [ngStyle]="{'background-image': 'url('+ getCardImg(baseCMSUrl.slice(0, -1) + cardData?.imagenTarjeta.urls[0]) +')'}"
           *ngIf="cardData.imagenTarjeta && cardData?.imagenTarjeta?.urls?.length > 0"></div>
      <div class="tvs-img-container"
           style="background-image: url('assets/images/website_general/paquetes_default.png')"
           *ngIf="!cardData.imagenTarjeta || cardData?.imagenTarjeta?.urls?.length <= 0"></div>
    </div>
  </div>
  <div class="card-body px-4">
    <h2 class="card-title">{{cardData.nombreTarjeta}}</h2>
  </div>
</div>
