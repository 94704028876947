<div class="p-0 m-0">
  <!--header home-->
  <div class="row tvs-carousel-row" [ngClass]="{'tvs-con-canales': hasChannelInfo == true, 'tvs-sin-canales': hasChannelInfo == false }">
    <div class="p-0 tvs-bg-hero">
      <ng-container *ngIf="!isNewBannerLoaded">
        <div class="loader-100">
          <img class="tvs-bannerhome-loader-img z-20" src="assets/images/website_general/loader-2.svg" alt="" />
        </div>
      </ng-container>
      <ng-container *ngIf="isNewBannerLoaded">
        <owl-carousel-o class="white-dots" [options]="customOptionsBanner">
          <ng-container *ngFor="let banner of newBannerHome; let i = index;">
            <ng-template carouselSlide>
              <div
                class="container-fluid z-0 tvs-bannerhome-bg tvs-bannerhome-bg-{{banner.colordeFondo.contentItems[0].identificador}}" loading="lazy">
                <div class="tvs-banner-container">
                  <!--Texto izquierda-->
                  <div class="tvs-home-banner-izq pt-4 pt-sm-5"
                    [ngClass]="{'sin-canal' : banner.canalHorario && banner.canalHorario.contentItems.length == 0}">
                    <div class="tvs-home-banner-title" [ngClass]="{'sin-texto' : !banner.texto || banner.texto == ''}">
                      <h1 class="tvs-text-white mt-2 mt-sm-0">{{banner.tituloSlide}}</h1>
                    </div>
                    <div class="tvs-home-banner-text mt-3 mt-sm-4 mt-md-4 mt-xl-5"
                      *ngIf="banner.texto && banner.texto != ''">
                      <p class="tvs-text-white">{{banner.texto}}</p>
                    </div>
                    <div class="tvs-home-banner-button mt-3 mt-sm-4 mt-md-4 mt-xl-5">
                      <a *ngIf="(banner.textoBoton && banner.textoBoton !== '') && (banner.linkBoton && banner.linkBoton !== '') && (banner.tituloSlide && banner.tituloSlide !== '')"
                        [attr.id]="dynamicId('bannerHome-conoce-mas-',banner.tituloSlide)"
                        class="bannerHome btn btn-primary tvs-btn z-10"
                        [ngClass]="{'btn-yellow' : (banner.colordeFondo.contentItems[0].identificador == '7' || banner.colordeFondo.contentItems[0].identificador == '8'), 'btn-orange' : banner.colordeFondo.contentItems[0].identificador == '9'}"
                        href="{{banner.linkBoton}}" target="_self">{{banner.textoBoton}}<fa-icon [icon]="faArrowRight"
                          class="pl-2"></fa-icon></a>
                    </div>
                    <div class="tvs-home-banner-disclaimer" *ngIf="banner.restricciones && banner.restricciones != ''">
                      <p class="tvs-text-white">{{banner.restricciones}}</p>
                    </div>
                  </div>
                  <!--Imagen-->
                  <div class="tvs-home-banner-image text-center"
                  [ngClass]="{'sin-canal' : (banner.canalHorario && banner.canalHorario.contentItems.length == 0),'sin-transparencia' : (banner.imagensintransparencia && banner.imagensintransparencia == true)}">
                  <img class="img img-fluid" src="{{baseCMSUrl.slice(0,-1) + banner.imagen.urls[0]}}" loading="lazy" tvshomebanner="1"
                    alt="" width="500" height="500"/>
                </div>
                  <!--Horario-canal-->
                  <div class="row tvs-home-banner-horacanal pt-lg-5 pr-lg-2 pt-md-3 pt-sm-1"
                    *ngIf="(banner.canalHorario && banner.canalHorario && banner.canalHorario.contentItems.length > 0) && (!banner.imagensintransparencia || banner.imagensintransparencia == false)">
                    <ng-container
                      *ngFor="let horaCanal of banner.canalHorario.contentItems | slice: 0:4; let j = index;">
                      <!--Un solo canal-->
                      <ng-container *ngIf="banner.canalHorario && banner.canalHorario.contentItems.length == 1">
                        <div class="tvs-home-banner-horacanal-bloque canal-solo col-12">
                          <div class="tvs-home-banner-canal-bloque">
                            <div class="rounded-circle tvs-home-banner-canal">
                              <img class="img img-fluid"
                                src="{{baseCMSUrl.slice(0,-1) + horaCanal.canal.contentItems[0].platImg.urls[0]}}"
                                alt="" />
                            </div>
                          </div>
                          <div class="tvs-home-banner-horacanal-texto">
                            <p class="tvs-text-white tvs-text-bold tvs-home-banner-evento">{{horaCanal.displayText}}</p>
                            <p class="tvs-text-white tvs-home-banner-fecha">{{horaCanal.fecha}}</p>
                            <p class="tvs-text-white tvs-home-banner-horario">{{horaCanal.horario}}</p>
                          </div>
                        </div>
                      </ng-container>
                      <!--2 canales-->
                      <ng-container
                        *ngIf="banner.canalHorario && banner.canalHorario && banner.canalHorario.contentItems.length == 2">
                        <div class="tvs-home-banner-horacanal-bloque canal-solo col-6 col-lg-12">
                          <div class="tvs-home-banner-canal-bloque">
                            <div class="rounded-circle tvs-home-banner-canal">
                              <img class="img img-fluid"
                                src="{{baseCMSUrl.slice(0,-1) + horaCanal.canal.contentItems[0].platImg.urls[0]}}"
                                alt="" />
                            </div>
                          </div>
                          <div class="tvs-home-banner-horacanal-texto">
                            <p class="tvs-text-white tvs-text-bold tvs-home-banner-evento">{{horaCanal.displayText}}</p>
                            <p class="tvs-text-white tvs-home-banner-fecha">{{horaCanal.fecha}}</p>
                            <p class="tvs-text-white tvs-home-banner-horario">{{horaCanal.horario}}</p>
                          </div>
                        </div>
                      </ng-container>
                      <!--3 canales-->
                      <ng-container
                        *ngIf="banner.canalHorario && banner.canalHorario && banner.canalHorario.contentItems.length == 3">
                        <div class="tvs-home-banner-horacanal-bloque col-4 col-md-4 col-lg-12">
                          <div class="tvs-home-banner-canal-bloque">
                            <div class="rounded-circle tvs-home-banner-canal">
                              <img class="img img-fluid"
                                src="{{baseCMSUrl.slice(0,-1) + horaCanal.canal.contentItems[0].platImg.urls[0]}}"
                                alt="" />
                            </div>
                          </div>
                          <div class="tvs-home-banner-horacanal-texto">
                            <p class="tvs-text-white tvs-text-bold tvs-home-banner-evento">{{horaCanal.displayText}}</p>
                            <p class="tvs-text-white tvs-home-banner-fecha">{{horaCanal.fecha}}</p>
                            <p class="tvs-text-white tvs-home-banner-horario">{{horaCanal.horario}}</p>
                          </div>
                        </div>
                      </ng-container>
                      <!--4 canales-->
                      <ng-container
                        *ngIf="banner.canalHorario && banner.canalHorario && banner.canalHorario.contentItems.length >= 4">
                        <div class="tvs-home-banner-horacanal-bloque col-3 col-md-3 col-lg-12">
                          <div class="tvs-home-banner-canal-bloque">
                            <div class="rounded-circle tvs-home-banner-canal">
                              <img class="img img-fluid"
                                src="{{baseCMSUrl.slice(0,-1) + horaCanal.canal.contentItems[0].platImg.urls[0]}}"
                                alt="" />
                            </div>
                          </div>
                          <div class="tvs-home-banner-horacanal-texto">
                            <p class="tvs-text-white tvs-text-bold tvs-home-banner-evento">{{horaCanal.displayText}}</p>
                            <p class="tvs-text-white tvs-home-banner-fecha">{{horaCanal.fecha}}</p>
                            <p class="tvs-text-white tvs-home-banner-horario">{{horaCanal.horario}}</p>
                          </div>
                        </div>
                      </ng-container>
                    </ng-container>
                  </div>
                </div>
              </div>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </ng-container>
    </div>
    <div class="tvs-carousel-row-bottom"></div>
  </div>
  <!--Estrategia-->
  <div class="row tvs-bg-white px-2 px-md-5 py-5">
    <div class="col-12 col-lg-6">
      <div class="row">
        <div class="col-6 text-right tvs-estrategia-ne">
          <div class="tvs-text-orange tvs-text-bold tvs-estrategia-nuestra tvs-h3-title">Nuestra</div>
          <div class="tvs-text-orange tvs-text-bold tvs-estrategia-estrategia tvs-h3-subtitle">estrategia</div>
        </div>
        <div class="col-6">
          <div class="tvs-estrategia-igual">=</div>
          <div class="tvs-estrategia-efectividad">Efectividad <br /> publicitaria</div>
        </div>
        <div class="col-12 text-center p-5 d-none d-sm-block">
          <p>
            ¡Te ayudamos a lograr tus objetivos y a maximizar la efectividad publicitaria!
          </p>
          <p>
            Nuestra fórmula te ofrece soluciones para obtener el balance adecuado, hacer crecer tu marca y optimizar el
            desempeño de tu campaña.
          </p>
        </div>
        <div class="col-12 text-left py-5 d-block d-sm-none">
          <p>
            ¡Te ayudamos a lograr tus objetivos y a maximizar la efectividad publicitaria!
          </p>
          <p>
            Nuestra fórmula te ofrece soluciones para obtener el balance adecuado, hacer crecer tu marca y optimizar el
            desempeño de tu campaña.
          </p>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-8 offset-sm-2 col-lg-6 offset-lg-0 px-md-5">
      <img class="img-fluid d-none d-sm-block" src="assets/images/website_general/estrategia-home-desktop.svg" alt="" loading="lazy" width="637" height="653"/>
      <img class="img-fluid d-block d-sm-none" src="assets/images/website_general/estrategia-mobile.svg" alt="" loading="lazy" width="274" height="281"/>
    </div>
    <!--Texto mobile-->
    <div class="col-12 d-sm-none py-5">
      <h3 class="tvs-home-text tvs-text-orange">Fuerza</h3>
      <p class="mb-5">Conectamos con tus consumidores llegando a diversas audiencias.</p>
      <h3 class="tvs-home-text tvs-text-yellow">Evaluación</h3>
      <p class="mb-5">Impulsamos tus métricas de marca a lo largo del embudo de marketing.</p>
      <h3 class="tvs-home-text tvs-text-red">Confianza</h3>
      <p class="mb-5">Colocamos tu marca en las plataformas correctas, con los formatos adecuados y en el momento
        preciso.</p>
      <h3 class="tvs-home-text tvs-text-magenta">Innovación</h3>
      <p class="mb-5">Desarrollamos nuevos proyectos y productos.</p>
    </div>
  </div>
  <!--Productos-->
  <div class="row tvs-bg-white">
    <div class="col-12 px-3 px-md-5 tvs-home-productos">
      <div class="col-12 mt-5 pt-5">
        <h2 class="tvs-text-x-bold tvs-text-white py-3 tvs-home-titulos">Productos por plataforma</h2>
      </div>
      <div class="col-12" *ngIf="!isProductosLoaded">
        <div class="row tvs-productos-unloaded">
          <img class="loader-img z-20" src="assets/images/website_general/loader-2.svg" alt="" />
        </div>
      </div>
      <div class="row mb-5 pb-5">
        <ng-container *ngIf="isProductosLoaded">
          <div class="col-12 col-md-6 col-lg-4 col-xl-3 tvs-home-tarj-productos" *ngFor="let producto of productos">
            <app-tarjeta-producto [attr.id]="dynamicId('tarjetaProducto-', producto.nombreTarjeta)"
              class="tarjetaProducto" [cardData]="producto"></app-tarjeta-producto>
          </div>
        </ng-container>
      </div>
     
    </div>
  </div>
  <!--Casos de éxito-->
  <div class="row px-2 px-md-5 py-5 tvs-bg-white">
    <div class="col-12">
      <h2 class="tvs-text-x-bold tvs-text-orange pt-5 pb-4 pl-0 pl-md-5 tvs-home-titulos">Casos de éxito</h2>
    </div>
    <div class="col-12" *ngIf="!isCasosExitoLoaded">
      <div class="row tvs-productos-unloaded">
        <img class="loader-img z-20" src="assets/images/website_general/loader-2.svg" alt="" />
      </div>
    </div>
    <div class="col-12 py-5" *ngIf="isCasosExitoLoaded">
      <div class="row">

        <ng-container *ngFor="let item of casosExitoCardData | slice: 0:2; let i = index;">
          <div class="col-12 col-sm-10 offset-sm-1 col-lg-6 offset-lg-0 p-0">
            <div class="row mt-4 mx-1 tvs-home-casos-tarjeta tvs-o-hidden">
              <div class="col-12 col-md-6 p-0 tvs-o-hidden tvs-home-casos-tarjeta-img d-none d-md-block"
                [ngStyle]="{'background-image': 'url('+ getCardImg(baseCMSUrl.slice(0, -1) + item?.casosExitoImage?.urls[0]) +')'}">
              </div>
              <div class="col-12 col-md-6 p-0 tvs-o-hidden d-block d-md-none">
                <img class="img-fluid" [src]="getCardImg(baseCMSUrl.slice(0, -1) + item?.casosExitoImage?.urls[0])"
                  alt="Casos de éxito" loading="lazy" height="282" width="361"/>
              </div>
              <div class="col-12 col-md-6 tvs-o-hidden">
                <div class="row px-3 tvs-home-casos-tarjeta-div-titulo">
                  <h1 class="tvs-text-orange my-3 tvs-home-casos-tarjeta-titulo">{{item.casosExitoTitulo}}</h1>
                </div>
                <div class="tvs-text-black my-3 small tvs-home-casos-tarjeta-div-texto"
                  [innerHTML]="getCasosText(item.casosExitoTextoE)"></div>
                <a class="btn btn-primary btn-orange tvs-btn mt-4 mb-3" href="{{item.casosExitoLink}}" target="_self"
                  *ngIf="item.casosExitoLink && item.casosExitoLink != '' && (item.casosExitoLink.includes('http') || item.casosExitoLink.includes('www') )">
                  Ver más<fa-icon [icon]="faArrowRight" class="pl-2"></fa-icon>
                </a>
                <a class="btn btn-primary btn-orange tvs-btn mt-4 mb-3" href="" (click)="navigateTo(item.casosExitoLink)"
                  *ngIf="item.casosExitoLink && item.casosExitoLink != '' && !item.casosExitoLink.includes('http') && !item.casosExitoLink.includes('www')">
                  Ver más<fa-icon [icon]="faArrowRight" class="pl-2"></fa-icon>
                </a>
              </div>
            </div>
          </div>
        </ng-container>

      </div>
    </div>
    <div class="col-12 py-5 text-center">
      <button id="homeBoton-casosDeExito" class="btn btn-primary btn-otange tvs-btn"
        (click)="navigateTo('futurecast')">Más casos de éxito<fa-icon [icon]="faPlus" class="pl-2"></fa-icon></button>
    </div>
  </div>
  <!--Contenidos (videos)-->
  <div class="row tvs-bg-white">
    <div class="col-12 px-2 px-md-5 tvs-home-programas">
      <div class="col-12 mt-5 pt-5">
        <h2 class="tvs-text-x-bold tvs-text-white tvs-home-titulos">Contenidos</h2>
      </div>
      <div class="col-12" *ngIf="!isVideosLoaded">
        <div class="row tvs-programas-unloaded">
          <img class="loader-img z-20" src="assets/images/website_general/loader-2.svg" alt="" />
        </div>
      </div>
      <div class="col-10 offset-1 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2  tvs-video-container-home mb-5 pb-5"
        *ngIf="isVideosLoaded">
        <owl-carousel-o class="white-dots" [options]="customOptionsVideo" (change)="pauseAllVideos()">
          <ng-container *ngFor="let item of videoCardData; let i = index;">
            <ng-template carouselSlide>
              <div class="tvs-jumbo-card-video">
                <div class="row">
                  <div class="col-12">
                    <video controls controlsList="nodownload" [muted]="'muted'" preload="none"
                      poster="{{baseCMSUrl.slice(0, -1) + item.videoThumb.urls[0]}}" playsinline
                      style="width: 100%; height: auto; object-fit: contain;background-color:#000;"
                      disablepictureinpicture [attr.id]="dynamicId('videoHome-',item.videoTitulo)" #video>
                      <source src="{{baseCMSUrl.slice(0, -1) + item.videoVideo.urls[0]}}" type="video/mp4">
                      Tu navegador no soporta la visualización de videos.
                    </video>
                  </div>
                </div>
              </div>
              <div class="col-12 my-3 text-center">
                <div class="titulo-videos-home w-100">
                  <h3 class="tvs-text-white tvs-text-bold">{{item.videoTitulo}}</h3>
                </div>
              </div>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>
    </div>
  </div>
  <!--Arma tu paquete-->
  <div class="row tvs-bg-white px-2 px-md-5 py-5">
    <div class="col-12 col-md-8 col-lg-5">
      <h2 class="tvs-text-x-bold tvs-text-orange pt-5 pb-4 mt-lg-5 tvs-home-titulos">Armamos tu paquete a la medida</h2>
      <p>Explora nuestra oferta y ponte en contacto con nosotros para hacerte una propuesta.</p>
      <div class="col-12 p-0 d-none d-lg-block pt-5">
        <a id="homeBoton-contacto" class="btn btn-primary btn-orange tvs-btn my-3" (click)="navigateTo('contacto')">
          Solicítalo aquí<fa-icon [icon]="faArrowRight" class="pl-2"></fa-icon>
        </a>
      </div>
    </div>
    <div class="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-1 px-5 pt-0 pb-5">
      <img class="img img-fluid z-10 tvs-img-w60" src="assets/images/website_general/ipad-televisa-llega-home.png"
        alt="Tablet con logo de Televisa" loading="lazy" width="382" height="436"/>
    </div>
    <div class="col-12 d-block d-lg-none">
      <a id="homeBoton-contacto" class="btn btn-primary btn-orange tvs-btn my-3" (click)="navigateTo('contacto')">
        Solicítalo aquí<fa-icon [icon]="faArrowRight" class="pl-2"></fa-icon>
      </a>
    </div>
  </div>
  <!--Modal-->
  <section class="col-12">
    <div class="tvs-modal" (click)="closeModalOutside()">
      <ng-container *ngFor="let item of modalHomeData; let i = index;">
        <!--Imagen-->
        <div class="w-100 tvs-modal-content text-center" *ngIf="tipoModal == 'imagen'">
          <div class="tvs-cerrar-modal c-pointer" (click)="closeModal()">
            <fa-icon [icon]="faTimes"></fa-icon>
          </div>
          <a class="c-pointer" href="{{item.linkModal}}" target="_self">
            <img class="img img-fluid tvs-modal-img" src="{{baseCMSUrl.slice(0, -1) + item.imagenModal.urls[0]}}"
              *ngIf="item.imagenModal && item.imagenModal != ''" />
            <img class="img img-fluid tvs-modal-img" src="assets/images/website_general/paquetes_default.png"
              *ngIf="!item.imagenModal || item.imagenModal == ''" />
          </a>
        </div>
        <!--video-->
        <div class="w-100 tvs-modal-content text-center" *ngIf="tipoModal == 'video'">
          <div class="tvs-jumbo-card-video-modal">
            <div class="row">
              <div class="col-12 col-md-10 offset-md-1 col-xl-8 offset-xl-2">
                <video controls autoplay controlsList="nodownload" preload="none" poster="none" playsinline
                  style="width: 100%; height: auto; object-fit: contain;background-color:#000;" disablepictureinpicture
                  id="videomodal">
                  <source src="{{baseCMSUrl.slice(0, -1) + item.imagenModal.urls[0]}}" type="video/mp4">
                  Tu navegador no soporta la visualización de videos.
                </video>
                <div class="tvs-cerrar-modal-video c-pointer" (click)="closeModal()">
                  <fa-icon [icon]="faTimes"></fa-icon>
                </div>
              </div>
              <div class="col-12 text-center mt-3" *ngIf="item.linkModal && item.linkModal != ''">
                <a class="btn btn-primary btn-pink tvs-btn" href="{{item.linkModal}}" target="_self">Ver más<fa-icon
                    [icon]="faPlus" class="pl-2"></fa-icon></a>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </section>
  <div class="fix_loading_carrusel">
    <ng-container *ngFor="let bannerlazy of newBannerHomeLazyLoading; let i = index;">
      <img src="{{baseCMSUrl.slice(0,-1) + bannerlazy.imagen.urls[0]}}" tvshomebannerLazyloader="1" width="500" height="500"/>
    </ng-container>
  </div>
</div>