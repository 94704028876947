import { Utilities } from '../services/utilities';
import { Catalogue } from './catalogue.model';

export class SearchData {

    p_tip_busqueda: string;
    p_dato_buscado: string;
    p_token_id: string;
    
    public static Create(data: {}) {
        const n = new SearchData();
        Object.assign(n, data);

        return n;
    }
}
