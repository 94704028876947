export class CustomOrder {
  
  static ordenaArregloPropiedadOrden(obj1 ,obj2 ){
    if(obj1.orden > obj2.orden) { return 1;}
    if(obj1.orden < obj2.orden) { return -1;}
    return 0;
  }

  private static daysOrder = [


    { dia: "INICIA", orden: -3 },
    { dia: "INICIO", orden: -2 },
    { dia: "ESTRENO", orden: -1 },
    { dia: "PRÓXIMAMENTE", orden: 0 },
    { dia: "LUNES A VIERNES", orden: 1 },
    { dia: "LUNES A JUEVES", orden: 2 },
    { dia: "LUNES, MARTES, JUEVES Y VIERNES", orden: 3 },
    { dia: "MIÉRCOLES Y SÁBADOS", orden: 4 },
    //{ dia: "SÁBADOS Y DOMINGOS", orden: 5 },
    { dia: "LUNES", orden: 5 },
    { dia: "MARTES", orden: 6 },
    { dia: "MIÉRCOLES", orden: 7 },
    { dia: "JUEVES", orden: 8 },
    { dia: "VIERNES", orden: 9 },
    { dia: "SÁBADO", orden: 10 },
    { dia: "SÁBADOS", orden: 10 },
    { dia: "SÁBADOS Y DOMINGOS", orden: 11 },
    { dia: "DOMINGO", orden: 12 },
    { dia: "DOMINGOS", orden: 12 },
    
  ];

  private static cangeDaysForNumbers(day:string){
    if (day && day.trim() !== '') {
      for(let i = 0; i < CustomOrder.daysOrder.length ; i++) {
        if(day.toUpperCase().includes(CustomOrder.daysOrder[i].dia))
        return CustomOrder.daysOrder[i].orden
      }
    }
    return 100;
  }

  private static changeHourForNumbers(hour){
    if (hour)
    {
      var horaInicio = hour.toUpperCase().split(" A ");
      var separado = horaInicio[0].split(":");
      var hora = separado[0];
      var separadoMinutos = separado[1]?.split(" ")?separado[1]?.split(" "):[""];
      var minutos = separadoMinutos.length>0?separadoMinutos[0]:"";
      var am_pm = separadoMinutos.length>1?separadoMinutos[1]:"";
      
      var horaNumerica = Number(hora);
      
      if(am_pm.toUpperCase().includes("P") && horaNumerica != 12)
      {
        horaNumerica = horaNumerica + 12.00;
      }

      if(!am_pm.toUpperCase().includes("P") && horaNumerica == 12)
      {
        horaNumerica = horaNumerica - 12.00;
      }
      
        
      if(isNaN(horaNumerica))
        {
          return 100;
        }
      else
        {
          if(!isNaN(minutos))
          {
            horaNumerica = horaNumerica + minutos/100
          }
          return horaNumerica;
        }
    }
		return 100;
  }

  private static OrdenaPorCanal(obj1 ,obj2 ){
    if(obj1.canalTarjetaContenido?.contentItems[0]?.platMediaId && obj2.canalTarjetaContenido?.contentItems[0]?.platMediaId){
      if(obj1.canalTarjetaContenido?.contentItems[0]?.platMediaId > obj2.canalTarjetaContenido?.contentItems[0]?.platMediaId) { return 1;}
      if(obj1.canalTarjetaContenido?.contentItems[0]?.platMediaId < obj2.canalTarjetaContenido?.contentItems[0]?.platMediaId) { return -1;}
      return 0;
    }
    else
    {
      return 0;
    }
    
  }

  private static OrdenaPorDiasPresentacion(obj1 ,obj2 ){
    if(CustomOrder.cangeDaysForNumbers(obj1.fechaTarjeta) > CustomOrder.cangeDaysForNumbers(obj2.fechaTarjeta)) { return 1;}
    if(CustomOrder.cangeDaysForNumbers(obj1.fechaTarjeta) < CustomOrder.cangeDaysForNumbers(obj2.fechaTarjeta)) { return -1;}
    return 0;
  }

  private static OrdenaPorHorarioPresentacion(obj1 ,obj2 ){
    if(CustomOrder.changeHourForNumbers(obj1.horaTarjeta) > CustomOrder.changeHourForNumbers(obj2.horaTarjeta)) { return 1;}
    if(CustomOrder.changeHourForNumbers(obj1.horaTarjeta) < CustomOrder.changeHourForNumbers(obj2.horaTarjeta)) { return -1;}
    return 0;
  }

  private static OrdenaPlataformas(obj1 ,obj2 ){
    if(obj1.idPlataformaTarjeta && obj2.idPlataformaTarjeta){
      if(Number(obj1.idPlataformaTarjeta) > Number(obj2.idPlataformaTarjeta)) { return 1;}
      if(Number(obj1.idPlataformaTarjeta) < Number(obj2.idPlataformaTarjeta)) { return -1;}
      return 0;
    }
    else
    {
      return 0;
    }
  }

  static ordenaNumOrden(obj1 ,obj2 ){
    if(obj1.numOrden && obj2.numOrden){
      if(Number(obj1.numOrden) > Number(obj2.numOrden)) { return 1;}
      if(Number(obj1.numOrden) < Number(obj2.numOrden)) { return -1;}
      return 0;
    }
    else
    {
      return 0;
    }
  }

  static ordenaProgramacion(obj1 ,obj2){

    var ordenPlataforma = CustomOrder.OrdenaPlataformas(obj1 ,obj2 )
    if( ordenPlataforma == 0 )
    {
      var ordenCanal = CustomOrder.OrdenaPorCanal(obj1 ,obj2 )
      if( ordenCanal == 0)
      {
        var ordenDia = CustomOrder.OrdenaPorDiasPresentacion(obj1 ,obj2 )
        if(ordenDia == 0)
        {
          return CustomOrder.OrdenaPorHorarioPresentacion(obj1 ,obj2 )
        }
        else
        {
          return ordenDia;
        }
      }
      else
      {
        return ordenCanal;
      }
    }
    else 
    {
      return ordenPlataforma;
    }
  }

}
