import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { gsap } from "gsap/all";
import { ConfigurationService } from 'src/app/services/configuration.service';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { ProductosPlancomercial } from 'src/app/models/cms/cms.plan.comercial.model';
import { DynamicHelper } from 'src/app/helpers/dynamic-helper';

@Component({
  selector: 'app-tarjeta-producto',
  templateUrl: './tarjeta-producto.component.html',
  styleUrls: ['./tarjeta-producto.component.scss']
})
export class TarjetaProductoComponent implements OnInit {

  faChevronRight = faChevronRight;

  @Input() cardData: ProductosPlancomercial;
  baseCMSUrl: string;

  constructor(
    public router: Router,
    private configuration: ConfigurationService
  ) {
    this.baseCMSUrl = this.configuration.baseCmsUrl
  }

  ngOnInit(): void {
    //TODO: This fix should be in backend
    this.cardData.descripcionTarjeta = this.cardData.descripcionTarjeta.replace("<p></p><li>", "<p></p><ul class='ulcard'><li>");
    this.cardData.descripcionTarjeta = this.cardData.descripcionTarjeta.replace("</li><p></p>", "</li></ul><p></p>");
    // console.log('productos', this.cardData);
  }

  public getCardImg(imagen: string) {
    if (!imagen)
      return imagen;

    var urlImagen: string = imagen.replace(/ /g, '%20');
    return urlImagen;
  }

  public navigateTo(path: string): void {
    this.router.navigateByUrl(path);
    gsap.to(window, {
      scrollTo: 0,
      duration: 0.5
    });
  }

  public friendlyUrl(nombre: string) {
    return DynamicHelper.friendlyUrlHelper(nombre);
  }

}
