<!--Bloques para detalles de caso de éxito-->

<!--Bloque 0 - Título + introducción + Imagen (Primer Contenedor)-->
<section class="w-100 mb-5 z-20" *ngIf="dataBlock.futureCastBlock.tipodeBloque == '0'">
    <div class="px-2 px-md-5">
      <div class="row">
        <div class="col-12 text-center">

          <img class="img-fluid text-center tvs-rounded-corner" src="{{baseCMSUrl.slice(0, -1) + dataBlock.futureCastBlock.casoImagen?.urls[0]}}" *ngIf="dataBlock.futureCastBlock.casoImagen && dataBlock.futureCastBlock.casoImagen.urls.length > 0" />
        </div>
      </div>
      <div class="row">
        <div class="col text-left">
          <h1 class="tvs-text-orange tvs-text-upper my-5" *ngIf="dataBlock.futureCastBlock.casoTitulo && dataBlock.futureCastBlock.casoTitulo != ''">{{dataBlock.futureCastBlock.casoTitulo}}</h1>
          <div class="col-12 px-0" style="white-space: pre-line" [innerHTML]="dataBlock.futureCastBlock.texto" *ngIf="dataBlock.futureCastBlock.texto && dataBlock.futureCastBlock.texto != ''">
          </div>
          <div class="col-12 px-0 tvs-text-black mt-5 tvs-casos-quote" style="white-space: pre-line; font-style:italic;" [innerHTML]="dataBlock.futureCastBlock.casoQuote" *ngIf="dataBlock.futureCastBlock.casoQuote && dataBlock.futureCastBlock.casoQuote != ''">
          </div>
        </div>
      </div>
    </div>
</section>

<!--Bloque 1 - Título + introducción + Imagen-->
<section class="w-100 mb-5 z-20" *ngIf="dataBlock.futureCastBlock.tipodeBloque == '1'">
    <div class="px-2 px-md-5">
      <div class="row">
        <div class="col-12 text-center">

          <img class="img-fluid text-center tvs-rounded-corner" src="{{baseCMSUrl.slice(0, -1) + dataBlock.futureCastBlock.casoImagen?.urls[0]}}" *ngIf="dataBlock.futureCastBlock.casoImagen && dataBlock.futureCastBlock.casoImagen.urls.length > 0" />
        </div>
      </div>
      <div class="row">
        <div class="col text-left">
          <h1 class="tvs-text-orange tvs-text-upper my-5" *ngIf="dataBlock.futureCastBlock.casoTitulo && dataBlock.futureCastBlock.casoTitulo != ''">{{dataBlock.futureCastBlock.casoTitulo}}</h1>
          <div class="col-12 px-0" style="white-space: pre-line" [innerHTML]="dataBlock.futureCastBlock.texto" *ngIf="dataBlock.futureCastBlock.texto">
          </div>
          <div class="col-12 px-0 tvs-text-black mt-5 tvs-casos-quote" style="white-space: pre-line; font-style: italic;" [innerHTML]="dataBlock.futureCastBlock.casoQuote" *ngIf="dataBlock.futureCastBlock.casoQuote && dataBlock.futureCastBlock.casoQuote != ''">
          </div>
        </div>
      </div>
    </div>
</section>

<!--Bloque 2 – Imagen Izquierda + Texto Derecha -->
<section class="w-100 mb-5 z-20" *ngIf="dataBlock.futureCastBlock.tipodeBloque == '2'">
    <div class="px-2 px-md-5">
      <div class="row">
        <div class="col-12 col-md-6 text-center">

          <img class="img-fluid text-center tvs-rounded-corner" src="{{baseCMSUrl.slice(0, -1) + dataBlock.futureCastBlock.casoImagen?.urls[0]}}" *ngIf="dataBlock.futureCastBlock.casoImagen" />
        </div>
        <div class="col-12 col-md-6 text-left">
          <h3 class="tvs-text-black tvs-text-upper mb-5" *ngIf="dataBlock.futureCastBlock.casoTitulo && dataBlock.futureCastBlock.casoTitulo != ''">{{dataBlock.futureCastBlock.casoTitulo}}</h3>
          <div class="col-12 px-0" style="white-space: pre-line" [innerHTML]="dataBlock.futureCastBlock.texto" *ngIf="dataBlock.futureCastBlock.texto && dataBlock.futureCastBlock.texto != ''"></div>
          <div class="col-12 px-0 tvs-text-black mt-5 tvs-casos-quote" style="white-space: pre-line; font-style: italic;" [innerHTML]="dataBlock.futureCastBlock.casoQuote" *ngIf="dataBlock.futureCastBlock.casoQuote && dataBlock.futureCastBlock.casoQuote != ''">
          </div>
        </div>
      </div>
    </div>
</section>

<!--Bloque 3 – Texto Izquierda + Imagen Derecha-->
<section class="w-100 mb-5 z-20" *ngIf="dataBlock.futureCastBlock.tipodeBloque == '3'">
    <div class="px-2 px-md-5">
      <div class="row">
        <div class="col-12 col-md-6 text-left">
          <h3 class="tvs-text-black tvs-text-upper mb-5" *ngIf="dataBlock.futureCastBlock.casoTitulo && dataBlock.futureCastBlock.casoTitulo != ''">{{dataBlock.futureCastBlock.casoTitulo}}</h3>
          <div class="col-12 px-0" style="white-space: pre-line" [innerHTML]="dataBlock.futureCastBlock.texto" *ngIf="dataBlock.futureCastBlock.texto && dataBlock.futureCastBlock.texto != ''"></div>
          <div class="col-12 px-0 tvs-text-black mt-5 tvs-casos-quote" style="white-space: pre-line; font-style: italic;" [innerHTML]="dataBlock.futureCastBlock.casoQuote" *ngIf="dataBlock.futureCastBlock.casoQuote && dataBlock.futureCastBlock.casoQuote != ''">
          </div>
        </div>
        <div class="col-12 col-md-6 text-center">

          <img class="img-fluid text-center tvs-rounded-corner" src="{{baseCMSUrl.slice(0, -1) + dataBlock.futureCastBlock.casoImagen?.urls[0]}}" *ngIf="dataBlock.futureCastBlock.casoImagen" />
        </div>
      </div>
    </div>
</section>

<!--Bloque 4 – Solo Imagen-->
<section class="w-100 mb-5 z-20" *ngIf="dataBlock.futureCastBlock.tipodeBloque == '4'">
    <div class="px-2 px-md-5">
      <div class="row">
        <div class="col-12 text-center">
          <img class="img-fluid text-center tvs-rounded-corner" src="{{baseCMSUrl.slice(0, -1) + dataBlock.futureCastBlock.casoImagen?.urls[0]}}" *ngIf="dataBlock.futureCastBlock.casoImagen" />
        </div>
      </div>
    </div>
</section>

<!--Bloque 5 – Título + Imagen Centro -->
<section class="w-100 mb-5 z-20" *ngIf="dataBlock.futureCastBlock.tipodeBloque == '5'">
    <div class="px-2 px-md-5">
      <div class="row">
        <div class="col-12 text-center">
          <h1 class="tvs-text-orange tvs-text-upper my-5" *ngIf="dataBlock.futureCastBlock.casoTitulo && dataBlock.futureCastBlock.casoTitulo != ''">{{dataBlock.futureCastBlock.casoTitulo}}</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center">
          <img class="img-fluid text-center tvs-rounded-corner" src="{{baseCMSUrl.slice(0, -1) + dataBlock.futureCastBlock.casoImagen?.urls[0]}}" *ngIf="dataBlock.futureCastBlock.casoImagen" />
        </div>
      </div>
    </div>
</section>

<!--Bloque 8 – Carrusel de Videos-->
<section class="w-100 mb-5 z-20" *ngIf="dataBlock.futureCastBlock.tipodeBloque == '8'">
    <div class="row">
      <div class="col-12 px-2 px-md-5 tvs-home-programas">
        <div class="col-12 my-5">&nbsp;</div>
        <div class="col-10 offset-1 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2  tvs-video-container-home mb-5 pb-5">
        <owl-carousel-o class="white-dots" [options]="customOptionsVideo" (change)="pauseAllVideos()">
          <ng-container *ngFor="let item of dataBlock.galeria.contentItems; let i = index;">
            <ng-template carouselSlide><!--*ngIf="i == 0"-->
              <div class="tvs-jumbo-card-video">
                <div class="row">
                  <div class="col-12">
                    <video controls controlsList="nodownload" [muted]="'muted'" preload="none"
                      poster="{{baseCMSUrl.slice(0, -1) + item?.imagenThumb?.urls[0]}}" playsinline
                      style="width: 100%; height: auto; object-fit: contain;background-color:#000;"
                      disablepictureinpicture
                      [attr.id]="dynamicId('videofuturecast-',i.toString())" #video>
                      <source src="{{baseCMSUrl.slice(0, -1) + item?.imagen?.urls[0]}}" type="video/mp4">
                      Tu navegador no soporta la visualización de videos.
                    </video> <!--autoplay-->
                  </div>
                </div>
                <div class="col-12 my-3">&nbsp;</div>
              </div>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>
      
      </div>
    </div>
</section>
  
<!--Bloque 9 – Botón de descarga-->
<section class="w-100 mb-5 z-20" *ngIf="dataBlock.futureCastBlock.tipodeBloque == '9'">
   <div class="px-2 px-md-5">
     <div class="row">
       <div class="col-12 col-md-10 offset-md-1 col-xl-8 offset-xl-2 text-center px-0">

         <a class="btn btn-primary btn-orange tvs-btn btn-block tvs-text-upper" href="{{dataBlock.futureCastBlock.linkBoton}}" target="_blank">{{dataBlock.futureCastBlock.textoBoton}}</a>

       </div>
     </div>
   </div>
</section>
