<ng-container *ngIf="!isBannerLoaded">
  <div class="contacto mb-5">
    <div class="row tvs-banner-unloaded">
      <img class="loader-img z-20" src="assets/images/website_general/loader-2.svg" alt="" />
    </div>
  </div>
</ng-container>
<ng-container *ngIf="isBannerLoaded">
  <app-header-image-new srcImage="{{bannerImage}}" srcImageMobile="{{bannerImageMobile}}" srcTitle="{{bannerTitle}}" srcTipo="4" class="contacto"></app-header-image-new>
</ng-container>


<div class="row tvs-bg-white px-2 px-md-5 py-5">
  <div class="col-12 my-4 px-4 pt-4">
    <h3 class="tvs-text-orange tvs-text-bold">Envíanos tus datos y un asesor te contactará</h3>
  </div>
  <div class="col-12">
    <form [formGroup]="aFormGroup" autocomplete="off">
      <div class="row mt-4">
        <div class="col-12 col-md-6 px-4">
          <div class="form-group">
            <label for="nombre">Nombre</label>
            <input class="form-control" type="text" formControlName="name" id="nombre" name="nombre" autocomplete="new-password" [ngClass]="{ 'is-invalid': submitted && f.name.errors }">
            <span class="invalid-feedback" *ngIf="submitted && !f.name.valid">
              Es obligatorio poner tu nombre
            </span>
          </div>
          <div class="form-group">
            <label for="surnames">Apellidos</label>
            <input class="form-control" type="text" formControlName="surnames" id="surnames" name="surnames" autocomplete="new-password" [ngClass]="{ 'is-invalid': submitted && f.surnames.errors }">
            <span class="invalid-feedback" *ngIf="submitted && !f.surnames.valid">
              Es obligatorio poner tus apellidos
            </span>
          </div>
          <div class="form-group">
            <label for="country">País</label>
            <select class="form-control mb-4" id="country" name="country" formControlName="country" [ngClass]="{ 'is-invalid': submitted && f.country.errors }">
              <option value="null" disabled="disabled" [selected]="true">Selecciona una opción</option>
              <option *ngFor="let pais of listaPaises" [ngValue]="pais.name">
                {{pais.name}}
              </option>
            </select>
            <span class="invalid-feedback" *ngIf="submitted && !f.country.valid">
              Es obligatorio seleccionar tu país
            </span>
          </div>
          <div class="form-group">
            <label for="compania">Compañía</label>
            <input class="form-control" type="text" formControlName="company" id="compania" name="compania" autocomplete="new-password" [ngClass]="{ 'is-invalid': submitted && f.company.errors }">
            <span class="invalid-feedback" *ngIf="submitted && !f.company.valid">
              Es obligatorio poner tu compañía
            </span>
          </div>
          <div class="form-group">
            <label for="puesto">Puesto</label>
            <input class="form-control" type="text" formControlName="company_position" id="puesto" name="puesto" autocomplete="new-password" [ngClass]="{ 'is-invalid': submitted && f.company_position.errors }">
          </div>
          <div class="form-group">
            <label for="email">Correo electrónico</label>
            <input class="form-control" type="text" formControlName="email" id="email" name="email" autocomplete="new-password" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
            <span class="invalid-feedback" *ngIf="submitted && !f.email.valid">
              Es obligatorio capturar tu correo electrónico
            </span>
          </div>
          <div class="form-group">
            <label for="telefono">Teléfono</label>
            <input class="form-control" type="text" formControlName="phone" id="telefono" name="telefono" aria-describedby="telefono" autocomplete="new-password" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }">
            <span class="invalid-feedback" *ngIf="submitted && !f.phone.valid">
              Es obligatorio capturar un teléfono de contacto
            </span>
          </div>
          <div class="form-group">
            <label class="form-control pl-0">Intereses:</label>
            <div class="form-check">
              <label class="form-check-label" for="tv_abierta">
                Televisión Radiodifundida
                <input class="form-check-input filter-check" type="checkbox" value="" id="tv_abierta" formControlName="tv_abierta">
                <span class="custom-check"></span>
              </label>
            </div>
            <div class="form-check">
              <label class="form-check-label" for="tv_paga">
                Televisa Networks
                <input class="form-check-input filter-check" type="checkbox" value="" id="tv_paga" formControlName="tv_paga">
                <span class="custom-check"></span>
              </label>
            </div>
            <div class="form-check">
              <label class="form-check-label" for="tv_regional">
                Televisa Regional
                <input class="form-check-input filter-check" type="checkbox" value="" id="tv_regional" formControlName="tv_regional">
                <span class="custom-check"></span>
              </label>
            </div>
            <div class="form-check">
              <label class="form-check-label" for="televisa_ads">
                Televisa Ads
                <input class="form-check-input filter-check" type="checkbox" value="" id="televisa_ads" formControlName="televisa_ads">
                <span class="custom-check"></span>
              </label>
            </div>
            <div class="form-check">
              <label class="form-check-label" for="izzi_sky">
                izzi + sky
                <input class="form-check-input filter-check" type="checkbox" value="" id="izzi_sky" formControlName="izzi_sky">
                <span class="custom-check"></span>
              </label>
            </div>
            <div class="form-check">
              <label class="form-check-label" for="editorial">
                Editorial Televisa
                <input class="form-check-input filter-check" type="checkbox" value="" id="editorial" formControlName="editorial">
                <span class="custom-check"></span>
              </label>
            </div>
            <div class="form-check">
              <label class="form-check-label" for="patrocinios">
                Patrocinios Deportivos
                <input class="form-check-input filter-check" type="checkbox" value="" id="patrocinios" formControlName="patrocinios">
                <span class="custom-check"></span>
              </label>
            </div>
            <div class="form-check">
              <label class="form-check-label" for="vix">
                ViX
                <input class="form-check-input filter-check" type="checkbox" value="" id="vix" formControlName="vix">
                <span class="custom-check"></span>
              </label>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 px-4">
          <div class="form-group">
            <label for="asunto">Asunto</label>
            <select class="form-control mb-4" id="asunto" formControlName="subject" name="asunto" [ngClass]="{ 'is-invalid': submitted && f.subject.errors }">
              <option value="null" disabled="disabled" [selected]="true">Selecciona una opción</option>
              <option>Publicidad / Ventas</option>
              <option>Otros</option>
            </select>
            <span class="invalid-feedback" *ngIf="submitted && !f.subject.valid">
              Es obligatorio seleccionar un asunto
            </span>
          </div>
          <div class="form-group">
            <label for="message">Mensaje</label>
            <textarea class="form-control mb-2" rows="8" placeholder="" formControlName="message" [ngClass]="{ 'is-invalid': submitted && f.message.errors }" maxlength="32000"></textarea>
            <span class="invalid-feedback mt-2" *ngIf="submitted && !f.message.valid">
              Es obligatorio ingresar un mensaje
            </span>
          </div>

          <div class="form-group">
            <div class="form-check mt-2">
              <label class="form-check-label" for="terms">
                Acepto los Términos y Condiciones del <a href="aviso-privacidad" target="_blank">Aviso de Privacidad</a>.
                <input class="form-check-input filter-check" type="checkbox" id="terms" formControlName="terms" [ngClass]="{ 'is-invalid': submitted && f.terms.errors }" />
                <span class="custom-check"></span>
              </label>
            </div>
          </div>
          <div class="form-group pt-4">
            <div class="recaptcha">
              <ngx-recaptcha2 #captchaElem
                              [siteKey]="siteKey"
                              (reset)="handleReset()"
                              (expire)="handleExpire()"
                              (load)="handleLoad()"
                              (success)="handleSuccess($event)"
                              [useGlobalDomain]="false"
                              formControlName="recaptcha">

              </ngx-recaptcha2>
            </div>
          </div>
          <div class="form-group my-5">
            <div class="row">
              <div class="col-12">
                <label>
                  Teléfono de contacto y WhatsApp <a id="link-whatsapp" href="https://wa.me/+525529720141" target="_blank">
                    <img class="tvs-rrss-icon-2 ml-2" src="assets/images/website_general/icono-whatsapp.svg" alt="WhatsApp" />
                  </a>
                </label><br />
                <label><a class="tvs-tel-link" href="tel:+525529720141">55 2972 0141</a></label><br />
                <label>Horario: Lunes a Viernes de 9 a 19 hrs. CST</label>
              </div>
              <div class="col-12 text-right mt-3">
                <button  type="button" class="btn btn-primary btn-orange tvs-btn" (click)="sendContactEmail()">
                  <span *ngIf="!is_loading">Enviar<fa-icon [icon]="faArrowRight" class="pl-2"></fa-icon></span>
                  <span *ngIf="is_loading">Cargando <img class="loader_gif_contact" src="assets/images/website_general/loader.gif" alt="" /></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </form>
  </div>
  <div class="col-12 mt-5 text-center d-none d-sm-block">
    <a id="contacto-directorio-comercial" class="btn btn-primary btn-orange tvs-btn my-5 tvs-padding-mobile" href="acerca-de#directorio-comercial">CONOCE NUESTRO DIRECTORIO COMERCIAL<fa-icon [icon]="faPlus" class="pl-2"></fa-icon></a>
  </div>
  <div class="col-12 mt-5 text-center d-block d-sm-none">
    <a id="contacto-directorio-comercial" class="btn btn-block btn-primary btn-orange tvs-btn my-5 tvs-padding-mobile" href="acerca-de#directorio-comercial">CONOCE NUESTRO DIRECTORIO COMERCIAL<fa-icon [icon]="faPlus" class="pl-2"></fa-icon></a>
  </div>
</div>
