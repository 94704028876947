export class ContactEmail {
    // Note: Using only optional constructor properties without backing store disables typescript's type checking for the type
    constructor() {
    }

    subject: string;
    name: string;
    surnames: string;
    country: string;
    company: string;
    email: string;
    phone: string;
    interests: string[];
    company_position: string;
    message: string;
}
