
//Modelos de Paquetes
export class DataPlanComercialPaquetes {
  paquetesPlancomercial: PaquetesPlancomercial[];
}

export class PaquetesPlancomercial {
  displayText: string;
  linkTarjeta?: any;
  nombreTarjeta?: string;
  tipoTarjeta?: string;
  imagenTarjeta?: ImagenTarjeta;
  imagenInternos?: ImagenTarjeta;
  orden?: number;
}

export class PlanComercialPaquetesReponse {
  data: DataPlanComercialPaquetes;
}

//Modelos para Productos
export class PlanComercialProductosResponse {
  data: DataPlanComercialProductos;
}

export class DataPlanComercialProductos {
  productosPlancomercial: ProductosPlancomercial[];
}

export class ProductosPlancomercial {
  displayText: string;
  nombreTarjeta: string;
  tipoTarjeta?: string;
  imagenTarjeta?: ImagenTarjeta;
  linkTarjeta?: ImagenTarjeta;
  linkProducto?: string;
  orden?: number;
  descripcionTarjeta?: string;
  plataformaAsociada?: PlataformaAsociada;
}

export class PlataformaAsociada {
  contentItems?: ContentItemPlataforma[];
}

export class ContentItemPlataforma {
  idPlataforma: number;
}

//Modelos para detalle producto
export class PlanComercialProductoDetalleResponse {
  data: DataPlanComercialProductoDetalle;
}
export class DataPlanComercialProductoDetalle {
  productoContenido: ProductoContenido[];
}
export class ProductoContenido {
  nombreProducto?: string;
  idProducto?: string;
  descripcion?: string;
  displayText?: string;
  subtituloInfografia?: string;
  textoInfografia?: string;
  tituloInfografia?: string;
  imagen?: ImagenTarjeta;
  imagenInfografia?: ImagenTarjeta;
  plataformaAsociada?: PlataformaAsociada;
  circuloProducto?: CirculoProducto;
  linkDestino?: string;
  published?: boolean;
}

export class CirculoProducto {
  contentItems?: CirculoProductoItems;
}
export class CirculoProductoItems {
  productoBlock?: CirculoProductoBlock;
}
export class CirculoProductoBlock {
  color?: CirculoProductoColor;
  titulo?: string;
  texto?: string;
  imagen?: ImagenTarjeta;
}
export class CirculoProductoColor {
  contentItems?: CirculoProductoColorItems;
}
export class CirculoProductoColorItems {
  valorHex?: string;
  nombre?: string;
}

//Modelos para Hero
export class PlanComercialHeroResponse {
  data: DataPlanComercialHero;
}

export class DataPlanComercialHero {
  bannersdePaquetes: BannersdePaquete[];
}

export class BannerImage {
  paths: string[];
  urls: string[];
}

export class ImagenBotonesAnt {
  paths: string[];
  urls: string[];
}

export class ImagenBotonesSig {
  paths: string[];
  urls: string[];
}

export class BannersdePaquete {
  displayText: string;
  bannerTitle: string;
  bannerImage: BannerImage;
  imagenMobile: BannerImage;
}

//Modelos para Pilares
export class PilaresResponse {
  data: DataPilares;
}

export class DataPilares {
  jumboCardPilares: JumboCardPilares[];
}

export class JumboCardPilares {
  pilaresTitulo: string;
  pilaresImage: PilaresImage;
  pilaresTexto1: string;
  pilaresTexto2?: any;
  pilaresQuote1?: any;
  pilaresQuote2?: any;
  pilaresLink?: any;
}

export class PilaresImage {
  urls: string[];
}

export class PaquetesDetalleResponse {
  data: PaquetesData;
}

export class PaquetesData {
  paquetes: Paquete[];
}

export class Paquete {
  author: string;
  paquetePadre: string;
  tarjetaPaquetes: Tarjeta;
}

export class Tarjeta {
  nombreTarjeta?: string;
  textoTarjeta?: string;
  tipoTarjeta?: string;
  verMasTarjeta?: string;
  cintilloTarjeta?: any;
  fechaTarjeta?: string;
  imagenTarjeta?: ImagenTarjeta;
  linkTarjeta?: LinkTarjeta;
  canalTarjeta?: string;
}

export class LinkTarjeta {
  paths: string[];
  urls: string[];
}

export class ImagenTarjeta {
  paths?: string[];
  urls?: string[];
}


export class AcercaDeResponse {
  data: AcercaDeData;
}

export class AcercaDeData {
  acercade: Acercade[];
}

export class Acercade {
  orden: number;
  contactoNombre: string;
  contactoPuesto: string;
  contactoTelefono: string;
  contentItemId: string;
}

/* Videos ViX productos televisa Ads */
export class VideosProductosResponse {
  data: VideosProductosResponseData;
}
export class VideosProductosResponseData {
  videosPlanComercial: VideosPlanComercial[];
}
export class VideosPlanComercial {
  displayText: string;
  orden: string;
  producto: Producto;
  eventoVideo?: EventoVideo;
}
export class Producto {
  contentItems?: ProductoData[];
}
export class ProductoData {
  orden: string;
  displayText: string;
}
export class EventoVideo {
  video?: Video;
  thumbnail?: Thumbnail;
}
export class Video {
  urls?: string[];
}
export class Thumbnail {
  urls?: string[];
}
