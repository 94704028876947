import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { EndpointBase } from './endpoint-base.service';
import { ConfigurationService } from './configuration.service';

@Injectable()
export class SearchEndpoint extends EndpointBase {

  get autocompleteUrl() { return this.configurations.baseUrl + '/api/Search/autocomplete'; }
  get autocompleteSignInUrl() { return this.configurations.baseUrl + '/api/Search/autocomplete-signin'; }
  get searchUrl() { return this.configurations.baseUrl + '/api/Search/search-data'; }
  get searchTestUrl() { return this.configurations.baseUrl + '/api/Search/search-test-data'; }
  get countViewedProfileUrl() { return this.configurations.baseUrl + '/api/Search/insert-log'; }
  
  
  
  constructor(private configurations: ConfigurationService, http: HttpClient, authService: AuthService) {
    super(http, authService);
  }

  getAutocompleteEndpoint<T>(term?: string, language?: string): Observable<T> {
    const endpointUrl = term && language ? `${this.autocompleteUrl}/${term}/${language}` : this.autocompleteUrl;

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getAutocompleteEndpoint(term, language));
      }));
  }

  getAutocompleteSignInEndpoint<T>(type?:string, term?: string): Observable<T> {
    const endpointUrl = term ? `${this.autocompleteSignInUrl}/${type}/${term}` : this.autocompleteSignInUrl;

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getAutocompleteSignInEndpoint(type,term));
      }));
  }

  getSearchResultsEndpoint<T>(searchDataObject: any): Observable<T> {

    //console.log(JSON.stringify(searchDataObject));
    return this.http.post<T>(this.searchUrl, JSON.stringify(searchDataObject), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getSearchResultsEndpoint(searchDataObject));
      }));
  }

  getSearchTestResultsEndpoint<T>(searchDataObject: any): Observable<T> {

    //console.log(JSON.stringify(searchDataObject));
    return this.http.post<T>(this.searchTestUrl, JSON.stringify(searchDataObject), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getSearchResultsEndpoint(searchDataObject));
      }));
  }

  updateCountProfileAsViewedEndpoint<T>(viewedProfile: any): Observable<T> {
    //console.log(JSON.stringify(viewedProfile));
    return this.http.post<T>(this.countViewedProfileUrl, JSON.stringify(viewedProfile), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.updateCountProfileAsViewedEndpoint(viewedProfile));
      }));
  }

}
