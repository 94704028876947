<!--No encontrado-->
<div class="row tvs-bg-white pb-5 tvs-producto-top" *ngIf="plataforma == 'No encontrado'">
  <div class="container container-80 ">
    <app-custom-breadcrumbs [breadcrumbsData]="breadcrumbsData"></app-custom-breadcrumbs>
  </div>
  <div class="container container-80 pb-5 tvs-bg-white no-scale">
    <div class="row mt-5">
      <div class="col-12 text-center">
        <h1 class="tvs-text-orange my-5">404 - NO ENCONTRADO</h1>
        <h3 class="tvs-text-black my-5">LA PÁGINA QUE BUSCAS NO EXISTE</h3>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-12 text-center">
        <button class="btn btn-secondary tvs-btn" (click)="goBack()">REGRESAR</button>
      </div>
    </div>
  </div>
</div>

<div class="row tvs-bg-white pb-5 tvs-plataforma-top px-2 px-sm-3 px-md-0 px-lg-4" *ngIf="content">
  <app-custom-breadcrumbs [breadcrumbsData]="breadcrumbsData" *ngIf="plataforma !== 'No encontrado'"></app-custom-breadcrumbs>
  <div class="tvs-plataforma-backlogo">
    <div class="tvs-plataforma-backlogo-up"></div>
    <div class="tvs-plataforma-backlogo-down"></div>
  </div>
  <!--Texto por plataforma-->
  <div class="container-fluid pt-5 px-2 px-md-5 tvs-bg-white">
    <ng-container *ngIf="!isCanalesLoaded">
      <div class="loader-min">
        <img class="loader-img z-20" src="assets/images/website_general/loader-2.svg" alt="" />
      </div>
    </ng-container>
    <ng-container *ngIf="isCanalesLoaded">
      <!--Introducción-->
      <div class="row mb-5 pt-5">
        <div class="col-12 col-md-7 col-lg-6 col-xl-5 text-left">
          <div class="float-left tvs-plataforma-sidelogo mr-3">
            <div class="tvs-plataforma-sidelogo-up"></div>
            <div class="tvs-plataforma-sidelogo-down"></div>
          </div>
          <h4 class="tvs-text-upper tvs-plataforma-contenidos-{{plataformaId}} mb-0">Contenidos</h4>
          <h1 class="tvs-text-orange tvs-plataforma-tituloplat">{{plataformaTitle}}</h1>
        </div>
        <div class="col-12 col-md-5 col-lg-6 col-xl-5 text-left">
          <p class="tvs-descripcion-general">{{plataformaText}}</p>
        </div>
      </div>
      <!--CTA's Regional-->
      <section class="row mb-5" *ngIf="plataforma  == '3'">
        <div class="col-12 col-md-6 col-lg-5 col-xl-4 text-center">
          <a id="boton-parrillas-regional" class="btn btn-primary btn-orange tvs-btn btn-block" href="{{urlParrillaDescargable}}" target="_blank">Descarga las parrillas del mes</a>
        </div>
        <div class="col-12 col-md-6 col-lg-5 col-xl-4 mt-3 mt-md-0 text-center">
          <a id="boton-canales-regional" class="btn btn-primary btn-orange tvs-btn btn-block " href="nuestra-oferta/plataforma/canales-tvregional">Conoce los canales regionales</a>
        </div>
      </section>
      <!--Búsqueda por plataforma-->
      <div class="row d-none d-sm-flex mb-5">
        <div class="col-12">
          <h4 class="tvs-text-orange">Búsqueda por plataforma:</h4>
        </div>
        <div class="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3 pos-relative mt-3 mx-0 pl-0 text-center">
          <div class="row ml-3 tvs-combo-plat" (click)="togglePlataformas()">
            <span>{{plataformaTitle}} <fa-icon [icon]="faChevronDown" class="pl-2 tvs-text-gray" *ngIf="!isPlataformasOpen"></fa-icon><fa-icon [icon]="faChevronUp" class="pl-2 tvs-text-gray" *ngIf="isPlataformasOpen"></fa-icon></span>
          </div>
          <div class="tvs-combo-plat-menu">
            <ul>
              <ng-container *ngFor="let plataformas of plataformasLinks; let i = index">
                <!--Todos, excepto izzi+sky y patrocinios deportivos-->
                <li *ngIf="plataformas.idPlataforma !== 6 && plataformas.idPlataforma !== 7">
                  <span (click)="navigateTo('nuestra-oferta/plataforma/' + friendlyUrl(plataformas.displayText))">{{plataformas.displayText}}</span>
                </li>
                <!--excepción para izzi+sky -->
                <li *ngIf="plataformas.idPlataforma == 7">
                  <span (click)="navigateToExternal(linkIzziSky)">{{plataformas.displayText}}</span>
                </li>
              </ng-container>
            </ul>
          </div>
        </div>
        <div class="d-none d-md-block col-md-2 col-lg-4 col-xl-6">&nbsp;</div>
        <div class="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3  pos-relative mt-3 mx-0 pr-0 text-center">
          <button class="btn btn-primary btn-orange tvs-btn btn-block" (click)="navigateTo('nuestra-oferta/generos')">Búsqueda por género</button>
        </div>
      </div>
      <!--Canales (excepto estadio azteca)-->
      <div class="row d-none d-sm-flex mb-5">
        <ng-container *ngIf="plataforma != '6'">
          <div class="col-sm-2 col-xl-1">
            <h4 *ngIf="plataforma == '1' || plataforma == '2'" class="tvs-text-orange">Canales</h4>
            <h4 *ngIf="plataforma == '3'" class="tvs-text-orange">Regiones</h4>
            <h4 *ngIf="plataforma == '4'" class="tvs-text-orange">Digital</h4>
            <h4 *ngIf="plataforma == '5'" class="tvs-text-orange">Revistas</h4>
          </div>
          <div class="col-sm-10 col-xl-11">
            <div class="row tvs-plat-topdiv">
              <ng-container *ngIf="plataforma == '1'">
                <div class="col-2 col-sm-5 col-md-3 col-lg-2 col-xxl-1 p-0 text-center tvs-boton-canal-img">
                  <a [attr.id]="dynamicId('botonCanal-','todos')" (click)="canalesTodos()">
                    <img class="img-fluid" src="assets/images/carga_cms/plataformas/todos-los-canales.jpg" alt="" />
                  </a>
                </div>
                <div class="form-check col-2 col-sm-5 col-md-3 col-lg-2 col-xxl-1 form-check-canal" *ngFor="let canal of catCanalesRadiodif">
                  <div class="form-check-label-canal">
                    <input class="form-check-input filter-check" type="checkbox" value="{{canal.id}}" id="canal_{{canal.id}}" [checked]='isCanalChecked(canal.id)' (change)="changeFilterCanales(canal.id,$event)">

                    <div class="custom-check-canal">
                      <img class="img-fluid" src="{{canal.imagen}}" alt="" />
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="plataforma == '2'">
                <div class="col-2 col-sm-5 col-md-3 col-lg-2 col-xxl-1 p-0 text-center tvs-boton-canal-img">
                  <a [attr.id]="dynamicId('botonCanal-','todos')" (click)="canalesTodos()">
                    <img class="img-fluid" src="assets/images/carga_cms/plataformas/todos-los-canales.jpg" alt="" />
                  </a>
                </div>
                <div class="form-check col-2 col-sm-5 col-md-3 col-lg-2 col-xxl-1 form-check-canal" *ngFor="let canal of catCanalesPaga">
                  <div class="form-check-label-canal">
                    <input class="form-check-input filter-check" type="checkbox" value="{{canal.id}}" id="canal_{{canal.id}}" [checked]='isCanalChecked(canal.id)' (change)="changeFilterCanales(canal.id,$event)">

                    <div class="custom-check-canal">
                      <img class="img-fluid" src="{{canal.imagen}}" alt="" />
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="plataforma == '3'">
                <div class="col-2 col-sm-5 col-md-3 col-lg-2 col-xxl-1 p-0 text-center tvs-boton-canal">
                  <div class="small text-center" [attr.id]="dynamicId('botonCanal-','todos')" (click)="canalesTodos()">Ver todas las regiones</div>
                </div>
                <div class="form-check col-2 col-sm-5 col-md-3 col-lg-2 col-xxl-1 form-check-canal" *ngFor="let canal of catCanalesRegional">
                  <div class="form-check-label-canal">
                    <input class="form-check-input filter-check" type="checkbox" value="{{canal.id}}" id="canal_{{canal.id}}" [checked]='isCanalChecked(canal.id)' (change)="changeFilterCanales(canal.id,$event)">

                    <div class="custom-check-canal">
                      <div class="small tvs-text-upper">{{canal.value}}</div>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="plataforma == '4'">
                <div class="col-2 col-sm-5 col-md-3 col-lg-2 col-xxl-1 p-0 text-center tvs-boton-canal-img">
                  <a [attr.id]="dynamicId('botonCanal-','todos')" (click)="canalesTodos()">
                    <img class="img-fluid" src="assets/images/carga_cms/plataformas/todos-los-medios.jpg" alt="" />
                  </a>
                </div>
                <div class="form-check col-2 col-sm-5 col-md-3 col-lg-2 col-xxl-1 form-check-canal" *ngFor="let canal of catDigital">
                  <div class="form-check-label-canal">
                    <input class="form-check-input filter-check" type="checkbox" value="{{canal.id}}" id="canal_{{canal.id}}" [checked]='isCanalChecked(canal.id)' (change)="changeFilterCanales(canal.id,$event)">

                    <div class="custom-check-canal">
                      <img class="img-fluid" src="{{canal.imagen}}" alt="" />
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="plataforma == '5'">
                <div class="col-2 col-sm-5 col-md-3 col-lg-2 col-xxl-1 p-0 text-center tvs-boton-canal-img">
                  <a [attr.id]="dynamicId('botonCanal-','todos')" (click)="canalesTodos()">
                    <img class="img-fluid" src="assets/images/carga_cms/plataformas/todas-las-revistas.jpg" alt="" />
                  </a>
                </div>
                <div class="form-check col-2 col-sm-5 col-md-3 col-lg-2 col-xxl-1 form-check-canal" *ngFor="let canal of catEditorial">
                  <div class="form-check-label-canal">
                    <input class="form-check-input filter-check" type="checkbox" value="{{canal.id}}" id="canal_{{canal.id}}" [checked]='isCanalChecked(canal.id)' (change)="changeFilterCanales(canal.id,$event)">

                    <div class="custom-check-canal">
                      <img class="img-fluid" src="{{canal.imagen}}" alt="" />
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </div>
      <!--Géneros-->
      <div class="row d-none d-sm-flex mb-5">
        <ng-container *ngIf="plataforma != '6'">
          <div class="col-sm-2 col-xl-1">
            <h4 class="tvs-text-orange">Géneros</h4>
          </div>
          <div class="col-sm-10 col-xl-11">
            <div class="row tvs-plat-topdiv">
              <ng-container *ngIf="fltGeneros">
                <div class="form-check col-2 col-sm-5 col-md-3 col-lg-3 col-xl-2 form-check-genero">
                  <div class="form-check-label-genero">
                    <input class="form-check-input filter-check" type="radio" value="0" id="genero_todos" name="Grupo_Generos" [checked]='isGeneroTodosSelected()' (change)="changeFilterGeneros('0',$event)">
                    <div class="custom-check-genero">
                      <div class="small text-center">Todos</div>
                    </div>
                  </div>
                </div>
                <div class="form-check col-2 col-sm-5 col-md-3 col-lg-3 col-xl-2 form-check-genero" *ngFor="let genero of catGeneros">
                  <div class="form-check-label-genero">
                    <input class="form-check-input filter-check" type="radio" value="{{genero.id}}" id="genero_{{genero.id}}" name="Grupo_Generos" [checked]='isGeneroChecked(genero.id)' (change)="changeFilterGeneros(genero.id,$event)">
                    <div class="custom-check-genero">
                      <div class="small text-center">{{genero.value}}</div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </div>

      <!--Filtros mobile botón-->
      <div class="row d-flex d-sm-none mb-5">
        <ng-container>
          <div class="col-8 offset-2">
            <button id="filtro-mobile" class="btn btn-primary btn-orange tvs-btn btn-block" (click)="openFilter()"><fa-icon [icon]="faFilter" class="pr-1 tvs-text-white"></fa-icon>Filtrar</button>
          </div>
        </ng-container>
      </div>

      <!--filtros mobile-->
      <div class="tvs-mobile-filter">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 text-right">
              <button class="btn btn-link tvs-close-filter tvs-text-white" (click)="closeFilter()">Cerrar<fa-icon [icon]="faTimes" class="pl-1 tvs-text-white"></fa-icon></button>
            </div>
            <div class="col-12 pt-5">
              <ul class="tvs-mobile-filter-list">
                <!--filtros plataformas-->
                <li class="tvs-text-white tvs-mobile-filter-sub tvs-mobile-filter-plat">
                  <div class="col-12" (click)="toggleMobilePlataforma()">Plataforma <fa-icon [icon]="faPlus" class="float-right tvs-text-white" *ngIf="!isPlataformaMobileOpen"></fa-icon><fa-icon [icon]="faMinus" class="float-right tvs-text-white" *ngIf="isPlataformaMobileOpen"></fa-icon></div>
                  <ul class="tvs-mobile-plataforma-list my-4">
                    <ng-container *ngFor="let plataformas of plataformasLinks; let i = index">
                      <li *ngIf="plataformas.idPlataforma !== 6 && plataformas.idPlataforma !== 7">
                        <span class="tvs-mobile-plataforma-check" [ngClass]="{'active': plataformaId == plataformas.idPlataforma }" (click)="navigateTo('nuestra-oferta/plataforma/' + friendlyUrl(plataformas.displayText))">{{plataformas.displayText}}</span>
                      </li>
                      <li *ngIf="plataformas.idPlataforma == 7">
                        <span class="tvs-mobile-plataforma-check" [ngClass]="{'active': plataformaId == plataformas.idPlataforma }" (click)="navigateToExternal(linkIzziSky)">{{plataformas.displayText}}</span>
                      </li>
                    </ng-container>
                  </ul>
                </li>
                <!--filtros canales-->
                <li class="tvs-text-white tvs-mobile-filter-sub tvs-mobile-filter-can" *ngIf="plataforma !== '6'">
                  <div *ngIf="plataforma == '1' || plataforma == '2'" class="col-12" (click)="toggleMobileCanales()">Canales <fa-icon [icon]="faPlus" class="float-right tvs-text-white" *ngIf="!isCanalesMobileOpen"></fa-icon><fa-icon [icon]="faMinus" class="float-right tvs-text-white" *ngIf="isCanalesMobileOpen"></fa-icon></div>
                  <div *ngIf="plataforma == '3'" class="col-12" (click)="toggleMobileCanales()">Regiones <fa-icon [icon]="faPlus" class="float-right tvs-text-white" *ngIf="!isCanalesMobileOpen"></fa-icon><fa-icon [icon]="faMinus" class="float-right tvs-text-white" *ngIf="isCanalesMobileOpen"></fa-icon></div>
                  <div *ngIf="plataforma == '4'" class="col-12" (click)="toggleMobileCanales()">Digital <fa-icon [icon]="faPlus" class="float-right tvs-text-white" *ngIf="!isCanalesMobileOpen"></fa-icon><fa-icon [icon]="faMinus" class="float-right tvs-text-white" *ngIf="isCanalesMobileOpen"></fa-icon></div>
                  <div *ngIf="plataforma == '5'" class="col-12" (click)="toggleMobileCanales()">Revistas <fa-icon [icon]="faPlus" class="float-right tvs-text-white" *ngIf="!isCanalesMobileOpen"></fa-icon><fa-icon [icon]="faMinus" class="float-right tvs-text-white" *ngIf="isCanalesMobileOpen"></fa-icon></div>
                  <div class="col-12">
                    <div class="row p-0 my-4">
                      <ng-container *ngIf="plataforma == '1'">
                        <div class="col-3 p-0 text-center tvs-boton-canal">
                          <div class="small text-center" [attr.id]="dynamicId('botonCanal-','todos')" (click)="canalesTodos()">Todos los canales</div>
                        </div>
                        <div class="form-check col-3 form-check-canal" *ngFor="let canal of catCanalesRadiodif">
                          <div class="form-check-label-canal">
                            <input class="form-check-input filter-check" type="checkbox" value="{{canal.id}}" id="canal_{{canal.id}}" [checked]='isCanalChecked(canal.id)' (change)="changeFilterCanales(canal.id,$event)">
                            <div class="custom-check-canal">
                              <img class="img-fluid" src="{{canal.imagen}}" alt="" />
                            </div>
                          </div>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="plataforma == '2'">
                        <div class="col-3 p-0 text-center tvs-boton-canal">
                          <div class="small text-center" [attr.id]="dynamicId('botonCanal-','todos')" (click)="canalesTodos()">Todos los canales</div>
                        </div>
                        <div class="form-check col-3 form-check-canal" *ngFor="let canal of catCanalesPaga">
                          <div class="form-check-label-canal">
                            <input class="form-check-input filter-check" type="checkbox" value="{{canal.id}}" id="canal_{{canal.id}}" [checked]='isCanalChecked(canal.id)' (change)="changeFilterCanales(canal.id,$event)">
                            <div class="custom-check-canal">
                              <img class="img-fluid" src="{{canal.imagen}}" alt="" />
                            </div>
                          </div>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="plataforma == '3'">
                        <div class="col-5 p-0 text-center tvs-boton-canal">
                          <div class="text-center" [attr.id]="dynamicId('botonCanal-','todos')" (click)="canalesTodos()">Ver todas las regiones</div>
                        </div>
                        <div class="form-check col-5 form-check-canal" *ngFor="let canal of catCanalesRegional">
                          <div class="form-check-label-canal">
                            <input class="form-check-input filter-check" type="checkbox" value="{{canal.id}}" id="canal_{{canal.id}}" [checked]='isCanalChecked(canal.id)' (change)="changeFilterCanales(canal.id,$event)">
                            <div class="custom-check-canal">
                              <div class="small tvs-text-upper">{{canal.value}}</div>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="plataforma == '4'">
                        <div class="col-3 p-0 text-center tvs-boton-canal">
                          <div class="small text-center" [attr.id]="dynamicId('botonCanal-','todos')" (click)="canalesTodos()">Todos los medios</div>
                        </div>
                        <div class="form-check col-3 form-check-canal" *ngFor="let canal of catDigital">
                          <div class="form-check-label-canal">
                            <input class="form-check-input filter-check" type="checkbox" value="{{canal.id}}" id="canal_{{canal.id}}" [checked]='isCanalChecked(canal.id)' (change)="changeFilterCanales(canal.id,$event)">
                            <div class="custom-check-canal">
                              <img class="img-fluid" src="{{canal.imagen}}" alt="" />
                            </div>
                          </div>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="plataforma == '5'">
                        <div class="col-3 p-0 text-center tvs-boton-canal">
                          <div class="small text-center" [attr.id]="dynamicId('botonCanal-','todos')" (click)="canalesTodos()">Todas las revistas</div>
                        </div>
                        <div class="form-check col-3 form-check-canal" *ngFor="let canal of catEditorial">
                          <div class="form-check-label-canal">
                            <input class="form-check-input filter-check" type="checkbox" value="{{canal.id}}" id="canal_{{canal.id}}" [checked]='isCanalChecked(canal.id)' (change)="changeFilterCanales(canal.id,$event)">
                            <div class="custom-check-canal">
                              <img class="img-fluid" src="{{canal.imagen}}" alt="" />
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </li>
                <!--filtros géneros-->
                <li class="tvs-text-white tvs-mobile-filter-sub tvs-mobile-filter-gen" *ngIf="plataforma != '6'">
                  <div class="col-12" (click)="toggleMobileGeneros()">Géneros <fa-icon [icon]="faPlus" class="float-right tvs-text-white" *ngIf="!isGenerosMobileOpen"></fa-icon><fa-icon [icon]="faMinus" class="float-right tvs-text-white" *ngIf="isGenerosMobileOpen"></fa-icon></div>
                  <div class="col-12 p-0 my-4">
                    <ng-container *ngIf="fltGeneros">
                      <div class="form-check col-12 form-check-genero">
                        <div class="form-check-label-genero">
                          <input class="form-check-input filter-check" type="radio" value="0" id="genero_todos" [checked]='isGeneroChecked("0")' (change)="changeFilterGeneros('0',$event)">
                          <div class="custom-check-genero">
                            <div class="small">Todos</div>
                          </div>
                        </div>
                      </div>
                      <div class="form-check col-12 form-check-genero" *ngFor="let genero of catGeneros">
                        <div class="form-check-label-genero">
                          <input class="form-check-input filter-check" type="radio" value="{{genero.id}}" id="genero_{{genero.id}}" [checked]='isGeneroChecked(genero.id)' (change)="changeFilterGeneros(genero.id,$event)">
                          <div class="custom-check-genero">
                            <div class="small">{{genero.value}}</div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </li>
                <!--Limpiar filtros-->
                <li class="tvs-text-white tvs-mobile-filter-sub tvs-mobile-filter-clear text-right">
                  <button class="btn btn-link tvs-clear-filter tvs-text-white" (click)="clearFilter()">Limpiar<fa-icon [icon]="faMinus" class="pl-1 tvs-text-white"></fa-icon></button>
                </li>
              </ul>
            </div>
            <!--buscar por género-->
            <div class="col-12 mt-3">
              <button id="buscar-genero-mobile" class="btn btn-block btn-primary btn-yellow tvs-btn" (click)="navigateTo('nuestra-oferta/generos')">Búsqueda por género</button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <!--Contenidos-->

  <div class="container-fluid pt-5 px-3 px-md-5 tvs-bg-white">
    <div class="row mb-5" *ngIf="plataforma  !== '6'">
      <!--Cuadrícula-->
      <div class="col-12">
        <div class="row card-{{screenRes}}">
          <ng-container *ngIf="!isProgramasLoaded">
            <div class="loader-min">
              <img class="loader-img z-20" src="assets/images/website_general/loader-2.svg" alt="" />
            </div>
          </ng-container>
          <ng-container *ngIf="isProgramasLoaded">
            <div *ngFor="let datosTarjeta of datosProgramacion; let i = index;" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xxl-2 px-1 tvs-card-dynamic-color">
              <a [attr.id]="dynamicId('tarjetaProgPlataforma-', datosTarjeta.nombreTarjeta)"
                 class="tarjetaProgPlataforma c-pointer"
                 (click)="goToProgram(datosTarjeta.linkTarjeta, datosTarjeta.nombreTarjeta , datosTarjeta.cintilloTarjeta, datosTarjeta.canalTarjetaContenido.contentItems[0].platNombre, datosTarjeta.genero.contentItems[0].generoTarjeta, datosTarjeta.fechaTarjeta, datosTarjeta.horaTarjeta)">
                <app-tarjeta-programa class="c-pointer" [cardData]="datosTarjeta"></app-tarjeta-programa>
              </a>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <!-- CTA -->
  <div class="container-fluid px-3 px-md-5 tvs-bg-white" *ngIf="isCtaLoaded">
    <div class="row px-0 pl-md-3 tvs-cta-bg tvs-cta-bg-{{plataforma}}">
      
      <div class="py-4 col-12 col-md-5 col-xl-4">
      
        <div class="row">
          <div class="col-12 pr-0">
            <div class="float-left tvs-plataforma-sidelogo-cta mr-3">
              <div class="tvs-plataforma-sidelogo-up-cta"></div>
              <div class="tvs-plataforma-sidelogo-down-cta"></div>
            </div>
      
            <h4 class="tvs-text-upper mb-0 tvs-cta-plan tvs-text-cta-1" *ngIf="plataforma == '1' || plataforma == '4'">Plan
              Comercial</h4>
            <h4 class="tvs-text-upper mb-0 tvs-cta-plan tvs-text-cta-2"
              *ngIf="plataforma == '2' || plataforma == '3' || plataforma == '5'">Plan Comercial</h4>
            <h1 class="tvs-text-white tvs-cta-titulo">{{plataformaTitle}}</h1>
          </div>
        </div>
        <div class="row">
          <div class="col-12 pr-0">
            <p class="tvs-text-white my-3 d-block d-xl-none">
              {{ctaText}}
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-center mt-2">
            <a id="tvs-cta-plataformas" class="btn btn-primary btn-yellow tvs-btn d-inline-block d-xl-none"
              (click)="navigateTo('plan-comercial/productos/' + platNombre)">Conoce más <fa-icon [icon]="faPlus"
                class="pl-2"></fa-icon></a>
          </div>
        </div>
      
      </div>

      <div class="py-5 col-12 col-xl-3 d-none d-xl-block text-center">
        <p class="tvs-text-white mt-4 mb-5">
          {{ctaText}}
        </p>
        <a id="tvs-cta-plataformas" class="btn btn-primary btn-yellow tvs-btn" (click)="navigateTo('plan-comercial/productos/' + platNombre)">Conoce más <fa-icon [icon]="faPlus" class="pl-2"></fa-icon></a>
      </div>

      <div class="px-0 col-12 col-md-7 col-xl-5 tvs-cta-img-container text-center">
        <img class="img-fluid mt-md-0" src="{{ctaImg}}" alt="" />

      </div>
    </div>
  </div>

</div>






