import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { GtmService } from 'ngx-gtm';
import { gsap } from "gsap/all";

@Component({
  selector: 'app-aviso-privacidad',
  templateUrl: './aviso-privacidad.component.html',
  styleUrls: ['./aviso-privacidad.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvisoPrivacidadComponent implements OnInit {

  title: string = 'Aviso de Privacidad';

  currentURL: string;

  bannerImage: string;
  bannerTitle: string;

  /*Loaders*/
  isBannerLoaded: boolean = false;

  constructor(
    private gtm: GtmService,
    private cdr: ChangeDetectorRef,
    private titleService: Title,
    private metaService: Meta,
    public router: Router,
  ) {
    this.getBannerImage();
    this.bannerTitle = 'Aviso de Privacidad';
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentURL = event.url;
        if (this.currentURL.includes('cookies')) {
          gsap.to(window,
            {
              duration: 1,
              scrollTo: "#cookies"
            }
          );
        }
      }
    });
  }

  ngOnInit(): void {
    this.gtm.push({ event: 'TelevisaLlega Aviso de Privacidad' });

    this.titleService.setTitle(this.title);
    this.metaService.removeTag("name = 'keywords'");
    this.metaService.removeTag("name = 'description'");
    this.metaService.addTags([
      { name: 'robots', content: 'noindex, nofollow, noodp' }
    ]);
  }

  public getBannerImage() {
    this.bannerImage = '';
    this.isBannerLoaded = true;
    this.cdr.markForCheck();
  }

}
