import { Component, OnInit, OnDestroy, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, ViewChildren, QueryList } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CmsHomeService } from '../../../services/cms/cms.home.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { gsap, ScrollTrigger, ScrollToPlugin, Draggable } from "gsap/all";
import { GtmService } from 'ngx-gtm';
import { MatVideoComponent } from 'mat-video/lib/video.component';
import { BannerHome, CarrouselVideosHome, CasosdeexitoHome, DataBannerHome, HomeCarruselPlataformas, CarruselBannerHome } from 'src/app/models/cms/cms.home.model';
import { AuthService } from 'src/app/services/auth.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { faTimes, faArrowRight, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { CmsPlanComercialService } from '../../../services/cms/cms.plan.comercial.service';
import { ProductosPlancomercial } from 'src/app/models/cms/cms.plan.comercial.model';
import { DynamicHelper } from 'src/app/helpers/dynamic-helper';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {
  title: string = 'Televisa llega';

  faTimes = faTimes;
  faArrowRight = faArrowRight;
  faPlus = faPlus;

  casosExitoCardData: CasosdeexitoHome[];
  videoCardData: CarrouselVideosHome[];
  newBannerHome: CarruselBannerHome[];
  newBannerHomeLazyLoading: CarruselBannerHome[];
  hasChannelInfo: boolean = false;

  isNewBannerLoaded: boolean = false;
  isProductosLoaded: boolean = false;
  tarjetasHover: any[];
  estrategiaSeccion: any[];
  estrategiaSeleccionada: number;
  estrategiaAnterior: number;
  estrategiaSiguiente: number;
  t: any;
  clickIsEnabled: boolean;
  baseCMSUrl : string;
  state: string = 'center';
  modalHomeData: any[];
  isModalOpen: boolean;
  tipoModal: string = '';
  productos: ProductosPlancomercial[];

  @ViewChildren('video') matVideo: QueryList<any>;
  video: HTMLVideoElement;
  videoPlayId: string = '';
  videoPlayNuevoId: string = '';

  /*Loaders*/
  isBannerLoaded: boolean = false;
  isPlataformasLoaded: boolean = false;
  isPlataformasMobileLoaded: boolean = false;
  isCasosExitoLoaded: boolean = false;
  isVideosLoaded: boolean = false;

  subscriptionCasosExito: Subscription;
  subscriptionPlataformas: Subscription;
  subscriptionPlataformasMobile: Subscription;
  subscriptionVideo: Subscription;

  constructor(
    public router: Router,
    private gtm: GtmService,
    private cmsHomeService : CmsHomeService,
    private authService: AuthService,
    private configuration: ConfigurationService,
    private cdr: ChangeDetectorRef,
    private titleService: Title,
    private metaService: Meta,
    private cmsPlanComercialService: CmsPlanComercialService,
  ) {

    gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, Draggable);
    gsap.ticker.lagSmoothing(false);
    this.baseCMSUrl = this.configuration.baseCmsUrl
    if(this.authService.isLoggedIn){
      this.getDataFromServices();
      this.getProductos();
    }
    else{
      /* Me loggeo y luego voy por los banners */
      this.authService.loginByApp().subscribe(resp => {//console.log('Resp login:',resp)
        this.getDataFromServices();
        this.getProductos();
      });
    }


  }

  playPauseVideo() {
    let videos = document.querySelectorAll("video");
    videos.forEach((video) => {
         // We can only control playback without insteraction if video is mute
        video.muted = true;
        // Play is a promise so we need to check we have it
        var playPromise = video.play();
        if (playPromise !== undefined) {
            playPromise.then(_ => {
                let observer = new IntersectionObserver(
                    (entries) => {
                        entries.forEach((entry) => {
                            if (
                                
                                entry.intersectionRatio !== 1 &&
                                !video.paused
                            ) {
                                //console.log('Video a pausar:', video);
                                video.pause();
                            } else if (entry.intersectionRatio == 1 && video.paused) {
                              this.videoPlayNuevoId = video.getAttribute('id');
                              if (this.videoPlayNuevoId !== this.videoPlayId) {
                                //console.log('le di play a:', this.videoPlayNuevoId);
                                this.gtm.push({
                                  event: 'play-video-home',
                                  videoId: this.videoPlayNuevoId
                                });
                                this.videoPlayId = this.videoPlayNuevoId;
                              }
                                //console.log('Video a dar play:', video.getAttribute('id'));
                                video.play();
                            }
                        });
                    },
                    { threshold: 1 }
                );
                observer.observe(video);
            }).catch(error => {
              console.log('Slow network detected.', error);
                // Auto-play was prevented
                // Show paused UI.
              });
        }
    });
  }

  ordenaArregloPropiedadOrden(obj1 ,obj2 ){
    if(obj1.orden > obj2.orden) { return 1;}
    if(obj1.orden < obj2.orden) { return -1;}
    return 0;
  }
  
  ordenaArregloBanner(obj1 ,obj2 ){
    if(obj1.bannerId > obj2.bannerId) { return 1;}
    if(obj1.bannerId < obj2.bannerId) { return -1;}
    return 0;
  }

  getDataFromServices() {
    this.getModalData();
    this.getCasosExitoCarDataformasData();
    this.getVideoCardData();
    this.getBannerData();
  }

  ngOnInit(): void {
    this.gtm.push({ event: 'TelevisaLlega Home' });

    this.titleService.setTitle(this.title);
    this.metaService.addTags([
      { name: 'keywords', content: 'Televisa, eventos deportivos, publicidad, industrias, programación, efectividad publicitaria, noticieros Televisa, televisión, canal 2, Las Estrellas, canal 5, novelas, plataformas, estrategia publicitaria, plan comercial, casos de éxito, mercadotecnia, marca' },
      { name: 'description', content: 'Televisa Llega es el sitio donde se encuentra toda la oferta comercial disponible de Grupo Televisa para transmitir campañas publicitarias de tu marca.' },
      { name: 'robots', content: 'index, follow' }
    ]);


    this.playPauseVideo();

  }

  ngAfterViewInit() {
    if (this.isModalOpen) {
      this.openModal();
    }
 
  }

  ngOnDestroy(): void {
    clearTimeout(this.t);
    if(this.subscriptionCasosExito)
    {
      this.subscriptionCasosExito.unsubscribe()
    }
  }

  isInViewport(element) {
    const rect = element.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  pauseAllVideos(){
    this.cdr.markForCheck();
    //console.log('Pauso todos los videos');
    var vids = document.getElementsByTagName('video');
    for (var i = 0; i < vids.length; i++) {
      this.video = vids.item(i);
      this.video.pause();
    }
    var that = this;
    setTimeout(function () {
      that.playPauseVideo();
    }, 1000);
  }

  public getProductos() {
    this.cmsPlanComercialService.getProductos().subscribe(response => {
      this.productos = response.data.productosPlancomercial;
      this.productos.sort(this.ordenaArregloPropiedadOrden);
      this.isProductosLoaded = true;
      this.cdr.markForCheck();
      //console.log('this.productos', this.productos);
    });
  }

  public getCasosExitoCarDataformasData() {
    /* Invocar servicio que recupera datos de casos de éxito*/
    this.subscriptionCasosExito = this.cmsHomeService.getCasosExitoHome().subscribe(dataResponse => {
      this.casosExitoCardData = dataResponse.data.casosdeexitoHome;
      this.casosExitoCardData.sort(this.ordenaArregloPropiedadOrden);
      //console.log('Returned data:', this.casosExitoCardData);
      this.isCasosExitoLoaded = true;
      this.cdr.markForCheck();
    });
    
  }

  public getCasosText(casoTexto: string) {
    var casoTextoProcesar: string = casoTexto.replace('<p>', '').replace('</p>', '*-*').replace('<span>', '').replace('</span>', '').replace('<p>', '').replace('</p>', '').replace('<strong>', '***').replace('</strong>', '/**').replace('<em>', '').replace('</em>', '');
    var casoTextoRender: string = '<p>' + casoTextoProcesar.substring(0, 340).replace('***', '<strong>').replace('/**', '</strong>').replace('*-*', '<br />') + '</p>';
    return casoTextoRender;
  }

  public getVideoCardData() {
    /* Invocar servicio que recupera videos para carrusel*/
    this.cmsHomeService.getCarrouselVideosHome().subscribe(dataResponse => {
      this.videoCardData = dataResponse.data.carruselVideosHome;
      this.videoCardData.sort(this.ordenaArregloPropiedadOrden);
      //console.log('Datos de videos ordenados: ',this.videoCardData)
      this.isVideosLoaded = true;
      this.cdr.markForCheck();
    });
   
  }

  iOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }

  public getBannerData() {
    this.cmsHomeService.getNewBannerHome().subscribe(dataResponse => {
      this.newBannerHome = dataResponse.data.carruselBannerHome;
      this.newBannerHome.sort(this.ordenaArregloPropiedadOrden);
      this.isNewBannerLoaded = true;
      this.cdr.markForCheck();
      //console.log('this.newBannerHome',this.newBannerHome);
      this.getBannerSize();

      setTimeout(() => {
        this.newBannerHomeLazyLoading = this.newBannerHome;
      }, 1000);
    });
  }

  public getBannerSize() {
    for (let bannerContent of this.newBannerHome) {
      if (bannerContent.canalHorario && bannerContent.canalHorario && bannerContent.canalHorario.contentItems.length > 0) {
        this.hasChannelInfo = true;
        this.cdr.markForCheck();
      }
    }
    //console.log('this.hasChannelInfo',this.hasChannelInfo);
  }

  public getModalData() {
    this.cmsHomeService.getModalHome().subscribe(dataResponse => {
      this.modalHomeData = dataResponse.data.homeAvisomodal
      if (this.modalHomeData.length > 0) {
        //console.log('Data de modal home:',this.modalHomeData);
        for (let obj of this.modalHomeData) {
          if (obj.habilitaModal) {
            //console.log('Modal home habilitado:',obj.habilitaModal);
            let contentType = obj.imagenModal.urls[0].toLowerCase().substring(obj.imagenModal.urls[0].toLowerCase().length - 5);
            //console.log('tipo de contenido modal:', contentType);
            if (contentType.includes('.mp4')) {
              this.tipoModal = 'video';
            } else if (contentType.includes('.jpg') || contentType.includes('.jpeg') || contentType.includes('.gif') || contentType.includes('.png')) {
              this.tipoModal = 'imagen';
            } else {
              this.tipoModal = ''
            }

            if (this.tipoModal !== '') {
              this.isModalOpen = true;
              this.openModal();
            } else {
              this.isModalOpen = false;
            }
          } else {
            //console.log('Modal home NO Habilitado:',obj.habilitaModal);
            this.isModalOpen = false;
          }
        }
      }
    });
  }

  public openModal() {
    gsap.to('.tvs-modal',
      {
        height: '100%',
        opacity: 1,
        duration: 1,
        //delay: 1
      }
    );
    if (this.tipoModal == 'imagen') {
      setTimeout(() => {
        this.closeModal();
      }, 15000);
    }
   
  }
  public closeModal() {
    gsap.to('.tvs-modal',
      {
        height: '0%',
        opacity: 0,
        duration: 1
      }
    );
    if (this.tipoModal == 'video') {
      this.pauseVideoModal();
    }
    this.isModalOpen = false;
  }
  public closeModalOutside() {
    if (this.tipoModal == 'imagen') {
      gsap.to('.tvs-modal',
        {
          height: '0%',
          opacity: 0,
          duration: 1
        }
      );
      this.isModalOpen = false;
    }     
  }

  pauseVideoModal() {
    this.cdr.markForCheck();
    //console.log('Pauso todos los videos');
    var vids = document.getElementsByTagName('video');
    for (var i = 0; i < vids.length; i++) {
      this.video = vids.item(i);
      this.video.pause();
    }
  }

  public navigateTo(path: string): void {
    if (path  && path !== '') { 
      this.router.navigateByUrl(path);
      gsap.to(window, {
        scrollTo: 0,
        duration: 0.5
      });
    }
  }

  customOptionsVideo: OwlOptions = {
    loop: false,
    rewind: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    navSpeed: 500,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
    },
    nav: true,
    autoplay: false,
    autoplayMouseleaveTimeout: 1000
  }

  customOptionsBanner: OwlOptions = {
    items: 1,
    stagePadding: 0,
    loop: true,
    mouseDrag: false,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    navSpeed: 500,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
    },
    nav: true,
    autoplay: true,
    autoplayTimeout: 7000,
    autoplaySpeed: 1000,
    autoplayHoverPause: true,
    autoplayMouseleaveTimeout: 1000
  }

  public getCardImg(imagen: string) {
    if (!imagen)
      return imagen;

    var urlImagen: string = imagen.replace(/ /g, '%20');
    return urlImagen;
  }


  public dynamicId(objeto: string, nombre: string) {
    const randomNumber = Math.floor(Math.random() * 10000) + 1;
    return DynamicHelper.dynamicIdHelper(objeto, nombre) + '-' + randomNumber;
  }

}
