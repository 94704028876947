<div class="app-component app-container" >

  <div class="container-fluid full-app-container">
    <app-website-menu></app-website-menu>
    <lib-breadcrumbs class="tvs-breadcrumbs"></lib-breadcrumbs>
    <router-outlet></router-outlet>


  </div>
    <app-website-footer></app-website-footer>
  <div class="tvs-up" [ngClass]="{'active':isScroll}">
    <a (click)="scrollTopWindow()" href="">
      <div class="tvs-round-btn-yellow tvs-btn-up">
      </div>
    </a>
  </div>
</div>
