import { Component, OnInit, HostListener, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { GtmService } from 'ngx-gtm';

@Component({
  selector: 'app-carta-editorial',
  templateUrl: './carta-editorial.component.html',
  styleUrls: ['./carta-editorial.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CartaEditorialComponent implements OnInit {

  title: string = 'Carta editorial';

  bannerImage: string;
  bannerTitle: string;

  state: string = 'center';

  /*Loaders*/
  isBannerLoaded: boolean = false;

  constructor(
    private gtm: GtmService,
    private cdr: ChangeDetectorRef,
    private titleService: Title,
    private metaService: Meta
  ) {
    this.getBannerImage();
  }

  ngOnInit(): void {
    this.gtm.push({ event: 'TelevisaLlega Carta editorial' });

    this.titleService.setTitle(this.title);
    this.metaService.addTags([
      { name: 'keywords', content: 'estrategia Televisa, comunicación Televisa, compromiso Televisa, estrategias de comunicación' },
      { name: 'description', content: 'Espacio donde se encuentran los valores, compromisos, objetivos y estrategias de comunicación para impulsar el desarrollo y fortalecimiento de tus marcas.' },
      { name: 'robots', content: 'index, follow' }
    ]);
  }

  public getBannerImage() {

    /* Invocar servicio que recupera imagen de portada de la página*/

    this.bannerImage = ''; 
    this.bannerTitle = 'Carta editorial';
    this.isBannerLoaded = true;
    this.cdr.markForCheck();
  }

}
