import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { gsap } from "gsap/all";
import { faChevronDown, faTimes, faChevronRight, faArrowRight, faFilter, faMinus } from '@fortawesome/free-solid-svg-icons';
import { GtmService } from 'ngx-gtm';
import { CmsFuturecastService } from 'src/app/services/cms/cms.futurecast.service';
import { FutureCast } from 'src/app/models/cms/cms.detalle-futurecast.model';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { CmsPlanComercialService } from 'src/app/services/cms/cms.plan.comercial.service';
import { AuthService } from 'src/app/services/auth.service';
import { DynamicHelper } from 'src/app/helpers/dynamic-helper';

@Component({
  selector: 'app-futurecast',
  templateUrl: './futurecast.component.html',
  styleUrls: ['./futurecast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FuturecastComponent implements OnInit {

  title: string = 'Futurecast';

  faChevronDown = faChevronDown;
  faTimes = faTimes;
  faChevronRight = faChevronRight;
  faArrowRight = faArrowRight;
  faFilter = faFilter;
  faMinus = faMinus;

  bannerImage: string;
  bannerImageMobile: string;
  bannerTitle: string;
  baseCMSUrl: string;

  casoExitoPrincipal: FutureCast[];
  casoExitoPrincipalTitle: string;
  casoExitoPrincipalDate: string;
  casoExitoPrincipalText: string;
  casoExitoPrincipalImg: string;
  casoExitoPrincipalLink: string;
  casoExitoPrincipalGenero: string;

  datosFuturecast: FutureCast[];
  datosFuturecastToMemory: FutureCast[];

  /*Catálogos*/
  catFuturecast: any[];
  futurecastFiltrados: any[];
  datosFuturecastCompletos : FutureCast[];

  /*Loaders*/
  isBannerLoaded: boolean = false;
  isCasoPrincLoaded: boolean = false;
  isCasoLoaded: boolean = false;

  isFilterMenuOpen: boolean = false;

  genero: string;

  constructor(
    private gtm: GtmService,
    public router: Router,
    private cmsFuturecastService: CmsFuturecastService,
    private configuration: ConfigurationService,
    private cmsPlanComercialService: CmsPlanComercialService,
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private titleService: Title,
    private metaService: Meta
  ) {
    this.baseCMSUrl = this.configuration.baseCmsUrl;
    this.genero = '0';
    if(this.authService.isLoggedIn){
      this.getBannerImage();
      this.getFuturecast();
    }
    else{
      /* Me loggeo y luego voy por los banners */
      this.authService.loginByApp().subscribe(resp => {//console.log('Resp login:',resp)
      this.getBannerImage();
      this.getFuturecast();
      });
    }
    
  }

  ngOnInit(): void {
    this.gtm.push({ event: 'TelevisaLlega Futurecast' });

    this.titleService.setTitle(this.title);
    this.metaService.addTags([
      { name: 'keywords', content: 'casos de éxito en publicidad, casos de éxito, publicidad en televisión, publicidad en Televisa, argumentos publicitarios, programas de televisión, análisis de industrias, detalle de industrias' },
      { name: 'description', content: 'Conoce el resultado de las distintas campañas publicitarias para marcas de diferentes categorias, apoyadas con nuestras estrategias y plataformas.' },
      { name: 'robots', content: 'index, follow' }
    ]);
  }

   

  public getCatalogos() {
    /*crear catálogos*/
    this.catFuturecast = [];
    this.datosFuturecastToMemory.forEach(element => {
      if(element.tipodelFuturecast)
      {
        var existe = false;
        this.catFuturecast.forEach( (item, index) => {
          if(item.id == element.tipodelFuturecast?.contentItems[0]?.contentItemId) existe = true;
        });
        if (!existe)
        {
          //console.log('Pushing al filtro:',element.tipodelFuturecast?.contentItems[0]?.contentItemId);
          this.catFuturecast.push({value: element.tipodelFuturecast?.contentItems[0]?.generoTarjeta, id:element.tipodelFuturecast?.contentItems[0]?.contentItemId, orden: element.tipodelFuturecast?.contentItems[0]?.orden})
        }
        
      }
    });
    this.catFuturecast.sort(this.ordenaArregloPropiedadOrden);
    //console.log('categorías:', this.catFuturecast);
    this.futurecastFiltrados = this.catFuturecast.slice();
  }

  ordenaArregloPropiedadOrden(obj1, obj2) {
    if (obj1.orden > obj2.orden) { return 1; }
    if (obj1.orden < obj2.orden) { return -1; }
    return 0;
  }

  public getBannerImage() {
    this.cmsPlanComercialService.getBannerHero().subscribe(response => {
      for(let i = 0; i < response.data.bannersdePaquetes.length ; i++) {
        if(response.data.bannersdePaquetes[i].displayText === 'Futurecast') {
          this.bannerImage = this.baseCMSUrl.slice(0, -1) + response.data.bannersdePaquetes[i].bannerImage.urls[0];
          this.bannerImageMobile = this.baseCMSUrl.slice(0, -1) + response.data.bannersdePaquetes[i].imagenMobile.urls[0];
          this.bannerTitle = response.data.bannersdePaquetes[i].bannerTitle;
          this.isBannerLoaded = true;
          this.cdr.markForCheck();
        }
      }
    });
  }

  isFutureChecked(futureId) {
    if (futureId == this.genero) {
      return true;
    }
    else {
      return false;
    }

  }

  borrarFiltros(){
    this.getBannerImage();
    this.getFuturecast();
  }

  isTodosSelected()
  {
    if (this.genero == '0')
    {
      return true;
    }
    else
    {
      return false;
    }
  }

  changeFilterFutureCast(idCheck, e) {

    if (idCheck == '0')
    {
      this.futurecastFiltrados = this.catFuturecast.slice();
      this.genero = '0';
    }
    else{
      this.futurecastFiltrados = [];
      this.futurecastFiltrados.push({value: 'futureCast filtrado', id: idCheck})
    }

    //console.log(this.canalesFiltrados);
    this.datosFuturecast = this.datosFuturecastCompletos.filter(programa => this.futurecastExiste(programa.idGeneroTarjeta) );
    //console.log(this.datosProgramacion);

    setTimeout(() => {
      this.closeFilter();
    }, 300);


  }

  futurecastExiste(id){
    //console.log(id);
    if(this.futurecastFiltrados.map(function(e) { return e.id; }).indexOf(id) != -1)// .indexOf(x => x.id.toString() === id.toString()) != -1)
    {
      //console.log('Id en filtro ', id);
      return true;
    }
    else
    {
      //console.log(id, 'no encontrado');
      return false;
    }
  }


  public getFuturecast() {
    //console.log('Entro a getFuturecast');
    this.cmsFuturecastService.getFuturecastCompleteData().subscribe(dataResponse => {
      this.datosFuturecastToMemory = dataResponse.data.futureCast.slice();
      this.datosFuturecast = dataResponse.data.futureCast.filter(caso => caso.esPrincipal==false).slice();
      /* Recorro todos los datos para ponerles la plataforma de Futurecast = 1 */
      this.datosFuturecast.forEach(element => {
        if(element.tarjeta)
        {
          element.idGeneroTarjeta = element.tipodelFuturecast?.contentItems[0]?.contentItemId;
          element.tarjeta.idPlataformaTarjeta = '1';
          element.tarjeta.verMasTarjeta = 'futurecast/detalle-futurecast/' + element.contentItemId;
          element.tarjeta.cintilloTarjeta = element.tipodelFuturecast?.contentItems[0]?.generoTarjeta;
        }
      });

      this.datosFuturecast.sort((a, b) => (a.ordenTarjeta < b.ordenTarjeta) ? 1 : -1);

      //console.log('Returned getFuturecastCompleteData:', this.datosFuturecast);
      this.datosFuturecastCompletos = this.datosFuturecast.filter(caso => caso.esPrincipal==false).slice();
      this.isCasoLoaded = true;
      this.cdr.markForCheck();

      /* Obtengo el caso principal */
      this.casoExitoPrincipal = dataResponse.data.futureCast.filter(caso => caso.esPrincipal==true).slice();
      //console.log('Returned casoExitoPrincipal:', this.casoExitoPrincipal);
      if (this.casoExitoPrincipal.length > 0) {
        for (let obj of this.casoExitoPrincipal) {
          this.casoExitoPrincipalTitle = obj.tarjeta?.nombreTarjeta;
          this.casoExitoPrincipalDate = '';
          this.casoExitoPrincipalText = obj.tarjeta?.textoTarjeta;
          this.casoExitoPrincipalImg = this.baseCMSUrl.slice(0, -1) + obj.tarjeta?.imagenTarjeta.urls[0];
          this.casoExitoPrincipalLink = 'futurecast/detalle-futurecast/' + obj.contentItemId; //obj.tarjeta?.verMasTarjeta;
          this.casoExitoPrincipalGenero = obj.tipodelFuturecast?.contentItems[0].displayText;
        }
      }
      this.isCasoPrincLoaded = true;
      this.cdr.markForCheck();
      this.getCatalogos();
    });

    
    }


  public openFilter() {
    if (!this.isFilterMenuOpen) {
      gsap.to(".tvs-mobile-filter", {
        width: '100%',
        padding: '20px',
        opacity: 1,
        duration: 0.5
      });
      this.isFilterMenuOpen = true;
    }
  }
  public closeFilter() {
    if (this.isFilterMenuOpen) {
      gsap.to(".tvs-mobile-filter", {
        width: 0,
        padding: '0px',
        opacity: 0,
        duration: 0.5
      });
      this.isFilterMenuOpen = false;
    }
  }

  public navigateTo(path: string): void {
    this.router.navigateByUrl(path);
    gsap.to(window, {
      scrollTo: 0,
      duration: 0.5
    });

  }

  public goToFuturecast(path: string, nombre: string, categoria: string) {
    window.open(path, '_self');
    //console.log(path, nombre, categoria);
    this.gtm.push({
      event: 'click-futurecast',
      type: 'Caso de éxito',
      category: categoria,
      name: nombre
    });
  }

  public dynamicId(objeto: string, nombre: string) {
    return DynamicHelper.dynamicIdHelper(objeto, nombre);
  }


}
