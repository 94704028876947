import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { EndpointBase } from './endpoint-base.service';
import { ConfigurationService } from './configuration.service';


@Injectable()
export class DropdownsEndpoint extends EndpointBase {

  get cataloguesUrl() { return this.configurations.baseUrl + '/api/Dropdowns/catalogues'; }
  get countriesUrl() { return this.configurations.baseUrl + '/api/Dropdowns/countries'; }
  
  constructor(private configurations: ConfigurationService, http: HttpClient, authService: AuthService) {
    super(http, authService);
  }

  getCataloguesEndpoint<T>(language?: string, id?:string): Observable<T> {
    const lang = language ? language : 'ES';
    const idCatalogue = id ? id : '1';
    const endpointUrl = `${this.cataloguesUrl}/${lang}/${idCatalogue}`;
    
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCataloguesEndpoint(language,id));
      }));
  }

  getCountriesEndpoint<T>(language?: string): Observable<T> {
    const lang = language ? language : 'ES';
    const endpointUrl = `${this.countriesUrl}/${lang}`;
    
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCountriesEndpoint(language));
      }));
  }
}
