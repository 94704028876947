import { Utilities } from '../services/utilities';


export class NotificationComplete {


    public id: string;
    public app_id: number;
    public notification_id: string;
    public user_id: string;
    public sender_user_id: string;
    public company_id: string;
    public notification_parent_id: string;
    public notification_type: number;
    public message: string;
    public is_read: boolean;
    public date_created: Date;
    public sender_user_name: string;
    public sender_company_name: string;
    public job_position: string;
    public company_name: string;
    public profile_image: string;

    public static Create(data: {}) {
        const n = new NotificationComplete();
        Object.assign(n, data);

        return n;
    }
}
