<ng-container *ngIf="!isBannerLoaded">
  <div class="eventos mb-5">
    <div class="row tvs-banner-unloaded">
      <img class="loader-img z-20" src="assets/images/website_general/loader-2.svg" alt="" />
    </div>
  </div>
</ng-container>
<ng-container *ngIf="isBannerLoaded">
  <app-header-image-new srcImage="{{bannerImage}}" srcImageMobile="{{bannerImageMobile}}" srcTitle="{{bannerTitle}}" srcTipo="3" class="eventos"></app-header-image-new>
</ng-container>

<div class="row px-2 px-md-5 py-5 tvs-bg-white">
  <section class="col-12 my-5" *ngIf="!isEventosLoaded">
      <div class="loader-min">
        <img class="loader-img z-20" src="assets/images/website_general/loader-2.svg" alt="" />
      </div>
  </section>
  <section class="col-12 pt-5" *ngIf="isEventosLoaded">
    <ng-container *ngFor="let evento of eventos; let i = index;">
      <section class="col-12 col-xl-10 offset-xl-1 my-4">
        <app-tarjeta-evento [eventData]="evento"></app-tarjeta-evento>
      </section>
    </ng-container>
  </section>
</div>

