import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { GtmService } from 'ngx-gtm';
import { gsap } from "gsap/all";
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';

import { BuscadorTelevisa } from 'src/app/models/buscador-televisa.model';
import { SearchService } from 'src/app/services/search.service';
import { SearchData } from 'src/app/models/search-data.model';
import { ConfigurationService } from 'src/app/services/configuration.service';

@Component({
  selector: 'app-resultados-buscador',
  templateUrl: './resultados-buscador.component.html',
  styleUrls: ['./resultados-buscador.component.scss']
})
export class ResultadosBuscadorComponent implements OnInit {

  faChevronUp = faChevronUp;
  faChevronDown = faChevronDown;

  title: string = 'Resultados de la búsqueda';

  bannerImage: string;
  bannerTitle: string;
  isBannerLoaded: boolean = false;
  isContentLoaded: boolean = false;
  content: boolean;
  busqueda: string;
  textoBusqueda: string;

  artFound: boolean = false;

  articulos: any[] = [
    { art: 'el'  },
    { art: 'la'  },
    { art: 'los' },
    { art: 'las' },
    { art: 'a' },
    { art: 'con' },
    { art: 'de' },
    { art: 'en' },
    { art: 'para' },
    { art: 'por' },
    { art: 'sin' },
    { art: 'so' }
  ];

  /*variables para mostrar u ocultar secciones dependiendo de los resultados*/
  resultProgramas: boolean = false;
  resultFuturecast: boolean = false;
  resultEventos: boolean = false;
  resultPaquetes: boolean = false;
  resultSecciones: boolean = false;

  /* controlo estados de secciones colapsadas/extendidas*/
  collapsedResultProgramas: boolean = false;
  collapsedResultFuturecast: boolean = false;
  collapsedResultEventos: boolean = false;
  collapsedResultPaquetes: boolean = false;
  collapsedResultSecciones: boolean = false;

  identificador: string;
  collapsedStatus: boolean;

  dataToSearch : SearchData;

  datosBusquedaCompleta: BuscadorTelevisa[];

  datosProgramacion: BuscadorTelevisa[];
  datosFuturecast: BuscadorTelevisa[];
  datosEvento: BuscadorTelevisa[];
  datosPaquete: BuscadorTelevisa[]; 
  datosSecciones: BuscadorTelevisa[];
  baseCMSUrl: string;

  constructor(
    private gtm: GtmService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private titleService: Title,
    private metaService: Meta,
    private configuration: ConfigurationService,
    private searchService: SearchService
  ) {
    this.getBannerImage();
    this.baseCMSUrl = this.configuration.baseCmsUrl;
    this.isContentLoaded = false;
  }

  ngOnInit(): void {

    this.isContentLoaded = false;
    this.cdr.markForCheck();

    this.route.params.subscribe(routeParams => {
      if (routeParams['busqueda']) {
        this.busqueda = routeParams['busqueda'];

        this.searchResult(this.busqueda);

      }
      else {
        this.busqueda = "No encontrado";
        this.textoBusqueda = 'No se han encontrado resultados.';
        this.isContentLoaded = true;
        this.cdr.markForCheck();
      }

      

    });

    this.gtm.push({ event: 'TelevisaLlega Búsqueda', term: this.busqueda });

    this.titleService.setTitle(this.title);
    this.metaService.addTags([
      { name: 'description', content: 'Resultados de búsqueda' },
      { name: 'robots', content: 'noindex, nofollow' }
    ]);

  }

 
  public getBannerImage() {

    /* Invocar servicio que recupera imagen de portada de la página*/

    this.bannerImage = 'assets/images/carga_cms/hero-plan-comercial.png';
    this.bannerTitle = 'Búsqueda';
    this.isBannerLoaded = true;
    this.cdr.markForCheck();
  }


  public toggleCollapse(numId: number) {

    this.collapsedStatus = false;

    switch (numId) {
      case 1:
        this.identificador = 'resultProgramas';
        if (this.collapsedResultProgramas === false) {
          this.collapsedStatus = false;
          this.collapsedResultProgramas = true;
        } else {
          this.collapsedStatus = true;
          this.collapsedResultProgramas = false;
        }
        break;
      case 2:
        this.identificador = 'resultFuturecast';
        if (this.collapsedResultFuturecast === false) {
          this.collapsedStatus = false;
          this.collapsedResultFuturecast = true;
        } else {
          this.collapsedStatus = true;
          this.collapsedResultFuturecast = false;
        }
        break;
      case 3:
        this.identificador = 'resultEventos';
        if (this.collapsedResultEventos === false) {
          this.collapsedStatus = false;
          this.collapsedResultEventos = true;
        } else {
          this.collapsedStatus = true;
          this.collapsedResultEventos = false;
        }
        break;
      case 4:
        this.identificador = 'resultPaquetes';
        if (this.collapsedResultPaquetes === false) {
          this.collapsedStatus = false;
          this.collapsedResultPaquetes = true;
        } else {
          this.collapsedStatus = true;
          this.collapsedResultPaquetes = false;
        }
        break;
      case 5:
        this.identificador = 'resultSecciones';
        if (this.collapsedResultSecciones === false) {
          this.collapsedStatus = false;
          this.collapsedResultSecciones = true;
        } else {
          this.collapsedStatus = true;
          this.collapsedResultSecciones = false;
        }
        break;
      default:
        break;
    }

    if (this.collapsedStatus === false) {
      gsap.to("#" + this.identificador, {
        height: '30px',
        duration: 0.5
      });

      gsap.to("." + this.identificador + " .tvs-expanded", {
        opacity: 0,
        duration: 0.3
      });

      gsap.to("." + this.identificador + " .tvs-collapsed", {
        opacity: 1,
        duration: 0.5
      });

    } else {
      gsap.to("#" + this.identificador, {
        height: 'auto',
        duration: 0.5
      });

      gsap.to("." + this.identificador + " .tvs-collapsed", {
        opacity: 0,
        duration: 0.3
      });

      gsap.to("." + this.identificador + " .tvs-expanded", {
        opacity: 1,
        duration: 0.5
      });

      
    }
  }

  public clearCollapse() {

    this.collapsedResultProgramas = false;
    this.collapsedResultFuturecast = false;
    this.collapsedResultEventos = false;
    this.collapsedResultPaquetes = false;
    this.collapsedResultSecciones = false;

    gsap.to("#resultProgramas, #resultFuturecast, #resultEventos, #resultPaquetes, #resultSecciones", {
      height: 'auto',
      duration: 0.5
    });

    gsap.to(".tvs-collapsed", {
      opacity: 0,
      duration: 0.3
    });

    gsap.to(".tvs-expanded", {
      opacity: 1,
      duration: 0.5
    });

  }

  public searchResult(texto: string) {

    this.isContentLoaded = false;
    this.cdr.markForCheck();

    this.artFound = false;
    for (let i = 0; i < this.articulos.length; i++) {
      if (texto.toLowerCase() === this.articulos[i].art) {
        this.artFound = true;
      }
    }

    if (this.artFound) {
      this.textoBusqueda = 'Por favor escribe más palabras para realizar la búsqueda.';
      this.resultProgramas = false;
      this.resultFuturecast = false;
      this.resultEventos = false;
      this.resultPaquetes = false;
      this.resultSecciones = false;

      this.isContentLoaded = true;
      this.cdr.markForCheck();

    } else {
      this.textoBusqueda = 'Resultados de búsqueda para: "' + this.busqueda + '"';
      this.dataToSearch = new SearchData();
      this.dataToSearch.p_tip_busqueda = 'A';
      this.dataToSearch.p_dato_buscado = this.busqueda;
      this.dataToSearch.p_token_id = 'TokenTelevisa';
      this.searchService.getSearchResults(this.dataToSearch).subscribe(data => {
        //console.log(data);
        this.datosBusquedaCompleta = data;
        //console.log("this.baseCMSUrl",this.baseCMSUrl.slice(0, -1));
        /* Agrego prefijos a las imagenes y links */
        this.datosBusquedaCompleta.forEach(element => {
          if(element.imagenTarjeta)
          {
            element.imagenTarjeta = this.baseCMSUrl.slice(0, -1) + element.imagenTarjeta;
          }
        });
        this.datosProgramacion = this.datosBusquedaCompleta.filter(resultado => resultado.tipoResultado == 1);
        this.datosFuturecast = this.datosBusquedaCompleta.filter(resultado => resultado.tipoResultado == 2);
        this.datosEvento = this.datosBusquedaCompleta.filter(resultado => resultado.tipoResultado == 3);
        this.datosPaquete = this.datosBusquedaCompleta.filter(resultado => resultado.tipoResultado == 4);
        this.datosSecciones = this.datosBusquedaCompleta.filter(resultado => resultado.tipoResultado == 5);
        if (this.datosProgramacion.length > 0)
        {
          this.resultProgramas = true;
        }
        else
        {
          this.resultProgramas = false;
        }
        if (this.datosFuturecast.length > 0)
        {
          this.resultFuturecast = true;
        }
        else
        {
          this.resultFuturecast = false;
        }
        if (this.datosEvento.length > 0)
        {
          this.resultEventos = true;
        }
        else
        {
          this.resultEventos = false;
        }
        if (this.datosPaquete.length > 0)
        {
          this.resultPaquetes = true;
        }
        else
        {
          this.resultPaquetes = false;
        }
        if (this.datosSecciones.length > 0)
        {
          this.resultSecciones = true;
        }
        else
        {
          this.resultSecciones = false;
        }
        if (this.datosBusquedaCompleta.length == 0)
        {
          this.resultProgramas = false;
          this.resultFuturecast = false;
          this.resultEventos = false;
          this.resultPaquetes = false;
          this.resultSecciones = false;
          this.textoBusqueda = 'No se han encontrado resultados.';
        }
        this.cdr.markForCheck();
      },
      err => {
        this.resultProgramas = false;
        this.resultFuturecast = false;
        this.resultEventos = false;
        this.resultPaquetes = false;
        this.resultSecciones = false;
        this.textoBusqueda = 'No se han encontrado resultados.';
        //console.log(err);
        this.cdr.markForCheck();
        });

      this.isContentLoaded = true;
      this.cdr.markForCheck();

    }

    this.clearCollapse();

  }

}

