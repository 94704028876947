<div class="row tvs-paquete-tarjeta tvs-o-hidden">
  <div class="col-12 col-md-6 col-lg-3 px-0 tvs-o-hidden">
    <div class="tvs-img-container"
         [ngStyle]="{'background-image': 'url('+ getCardImg(baseCMSUrl.slice(0, -1) + cardData?.imagenTarjeta.urls[0]) +')'}"
         *ngIf="cardData?.imagenTarjeta && cardData?.imagenTarjeta.urls[0] != ''">
    </div>
    <div class="tvs-img-container"
         style="background-image: url('assets/images/website_general/paquetes_default.png')"
         *ngIf="!cardData?.imagenTarjeta || cardData?.imagenTarjeta.urls[0] == ''">
    </div>
  </div>
  <div class="col-12 col-md-6 tvs-center-tab">
    <p class="my-3" *ngIf="cardData.fechaTarjeta && cardData.fechaTarjeta != ''">{{cardData.fechaTarjeta}}</p>
    <h3 class="tvs-text-orange tvs-text-bold" *ngIf="cardData.nombreTarjeta && cardData.nombreTarjeta != ''">{{cardData.nombreTarjeta}}</h3>
    <p class="small my-3" *ngIf="cardData.textoTarjeta && cardData.textoTarjeta != ''">{{cardData.textoTarjeta}}</p>
  </div>
  <div class="col-12 col-lg-3">
    <h3  class="tvs-text-orange tvs-text-bold mt-4" *ngIf="cardData.fechaTarjeta && cardData.fechaTarjeta != ''">Vigencia</h3>
    <p *ngIf="cardData.fechaTarjeta && cardData.fechaTarjeta != ''">{{cardData.fechaTarjeta}}</p>
  </div>
</div>
