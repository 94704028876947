import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, HostListener } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { faUser, faChevronDown, faTimes, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { GtmService } from 'ngx-gtm';
import { Subscription } from 'rxjs';
import { Acercade } from 'src/app/models/cms/cms.plan.comercial.model';
import { AuthService } from 'src/app/services/auth.service';
import { CmsPlanComercialService } from 'src/app/services/cms/cms.plan.comercial.service';
import { ConfigurationService } from 'src/app/services/configuration.service';

@Component({
  selector: 'app-acerca-de',
  templateUrl: './acerca-de.component.html',
  styleUrls: ['./acerca-de.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AcercaDeComponent implements OnInit {

  title: string = 'Acerca de';

  public screenWidth: number = 0;
  public screenRes: string = '';

  faUser = faUser;
  faChevronDown = faChevronDown;
  faTimes = faTimes;
  faChevronRight = faChevronRight;
  bannerImage: string;
  bannerImageMobile: string;
  bannerTitle: string;
  baseCMSUrl: string;

  introContacto: any[];
  contactosTitle: string;
  contactosDate: string;
  contactosIntro: string;
  contactosText: string;
  contactosImg: string;
  contactosLink: string;

  datosContacto: Acercade[];

  /*Loaders*/
  isBannerLoaded: boolean = false;
  isDirectorioLoaded: boolean = false;
  isIntroLoaded: boolean = false;

  subscriptionContactos: Subscription;

  constructor(
    private gtm: GtmService,
    public router: Router,
    private cmsPlanComercialService: CmsPlanComercialService,
    private configuration: ConfigurationService,
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private titleService: Title,
    private metaService: Meta
  ) {
     
  }

  ngOnInit(): void {
    this.baseCMSUrl = this.configuration.baseCmsUrl;
    if(this.authService.isLoggedIn){
      this.getBannerImage();
      this.getDatosContacto();
    }
    else{
      /* Me loggeo y luego voy por los banners */
      this.authService.loginByApp().subscribe(resp => {//console.log('Resp login:',resp)
      this.getBannerImage();
      this.getDatosContacto();
      });
    }
    this.getContactoIntro();
    this.gtm.push({ event: 'TelevisaLlega Acerca de' });

    this.titleService.setTitle(this.title);
    this.metaService.addTags([
      { name: 'keywords', content: 'Televisa, Televisa Llega, crecimiento de marcas, directorio comercial Televisa, publicidad en Televisa, propósito Televisa, experiencia Televisa' },
      { name: 'description', content: 'Conoce cómo podemos apoyarte para satisfacer tus necesidades publicitarias y nuestro compromiso para fortalecer el posicionamiento de tus marcas.' },
      { name: 'robots', content: 'index, follow' }
    ]);
    this.screenWidth = window.innerWidth;
    this.getScreenRes();
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.screenWidth = window.innerWidth;
    this.getScreenRes();
  }

  public getScreenRes () {
    if (this.screenWidth < 576) {
      this.screenRes = 'xs';
    } 
    else if (this.screenWidth >= 576 && this.screenWidth < 768) {
      this.screenRes = 'sm';
    }
    else if (this.screenWidth >= 768 && this.screenWidth < 992) {
      this.screenRes = 'md';
    }
    else if (this.screenWidth >= 992 && this.screenWidth < 1200) {
      this.screenRes = 'lg';
    }
    else if (this.screenWidth >= 1200 && this.screenWidth < 1440) {
      this.screenRes = 'xl';
    }
    else if (this.screenWidth >= 1440) {
      this.screenRes = 'xxl';
    }
    //console.log('bootstrap res:', this.screenRes);
  }

  public getBannerImage() {
    this.cmsPlanComercialService.getBannerHero().subscribe(response => {
      //console.log("Cargando banner - Acerca de");
      for(let i = 0; i < response.data.bannersdePaquetes.length ; i++) {
        //console.log("Banner cargado - Acerca de", response.data);
        if(response.data.bannersdePaquetes[i].displayText === 'Acerca-de') {
          this.bannerImage = this.baseCMSUrl.slice(0, -1) + response.data.bannersdePaquetes[i].bannerImage.urls[0];
          this.bannerImageMobile = this.baseCMSUrl.slice(0, -1) + response.data.bannersdePaquetes[i].imagenMobile.urls[0];
          this.bannerTitle = response.data.bannersdePaquetes[i].bannerTitle;
          this.isBannerLoaded = true;
          this.cdr.markForCheck();
        }
      }
    });

  }

  ngOnDestroy(): void {
    if(this.subscriptionContactos){
      this.subscriptionContactos.unsubscribe()
    }
  }

  public getContactoIntro() {
    this.introContacto = [
      {
        acercaDeTitulo: 'Acerca de',
        acercaDeIntro: 'En TelevisaUnivision, nuestro propósito es contribuir a la efectividad de las campañas publicitarias para fortalecer el posicionamiento de las marcas.',
        acercaDeTexto: '<p>Día con día acrecentamos nuestra experiencia y mejoramos nuestra oferta comercial con el objetivo de adecuarnos a las necesidades actuales del mercado y satisfacer las necesidades de nuestros clientes.</p><p>Contamos con la fuerza de nuestras plataformas y contenidos llegando a todas las audiencias del país, aumentamos la confianza de nuestros clientes cuidando el espacio donde tienen presencia sus marcas, generamos evaluaciones constantes que nos permiten conocer el nivel de efectividad de las campañas y, con el apoyo de la tecnología, desarrollamos productos y mediciones que marcarán la pauta en el mercado publicitario.</p><p>Acompañamos a nuestros clientes a través de todo el proceso para garantizar la correcta ejecución de las campañas.</p><p>Para construir las mejores estrategias de comunicación.</p><p><b>#TelevisaLlega más fuerte para ti.</b></p>',
        acercaDeImagen: '',
      }
    ];


    if (this.introContacto.length > 0) {
      for (let obj of this.introContacto) {
        this.contactosTitle = obj.acercaDeTitulo;
        this.contactosIntro = obj.acercaDeIntro;
        this.contactosText = obj.acercaDeTexto;
        this.contactosImg = obj.acercaDeImagen;
      }
    }
    this.isIntroLoaded = true;
    this.cdr.markForCheck();
  }

  ordenaArregloPropiedadOrden(obj1 ,obj2 ){
    if(obj1.orden > obj2.orden) { return 1;}
    if(obj1.orden < obj2.orden) { return -1;}
    return 0;
  }

  public getDatosContacto() {
    //console.log("Cargando datos - Acerca de");
    this.subscriptionContactos = this.cmsPlanComercialService.getAcercaDe().subscribe(response => {
      this.datosContacto = response.data.acercade;
      //console.log("Datos cargados - Acerca de", this.datosContacto);
      this.datosContacto.sort(this.ordenaArregloPropiedadOrden);
      this.isDirectorioLoaded = true;
      this.cdr.markForCheck();
    });
  }





}
