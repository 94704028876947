import { Component, OnInit, Input } from '@angular/core';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { CardData } from 'src/app/models/card-data.model';

@Component({
  selector: 'app-tarjeta-simple',
  templateUrl: './tarjeta-simple.component.html',
  styleUrls: ['./tarjeta-simple.component.scss']
})
export class TarjetaSimpleComponent implements OnInit {

  @Input() cardData: CardData;
  baseCMSUrl : string;

  constructor(private configuration: ConfigurationService) {
    this.baseCMSUrl = this.configuration.baseCmsUrl
  }

  ngOnInit(): void {
  }

  public getCardImg(imagen: string) {
    if(!imagen)
      return imagen;

    var urlImagen: string = imagen.replace(/ /g, '%20');
    return urlImagen;
  }

}
