import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { gsap } from "gsap/all";
import { OfertaContenido } from 'src/app/models/cms/cms.oferta.model';
import { ConfigurationService } from 'src/app/services/configuration.service';

@Component({
  selector: 'app-tarjeta-programa',
  templateUrl: './tarjeta-programa.component.html',
  styleUrls: ['./tarjeta-programa.component.scss']
})
export class TarjetaProgramaComponent implements OnInit {

  @Input() cardData: OfertaContenido;
  baseCMSUrl: string;
  constructor(
    public router: Router,
    private configuration: ConfigurationService
  ) 
  { 
    this.baseCMSUrl = this.configuration.baseCmsUrl
  }

  ngOnInit(): void {

  }

  public getCardImg(imagen: string) {
    var urlImagen: string = imagen.replace(/ /g,'%20');
    return urlImagen;
  }

  public navigateTo(path: string): void {
    this.router.navigateByUrl(path);
    gsap.to(window, {
      scrollTo: 0,
      duration: 0.5
    });
  }
}
