import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, HostListener } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { gsap } from "gsap/all";
import { faChevronDown, faChevronUp, faTimes, faChevronRight, faCheck, faPlus, faFilter, faMinus } from '@fortawesome/free-solid-svg-icons';
import { GtmService } from 'ngx-gtm';
import { CmsPlanComercialService } from 'src/app/services/cms/cms.plan.comercial.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { OfertaContenido } from 'src/app/models/cms/cms.oferta.model';
import { CmsOfertaService } from 'src/app/services/cms/cms.oferta.service';
import { AuthService } from 'src/app/services/auth.service';
import { CustomOrder } from 'src/app/helpers/custom-order';
import { ProductosPlancomercial } from 'src/app/models/cms/cms.plan.comercial.model';
import { DynamicHelper } from 'src/app/helpers/dynamic-helper';

@Component({
  selector: 'app-generos',
  templateUrl: './generos.component.html',
  styleUrls: ['./generos.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenerosComponent implements OnInit {

  title: string = 'Géneros';

  public screenWidth: number = 0;
  public screenRes: string = '';

  faChevronDown = faChevronDown;
  faChevronUp = faChevronUp;
  faTimes = faTimes;
  faChevronRight = faChevronRight;
  faCheck = faCheck;
  faPlus = faPlus;
  faMinus = faMinus;
  faFilter = faFilter;
  content: boolean = false;
  genero: string;
  bannerImage: string;
  bannerTitle: string;
  baseCMSUrl: string;
  datosGenero: any[]; /* Crear e importar modelo */
  datosProgramacion: OfertaContenido[];
  datosProgramacionServicio: OfertaContenido[];
  datosProgramacionCompleta: OfertaContenido[];
  catPlataformas: any[];

  nombreGenero: string;
  textoGenero: string;

  /*Catálogos*/
  catGeneros: any[];

  /* Filtros */
  canalesFiltrados: any[];
  generosFiltrados: any[];
  /*filtros mobile*/
  isFilterMenuOpen: boolean = false;
  isPlataformaMobileOpen: boolean = false;

  /*Loaders*/
  isBannerLoaded: boolean = false;
  isProgramasLoaded: boolean = false;

  breadcrumbsData: any[];

  isPlataformasOpen: boolean = false;
  plataformasLinks: any[];
  productos: ProductosPlancomercial[];
  linkIzziSky: string;
  isProductosLoaded: boolean = false;

  constructor(
    private gtm: GtmService,
    public router: Router,
    private route: ActivatedRoute,
    private _location: Location,
    private cmsPlanComercialService: CmsPlanComercialService,
    private cmsOfertaService: CmsOfertaService,
    private authService: AuthService,
    private configuration: ConfigurationService,
    private cdr: ChangeDetectorRef,
    private titleService: Title,
    private metaService: Meta
  ) {
    this.baseCMSUrl = this.configuration.baseCmsUrl;

  }

  ngOnInit(): void {
    this.gtm.push({ event: 'TelevisaLlega Géneros' });

    this.genero = '0';

    this.breadcrumbsData = [
      { nombre: 'Nuestra oferta', link: 'nuestra-oferta' },
      { nombre: 'Búsqueda por género', link: '' }
    ];

    if (this.authService.isLoggedIn) {
      this.getBannerImage();
      this.changeGeneroData(this.genero);
    }
    else {
      /* Me loggeo y luego voy por los banners */
      this.authService.loginByApp().subscribe(resp => {//console.log('Resp login:',resp)
        this.getBannerImage();
        this.changeGeneroData(this.genero);
      });
    }
    this.content = true;
    this.cdr.markForCheck();
    this.titleService.setTitle(this.title);
    this.metaService.addTags([
      { name: 'keywords', content: 'entretenimiento, noticias, propuestas, editorial, editorial Televisa, televisión regional, tv de paga, televisión de paga, plataformas digitales, Televisa, noticieros televisa, televisa novelas, las estrellas tv, novelas de televisa, programas, programas de Televisa, programación, eventos deportivos, series, series de canal 5, ligas de fútbol' },
      { name: 'description', content: 'Conoce la oferta de los géneros que tenemos para todas las audiencias, desde noticieros, deportes, películas, telenovelas, entretenimiento y muchos otros más.' },
      { name: 'robots', content: 'index, follow' }
    ]);

    this.getScreenRes();

  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.screenWidth = window.innerWidth;
    this.getScreenRes();
  }

  public getScreenRes () {
    if (this.screenWidth < 576) {
      this.screenRes = 'xs';
    } 
    else if (this.screenWidth >= 576 && this.screenWidth < 768) {
      this.screenRes = 'sm';
    }
    else if (this.screenWidth >= 768 && this.screenWidth < 992) {
      this.screenRes = 'md';
    }
    else if (this.screenWidth >= 992 && this.screenWidth < 1200) {
      this.screenRes = 'lg';
    }
    else if (this.screenWidth >= 1200 && this.screenWidth < 1440) {
      this.screenRes = 'xl';
    }
    else if (this.screenWidth >= 1440) {
      this.screenRes = 'xxl';
    }
    //console.log('bootstrap res:', this.screenRes);
  }

  changeGeneroData(generoData) {

    this.genero = generoData;
    this.getCatalogos();
    this.getBannerImage();
    this.getProgramacion();
    this.getProductos();
    this.getPlataformasLinks();

  }

  public getPlataformasLinks() {
    this.cmsOfertaService.getTiposDePlataforma().subscribe(response => {
      //console.log('Resonse de getTiposDePlataforma:',response);
      this.plataformasLinks = response.data.ofertaTiposdePlataforma;
      this.plataformasLinks.sort(this.ordenaArregloPropiedadOrdenPlataforma);

    });
  }

  public ordenaArregloPropiedadOrdenPlataforma(obj1, obj2) {
    if (obj1.orden > obj2.orden) { return 1; }
    if (obj1.orden < obj2.orden) { return -1; }
    return 0;
  }

  public getProductos() {
    this.cmsPlanComercialService.getProductos().subscribe(response => {
      this.productos = response.data.productosPlancomercial;
      this.productos.sort(this.ordenaArregloPropiedadOrden);
      this.isProductosLoaded = true;
      this.cdr.markForCheck();
      //console.log('this.productos', this.productos);
      for (let i = 0; i < this.productos?.length; i++) {
        if (this.productos[i].nombreTarjeta.includes('izzi')) {
          this.linkIzziSky = this.productos[i].linkProducto;
          //console.log('this.linkIzziSky', this.linkIzziSky);
        }
      }
    });

  }

  public getCatalogos() {
    /*crear catálogos*/
    this.catPlataformas = [
      { value: 'Televisión Radiodifundida', id: '1' },
      { value: 'Televisión de Paga', id: '2' },
      { value: 'Televisión Regional', id: '3' },
      { value: 'Digital', id: '4' },
      { value: 'Editorial', id: '5' },
      { value: 'Club América y Estadio Azteca', id: '6' },
    ];

    this.catGeneros = [];
    this.cmsOfertaService.getGeneros().subscribe(response => {
      for (let i = 0; i < response.data.ofertaGeneros.length; i++) {
        this.catGeneros.push({ value: response.data.ofertaGeneros[i].generoTarjeta, id: response.data.ofertaGeneros[i].contentItemId, orden: response.data.ofertaGeneros[i].idGeneroTarjeta });
      }
      this.catGeneros.sort(this.ordenaArregloPropiedadOrden);
      this.cdr.markForCheck();
    });
  }

  public getBannerImage() {

    /* Invocar servicio que recupera imagen de portada de la página*/
    this.cmsPlanComercialService.getBannerHero().subscribe(response => {
      for (let i = 0; i < response.data.bannersdePaquetes.length; i++) {
        if (response.data.bannersdePaquetes[i].displayText === 'Generos') {
          this.bannerImage = this.baseCMSUrl.slice(0, -1) + response.data.bannersdePaquetes[i].bannerImage.urls[0];
          this.bannerTitle = response.data.bannersdePaquetes[i].bannerTitle;
          this.isBannerLoaded = true;
          this.cdr.markForCheck();
        }
      }
    });
  }

  ordenaArregloPropiedadOrden(obj1, obj2) {
    if (obj1.orden > obj2.orden) { return 1; }
    if (obj1.orden < obj2.orden) { return -1; }
    return 0;
  }

  public getGeneros(genero: string) {
    this.textoGenero = 'Los contenidos y géneros más atractivos e interesantes en el mayor número de plataformas posible.';
    this.generosFiltrados = [];
    if (genero == '0') {
      this.generosFiltrados = this.catGeneros.slice();
    }
    else {
      this.generosFiltrados.push({ value: 'Genero filtrado', id: genero });
    }
    if (this.datosProgramacionCompleta) {
      this.datosProgramacion = this.datosProgramacionCompleta.filter(programa => this.generoExiste(programa.genero?.contentItems[0]?.contentItemId?.toString()));
      //console.log("Terminando getGeneros, estos son filtrados: ", this.generosFiltrados, this.datosProgramacion);  
    }
  }

  isTodosSelected() {
    if (this.genero == '0') {
      return true;
    }
    else {
      return false;
    }
  }

  changeFilterGeneros(idCheck, e) {
    //console.log("Entro a changeFilterGenero: ", idCheck);
    if (idCheck == '0') {
      this.generosFiltrados = this.catGeneros.slice();
      this.genero = '0';
    }
    else {
      this.generosFiltrados = [];
      this.generosFiltrados.push({ value: 'Genero filtrado', id: idCheck });
    }
    //console.log(this.canalesFiltrados);
    this.datosProgramacion = this.datosProgramacionCompleta.filter(programa => this.generoExiste(programa.genero?.contentItems[0]?.contentItemId?.toString()));
    this.closeFilter();
    this.cdr.markForCheck();
    //console.log("Salgo de changeFilterGenero, esta es la programacion: ", this.datosProgramacion);
    //console.log(this.datosProgramacion);
  }

  generoExiste(id) {
    //console.log(id,this.generosFiltrados);
    if (this.generosFiltrados.map(function (e) { return e.id; }).indexOf(id) != -1)// .indexOf(x => x.id.toString() === id.toString()) != -1)
    {
      return true;
    }
    else {
      //console.log(id, 'Genero no encontrado');
      return false;
    }
  }

  public getProgramacion() {
    //console.log('Entro a getProgramacion por genero');
    this.cmsOfertaService.getOfertaContenidos().subscribe(response => {
      //console.log('Resonse de getOfertaContenidos:',response);
      this.datosProgramacionServicio = response.data.ofertaContenidos;

      this.datosProgramacionServicio.forEach(element => {
        element.idPrograma = element.contentItemId;
        element.linkTarjeta = '/nuestra-oferta/detalle-programa/' + element.contentItemId;
        if (element.canalTarjetaContenido) {
          element.idPlataformaTarjeta = element.canalTarjetaContenido.contentItems[0].platId;
          this.catPlataformas.filter(p => p.id == element.canalTarjetaContenido.contentItems[0].platId).forEach(plat => {
            element.cintilloTarjeta = plat.value;
          })
        }
      });
      this.datosProgramacion = this.datosProgramacionServicio.slice();
      this.datosProgramacion.sort(CustomOrder.ordenaProgramacion);
      this.datosProgramacionCompleta = this.datosProgramacion.slice();
      //console.log('Salgo de getProgramacion por genero', this.datosProgramacionCompleta, this.datosProgramacion);
      this.isProgramasLoaded = true;
      this.cdr.markForCheck();
      this.getGeneros(this.genero);
    });
  }

  public isThisGenero(generoId) {
    if (generoId == this.genero) {
      return true;
    }
    else {
      return false;
    }
  }

  public openFilter() {
    if (!this.isFilterMenuOpen) {
      gsap.to(window, {
        scrollTo: 0,
        duration: 0.5
      });
      gsap.to(".tvs-mobile-filter", {
        width: '100%',
        padding: '20px',
        opacity: 1,
        duration: 0.5
      });
      this.isFilterMenuOpen = true;
    }
  }
  public closeFilter() {
    if (this.isFilterMenuOpen) {
      gsap.to(".tvs-mobile-filter", {
        width: 0,
        padding: '0px',
        opacity: 0,
        duration: 0.5
      });
      this.isFilterMenuOpen = false;
    }
  }

  public changeGenero(idGenero) {
    this.navigateToStill("/nuestra-oferta/generos;genero=" + idGenero);
  }

  public navigateTo(path: string): void {
    this.router.navigateByUrl(path);
    gsap.to(window, {
      scrollTo: 0,
      duration: 0.5
    });
  }

  isGeneroChecked(id) {
    if (id == this.genero) {
      return true;
    }
    else {
      return false;
    }

  }

  public togglePlataformas() {
    if (this.isProductosLoaded) {
      if (!this.isPlataformasOpen) {
        gsap.to(".tvs-combo-plat-menu", {
          height: 'auto',
          paddingTop: '30px',
          duration: 0.5
        });
        this.isPlataformasOpen = true;
      } else {
        gsap.to(".tvs-combo-plat-menu", {
          height: '0',
          paddingTop: '0',
          duration: 0.5
        });
        this.isPlataformasOpen = false;
      }
    }
  }

  public toggleMobilePlataforma() {
    if (!this.isPlataformaMobileOpen) {
      this.toggleMobileAllFilters();
      gsap.to(".tvs-mobile-filter-plat", {
        height: 'auto',
        duration: 0.5
      });
      this.isPlataformaMobileOpen = true;
    } else {
      gsap.to(".tvs-mobile-filter-plat", {
        height: '35px',
        duration: 0.5
      });
      this.isPlataformaMobileOpen = false;
    }
  }


  public toggleMobileAllFilters() {
    if (this.isPlataformaMobileOpen) { this.toggleMobilePlataforma(); }
  }

  public navigateToStill(path: string): void {
    this.router.navigateByUrl(path);
  }

  public navigateToExternal(path: string) {
    window.open(path, "_blank");
  }


  public goToProgram(path: string, nombre: string, plataforma: string, canal: string, genero: string, fecha: string, hora: string) {
    window.open(path, '_self');

    this.gtm.push({
      event: 'click-programa',
      type: 'Programa',
      from: 'Géneros',
      platform: plataforma,
      channel: canal,
      genre: genero,
      name: nombre,
      date: fecha,
      schedule: hora
    });

    //console.log(path, nombre, plataforma, canal, genero);
  }

  public goBack() {
    this._location.back();
  }

  public dynamicId(objeto: string, nombre: string) {
    return DynamicHelper.dynamicIdHelper(objeto, nombre);
  }

  public friendlyUrl(nombre: string) {
    return DynamicHelper.friendlyUrlHelper(nombre);
  }


}
