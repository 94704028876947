
<div class="row tvs-bg-white pb-5 tvs-programa-top px-2 px-sm-3 px-md-0 px-lg-4" *ngIf="!isProgramaLoaded">
  <div class="container-fluid pt-5 px-2 px-md-5 tvs-bg-white">
    <div class="loader-min">
      <img class="loader-img z-20" src="assets/images/website_general/loader-2.svg" alt="" />
    </div>
  </div>
</div>

<div class="row tvs-bg-white pb-5 tvs-programa-top px-2 px-sm-3 px-md-0 px-lg-4" *ngIf="isProgramaLoaded">
  <app-custom-breadcrumbs [breadcrumbsData]="breadcrumbsData"></app-custom-breadcrumbs>
  <div class="tvs-programa-backlogo">
    <div class="tvs-programa-backlogo-up"></div>
    <div class="tvs-programa-backlogo-down"></div>
  </div>
  <div class="container-fluid pt-5 px-2 px-md-5 tvs-bg-white">
    <!--Introducción-->
    <div class="row mb-5 pt-5">
      <div class="col-12 text-left">
        <div class="float-left tvs-programa-sidelogo mr-3 tvs-programa-sidelogo">
          <div class="tvs-programa-sidelogo-up"></div>
          <div class="tvs-programa-sidelogo-down"></div>
        </div>
        <h4 class="tvs-text-upper tvs-programa-contenidos mb-0">Contenidos</h4>
        <h1 class="tvs-text-orange tvs-programa-tituloplat">{{programaPlataforma}}</h1>
      </div>
    </div>
  </div>
</div>

<div class="row tvs-bg-white pb-5 px-2 px-md-5" *ngIf="!isProgramaLoaded">
  <div class="col-12">
    <div class="loader-min">
      <img class="loader-img z-20" src="assets/images/website_general/loader-2.svg" alt="" />
    </div>
  </div>
</div>

<div class="row tvs-bg-white pb-5 px-2 px-md-5" *ngIf="isProgramaLoaded">
  <div class="col-12 text-center px-5 d-block d-md-none">
      <img class="img-fluid tvs-rounded-corner" src="{{programaImagen}}" *ngIf="programaImagen && programaImagen != ''" />
      <img class="img-fluid tvs-rounded-corner" src="assets/images/website_general/paquetes_default.png" *ngIf="!programaImagen || programaImagen == ''" />
  </div>

  <div class="col-12 col-md-6">
    <div class="row">
      <div class="col-12 text-left my-5">
        <h3 class="tvs-text-black ">{{programaNombre}}</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-left mb-3 pr-lg-5">
        <h4 class="tvs-text-orange tvs-text-bold">Descripción</h4>
        <p class="small">{{programaDescripcion}}</p>
      </div>
      <div class="col-12 text-left mb-3">
        <div class="row">
          <div class="col-12">
            <h4 class="tvs-text-orange tvs-text-bold">Horario</h4>
          </div>
          <div class="col-12 mb-3">
            <div class="row pl-3">
              <div class="tvs-cuadro-fecha small text-center tvs-text-bold" *ngFor="let cuadros of diasCuadro; let i = index">{{cuadros}}</div>
            </div>
            <div class="row mt-3 pl-3" *ngIf="programaHora && programaHora !== ''">
              <div class="tvs-cuadro-fecha small text-center">{{programaHora}}</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <h4 class="tvs-text-red tvs-text-bold">Canal</h4>
          </div>
          <div class="col-12 mb-3">
            <a class="c-pointer" (click)="navigateTo('nuestra-oferta/plataforma/' + friendlyUrl(programaPlataforma) + '/' + programaCanalId)">
              <img class="tvs-canal-mini float-left mr-3" src="{{programaCanalImagen}}" />
            </a>
          </div>
        </div>
        <div class="row" *ngIf="programaWebsite && programaWebsite !== '' ">
          <div class="col-12">
            <h4 class="tvs-text-orange tvs-text-bold">Sitio web</h4>
          </div>
          <div class="col-12 mb-3">
            <a class="small" href="{{programaWebsite}}" target="_blank">{{programaWebsite}}</a>
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-3">
            <button [attr.id]="dynamicId('pautaPrograma-',programaNombre)" class="pautaPrograma btn btn-primary btn-orange tvs-btn d-none d-md-block" (click)="navigateTo('contacto')">Me interesa pautar aquí<fa-icon [icon]="faArrowRight" class="pl-2 tvs-text-white"></fa-icon></button>
            <button [attr.id]="dynamicId('pautaPrograma-',programaNombre)" class="pautaPrograma btn btn-primary btn-orange tvs-btn d-block d-md-none btn-block" (click)="navigateTo('contacto')">Me interesa pautar aquí<fa-icon [icon]="faArrowRight" class="pl-2 tvs-text-white"></fa-icon></button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6 d-none d-md-block">
    <img class="img-fluid tvs-rounded-corner" src="{{programaImagen}}" *ngIf="programaImagen && programaImagen != ''" />
    <img class="img-fluid tvs-rounded-corner" src="assets/images/website_general/paquetes_default.png" *ngIf="!programaImagen || programaImagen == ''" />
  </div>
  <!--Carrusel otros programas-->
  <div class="col-12 my-5">
    <div class="row  px-0">
      <div class="col-12 mt-5 px-0 text-center">
        <h3 class="tvs-text-orange tvs-text-bold mb-5">Conoce todos los contenidos de {{programaPlataforma}}</h3>
      </div>
    </div>
    <div class="row">
      <div class="col-10 offset-1 col-md-12 offset-md-0">
        <app-carrusel-programas [idPlataformaAsociada]="programaIdPlataforma" [nombrePlataforma]="programaPlataforma"></app-carrusel-programas>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center">
        <button class="btn btn-primary btn-orange tvs-btn" (click)="navigateTo('nuestra-oferta/plataforma/' + friendlyUrl(programaPlataforma))">Ver todos <fa-icon [icon]="faPlus" class="pl-1"></fa-icon></button>
      </div>
    </div>
  </div>
</div>
