import { Component, OnInit } from '@angular/core';
import { GtmService } from 'ngx-gtm';

@Component({
  selector: 'app-canales-regional',
  templateUrl: './canales-regional.component.html',
  styleUrls: ['./canales-regional.component.scss']
})
export class CanalesRegionalComponent implements OnInit {

  bannerImage: string;
  bannerTitle: string;
  canalesRegional: any[];
  /*Loaders*/
  isBannerLoaded: boolean = false;
  isCanalesLoaded: boolean = false;

  constructor(private gtm: GtmService) {
    this.getBannerImage();
    this.getCanalesRegional();
  }

  ngOnInit(): void {
    this.gtm.push({ event: 'TelevisaLlega Canales Regionales' });
  }

  public getBannerImage() {

    /* Invocar servicio que recupera imagen de portada de la página*/
    this.bannerImage = '';
    this.bannerTitle = 'Canales Televisón Regional';
    this.isBannerLoaded = true;
  }

  getCanalesRegional() {
  /* Invocar servicio que recupera info de canales regionales*/
    this.canalesRegional = [
      {
        "nombreCanal": "ACAPULCO, GRO.",
        "numeroCanal": "9.1"
      },
      {
        "nombreCanal": "AGUASCALIENTES, AGS.",
        "numeroCanal": "9.1"
      },
      {
        "nombreCanal": "CAMPECHE, CAMP.",
        "numeroCanal": "9.1"
      },
      {
        "nombreCanal": "CANCÚN, Q.ROO.",
        "numeroCanal": "9.1"
      },
      {
        "nombreCanal": "CD. JUÁREZ, CHIH.",
        "numeroCanal": "8.1"
      },
      {
        "nombreCanal": "CD. OBREGÓN, SON.",
        "numeroCanal": "5.1"
      },
      {
        "nombreCanal": "CD. VICTORIA, TAMPS.",
        "numeroCanal": "9.1"
      },
      {
        "nombreCanal": "CHETUMAL, Q. ROO ",
        "numeroCanal": "9.1"
      },
      {
        "nombreCanal": "CHIHUAHUA, CHIH. ",
        "numeroCanal": "5.2"
      },
      {
        "nombreCanal": "COATZACOALCOS, VER.",
        "numeroCanal": "9.1"
      },
      {
        "nombreCanal": "COLIMA, COL.",
        "numeroCanal": "9.1"
      },
      {
        "nombreCanal": "COMITÁN, CHIS.",
        "numeroCanal": "9.1"
      },
      {
        "nombreCanal": "CUERNAVACA, MOR.",
        "numeroCanal": "9.1"
      },
      {
        "nombreCanal": "CULIACÁN, SIN.",
        "numeroCanal": "9.1"
      },
      {
        "nombreCanal": "DURANGO, DGO.",
        "numeroCanal": "5.2"
      },
      {
        "nombreCanal": "ENSENADA B.C.N.",
        "numeroCanal": "4.1"
      },
      {
        "nombreCanal": "GUADALAJARA, JAL.",
        "numeroCanal": "8.1"
      },
      {
        "nombreCanal": "GUADALAJARA, JAL.",
        "numeroCanal": "4.1"
      },
      {
        "nombreCanal": "HERMOSILLO, SON.",
        "numeroCanal": "12.1"
      },
      {
        "nombreCanal": "LEÓN, GTO.",
        "numeroCanal": "12.1"
      },
      {
        "nombreCanal": "LOS MOCHIS, SIN.",
        "numeroCanal": "9.1"
      },
      {
        "nombreCanal": "MATAMOROS",
        "numeroCanal": "9.1"
      },
      {
        "nombreCanal": "MAZATLÁN, SIN.",
        "numeroCanal": "9.1"
      },
      {
        "nombreCanal": "MÉRIDA, YUC.",
        "numeroCanal": "8.1"
      },
      {
        "nombreCanal": "MEXICALI, B.C.N.",
        "numeroCanal": "4.1"
      },
      {
        "nombreCanal": "MONTERREY, N.L.",
        "numeroCanal": "8.1"
      },
      {
        "nombreCanal": "MONTERREY, N.L.",
        "numeroCanal": "4.1"
      },
      {
        "nombreCanal": "NUEVO LAREDO, TAMPS.",
        "numeroCanal": "4.1"
      },
      {
        "nombreCanal": "OAXACA, OAX.",
        "numeroCanal": "8.1"
      },
      {
        "nombreCanal": "PIEDRAS NEGRAS, COAH.",
        "numeroCanal": "9.1"
      },
      {
        "nombreCanal": "PUEBLA, PUE.",
        "numeroCanal": "4.1"
      },
      {
        "nombreCanal": "QUERÉTARO, QRO.",
        "numeroCanal": "9.1"
      },
      {
        "nombreCanal": "SALTILLO, COAH.",
        "numeroCanal": "9.1"
      },
      {
        "nombreCanal": "SAN CRISTOBAL",
        "numeroCanal": "9.1"
      },
      {
        "nombreCanal": "SAN LUÍS POTOSÍ, S.L.P.",
        "numeroCanal": "8.1"
      },
      {
        "nombreCanal": "TAMPICO, TAMPS.",
        "numeroCanal": "4.1"
      },
      {
        "nombreCanal": "TAPACHULA, CHIS.",
        "numeroCanal": "9.1"
      },
      {
        "nombreCanal": "TEPIC, NAY.",
        "numeroCanal": "9.1"
      },
      {
        "nombreCanal": "TIJUANA B.C.N.",
        "numeroCanal": "12.1"
      },
      {
        "nombreCanal": "TOLUCA, MEX.",
        "numeroCanal": "9.1"
      },
      {
        "nombreCanal": "TORREÓN, COAH.",
        "numeroCanal": "9.1"
      },
      {
        "nombreCanal": "TUXTLA GUTIÉRREZ, CHIS.",
        "numeroCanal": "9.1"
      },
      {
        "nombreCanal": "VERACRUZ, VER.",
        "numeroCanal": "8.1"
      },
      {
        "nombreCanal": "VILLAHERMOSA, TAB.",
        "numeroCanal": "9.1"
      },
      {
        "nombreCanal": "ZACATECAS, ZAC.",
        "numeroCanal": "9.1"
      }
    ];

    this.isCanalesLoaded = true;
  }

}
