export class DynamicHelper {

  static dynamicIdHelper(objeto: string, nombre: string) {
    var name;
    if (nombre && nombre !== '') {
      name = nombre.toLowerCase();
    } else {
      name = 'sin nombre';
    }
    name = name.replace(/[+]/g, '-mas-');
    name = name.replace(/[!¡¿@#’$%^&*(),.?":{}|<>]/g, '');
    name = name.replace(/[\s/]/g, '-');
    name = name.replace(/[á]/g, 'a');
    name = name.replace(/[é]/g, 'e');
    name = name.replace(/[í]/g, 'i');
    name = name.replace(/[ó]/g, 'o');
    name = name.replace(/[ú]/g, 'u');
    name = name.replace(/[ñ]/g, 'n');
    name = name.replace('--', '-');
    name = name.replace('--', '-');
    return objeto + name;
  }

  static friendlyUrlHelper(nombre: string) {
    var liga;
    if (nombre && nombre !== '') {
      liga = nombre.toLowerCase();
    } else {
      liga = 'sin nombre';
    }
    liga = liga.trim()
    liga = liga.replace(/[+]/g, '-');
    liga = liga.replace(/[!¡¿@#’$%^&*(),.?":{}|<>]/g, '');
    liga = liga.replace(/[\s/]/g, '-');
    liga = liga.replace(/[á]/g, 'a');
    liga = liga.replace(/[é]/g, 'e');
    liga = liga.replace(/[í]/g, 'i');
    liga = liga.replace(/[ó]/g, 'o');
    liga = liga.replace(/[ú]/g, 'u');
    liga = liga.replace(/[ñ]/g, 'n');
    liga = liga.replace('--', '-');
    liga = liga.replace('--', '-');
    return liga;
  }


} 
