<ng-container *ngIf="!isBannerLoaded">
  <div class="acerca-de mb-5">
    <div class="row tvs-banner-unloaded">
      <img class="loader-img z-20" src="assets/images/website_general/loader-2.svg" alt="" />
    </div>
  </div>
</ng-container>
<ng-container *ngIf="isBannerLoaded">
  <app-header-image-new srcImage="{{bannerImage}}" srcImageMobile="{{bannerImageMobile}}" srcTitle="{{bannerTitle}}" srcTipo="1" class="acerca-de"></app-header-image-new>
</ng-container>

<div class="row px-2 px-md-5 py-5 tvs-bg-white">

  <!--Texto Acerca De-->

  <div class="container-fluid pt-4">
    <ng-container *ngIf="!isIntroLoaded">
      <div class="loader-100">
        <img class="loader-img z-20" src="assets/images/website_general/loader-2.svg" alt="" />
      </div>
    </ng-container>
    <ng-container *ngIf="isIntroLoaded">
      <div class="row mt-5 mt-md-0">
        <div class="col-12 col-md-10 col-lg-8 text-left">
          <h3 class="my-4 tvs-text-orange tvs-text-bold" [innerHTML]="contactosIntro"></h3>
        </div>
        <div class="col-12">
          <div [innerHTML]="contactosText"></div>
        </div>
      </div>
    </ng-container>
  </div>

  <!--Contenidos-->
  
  <div class="container-fluid" id="directorio-comercial">
    <div class="row my-3" *ngIf="!isDirectorioLoaded">
      <div class="col-12">
        <div class="loader-min">
          <img class="loader-img z-20" src="assets/images/website_general/loader-2.svg" alt="" />
        </div>
      </div>
    </div>
    <div class="row my-5" *ngIf="isDirectorioLoaded">
      <div class="col-12 text-center">
        <h1 class="tvs-text-orange">Directorio comercial</h1>
      </div>
    </div>

    <div class="row" *ngIf="isDirectorioLoaded">
      <!--Cuadrícula-->
      <div class="col-12 mb-5">
        <div class="row tvs-contacto-{{screenRes}}">
          <ng-container *ngIf="isDirectorioLoaded" class="tvs-card-contacto-parent">
            <div *ngFor="let datosTarjeta of datosContacto; let i = index;" class="col-12 col-sm-6 col-lg-4 col-xl-3 tvs-card-contacto-selector">
              <app-tarjeta-contacto [contactoData]="datosTarjeta"></app-tarjeta-contacto>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>




</div>
