import { ContactMessage } from './../models/contact-message.model';
import { Injectable } from '@angular/core';
import { Observable, interval, BehaviorSubject } from 'rxjs';
import { map, flatMap, startWith } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { NotificationEndpoint } from './notification-endpoint.service';
import { Notification } from '../models/notification.model';
import { UserPeople } from '../models/user-people.model';
import { NotificationComplete } from '../models/notification-complete.model';
import { ContactEmail } from '../models/contact-emal.model';

@Injectable()
export class NotificationService {
  private lastNotificationDate: Date;
  private _recentNotifications: Notification[];
  private notificationsData = new BehaviorSubject<NotificationComplete[]>(new Array<NotificationComplete>());
  private notificationSelected = new BehaviorSubject<NotificationComplete>(new NotificationComplete());

  // Observable Object streams
  notificationsData$ = this.notificationsData.asObservable();
  notificationSelected$ = this.notificationSelected.asObservable();

  get currentUser() {
    return this.authService.currentUser;
  }

  get recentNotifications() {
    return this._recentNotifications;
  }

  set recentNotifications(notifications: Notification[]) {
    this._recentNotifications = notifications;
  }

  constructor(private notificationEndpoint: NotificationEndpoint, private authService: AuthService) {

  }

  setNotificationsData(newNotificationsData: NotificationComplete[]){
    this.notificationsData.next(newNotificationsData);
  }

  setNotificationSelected(NotificationComplete: NotificationComplete){
    this.notificationSelected.next(NotificationComplete);
  }

  private processNewNotificationsFromResponse(response) {
    const notifications = this.getNotificationsFromResponse(response);

    for (const n of notifications) {
      if (n.date > this.lastNotificationDate) {
        this.lastNotificationDate = n.date;
      }
    }

    return notifications;
  }

  private getNotificationsFromResponse(response) {
    const notifications: Notification[] = [];

    for (const i in response) {
      if (response.hasOwnProperty(i)) {
        notifications[i] = Notification.Create(response[i]);
      }
    }

    notifications.sort((a, b) => b.date.valueOf() - a.date.valueOf());
    notifications.sort((a, b) => (a.isPinned === b.isPinned) ? 0 : a.isPinned ? -1 : 1);

    this.recentNotifications = notifications;

    return notifications;
  }

  getNotificationsByUser(appId:number, userId: string){
    return this.notificationEndpoint.getNotificationsByUserEndpoint<NotificationComplete[]>(appId, userId);
  }

  getNotificationsByUserPeriodically(appId:number, userId: string) {
    return interval(60000).pipe(
      startWith(0),
      flatMap(() => {
        return this.notificationEndpoint.getNotificationsByUserEndpoint<NotificationComplete[]>(appId, userId)/*.pipe(
          map(response => this.processNewNotificationsFromResponse(response)))*/;
      }));
  }

  postContactMessage(notification: ContactMessage){
    return this.notificationEndpoint.getPostMessageContactEndpoint<ContactMessage[]>(notification);
  }

  postMessageReply(notification: ContactMessage){
    return this.notificationEndpoint.postMessageReplyContactEndpoint<ContactMessage[]>(notification);
  }

  putNotificationAsRead(app_id: number, notification_id: string, user_id: string){
    return this.notificationEndpoint.getPutNotificationAsRead<NotificationComplete>(app_id, notification_id, user_id);
  }
  
  postNewUserNotification(notification: ContactMessage){
    return this.notificationEndpoint.getPostNewUserContactEndpoint<ContactMessage[]>(notification);
  }

  putAcceptUserAdmin(app_id: number, user_id: string, company_id:string, is_accepted: number, company_role: string){
    return this.notificationEndpoint.getPutAcceptUserAsAdminEndpoint<UserPeople>(app_id, user_id, company_id, is_accepted, company_role);
  }

  getMessagesByUser(appId:number, userId: string, searchType: string){
    return this.notificationEndpoint.getMessagesByUserEndpoint<NotificationComplete[]>(appId, userId, searchType);
  }

  postSendEmailContact(correo: ContactEmail){
    return this.notificationEndpoint.postSendEmailContactEndpoint<ContactEmail>(correo);
  }

}
