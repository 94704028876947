import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Input } from '@angular/core';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { CmsOfertaService } from 'src/app/services/cms/cms.oferta.service';
import { OfertaContenido, OfertaPlataforma, TiposPlataformaResponse } from 'src/app/models/cms/cms.oferta.model';
import { CustomOrder } from 'src/app/helpers/custom-order';
import { GtmService } from 'ngx-gtm';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { gsap } from "gsap/all";
import { DynamicHelper } from 'src/app/helpers/dynamic-helper';

@Component({
  selector: 'app-carrusel-programas',
  templateUrl: './carrusel-programas.component.html',
  styleUrls: ['./carrusel-programas.component.scss']
})
export class CarruselProgramasComponent implements OnInit {

  @Input() idPlataformaAsociada: string = '0';
  @Input() nombrePlataforma: string = '';
  plataformaProgramas: string;
  baseCMSUrl: string;
  datosProgramacion: OfertaContenido[];
  datosProgramacionServicio: OfertaContenido[];
  datosProgramacionCompleta: any[];
  catPlataformas: any[];

  isCarruselLoaded: boolean = false;

  constructor(
    private gtm: GtmService,
    private configuration: ConfigurationService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private cmsOfertaService: CmsOfertaService,
    private cdr: ChangeDetectorRef,
  ) {
    this.baseCMSUrl = this.configuration.baseCmsUrl
  }

  ngOnInit(): void {
    //console.log('Estoy en plataforma:', this.plataformaProgramas);

    if (this.authService.isLoggedIn) {
      this.plataformaProgramas = this.idPlataformaAsociada;
      this.getProgramacion(this.plataformaProgramas);
    }
    else {
      this.authService.loginByApp().subscribe(resp => {
        this.plataformaProgramas = this.idPlataformaAsociada;
        this.getProgramacion(this.plataformaProgramas);
      });
    }

  }

  public getProgramacion(plataforma: string) {
    /* Cargo los programas desde el servicio */
    //console.log('Entro a getProgramacion por plataforma', plataforma);
    this.cmsOfertaService.getOfertaContenidos().subscribe(response => {
      //console.log('Response de getOfertaContenidos:', response);
      this.datosProgramacion = new Array<OfertaContenido>();
      this.datosProgramacionServicio = response.data.ofertaContenidos;
      for (let i = 0; i < this.datosProgramacionServicio.length; i++) {
        if (this.datosProgramacionServicio[i].canalTarjetaContenido?.contentItems[0]?.platId == plataforma) {
          //console.log('Coincide con plataforma: ', this.datosProgramacionServicio[i].canalTarjetaContenido?.contentItems[0]?.platId, this.datosProgramacionServicio[i]), 
          this.datosProgramacion.push(this.datosProgramacionServicio[i]);
        }
      }
      this.datosProgramacion.forEach(element => {
        element.idPrograma = element.contentItemId;
        element.linkTarjeta = '/nuestra-oferta/detalle-programa/' + element.contentItemId;
        if (element.canalTarjetaContenido) {
          element.idPlataformaTarjeta = element.canalTarjetaContenido.contentItems[0].platId;
        }
      });
      this.datosProgramacionCompleta = this.datosProgramacion;
      this.datosProgramacionCompleta.forEach(element => {
        element.orden = this.getRandom();
      });
      this.datosProgramacionCompleta.sort(CustomOrder.ordenaArregloPropiedadOrden);
      //console.log('Salgo de getProgramacion por plataforma', this.datosProgramacionCompleta);
      this.isCarruselLoaded = true;
      this.cdr.markForCheck();
    });

  }

  public getRandom() {
    return Math.floor(Math.random() * (100 - 1)) + 1;
  }

  public navigateTo(path: string): void {
    this.router.navigateByUrl(path);
    gsap.to(window, {
      scrollTo: 0,
      duration: 0.5
    });
  }

  customOptions: OwlOptions = {
    loop: false,
    rewind: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 500,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      800: {
        items: 3
      },
      940: {
        items: 4
      },
      1200: {
        items: 4
      },
      1500: {
        items: 5
      },
      2000: {
        items: 6
      }
    },
    //items: 4,
    nav: true,
    stagePadding: 0,
    autoplay: false,
    URLhashListener: true
  }

  public goToProgram(path: string, nombre: string, plataforma: string, canal: string, genero: string, fecha: string, hora: string) {
    window.open(path, '_self');
    //console.log(path, nombre, plataforma, canal, genero);
    this.gtm.push({
      event: 'click-programa',
      type: 'Programa',
      from: 'Productos',
      platform: plataforma,
      channel: canal,
      genre: genero,
      name: nombre,
      date: fecha,
      schedule: hora
    });
  }

  public dynamicId(objeto: string, nombre: string) {
    return DynamicHelper.dynamicIdHelper(objeto, nombre);
  }

  public friendlyUrl(nombre: string) {
    return DynamicHelper.friendlyUrlHelper(nombre);
  }


}
