<!--Desktop Nuevo-->
<div class="row tvs-menu-standard d-none d-lg-flex m-0 py-4">
  <!--Logo-->
  <div class="col-2 text-right z-20 tvs-logo-container-menu">
    <a href="" target="_self" aria-label="Botón inicio">
      <img class="tvs-logo mx-auto img img-fluid" src="assets/images/website_general/logo-televisaunivisionllega.png" alt="Botón inicio" width="117.5" height="45"/>
    </a>
  </div>
  <!--Cuerpo menu-->
  <div class="col-10 tvs-menu-row-desktop text-center">
    <div class="row">
      <div class="col-12">
        <div class="btn-group btn-group-toggle tvs-navbar-menu" data-toggle="buttons">
          <div class="btn-menu tvs-menu-oferta" [ngClass]="{'active': currentURL.includes('nuestra-oferta')}" (mouseenter)="openSubMenuOferta()" (mouseleave)="closeSubMenuOferta()">
            <div class="h-100 w-100 p-0 m-0" (click)="navigateTo('nuestra-oferta')">
              <a id="nuestraOferta" href="">Nuestra oferta<fa-icon [icon]="faChevronDown" class="pl-1"></fa-icon></a>
            </div>
            <!--Submenu nuestra-oferta-->
            <div class="tvs-submenu-desktop tvs-submenu-oferta text-left">
              <ul>
                <ng-container *ngFor="let item of objPlataformas; let i = index;">
                  <li *ngIf="item.idPlataforma !== 6 && item.idPlataforma !== 7">
                    <!--Todos, excepto izzi+sky y patrocinios deportivos-->
                    <a class="btn btn-link tvs-text-bold" (click)="navigateTo('nuestra-oferta/plataforma/' +  friendlyUrl(item.displayText))">{{item.displayText}}</a>
                  </li>
                  <li *ngIf="item.idPlataforma == 7">
                    <!--excepción para izzi+sky -->
                    <a class="btn btn-link tvs-text-bold" href="{{linkIzziSky}}" target="_blank">{{item.displayText}}</a>
                  </li>
                </ng-container>
                <li>
                  <a class="btn btn-link tvs-text-bold" href="nuestra-oferta/patrocinios-deportivos" target="_self">Patrocinios Deportivos</a>
                </li>
                <li>
                  <a class="btn btn-link tvs-text-bold" (click)="navigateTo('nuestra-oferta/generos')">Géneros</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="btn-menu tvs-menu-plan" [ngClass]="{'active': currentURL.includes('plan-comercial')}" (mouseenter)="openSubMenuPlanComercial()" (mouseleave)="closeSubMenuPlanComercial()">
            <div class="h-100 w-100 p-0 m-0" (click)="navigateTo('plan-comercial')">
              <a id="planComercial" >Plan Comercial<fa-icon [icon]="faChevronDown" class="pl-1"></fa-icon></a>
            </div>
            <!--Submenu plan-comercial-->
            <div class="tvs-submenu-desktop tvs-submenu-plan text-left">
              <ul>
                <li  *ngFor="let item of productos; let i = index;">
                  <a class="btn btn-link tvs-text-bold" (click)="navigateTo('plan-comercial/productos/' + friendlyUrl(item.nombreTarjeta))">{{item.nombreTarjeta}}</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="btn-menu" [ngClass]="{'active': currentURL.includes('paquetes')}">
            <div class="h-100 w-100 p-0 m-0" (click)="navigateTo('paquetes')">
              <a id="paquetes" href="">Paquetes</a>
            </div>
          </div>
          <div class="btn-menu" [ngClass]="{'active': currentURL.includes('futurecast')}">
            <div class="h-100 w-100 p-0 m-0" (click)="navigateTo('futurecast')">
              <a id="futurecast" href="">Futurecast</a>
            </div>
          </div>
          <div class="btn-menu" [ngClass]="{'active': currentURL.includes('acerca-de')}">
            <div class="h-100 w-100 p-0 m-0" (click)="navigateTo('acerca-de')">
              <a id="acercaDe" href="">Acerca de</a>
            </div>
          </div>
          <div class="btn-menu" [ngClass]="{'active': currentURL.includes('eventos')}">
            <div class="h-100 w-100 p-0 m-0" (click)="navigateTo('eventos')">
              <a id="eventos" href="">Eventos</a>
            </div>
          </div>
          <div class="btn-menu" [ngClass]="{'active': currentURL.includes('contacto')}">
            <div class="h-100 w-100 p-0 m-0"  (click)="navigateTo('contacto')">
              <a id="contacto" href="">Contacto</a>
            </div>
          </div>
          <!--Buscador-->
          <form [formGroup]="desktopFormGroup" autocomplete="off" (submit)="search('d')" class="tvs-search-form">
            <label class="btn btn-menu search-box">
              <input type="text" formControlName="searchDesktop" name="buscar" id="buscar" autocomplete="off" class="input-search" placeholder="Buscar" (blur)="closeSearchBox()" />
              <button type="button" class="btn btn-sm btn-search c-pointer" (click)="search('d')" aria-label="Buscador">
                <fa-icon [icon]="faSearch"></fa-icon>
              </button>
            </label>
          </form>
          <div class="tvs-circle-search text-center align-middle c-pointer" (click)="openSearchBox()">
            <fa-icon [icon]="faSearch"></fa-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>




<!--tablet & mobile-->
<div class="row tvs-menu-mobile d-flex d-lg-none w-100">
  <div class="col-6 text-left z-2000">
    <a href="" target="_self" aria-label="Botón inicio">
      <img class="tvs-logo mx-auto img img-fluid" src="assets/images/website_general/logo-televisaunivisionllega.png" alt="Botón inicio" width="117.5" height="45"/>
    </a>
  </div>
  <div class="col-6 text-right z-2000">
    <a class="btn btn-link" (click)="toggleMobileMenu()" aria-label="Botón menú">
      <div class="mobile-main-menu-open" *ngIf="!isMobileOpen">
        <fa-icon class="tvs-text-white" [icon]="faBars"></fa-icon>
      </div>
      <div class="mobile-main-menu-close" *ngIf="isMobileOpen">
        <fa-icon class="tvs-text-white" [icon]="faTimes"></fa-icon>
      </div>
    </a>
  </div>
  <div class="topnav">
    <ul class="m-0 p-0">
      <li>
        <a class="btn btn-link my-2 tvs-mobile-submenu-not-toggle"
           (click)="navigateTo('nuestra-oferta')"
           [ngClass]="{'active': currentURL.includes('nuestra-oferta')}">Nuestra oferta</a>
        <a class="tvs-mobile-submenu-toggle"
           (click)="toggleSubMenuOfertaMobile()"><fa-icon [icon]="faChevronDown" class="float-right" *ngIf="!mobileSubmenuOfertaOpen"></fa-icon><fa-icon [icon]="faChevronUp" class="float-right" *ngIf="mobileSubmenuOfertaOpen"></fa-icon></a>
        <div class="tvs-submenu-oferta-mobile" [ngClass]="{'open': mobileSubmenuOfertaOpen}">
          <ul>
            <ng-container *ngFor="let item of objPlataformas; let i = index;">
              <li *ngIf="item.idPlataforma !== 6 && item.idPlataforma !== 7">
                <!--Todos, excepto izzi+sky y patrocinios deportivos-->
                <a class="btn btn-link my-1" (click)="navigateTo('nuestra-oferta/plataforma/' + friendlyUrl(item.displayText))">{{item.displayText}}</a>
              </li>
              <li *ngIf="item.idPlataforma == 7">
                <!--excepción para izzi+sky -->
                <a class="btn btn-link my-1" href="{{linkIzziSky}}" target="_blank">{{item.displayText}}</a>
              </li>
            </ng-container>
            <li>
              <a class="btn btn-link my-1" href="nuestra-oferta/patrocinios-deportivos" target="_self">Patrocinios Deportivos</a>
            </li>
            <li>
              <a class="btn btn-link my-1" (click)="navigateTo('nuestra-oferta/generos')">Géneros</a>
            </li>
          </ul>
        </div>
      </li>
      <li>
        <a class="btn btn-link my-2 tvs-mobile-submenu-not-toggle"
           (click)="navigateTo('plan-comercial')"
           [ngClass]="{'active': currentURL.includes('plan-comercial')}">Plan Comercial</a>
        <a class="tvs-mobile-submenu-toggle"
           (click)="toggleSubMenuPlanMobile()"><fa-icon [icon]="faChevronDown" class="float-right" *ngIf="!mobileSubmenuPlanOpen"></fa-icon><fa-icon [icon]="faChevronUp" class="float-right" *ngIf="mobileSubmenuPlanOpen"></fa-icon></a>
        <div class="tvs-submenu-plan-mobile" [ngClass]="{'open': mobileSubmenuPlanOpen}">
          <ul>
            <li *ngFor="let item of productos; let i = index;">
              <a class="btn btn-link my-1" (click)="navigateTo('plan-comercial/productos/' + friendlyUrl(item.nombreTarjeta))">{{item.nombreTarjeta}}</a>
            </li>
          </ul>
        </div>
      </li>
      <li>
        <a class="btn btn-link my-2" (click)="navigateTo('paquetes')" [ngClass]="{'active': currentURL.includes('paquetes')}">Paquetes</a>
      </li>
      <li>
        <a class="btn btn-link my-2" (click)="navigateTo('futurecast')" [ngClass]="{'active': currentURL.includes('futurecast')}">Futurecast</a>
      </li>
      <li>
        <a class="btn btn-link my-2" (click)="navigateTo('acerca-de')" [ngClass]="{'active': currentURL.includes('acerca-de')}">Acerca de</a>
      </li>
      <li>
        <a class="btn btn-link my-2" (click)="navigateTo('eventos')" [ngClass]="{'active': currentURL.includes('eventos')}">Eventos</a>
      </li>
      <li>
        <a class="btn btn-link my-2" (click)="navigateTo('contacto')" [ngClass]="{'active': currentURL.includes('contacto')}">Contacto</a>
      </li>

      <li class="my-4">
        <form [formGroup]="mobileFormGroup" autocomplete="off" (submit)="search('m')" class="tvs-mobile-search-form">
          <button type="button" class="btn btn-sm c-pointer btn-search-mobile pl-2" (click)="search('m')">
            <fa-icon [icon]="faSearch"></fa-icon>
          </button>
          <input type="text" formControlName="searchMobile" name="buscarMobile" id="buscarMobile" class="input-search input-search-mobile" placeholder="" />
        </form>
      </li>


    </ul>
  </div>


</div>
