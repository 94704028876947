<div class="row tvs-evento-tarjeta tvs-o-hidden">
  <div class="col-12 col-md-6 col-lg-3 px-0 tvs-o-hidden">
    <div class="tvs-img-container"
         [ngStyle]="{'background-image': 'url('+ getCardImg(eventData.imagenEvento) +')'}"
         *ngIf="eventData.imagenEvento && eventData.imagenEvento != ''">
    </div>
    <div class="tvs-img-container"
         style="background-image: url('assets/images/website_general/paquetes_default.png')"
         *ngIf="!eventData.imagenEvento || eventData.imagenEvento == ''">
    </div>
  </div>
  <div class="col-12 col-md-6 tvs-center-tab">
    <h3 class="tvs-text-orange tvs-text-bold my-4 tvs-evento-tarjeta-titulo">{{eventData.nombreEvento}}</h3>
    <div class="small mb-3" style="white-space: pre-line" [innerHTML]="eventData.textoEvento" *ngIf="eventData.textoEvento && eventData.textoEvento != ''"></div>

  </div>
  <div class="col-12 col-lg-3">
    <h3 class="tvs-text-orange tvs-text-bold my-4" *ngIf="eventData.fechaEvento && eventData.fechaEvento != ''">Fecha</h3>
    <p class="small" *ngIf="eventData.fechaEvento && eventData.fechaEvento != ''">{{eventData.fechaEvento}}</p>
    <p class="small" *ngIf="eventData.horarioEvento && eventData.horarioEvento != ''">{{eventData.horarioEvento}}</p>
    <div class="w-100 text-left mt-5 mb-3" *ngIf="eventData.linkEvento && eventData.linkEvento != ''">
      <button class="btn btn-primary btn-orange tvs-btn" (click)="goToEvent(eventData.linkEvento, eventData.nombreEvento)">
        Ver más<fa-icon [icon]="faArrowRight" class="pl-2"></fa-icon>
      </button>
    </div>
  </div>
</div>
