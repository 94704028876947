import { Component, OnInit, HostListener, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService, AlertDialog, DialogType } from '../../../services/alert.service';

import { ConfigurationService } from '../../../services/configuration.service';
import { Subject, Subscription } from 'rxjs';
import { fadeInOut } from '../../../services/animations';
import { gsap } from "gsap/all";
const alertify: any = require('../../../assets/scripts/alertify.js');


@Component({
  selector: 'app-web-layout',
  templateUrl: './web-layout.component.html',
  styleUrls: ['./web-layout.component.scss'],
  animations: [fadeInOut],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WebLayoutComponent implements OnInit {

  scrollPositionTop: number;
  isScroll: boolean = false;

  /* Subscriptions */
  dialogSubscription: Subscription;
  messagesSubscription: Subscription;
  stickyToasties: number[] = [];

  destroy = new Subject();
 	destroy$ = this.destroy.asObservable();

  constructor(
    private alertService: AlertService,
    public configurations: ConfigurationService,
    public router: Router
  ) {
    
  }

  

  ngOnDestroy(): void {
    this.destroy.next();
  }

  ngOnInit(): void {
    this.dialogSubscription = this.alertService.getDialogEvent().subscribe(alert => this.showDialog(alert));
  }
  showDialog(dialog: AlertDialog) {
    //console.log('Mostrando dialog:', dialog);
    alertify.set({
      labels: {
        ok: dialog.okLabel || 'OK',
        cancel: dialog.cancelLabel || 'Cancel'
      }
    });

    switch (dialog.type) {
      case DialogType.alert:
        alertify.alert(dialog.message);

        break;
      case DialogType.confirm:
        alertify
          .confirm(dialog.message, (e) => {
            if (e) {
              dialog.okCallback();
            } else {
              if (dialog.cancelCallback) {
                dialog.cancelCallback();
              }
            }
          });

        break;
      case DialogType.prompt:
        alertify
          .prompt(dialog.message, (e, val) => {
            if (e) {
              dialog.okCallback(val);
            } else {
              if (dialog.cancelCallback) {
                dialog.cancelCallback();
              }
            }
          }, dialog.defaultValue);

        break;
    }
  }
  
  @HostListener("window:scroll", []) onWindowScroll() {
    const verticalOffset = window.pageYOffset 
          || document.documentElement.scrollTop 
          || document.body.scrollTop || 0;
    if(verticalOffset > 10)
    {
      this.isScroll = true;
    }
    else{
      this.isScroll = false;
    }
    //console.log('Scroll vertical: ', verticalOffset);
}

  public scrollTopWindow() {
    gsap.to(window, {
      scrollTo: 0,
      duration: 1,
      ease: "power1.out"
    });
  }
}
