import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { gsap } from "gsap/all";
import { BuscadorTelevisa } from 'src/app/models/buscador-televisa.model';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { GtmService } from 'ngx-gtm';
import { DynamicHelper } from 'src/app/helpers/dynamic-helper';

@Component({
  selector: 'app-tarjeta-buscador',
  templateUrl: './tarjeta-buscador.component.html',
  styleUrls: ['./tarjeta-buscador.component.scss']
})
export class TarjetaBuscadorComponent implements OnInit {

  @Input() cardData: BuscadorTelevisa;
  baseCMSUrl: string;

  constructor(
    private gtm: GtmService,
    public router: Router,
    private configuration: ConfigurationService
  ) {
    this.baseCMSUrl = "";
  }

  ngOnInit(): void {

  }

  public getCardImg(imagen: string) {
    var urlImagen: string = imagen.replace(/ /g, '%20');
    return urlImagen;
  }

  public navigateTo(path: string): void {
    this.router.navigateByUrl(path);
    gsap.to(window, {
      scrollTo: 0,
      duration: 0.5
    });
  }

  public dynamicId(objeto: string, nombre: string) {
    return DynamicHelper.dynamicIdHelper(objeto, nombre);
  }

  public goToLink(path: string, nombre: string, tipoContenido: number) {
    window.open(path, '_blank');
    var tipo: string;
    switch (tipoContenido) {
      case 1:
        tipo = 'Programa';
        break;
      case 2:
        tipo = 'Futurecast';
        break;
      case 3:
        tipo = 'Evento';
        break;
      case 4:
        tipo = 'Paquete';
        break;
      case 5:
        tipo = 'Página';
        break;
      default:
        break;
    }
    this.gtm.push({
      event: 'click-buscador',
      type: tipo,
      from: 'Buscador',
      name: nombre,
    });
    
  }

}
