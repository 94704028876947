import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, Event, NavigationEnd } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { gsap } from "gsap/all";
import { GtmService } from 'ngx-gtm';
import { CmsPlanComercialService } from 'src/app/services/cms/cms.plan.comercial.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { AuthService } from 'src/app/services/auth.service';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { CmsOfertaService } from 'src/app/services/cms/cms.oferta.service';
import { OfertaPlataforma, TiposPlataformaResponse, OfertaTiposdePlataforma } from 'src/app/models/cms/cms.oferta.model';
import { ProductosPlancomercial } from 'src/app/models/cms/cms.plan.comercial.model';
import { DynamicHelper } from 'src/app/helpers/dynamic-helper';

@Component({
  selector: 'app-nuestra-oferta',
  templateUrl: './nuestra-oferta.component.html',
  styleUrls: ['./nuestra-oferta.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NuestraOfertaComponent implements OnInit {

  title: string = 'Nuestra oferta';

  currentURL: string;

  faPlus = faPlus;

  bannerImage: string;
  bannerImageMobile: string;
  bannerTitle: string;
  baseCMSUrl: string;

  /*Loaders*/
  isBannerLoaded: boolean = false;

  tiposPlataformaResponse: TiposPlataformaResponse;
  objPlataformas: OfertaTiposdePlataforma[];
  catMediosCompletos: OfertaPlataforma[];
  productos: ProductosPlancomercial[];

/* Plataformas */

  canalesPlataforma: any[];

  isOfertaLoaded: boolean = false;

  objPlataformaDigital: any[]; //1
  objPlataformaRadiodif: any[]; //2
  objPlataformaRegional: any[]; //3
  objPlataformaNetworks: any[]; //4
  objPlataformaIzzi: any[]; //5
  objPlataformaEditorial: any[]; //6
  objPlataformaPatrocinios: any[]; //7

  isLoadedPlataformaDigital: boolean = false; //1
  isLoadedPlataformaRadiodif: boolean = false; //2
  isLoadedPlataformaRegional: boolean = false; //3
  isLoadedPlataformaNetworks: boolean = false; //4
  isLoadedPlataformaIzzi: boolean = false; //5
  isLoadedPlataformaEditorial: boolean = false; //6
  isLoadedPlataformaPatrocinios: boolean = false; //7

  objCanalesRadiodif: any[]; //2
  objCanalesRegional: any[]; //3
  objCanalesNetworks: any[]; //4
  objCanalesIzzi: any[]; //5
  objCanalesEditorial: any[]; //6
  objCanalesPatrocinios: any[]; //7


  isLoadedCanalesRadiodif: boolean = false;
  isLoadedCanalesNetworks: boolean = false;
  isLoadedCanalesEditorial: boolean = false;
  isLoadedCanalesPatrocinios: boolean = false;

  baseUrlPlataformas: string = 'nuestra-oferta/plataforma/';
  baseUrlProductos: string = 'plan-comercial/productos/';

  
  constructor(
    private gtm: GtmService,
    public router: Router,
    private cmsPlanComercialService: CmsPlanComercialService,
    private configuration: ConfigurationService,
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private titleService: Title,
    private metaService: Meta,
    private cmsOfertaService: CmsOfertaService,
  ) 
  {
    this.baseCMSUrl = this.configuration.baseCmsUrl;
    if (this.authService.isLoggedIn) {
      this.getMediosPlataforma();
      this.getBannerImage();
      this.getPlataformasFullData();
      this.getProductos();
    }
    else {
        /* Me loggeo y luego voy por los banners */
      this.authService.loginByApp().subscribe(resp => {//console.log('Resp login:',resp)
        this.getMediosPlataforma();
        this.getBannerImage();
        this.getPlataformasFullData();
        this.getProductos();
      });
    }
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentURL = event.url;
      }
    });
  }

  ngOnInit(): void {
    this.gtm.push({ event: 'TelevisaLlega Nuestra oferta' });

    this.titleService.setTitle(this.title);
    this.metaService.addTags([
      { name: 'keywords', content: 'televisión, televisión abierta, televisión de paga, televisión regional, editorial Televisa, Estadio Azteca, Club América, Televisa digital, entretenimiento, noticias, novelas, deportes, películas, programas, revistas, comedia, series, música, reality show, programación, canal de televisión, canales, Televisa, fútbol' },
      { name: 'description', content: 'Conoce los diversos canales, programas, sitios, revistas y toda la oferta comercial disponible de Televisa para cada necesidad de comunicación.' },
      { name: 'robots', content: 'index, follow' }
    ]);
  }

  public getProductos() {
    this.cmsPlanComercialService.getProductos().subscribe(response => {
      this.productos = response.data.productosPlancomercial;
      this.productos.sort(this.ordenaArregloPropiedadOrden);
      this.cdr.markForCheck();
      //console.log('this.productos', this.productos);
    });
  }

  ordenaArregloPropiedadOrden(obj1, obj2) {
    if (obj1.orden > obj2.orden) { return 1; }
    if (obj1.orden < obj2.orden) { return -1; }
    return 0;
  }

  public getBannerImage() {
    /* Invocar servicio que recupera imagen de portada de la página*/
    this.cmsPlanComercialService.getBannerHero().subscribe(response => {
      for(let i = 0; i < response.data.bannersdePaquetes.length ; i++) {
        if(response.data.bannersdePaquetes[i].displayText === 'Nuestra-oferta') {
          this.bannerImage = this.baseCMSUrl.slice(0, -1) + response.data.bannersdePaquetes[i].bannerImage.urls[0];
          this.bannerImageMobile = this.baseCMSUrl.slice(0, -1) + response.data.bannersdePaquetes[i].imagenMobile.urls[0];
          this.bannerTitle = response.data.bannersdePaquetes[i].bannerTitle;
          this.isBannerLoaded = true;
          this.cdr.markForCheck();
        }
      }
    });
  }

  public navigateTo(path: string): void {
    this.router.navigateByUrl(path);
    gsap.to(window, {
      scrollTo: 0,
      duration: 0.5
    });
  }

  customOptionsMobile: OwlOptions = {
    loop: false,
    rewind: true,
    mouseDrag: false,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 500,
    navText: ['', ''],
    items: 1,
    nav: true,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplaySpeed: 1000,
  }

/* Obtengo data de plataformas */

  public getPlataformasFullData() {
    this.objPlataformas = [];
    this.cmsOfertaService.getTiposDePlataforma().subscribe(response => {
      this.tiposPlataformaResponse = response;
      this.objPlataformas = this.tiposPlataformaResponse.data.ofertaTiposdePlataforma;
      //console.log('Response de getTiposDePlataforma:', this.tiposPlataformaResponse);
      this.objPlataformas.sort(this.ordenaArregloPropiedadOrdenPlataforma);

      //console.log('this.objPlataformas:', this.objPlataformas);

      for (let i = 0; i < this.objPlataformas?.length; i++) {
        switch (this.objPlataformas[i].orden.toString()) {
          case '1':
            this.objPlataformaDigital = [
              {
                'idPlataforma': this.objPlataformas[i].idPlataforma,
                'displayText': this.objPlataformas[i].displayText,
                'plataformaTexto': this.objPlataformas[i].plataformaTexto
              }
            ];
            this.isLoadedPlataformaDigital = true;
            this.cdr.markForCheck();
            //console.log('this.objPlataformaDigital', this.objPlataformaDigital);
            break;
          case '2':
            this.objPlataformaRadiodif = [
              {
                'idPlataforma': this.objPlataformas[i].idPlataforma,
                'displayText': this.objPlataformas[i].displayText,
                'plataformaTexto': this.objPlataformas[i].plataformaTexto
              }
            ];
            this.isLoadedPlataformaRadiodif = true;
            this.cdr.markForCheck();
            //console.log('this.objPlataformaRadiodif', this.objPlataformaRadiodif);
            break;
          case '3':
            this.objPlataformaRegional = [
              {
                'idPlataforma': this.objPlataformas[i].idPlataforma,
                'displayText': this.objPlataformas[i].displayText,
                'plataformaTexto': this.objPlataformas[i].plataformaTexto
              }
            ];
            this.isLoadedPlataformaRegional = true;
            this.cdr.markForCheck();
            //console.log('this.objPlataformaRegional', this.objPlataformaRegional);
            break;
          case '4':
            this.objPlataformaNetworks = [
              {
                'idPlataforma': this.objPlataformas[i].idPlataforma,
                'displayText': this.objPlataformas[i].displayText,
                'plataformaTexto': this.objPlataformas[i].plataformaTexto
              }
            ];
            this.isLoadedPlataformaNetworks = true;
            this.cdr.markForCheck();
            //console.log('this.objPlataformaNetworks', this.objPlataformaNetworks);
            break;
          case '5':
            this.objPlataformaIzzi = [
              {
                'idPlataforma': this.objPlataformas[i].idPlataforma,
                'displayText': this.objPlataformas[i].displayText,
                'plataformaTexto': this.objPlataformas[i].plataformaTexto
              }
            ];
            this.isLoadedPlataformaIzzi = true;
            this.cdr.markForCheck();
            //console.log('this.objPlataformaIzzi', this.objPlataformaIzzi);
            break;
          case '6':
            this.objPlataformaEditorial = [
              {
                'idPlataforma': this.objPlataformas[i].idPlataforma,
                'displayText': this.objPlataformas[i].displayText,
                'plataformaTexto': this.objPlataformas[i].plataformaTexto
              }
            ];
            this.isLoadedPlataformaEditorial = true;
            this.cdr.markForCheck();
            //console.log('this.objPlataformaEditorial', this.objPlataformaEditorial);
            break;
          case '7':
            this.objPlataformaPatrocinios = [
              {
                'idPlataforma': this.objPlataformas[i].idPlataforma,
                'displayText': this.objPlataformas[i].displayText,
                'plataformaTexto': this.objPlataformas[i].plataformaTexto
              }
            ];
            this.isLoadedPlataformaPatrocinios = true;
            this.cdr.markForCheck();
            //console.log('this.objPlataformaPatrocinios', this.objPlataformaPatrocinios);
            break;
          default:
            break;
        }
      }
    });
  }

  public ordenaArregloPropiedadOrdenPlataforma(obj1, obj2) {
    if (obj1.orden > obj2.orden) { return 1; }
    if (obj1.orden < obj2.orden) { return -1; }
    return 0;
  }

  public ordenaArregloPropiedadIdPlataforma(obj1, obj2) {
    if (obj1.idPlataforma > obj2.idPlataforma) { return 1; }
    if (obj1.idPlataforma < obj2.idPlataforma) { return -1; }
    return 0;
  }

  public getMediosPlataforma() {
    this.catMediosCompletos = [];
    this.cmsOfertaService.getPlataformas().subscribe(response => {
      this.catMediosCompletos = response.data.ofertaPlataformas;
      for (let i = 0; i < this.catMediosCompletos?.length; i++) {
        this.getPlataformaCanales(i.toString());
      }
      //console.log('this.catMediosCompletos:',this.catMediosCompletos);
    });
    this.cdr.markForCheck();
  }

  public ordenaArregloPlataformas(obj1, obj2) {
    if (obj1.platId > obj2.platId) { return 1; }
    if (obj1.platId < obj2.platId) { return -1; }
    return 0;
  }

  public getPlataformaCanales(plataformaId: string) {
    
    this.canalesPlataforma = [];
    for (let i = 0; i < this.catMediosCompletos?.length; i++) {
      if (this.catMediosCompletos[i].platId == plataformaId) {
        this.canalesPlataforma.push(
          {
            platId: this.catMediosCompletos[i].platId,
            platMediaId: this.catMediosCompletos[i].platMediaId,
            platNombre: this.catMediosCompletos[i].platNombre,
            platImg: this.baseCMSUrl.slice(0, -1) + this.catMediosCompletos[i].platImg.urls[0],
            numSecu: this.catMediosCompletos[i].numSecu,
            genero: this.catMediosCompletos[i].generoodescripcioncorta
          });
      }
    }

    this.canalesPlataforma.sort(this.ordenaArregloPropiedadNumSecu);
    switch (plataformaId) {
      case '1':
        this.objCanalesRadiodif = this.canalesPlataforma;
        this.isLoadedCanalesRadiodif = true;
        this.cdr.markForCheck();
        //console.log('this.objCanalesRadiodif', this.objCanalesRadiodif);
        break;
      case '2':
        this.objCanalesNetworks = this.canalesPlataforma;
        this.isLoadedCanalesNetworks = true;
        this.cdr.markForCheck();
        //console.log('this.objCanalesNetworks', this.objCanalesNetworks);
        break;
      case '5':
        this.objCanalesEditorial = this.canalesPlataforma;
        this.isLoadedCanalesEditorial = true;
        this.cdr.markForCheck();
        //console.log('this.objCanalesEditorial', this.objCanalesEditorial);
        break;
      case '6':
        this.objCanalesPatrocinios = this.canalesPlataforma;
        this.isLoadedCanalesPatrocinios = true;
        this.cdr.markForCheck();
        if (this.currentURL.includes('patrocinios-deportivos')) {
          setTimeout(function () {
            gsap.to(window,
              {
                duration: 0.5,
                scrollTo: "#patrocinios-deportivos"
              }
            );
          }, 1000);
        }
        //console.log('this.objCanalesPatrocinios', this.objCanalesPatrocinios);
        break;
      default:
        break;
    }

    //console.log('this.canalesPlataforma', this.canalesPlataforma);
  }

  ordenaArregloPropiedadNumSecu(obj1, obj2) {
    if (obj1.numSecu > obj2.numSecu) { return 1; }
    if (obj1.numSecu < obj2.numSecu) { return -1; }
    return 0;
  }

  public dynamicId(objeto: string, nombre: string) {
    return DynamicHelper.dynamicIdHelper(objeto, nombre);
  }

  public friendlyUrl(nombre: string) {
    return DynamicHelper.friendlyUrlHelper(nombre);
  }

}
