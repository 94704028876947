import { Injectable } from '@angular/core';
import { Observable, Subject, forkJoin } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';

import { DropdownsEndpoint } from './dropdowns-endpoint.service';
import { AuthService } from './auth.service';
import { Catalogue } from '../models/catalogue.model';
import { Country } from '../models/country.model';

@Injectable()
export class DropdownsService {
  constructor(
    private authService: AuthService,
    private DropdownsEndpoint: DropdownsEndpoint) {

  }

  getCatalogue(language?: string, id?:string) {
    return this.DropdownsEndpoint.getCataloguesEndpoint<Catalogue[]>(language,id);
  }

  getCountries(language?: string) {
    return this.DropdownsEndpoint.getCountriesEndpoint<Country[]>(language);
  }

 }
