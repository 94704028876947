import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router, Event, NavigationEnd  } from '@angular/router';
import { gsap } from "gsap/all";
import { faSearch, faChevronRight, faChevronDown, faBars, faTimes, faArrowLeft, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { CmsOfertaService } from 'src/app/services/cms/cms.oferta.service';
import { AuthService } from 'src/app/services/auth.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TiposPlataformaResponse } from 'src/app/models/cms/cms.oferta.model';
import { ProductosPlancomercial } from 'src/app/models/cms/cms.plan.comercial.model';
import { CmsPlanComercialService } from 'src/app/services/cms/cms.plan.comercial.service';
import { DynamicHelper } from 'src/app/helpers/dynamic-helper';

@Component({
  selector: 'app-website-menu',
  templateUrl: './website-menu.component.html',
  styleUrls: ['./website-menu.component.scss']
})
export class WebsiteMenuComponent implements OnInit {

  currentURL: string;

  faSearch = faSearch;
  faChevronRight = faChevronRight;
  faChevronDown = faChevronDown;
  faChevronUp = faChevronUp;
  faBars = faBars;
  faTimes = faTimes;
  faArrowLeft = faArrowLeft;

  searchBoxState: boolean;

  isMobileOpen: boolean;
  isMobileSubmenuOfertaOpen: boolean = false;
  isMobileSubmenuPlanOpen: boolean = false;

  public desktopFormGroup: FormGroup;
  public mobileFormGroup: FormGroup;
  searching: string;
  searchTerm: string;
  submitted: boolean;

  tiposPlataformaResponse: TiposPlataformaResponse;
  objPlataformas: any[];

  productos: ProductosPlancomercial[];
  linkIzziSky: string;

  submenuOfertaOpen: boolean = false;
  submenuPlanOpen: boolean = false;

  mobileSubmenuOfertaOpen: boolean = false;
  mobileSubmenuPlanOpen: boolean = false;


  isProductosLoaded: boolean = false;
  isPlataformasLoaded: boolean = false;

  constructor(
    public router: Router,
    private cmsOfertaService: CmsOfertaService,
    private cmsPlanComercialService: CmsPlanComercialService,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
  ) {
    this.searchBoxState = false;
    this.isMobileOpen = false;
    this.isMobileSubmenuOfertaOpen = false;
    this.isMobileSubmenuPlanOpen = false;

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentURL = event.url;
        this.cdr.markForCheck();
      }
    });

  }

  ngOnInit(): void {
    if(this.authService.isLoggedIn){
      this.populateMenu();
    }
    else{
      /* Me loggeo y luego voy por los banners */
      this.authService.loginByApp().subscribe(resp => {//console.log('Resp login:',resp)
        this.populateMenu();
      });
    }

    this.desktopFormGroup = this.formBuilder.group({
      searchDesktop: ['', Validators.required]
    });
    this.mobileFormGroup = this.formBuilder.group({
      searchMobile: ['', Validators.required]
    });

  }

  public getPlataformasFullData() {
    this.objPlataformas = [];
    this.cmsOfertaService.getTiposDePlataforma().subscribe(response => {
      this.tiposPlataformaResponse = response;
      this.objPlataformas = this.tiposPlataformaResponse.data.ofertaTiposdePlataforma;
      //console.log('Response de getTiposDePlataforma:', this.tiposPlataformaResponse);
      for (let i = 0; i < this.objPlataformas?.length; i++) {
        this.objPlataformas.sort(this.ordenaArregloPropiedadOrdenPlataforma);
        this.isPlataformasLoaded = true;
        this.cdr.markForCheck();
      }
      //console.log('Estoy en Menu -> objPlataformas:', this.objPlataformas);
    });
  }


  public ordenaArregloPropiedadOrdenPlataforma(obj1, obj2) {
    if (obj1.orden > obj2.orden) { return 1; }
    if (obj1.orden < obj2.orden) { return -1; }
    return 0;
  }

  public getProductos() {
    this.cmsPlanComercialService.getProductos().subscribe(response => {
      this.productos = response.data.productosPlancomercial;
      this.productos.sort(this.ordenaArregloPropiedadOrden);
      this.isProductosLoaded = true;
      this.cdr.markForCheck();
      //console.log('this.productos', this.productos);
      for (let i = 0; i < this.productos?.length; i++) {
        if (this.productos[i].nombreTarjeta.includes('izzi')) {
          this.linkIzziSky = this.productos[i].linkProducto;
          //console.log('this.linkIzziSky', this.linkIzziSky);
        }
      }
    });

  }

  get d() {
    return this.desktopFormGroup.controls;
  }
  get m() {
    return this.mobileFormGroup.controls;
  }

  public search(deskMobile: string) {
    this.searching = '';
    this.searching = deskMobile;

    if (this.searching == "m") {
      if (!this.mobileFormGroup.invalid) {
        this.searchTerm = "/buscar;busqueda=" + this.m.searchMobile.value;
        this.executeSearch(this.searchTerm);
        this.toggleMobileMenu();
        this.m.searchMobile.reset();
      }
    } else if (this.searching == "d") {
      if (!this.desktopFormGroup.invalid) {
        this.searchTerm = "/buscar;busqueda=" + this.d.searchDesktop.value;
        this.executeSearch(this.searchTerm);
        this.closeSearchBox();
        this.d.searchDesktop.reset();
      }
    }
  }

  public executeSearch(busqueda: string) {
    this.submitted = true;
    this.router.navigateByUrl(busqueda);
  }


  populateMenu() {
    this.getPlataformasFullData();
    this.getProductos();
  }


  ordenaArregloPropiedadOrden(obj1 ,obj2 ) : number {
    if(obj1.orden > obj2.orden) { return 1;}
    if(obj1.orden < obj2.orden) { return -1;}
    return 0;
  }


  public navigateTo(path: string): void {
    this.closeSubMenuOferta();
    this.closeSubMenuPlanComercial();
    this.closeMobileMenu();
    this.closeSubMenuOfertaMobile();
    this.closeSubMenuPlanMobile();
    this.router.navigateByUrl(path);
    gsap.to(window, {
      scrollTo: 0,
      duration: 0.5
    });
  }

  /* Submenus desktop */
  public openSubMenuOferta() {
    if (this.submenuOfertaOpen == false && this.isPlataformasLoaded == true && this.isProductosLoaded == true) {
      gsap.to(".tvs-menu-oferta", {
        overflow: 'visible',
        duration: 0.15
      });
      gsap.to(".tvs-submenu-oferta", {
        display: 'block',
        duration: 0.1
      });
      gsap.to(".tvs-submenu-oferta", {
        height: 'auto',
        duration: 0.25
      });
      this.submenuOfertaOpen = true;
    }
  }
  public closeSubMenuOferta() {
    if (this.submenuOfertaOpen == true) {
      gsap.to(".tvs-submenu-oferta", {
        display: 'none',
        duration: 0.1
      });
      gsap.to(".tvs-submenu-oferta", {
        height: '0',
        duration: 0.25
      });
      gsap.to(".tvs-menu-oferta", {
        overflow: 'hidden',
        duration: 0.15
      });
      this.submenuOfertaOpen = false;
    } 
  }

  public openSubMenuPlanComercial() {
    if (this.submenuPlanOpen == false && this.isProductosLoaded == true) {
      gsap.to(".tvs-menu-plan", {
        overflow: 'visible',
        duration: 0.15
      });
      gsap.to(".tvs-submenu-plan", {
        display: 'block',
        duration: 0.1
      });
      gsap.to(".tvs-submenu-plan", {
        height: 'auto',
        duration: 0.25
      });
      this.submenuPlanOpen = true;
    }
  }
  public closeSubMenuPlanComercial() {
    if (this.submenuPlanOpen == true) {
      gsap.to(".tvs-submenu-plan", {
        display: 'none',
        duration: 0.1
      });
      gsap.to(".tvs-submenu-plan", {
        height: '0',
        duration: 0.25
      });
      gsap.to(".tvs-menu-plan", {
        overflow: 'hidden',
        duration: 0.15
      });
      this.submenuPlanOpen = false;
    }
  }

  /* Menus mobile */
  public toggleMobileMenu() {
    if (this.isMobileOpen == false) {
      this.isMobileOpen = true;
      gsap.to(".tvs-menu-mobile .topnav", {
        display: 'block',
        duration: 0.1
      });
      gsap.to(".tvs-menu-mobile .topnav", {
        height: 'auto',
        padding: '1rem',
        opacity: 1,
        duration: 0.5
      });

    } else if (this.isMobileOpen == true) {
      this.isMobileOpen = false;
      gsap.to(".tvs-menu-mobile .topnav", {
        height: '0',
        padding: '0px',
        opacity: 0,
        duration: 0.5
      });
      gsap.to(".tvs-menu-mobile .topnav", {
        display: 'none',
        duration: 0.1
      });
      if (this.mobileSubmenuPlanOpen == true) {
        this.closeSubMenuPlanMobile();
      }
      if (this.mobileSubmenuOfertaOpen == true) {
        this.closeSubMenuOfertaMobile();
      }
    }
  }
  public closeMobileMenu() {
    this.isMobileOpen = false;
    gsap.to(".tvs-menu-mobile .topnav", {
      height: '0',
      padding: '0px',
      opacity: 0,
      duration: 0.5
    });
    gsap.to(".tvs-menu-mobile .topnav", {
      display: 'none',
      duration: 0.1
    });
    if (this.mobileSubmenuPlanOpen == true) {
      this.closeSubMenuPlanMobile();
    }
    if (this.mobileSubmenuOfertaOpen == true) {
      this.closeSubMenuOfertaMobile();
    }
  }
/* Submenus mobile */
  public toggleSubMenuOfertaMobile() {
    if (this.mobileSubmenuPlanOpen == true) {
      this.closeSubMenuPlanMobile();
    }
    if (this.mobileSubmenuOfertaOpen == false) {
      this.openSubMenuOfertaMobile();
    } else {
      this.closeSubMenuOfertaMobile();
    }
  }
  public openSubMenuOfertaMobile() {
    if (this.isPlataformasLoaded == true && this.isProductosLoaded == true) {
      gsap.to(".tvs-submenu-oferta-mobile", {
        display: 'block',
        duration: 0.1
      });
      gsap.to(".tvs-submenu-oferta-mobile", {
        height: 'auto',
        duration: 0.25
      });
      this.mobileSubmenuOfertaOpen = true;
    }
  }
  public closeSubMenuOfertaMobile() {
    gsap.to(".tvs-submenu-oferta-mobile", {
      display: 'none',
      duration: 0.1
    });
    gsap.to(".tvs-submenu-oferta-mobile", {
      height: '0',
      duration: 0.25
    });
    this.mobileSubmenuOfertaOpen = false;
  }

  public toggleSubMenuPlanMobile() {
    if (this.mobileSubmenuOfertaOpen == true) {
      this.closeSubMenuOfertaMobile();
    }
    if (this.mobileSubmenuPlanOpen == false) {
      this.openSubMenuPlanMobile();
    } else {
      this.closeSubMenuPlanMobile();
    }
  }
  public openSubMenuPlanMobile() {
    if (this.isProductosLoaded == true) {
      gsap.to(".tvs-submenu-plan-mobile", {
        display: 'block',
        duration: 0.1
      });
      gsap.to(".tvs-submenu-plan-mobile", {
        height: 'auto',
        duration: 0.25
      });
      this.mobileSubmenuPlanOpen = true;
    }
  }
  public closeSubMenuPlanMobile() {
    gsap.to(".tvs-submenu-plan-mobile", {
      display: 'none',
      duration: 0.1
    });
    gsap.to(".tvs-submenu-plan-mobile", {
      height: '0',
      duration: 0.25
    });
    this.mobileSubmenuPlanOpen = false;
  }

  /* Buscador desktop */
  public openSearchBox() {

    var tl = gsap.timeline();

    tl.to(".tvs-circle-search", {
      opacity: 0,
      width: '0',
      duration: 0.2
    });
    tl.to(".search-box", {
      width: '185px',
      marginLeft: '10px',
      duration: 0.3
    }, '+=0.1');
    tl.to(".input-search", {
      width: '140px',
      duration: 0.3
    }, '+=0.1');
    tl.to(".btn-search", {
      width: 'auto',
      duration: 0.3
    }, '+=0.1');


    this.searchBoxState = true;
    document.getElementById('buscar').focus();
  }
  public closeSearchBox() {
    if (this.searchBoxState) {

      var tl = gsap.timeline();

      tl.to(".search-box", {
        width: 0,
        marginLeft: '40px',
        duration: 0.3
      });
      tl.to(".input-search", {
        width: '0',
        duration: 0.3
      });
      tl.to(".btn-search", {
        width: '0',
        duration: 0.3
      });

      tl.to(".tvs-circle-search", {
        opacity: 1,
        width: '35px',
        duration: 0.2
      }, '+=0.1');

      this.searchBoxState = false;
    }
  }

  public friendlyUrl(nombre: string) {
    return DynamicHelper.friendlyUrlHelper(nombre);
  }



}
