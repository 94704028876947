<!--No encontrado-->
<div class="row tvs-bg-white pb-5 tvs-producto-top" *ngIf="productoId == 'No encontrado'">
  <div class="container container-80 ">
    <app-custom-breadcrumbs [breadcrumbsData]="breadcrumbsData"></app-custom-breadcrumbs>
  </div>
  <div class="container container-80 pb-5 tvs-bg-white no-scale">
    <div class="row mt-5">
      <div class="col-12 text-center">
        <h1 class="tvs-text-orange my-5">404 - NO ENCONTRADO</h1>
        <h3 class="tvs-text-orange my-5">LA PÁGINA QUE BUSCAS NO EXISTE</h3>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-12 text-center">
        <button class="btn btn-primary btn-yellow tvs-btn" (click)="goBack()">REGRESAR</button>
      </div>
    </div>
  </div>
</div>

<!--Para productos 1-5-->
<div class="row tvs-bg-white tvs-producto-top px-2 px-sm-3 px-md-0 px-lg-4" *ngIf="content && productoId !== '6' && productoId !== '7' && productoId !== '8'">
  <app-custom-breadcrumbs [breadcrumbsData]="breadcrumbsData"
    *ngIf="productoId !== 'No encontrado'"></app-custom-breadcrumbs>
  <div class="tvs-producto-backlogo">
    <div class="tvs-producto-backlogo-up"></div>
    <div class="tvs-producto-backlogo-down"></div>
  </div>
  <div class="container-fluid pt-5 px-2 px-md-5 tvs-bg-white">
    <ng-container *ngIf="!isProductoDetalleLoaded">
      <div class="loader-min">
        <img class="loader-img z-20" src="assets/images/website_general/loader-2.svg" alt="" />
      </div>
    </ng-container>
    <!--Introducción-->
    <ng-container *ngIf="isProductoDetalleLoaded">
      <ng-container *ngFor="let productoContenido of productoDetalle; let i = index;">
        <div class="row pt-5" *ngIf="productoContenido.idProducto === productoId">
          <div class="col-12 col-md-7 col-lg-6 col-xl-5 text-left">
            <div class="float-left tvs-producto-sidelogo mr-3 tvs-producto-sidelogo">
              <div class="tvs-producto-sidelogo-up"></div>
              <div class="tvs-producto-sidelogo-down"></div>
            </div>
            <!--Para casi todos-->
            <h4 class="tvs-text-upper tvs-text-black mb-0"
              *ngIf="productoId == '1' || productoId == '2' || productoId == '3' || productoId == '4'">Plan Comercial
            </h4>
            <!--Para izzy+sky(5)-->
            <h4 class="tvs-text-upper tvs-producto-contenidos mb-0" *ngIf="productoId == '5'">Plan Comercial</h4>            
            
            <!-- Para Ads(1), Radiodifundida(2), Regional(3), Networks(4) -->
            <h1 class="tvs-text-orange tvs-producto-tituloplat"
              *ngIf="productoId == '1' || productoId == '2' || productoId == '3' || productoId == '4'">
              {{productoContenido.nombreProducto}}</h1>
            <!--Para izzy+sky(5)-->
            <h1 class="tvs-text-orange tvs-producto-tituloplat-2" *ngIf="productoId == '5'">
              {{productoContenido.nombreProducto}}</h1>
              
          </div>
          <!--Televisión Radiodifundida (2) Intro-->
          <div class="col-12 col-md-5 col-lg-6 col-xl-5 " *ngIf="productoId == '2'">
            <p class="small mt-3">En TelevisaUnivision encuentras los mejores: realities, noticieros, eventos
              deportivos, telenovelas, programas de revista, dramatizados unitarios, series, películas, programas de
              concurso, cómicos, eventos musicales y mucho más.</p>
          </div>
          <!--Televisa Ads (1) OK-->
          <div class="col-12" *ngIf="productoId == '1'">
            <div class="row mt-5 mb-4">
              <div class="col-12 col-md-4 col-lg-5 pr-lg-5">
                <div class="row px-3">
                  <h4 class="tvs-text-orange tvs-text-bold tvs-titulo-infografia">La Nueva Era del video</h4>
                  <h5 class="pr-lg-5 tvs-text-bold">La oferta más robusta de video cross-platform del mercado
                    mexicano en
                    digital.</h5>
                  <p class="pr-lg-5">Tu marca, al formar parte del ecosistema digital de Televisa Ads, adquiere
                    una mayor
                    relevancia contextual entre nuestras audiencias, ya que convive directamente con los contenidos
                    favoritos de
                    tu público meta y es mostrada dentro de un entorno seguro y eficaz.</p>
                  <p class="pr-lg-5">Tus anuncios impactan solamente a los perfiles de usuarios que te
                    interesan,
                    generando un alcance efectivo y uniéndote a la conversación de una manera que les atrae
                    orgánicamente.</p>
                </div>
                <div class="row px-3">
                  <img class="tvs-img-logodigital my-4"
                    src="assets/images/carga_cms/televisa-digital/televisa-ads-logo-001.svg" alt="" />
                  <p class="pr-lg-5">Creamos contigo campañas relevantes de video MULTI-DEVICE donde tu marca
                    alcanza tus
                    objetivos.</p>
                </div>
              </div>
              <div class="col-12 col-md-8 col-lg-7">
                <div class="row px-3">
                  <div class="col-12 text-center">
                    <img class="img-fluid tvs-img-infografia"
                      src="assets/images/carga_cms/televisa-digital/dispositivos-02.png" alt="" />
                    <p class="small tvs-text-black mt-4">Construimos todo el producto para brindar la mejor experiencia
                      de
                      streaming con publicidad</p>
                  </div>
                </div>
                <div class="row px-3">
                  <div class="col-12">
                    <div class="row px-2">
                      <div class="col-12 tvs-division-superior mb-3"></div>
                      <div class="col-4 col-md-3 col-lg-2 text-center mb-3">
                        <img class="tvs-icono-productos"
                          src="assets/images/carga_cms/televisa-digital/brand-safe-01.svg" alt="" />
                        <h4 class="tvs-text-orange tvs-text-bold tvs-x-small mt-1">Brand Safe</h4>
                      </div>
                      <div class="col-4 col-md-3 col-lg-2 text-center mb-3">
                        <img class="tvs-icono-productos"
                          src="assets/images/carga_cms/televisa-digital/viewability-001.svg" alt="" />
                        <h3 class="tvs-text-orange tvs-text-x-bold mb-0">100%</h3>
                        <h4 class="tvs-text-orange tvs-text-bold tvs-x-small">viewability</h4>
                      </div>
                      <div class="col-4 col-md-3 col-lg-2 text-center mb-3">
                        <img class="tvs-icono-productos"
                          src="assets/images/carga_cms/televisa-digital/human-traffic-001.svg" alt="" />
                        <h3 class="tvs-text-orange tvs-text-x-bold mb-0">100%</h3>
                        <h4 class="tvs-text-orange tvs-text-bold tvs-x-small">Human traffic</h4>
                      </div>
                      <div class="col-4 col-md-3 col-lg-2 text-center mb-3">
                        <img class="tvs-icono-productos" src="assets/images/carga_cms/televisa-digital/vtr-001.svg"
                          alt="" />
                        <h3 class="tvs-text-orange tvs-text-x-bold mb-0">100%</h3>
                        <h4 class="tvs-text-orange tvs-text-bold tvs-x-small">VTR</h4>
                      </div>
                      <div class="col-5 col-md-4 col-lg-3 text-center mb-3">
                        <img class="tvs-icono-productos"
                          src="assets/images/carga_cms/televisa-digital/beneficios-001.svg" alt="" />
                        <h4 class="tvs-text-orange tvs-text-bold tvs-x-small mt-1">Beneficios</h4>
                        <ul class="tvs-x-small tvs-text-black p-0 m-0 pl-3 text-left">
                          <li>Pantalla completa</li>
                          <li>Sonido prendido</li>
                          <li>Instream</li>
                          <li>Menos cantidad de anuncios</li>
                          <li>Unskippable</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row px-3">
                  <div class="col-12 tvs-division-superior mb-3"></div>
                  <div class="col-4 col-md-3 col-lg-2 text-center mb-3">
                    <img class="tvs-icono-productos"
                      src="assets/images/carga_cms/televisa-digital/human-traffic-001.svg" alt="" />
                    <p class="tvs-x-small tvs-text-black">Una de las 10 principales propiedades con mayor alcance en
                      México.</p>
                  </div>
                  <div class="col-4 col-md-3 col-lg-2 text-center mb-3">
                    <img class="tvs-icono-productos" src="assets/images/carga_cms/televisa-digital/dispositivos-001.svg"
                      alt="" />
                    <p class="tvs-x-small tvs-text-black">Dentro del top 10 de propiedades de consumo de video en
                      México.</p>
                  </div>
                </div>
                <div class="row px-3 mb-5">
                  <div class="col-12">
                    <p class="tvs-x-small tvs-text-light-gray">Fuente: Comscore Mayo 2021, promedio mayo 2020 - mayo
                      2021 |
                      promedio abril 2020 - abril 2021 | Tubular, Febrero 2021.</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row px-3 mb-4">
              <div class="col-12 col-md-4 col-lg-5 pr-lg-5">
                <div class="row">
                  <img class="tvs-img-logodigital my-4" src="assets/images/carga_cms/televisa-digital/logo-vix-001.svg"
                    alt="" />
                </div>
                <div class="row">
                  <p class="pr-lg-5">Es el único súper streaming global en ESPAÑOL, continúa creciendo y
                    posicionándose
                    frente a los servicios AVOD disponibles en México.</p>
                  <ul class="pr-lg-5">
                    <li>135 Canales y +60,000 de contenido.</li>
                    <li>Entretenimiento en todos los géneros y formatos.</li>
                    <li>Películas y Series en español para todas las audiencias.</li>
                    <li>Contenido Exclusivo con todas las estrellas y talento de TelevisaUnivision.</li>
                    <li>Canales de Noticias y Deportes: partidos en vivo, análisis y mucho más.</li>
                  </ul>
                </div>
              </div>
              <div class="col-12 col-md-8 col-lg-7 text-center">
                <img class="img-fluid tvs-img-infografia"
                  src="assets/images/carga_cms/televisa-digital/imagen-vix-01.jpeg" alt="" />
              </div>
            </div>

            <div class="row tvs-bg-white" *ngIf="isVideoLoaded">
              <div class="col-12 px-0 tvs-video-producto-ads">
                <div class="col-10 offset-1 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2 my-5 py-5 "> 
                  <owl-carousel-o [options]="customOptionsVideo" (change)="pauseAllVideos()">
                    <ng-container *ngFor="let item of videoData; let i = index;">
                      <ng-template carouselSlide>
                        <div class="tvs-jumbo-card-video">
                          <div class="row">
                            <div class="col-12">
                              <video controls controlsList="nodownload" [muted]="'muted'" preload="none"
                                poster="{{item.urlthumbnail}}" playsinline
                                style="width: 100%; height: auto; object-fit: contain;background-color:#000;"
                                disablepictureinpicture id="videoTelevisaAds" #video>
                                <source src="{{item.urlVideo}}" type="video/mp4">
                                Tu navegador no soporta la visualización de videos.
                              </video>
                            </div>
                          </div>
                        </div>
                      </ng-template>
                    </ng-container>
                  </owl-carousel-o>
                </div>
              </div>
            </div>
            <!-- cards Televisa Ads -->
            <div class="row px-3 tvs-bg-white">
              <div class="col d-none d-xl-block"></div>
              <div class="col-1 d-block d-md-none"></div>
              <div class="col-10 col-md-4 col-xl-3 p-0 my-2 tvs-card-rounded tvs-ml-minus-2">
                <div class="col-12 tvs-card-rounded-header-1 text-center p-4">
                  <p class="tvs-text-white"><b>4 de cada 10 personas visita una propiedad de TelevisaUnivision mensualmente en México.</b></p>
                </div>
                <div class="col-12 p-3 pb-4">
                  <h4 class="tvs-text-orange w-100 mb-0 mt-3">+3,600M</h4>
                  <p class="tvs-text-gray w-100 mb-2">Views cross-platform</p>
                  <h4 class="tvs-text-orange w-100 mb-0 mt-3">+20M</h4>
                  <p class="tvs-text-gray w-100 mb-2">Personas en sitio digital y apps</p>
                  <h4 class="tvs-text-orange w-100 mb-0 mt-3">5ta</h4>
                  <p class="tvs-text-gray w-100 mb-2">Posición top de propiedades en México</p>
                </div>
              </div>
              <div class="col-1 d-block d-md-none"></div>
              <div class="col-10 offset-1 offset-md-0 col-md-4 col-xl-3 p-0 mx-md-2 my-2 tvs-card-rounded">
                <div class="col-12 tvs-card-rounded-header-1 text-center p-4">
                  <p class="tvs-text-white"><b>TelevisaUnivision es el número 1 de las compañías de medios de LATAM en social video views cross-platform.</b></p>
                </div>
                <div class="col-12 p-3 pb-4">
                  <h4 class="tvs-text-wine w-100 mb-0 mt-3">+31M</h4>
                  <p class="tvs-text-gray w-100 mb-2">Perfiles capturados dentro de la DMP</p>
                  <h4 class="tvs-text-wine w-100 mb-0 mt-3">400</h4>
                  <p class="tvs-text-gray w-100 mb-2">Perfiles de audiencias disponibles en la DMP</p>
                </div>
              </div>
              <div class="col-1 d-block d-md-none"></div>
              <div class="col-10 offset-1 offset-md-0 col-md-4 col-xl-3 p-0 my-2 tvs-card-rounded tvs-mr-minus-2">
                <div class="col-12 tvs-card-rounded-header-1 text-center p-4">
                  <p class="tvs-text-white"><b>5 de los 10 perfiles de contenido con más social video views cross-platform en México son de TelevisaUnivision.</b></p>
                </div>
                <div class="col-12 p-3 pb-4">
                  <h4 class="tvs-text-yellow w-100 mb-0 mt-3">24</h4>
                  <p class="tvs-text-gray w-100 mb-2">Sitios y apps</p>
                  <h4 class="tvs-text-yellow w-100 mb-0 mt-3">+27</h4>
                  <p class="tvs-text-gray w-100 mb-2">Principales cuentas sociales</p>
                  <h4 class="tvs-text-yellow w-100 mb-0 mt-3">19</h4>
                  <p class="tvs-text-gray w-100 mb-2">Canales de YouTube activos</p>
                </div>
              </div>
              <div class="col"></div>
            </div>
            <div class="row px-3">
              <div class="col-1 d-block d-md-none"></div>
              <div class="col d-none d-xl-block"></div>
              <div class="col-10 col-md-12 col-xl-9 px-0">
                <p class="tvs-text-gray tvs-x-small">Fuente: DMP Televisa. Comscore. Promedio mensual. Enero - Mayo 2020. México. Universo digital medido por Comscore 67M usuarios. Personas +6 desktop y +18 mobile. Personas en sitios y apps sitios Televisa Digital | Total Grupo Televisa. Posición top 100 properties México. Tubular Intelligence. Junio 2020. LATAM leaderboard properties Grupo Televisa | México leaderboard creators: Views cross-platform (Facebook, Youtube)</p>
              </div>
              <div class="col d-block d-md-none d-xl-block"></div>
            </div>
          </div>
          <!--Televisión Radiodifundida (2) OK-->
          <div class="col-12" *ngIf="productoId == '2'">
            <div class="row mt-5">
              <div class="col-12 col-md-4 col-lg-5 pt-md-5 pr-md-5">
                <h4 class="tvs-text-orange tvs-text-bold mt-5 mx-3 tvs-titulo-infografia">Alcanzamos a 3 de cada 4
                  personas que encienden el televisor en un semana típica.</h4>
              </div>
              <div class="col-12 col-md-8 col-lg-7">
                <img class="img-fluid d-none d-sm-block tvs-img-infografia"
                  src="assets/images/carga_cms/television-radiodifundida/grafica-desktop.png" alt="" />
                <img class="img-fluid d-block d-sm-none"
                  src="assets/images/carga_cms/television-radiodifundida/grafico-mobile.svg"
                  alt="" />
              </div>
            </div>
          </div>
          <!--Televisa Regional (3) OK-->
          <div class="col-12" *ngIf="productoId == '3'">
            <div class="row mt-5 mx-sm-5 px-sm-5 mx-md-0 px-md-0">
              <div class="col-12 col-md-4 col-lg-5 pt-md-5 pr-lg-5">
                <h4 class="tvs-text-black tvs-text-bold my-5 px-xl-5 tvs-titulo-infografia">“Las cosas me interesan
                  porque suceden aquí, porque la tele local, es la tele de aquí”.</h4>
              </div>
              <div class="col-12 col-md-8 col-lg-7">
                <img class="img-fluid mb-5 mt-sm-5 tvs-img-infografia"
                  src="assets/images/carga_cms/televisa-regional/mapa-areas-nielsen.svg" alt="" />
              </div>
            </div>
          </div>
          <!--Televisa Networks (4) OK-->
          <div class="col-12" *ngIf="productoId == '4'">
            <div class="row my-5 px-3 px-md-0">
              <div class="col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2 tvs-card-netwoks">
                <div class="row">
                  <div class="col-6 col-md-5 tvs-card-netwoks-img text-center">
                    <img class="img-fluid" src="assets/images/carga_cms/televisa-networks/logo-televisa-clasico.png"
                      alt="" />
                  </div>
                  <div class="col-6 col-md-7 py-3 pt-md-5">
                    <p class="mb-3 small tvs-text-gray">Las mejores producciones, contenidos exclusivos y originales en
                      un solo lugar.</p>
                    <h4 class="mb-3 tvs-text-orange">14.5M de suscriptores</h4>
                    <h4 class="mb-0 tvs-text-orange">15 señales</h4>
                    <p class="mb-3 small tvs-text-gray">de televisión de paga</p>
                    <h4 class="mb-0 tvs-text-orange">32.5M de personas</h4>
                    <p class="mb-3 small tvs-text-gray">alcance mensual</p>
                    <h4 class="mb-0 tvs-text-orange">26 producciones</h4>
                    <p class="mb-3 small tvs-text-gray">originales</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--izzi + sky (5) OK-->
          <div class="col-12 my-5" *ngIf="productoId == '5'">
            <div class="row">
              <div class="col-10 offset-1 col-sm-8 offset-sm-2 col-md-5 offset-md-1 col-lg-4 offset-lg-2 p-0 mr-0 mr-md-2 my-2 tvs-card-rounded">
                <div class="col-12 tvs-card-rounded-header-2">
                  <img class="img-fluid" src="assets/images/carga_cms/izzi-sky/izzi-logo-transparent.png" alt="" />
                </div>
                <div class="col-12 p-3 pb-5">
                  <p class="tvs-text-gray w-100 mb-3">La mejor segmentación geográfica a nivel operadores de televisión de paga.</p>
                  <h3 class="tvs-text-orange w-100 mb-3">4.3M de suscriptores</h3>
                  <h4 class="tvs-text-orange w-100 mb-0 mt-3">19 partidos</h4>
                  <p class="tvs-text-gray w-100 mb-2">exclusivos</p>
                  <h4 class="tvs-text-orange w-100 mb-0 mt-3">10</h4>
                  <p class="tvs-text-gray w-100 mb-2">simultaneos</p>
                  <h4 class="tvs-text-orange w-100 mb-0 mt-3">70 partidos</h4>
                  <p class="tvs-text-gray w-100 mb-2">en temporada regular incluyendo los clásicos tapatíos, también disponible en</p>
                  <div class="col-12 p-0 text-left">
                    <img class="tvs-izzi-card-img" src="assets/images/carga_cms/izzi-sky/blim-tv-logo.png" alt="" />
                  </div>
                </div>
              </div>
              <div class="tvs-izzi-mas-sky mx-2 text-center">
                <fa-icon [icon]="faPlus" class="tvs-text-white tvs-x-bold"></fa-icon>
              </div>
              <div class="col-10 offset-1 col-sm-8 offset-sm-2 col-md-5 col-lg-4 p-0 ml-md-2 my-2 tvs-card-rounded">
                <div class="col-12 tvs-card-rounded-header-2 text-center">
                  <img class="img-fluid" src="assets/images/carga_cms/izzi-sky/sky-logo-transparent.png" alt="" />
                </div>
                <div class="col-12 p-3 pb-5">
                  <p class="tvs-text-gray w-100 mb-3">Sistema líder de televisión de paga directa al hogar vía satélite.</p>
                  <h3 class="tvs-text-orange w-100 mb-3">74M de suscriptores</h3>
                  <h4 class="tvs-text-orange w-100 mb-0 mt-3">&nbsp;</h4>
                  <p class="tvs-text-gray w-100 mb-2">Eventos especiales exclusivos en SKY y más.</p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-1 col-sm-2 col-md-1 col-lg-2 my-2"></div>
              <div class="my-2 tvs-card-full-width">
                <div class="row">
                  <div class="col-6 col-md-4 col-xl-4 pr-0">
                    <img class="img-fluid" src="assets/images/carga_cms/izzi-sky/tokyo-2020-img.png" alt="" />
                  </div>
                  <div class="col-6 col-md-8 col-xl-8 tvs-border-left">
                    <p class="tvs-text-gray w-100 px-2 py-3 pt-md-4 pt-xl-5 mb-0">Transmisión de los XXXII Juegos Olímpicos de Verano</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-1 col-sm-2 col-md-1 col-lg-2 my-2"></div>
              <div class="my-2 tvs-card-full-width">
                <div class="row">
                  <div class="col-6 col-md-4 col-xl-4 pr-0">
                    <img class="img-fluid" src="assets/images/carga_cms/izzi-sky/nfl-afizzionados-img.png" alt="" />
                  </div>
                  <div class="col-6 col-md-8 col-xl-8 tvs-border-left">
                    <p class="tvs-text-gray w-100 px-2 py-3 pt-md-4 pt-xl-5 mb-0">Llega a <b>12M</b> de suscriptores</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 my-5 text-center">
                  <a target="_blank" class="btn btn-primary btn-orange tvs-btn" href="{{productoContenido.linkDestino}}">Ver programación <fa-icon
                    [icon]="faPlus" class="pl-1 tvs-text-white"></fa-icon></a>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
<!--Para productos 6, 7 y 8-->
<div class="row tvs-bg-white tvs-producto-top-2" *ngIf="content && productoId !== '1' && productoId !== '2' && productoId !== '3' && productoId !== '4' && productoId !== '5'">
  <div class="container-fluid tvs-bg-white" *ngIf="!isProductoDetalleLoaded">
    <div class="loader-min">
      <img class="loader-img z-20" src="assets/images/website_general/loader-2.svg" alt="" />
    </div>
  </div>
  <div class="container-fluid mb-5 pb-5 tvs-producto-background-{{productoId}}" *ngIf="isProductoDetalleLoaded">
    <app-custom-breadcrumbs class="pl-2 pl-sm-3 pl-md-0 pl-lg-4" [breadcrumbsData]="breadcrumbsData"
      *ngIf="productoId !== 'No encontrado'" srcTipo="{{productoId}}"></app-custom-breadcrumbs>

    <div class="container-fluid px-2 px-md-5">
      <!--Introducción-->

      <ng-container *ngFor="let productoContenido of productoDetalle; let i = index;">
        <div class="row pt-5" *ngIf="productoContenido.idProducto === productoId">
          <div class="col-12 col-md-6 col-xl-5 text-left mb-5">
            <div class="row mb-5">
              <div class="col-12">
                <div class="float-left tvs-producto-sidelogo mr-3 tvs-producto-sidelogo-{{productoId}}">
                  <div class="tvs-producto-sidelogo-up"></div>
                  <div class="tvs-producto-sidelogo-down"></div>
                </div>
              
                <!--Para Editorial(6)-->
                <h4 class="tvs-text-upper tvs-text-yellow mb-0" *ngIf="productoId == '6'">Plan Comercial</h4>
                <!--Para Patrocinios(7)-->
                <h4 class="tvs-text-upper tvs-text-orange mb-0" *ngIf="productoId == '7'">Plan Comercial</h4>
                <!--Para Multiplataforma(8)-->
                <h4 class="tvs-text-upper tvs-text-white tvs-producto-contenidos mb-0" *ngIf="productoId == '8'">Plan
                  Comercial</h4>

                  <!--Para Editorial (6), Patrocinios(7), Multiplataforma(8)-->
                <h1 class="tvs-text-white tvs-producto-tituloplat"
                  *ngIf="productoId == '6' || productoId == '7' || productoId == '8'">{{productoContenido.nombreProducto}}
                </h1>
              </div>
            </div>
            <!--sección izquierda-->
            <!--Editorial Televisa (6)-->
            <div class="row" *ngIf="productoId == '6'">
              <div class="col-12 mb-3">
                <h3 class="tvs-text-white">Con un portafolio de marcas icónicas y editores especializados, Editorial Televisa conecta a millones de lectores con contenidos y experiencias inspiradoras a través de todas sus plataformas.
                </h3>
              </div>
              <div class="col-12 mb-4">
                <ul class="tvs-text-white">
                  <li>Alcance total multiplataforma +70M</li>
                  <li>13 titulos especializados</li>
                  <li>Curamos contenidos 24/7 en todos nuestros sitios web y social media</li>
                  <li>Soluciones de marketing customizadas para todas las industrias</li>
                  <li>Ofrecemos la exclusividad del print y la interacción digital</li>
                </ul>
              </div>
            </div>
            <!--Patrocinios deportivos (7)-->
            <div class="row" *ngIf="productoId == '7'">
              <div class="col-12 mb-3">
                <h3 class="tvs-text-orange mb-0">ESTADIO AZTECA</h3>
                <h3 class="tvs-text-orange">Sede de la copa del Mundo 2026</h3>
              </div>
              <div class="col-12">
                <ul class="tvs-text-white">
                  <li>Capacidad para 87K espectadores</li>
                  <li>Testigo de grandes artistas y deportistas</li>
                  <li>Sede de dos Copas del Mundo: 1970 y 1986</li>
                  <li>Recinto oficial de la Selección de Fútbol de México</li>
                  <li>Escenario de eventos inolvidables</li>
                  <li>Casa de la NFL en México</li>
                  <li>Locación emblemática del cine mexicano</li>
                  <li>Vanguardia tecnológica y ambiental</li>
                  <li>Casa del Club América y Cruz Azul</li>
                  <li>Más de 10 mil goles en la cancha</li>
                  <li>54 años de vivir la emoción del deporte</li>
                </ul>
              </div>
            </div>

          </div>
          <!--Sección derecha-->
          <!--Editorial Televisa (6)-->
          <div class="col-12 col-md-6 col-xl-7 py-0 px-5 px-md-0 px-xl-5" *ngIf="productoId == '6'">
            <img class="img-fluid" src="assets/images/carga_cms/editorial-televisa/inicio-editorial-televisa-bco.png" alt="" />
          </div>
          <!--Patrocinios Deportivos (7)-->
          <div class="col-12 col-md-6 col-xl-7 px-0" *ngIf="productoId == '7'">
            <img class="img-fluid tvs-img-azteca"
                    src="assets/images/carga_cms/patrocinios-deportivos/patrocinios-img-estadio.png" alt="" />
          </div>
          <!--Multiplataforma (8)-->
          <div class="col-12 col-md-6 col-xl-7" *ngIf="productoId == '8'">
            <img class="img-fluid" src="assets/images/carga_cms/multiplataforma/multiplataforma-02.png" alt="" />
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<!--Patrocinios Deportivos (club América)(7)-->
<div class="row tvs-bg-white" *ngIf="content && productoId == '7'">
  <div class="container-fluid mb-5 pb-5 tvs-producto-background-7-2" *ngIf="isProductoDetalleLoaded">
    <div class="container-fluid px-2 px-md-5">
      <div class="row pt-5">

        <div class="col-12 col-md-8 offset-md-2 col-lg-4 offset-lg-0 mb-5">
          <div class="row">
            <div class="col-12">
              <h3 class="tvs-text-white mb-0">CLUB AMÉRICA</h3>
              <h3 class="tvs-text-white mb-3">El equipo más grande de México</h3>
              <h4 class="tvs-text-white">30M afición en México</h4>
            </div>
          </div>
          <div class="row">
            <div class="col-6 pt-5">
              <img class="img-fluid" src="assets/images/carga_cms/patrocinios-deportivos/america-001.png" alt="" />
            </div>
            <div class="col-6">
              
            </div>
          </div>
        </div>
        <div class="col-12 col-md-8 offset-md-2 col-lg-4 offset-lg-0 mb-5">
          <img class="img-fluid" src="assets/images/carga_cms/patrocinios-deportivos/jugadores-america.png" alt="" />
        </div>
        <div class="col-12 col-md-8 offset-md-2 col-lg-4 offset-lg-0 mb-5">
          <div class="row mb-5">
            <div class="col-12 text-center">
              <h3 class="tvs-text-white mt-5">Ciudades con mayor afición</h3>
            </div>
          </div>
          <div class="row mb-5">
            <div class="col-8">
              <ul class="tvs-text-white">
                <li>CDMX</li>
                <li>Toluca</li>
                <li>Guadalajara</li>
                <li>Puebla</li>
                <li>Veracruz</li>
                <li>Tijuana</li>
                <li>Ciudad Juárez</li>
              </ul>
            </div>
            <div class="col-4">
              <ul style="list-style: none !important;" class="tvs-text-white tvs-text-bold">
                <li><b>15.4%</b></li>
                <li><b>6.2%</b></li>
                <li><b>3.0%</b></li>
                <li><b>2.2%</b></li>
                <li><b>2.0%</b></li>
                <li><b>1.5%</b></li>
                <li><b>1.5%</b></li>
              </ul>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<!--Círculos-->
<div class="row tvs-bg-white px-2 px-sm-3 px-md-0 px-lg-4" *ngIf="content">
  <div class="container-fluid px-2 px-md-5 tvs-bg-white">
    <ng-container *ngIf="isProductoDetalleLoaded">

      <ng-container *ngFor="let productoContenido of productoDetalle; let i = index;">
        <!--Círculos por producto (Excepto Patrocinios Deportivos)-->
        <ng-container *ngIf="productoContenido.idProducto === productoId && productoId != '7'">
          <div class="row mt-5" *ngIf="productoContenido.circuloProducto?.contentItems?.length > 0 ">
            <div class="col-12">
              <h3 class="tvs-text-red text-center tvs-text-bold mb-5">¿Qué ofrecemos en
                {{productoContenido.nombreProducto}}?</h3>
            </div>
            <div class="d-none d-xl-block col-xl-5"
              *ngIf="productoContenido.circuloProducto?.contentItems?.length == 1">
            </div>
            <div class="d-none d-xl-block col-xl-4"
              *ngIf="productoContenido.circuloProducto?.contentItems?.length == 2">
            </div>
            <div class="d-none d-xl-block col-xl-3"
              *ngIf="productoContenido.circuloProducto?.contentItems?.length == 3">
            </div>
            <div class="d-none d-xl-block col-xl-2"
              *ngIf="productoContenido.circuloProducto?.contentItems?.length == 4">
            </div>
            <div class="d-none d-xl-block col-xl-1"
              *ngIf="productoContenido.circuloProducto?.contentItems?.length >= 5">
            </div>
            <ng-container
              *ngFor="let circulos of productoContenido.circuloProducto.contentItems | slice: 0:5; let i = index;">
              <div
                class="col-8 offset-2 col-sm-6 offset-sm-3 col-md-5 offset-md-1 col-lg-3 offset-lg-0 col-xl-2 px-2 mb-5">
                <div class="row text-center">
                  <div class="col-12 tvs-circulo">
                    <img class="img-fluid rounded-circle" src="assets/images/website_general/fondo-circulo-2.png" alt=""
                      [ngStyle]="{'background' : circulos.productoBlock.color.contentItems[0].valorHex}" />
                    <h3 class="tvs-circulo-titulo tvs-text-bold tvs-text-white">{{circulos.productoBlock.titulo}}</h3>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 mt-2">
                    <p class="text-center tvs-circulo-texto">{{circulos.productoBlock.texto}}</p>
                  </div>
                </div>
                <div class="row"
                  *ngIf="circulos.productoBlock.imagen?.urls[0] && circulos.productoBlock.imagen?.urls[0] !== ''">
                  <div class="col-12">
                    <img [src]="getImg(baseCMSUrl.slice(0, -1) + circulos.productoBlock.imagen?.urls[0])" alt=""
                      class="text-center tvs-circulo-imagen img-fluid" />
                  </div>
                </div>
              </div>
            </ng-container>
            <div class="d-none d-xl-block col-xl-5"
              *ngIf="productoContenido.circuloProducto?.contentItems?.length == 6">
            </div>
            <div class="d-none d-xl-block col-xl-4"
              *ngIf="productoContenido.circuloProducto?.contentItems?.length == 7">
            </div>
            <div class="d-none d-xl-block col-xl-3"
              *ngIf="productoContenido.circuloProducto?.contentItems?.length == 8">
            </div>
            <div class="d-none d-xl-block col-xl-2"
              *ngIf="productoContenido.circuloProducto?.contentItems?.length == 9">
            </div>
            <div class="d-none d-xl-block col-xl-1"
              *ngIf="productoContenido.circuloProducto?.contentItems?.length >= 10">
            </div>
            <ng-container
              *ngFor="let circulos of productoContenido.circuloProducto.contentItems | slice: 5:10; let i = index;">
              <div
                class="col-8 offset-2 col-sm-6 offset-sm-3 col-md-5 offset-md-1 col-lg-3 offset-lg-0 col-xl-2 px-2 mb-5">
                <div class="row text-center">
                  <div class="col-12 tvs-circulo">
                    <img class="img-fluid rounded-circle" src="assets/images/website_general/fondo-circulo-2.png" alt=""
                      [ngStyle]="{'background' : circulos.productoBlock.color.contentItems[0].valorHex}" />
                    <h3 class="tvs-circulo-titulo tvs-text-bold tvs-text-white">{{circulos.productoBlock.titulo}}</h3>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 mt-2">
                    <p class="text-center tvs-circulo-texto">{{circulos.productoBlock.texto}}</p>
                  </div>
                </div>
                <div class="row"
                  *ngIf="circulos.productoBlock.imagen?.urls[0] && circulos.productoBlock.imagen?.urls[0] !== ''">
                  <div class="col-12">
                    <img [src]="getImg(baseCMSUrl.slice(0, -1) + circulos.productoBlock.imagen?.urls[0])" alt=""
                      class="text-center tvs-circulo-imagen img-fluid" />
                  </div>
                </div>
              </div>
            </ng-container>
            <div class="d-none d-xl-block col-xl-5"
              *ngIf="productoContenido.circuloProducto?.contentItems?.length == 11">
            </div>
            <div class="d-none d-xl-block col-xl-4"
              *ngIf="productoContenido.circuloProducto?.contentItems?.length == 12">
            </div>
            <div class="d-none d-xl-block col-xl-3"
              *ngIf="productoContenido.circuloProducto?.contentItems?.length == 13">
            </div>
            <div class="d-none d-xl-block col-xl-2"
              *ngIf="productoContenido.circuloProducto?.contentItems?.length == 14">
            </div>
            <div class="d-none d-xl-block col-xl-1"
              *ngIf="productoContenido.circuloProducto?.contentItems?.length == 15">
            </div>
            <ng-container
              *ngFor="let circulos of productoContenido.circuloProducto.contentItems | slice: 10:15; let i = index;">
              <div
                class="col-8 offset-2 col-sm-6 offset-sm-3 col-md-5 offset-md-1 col-lg-3 offset-lg-0 col-xl-2 px-2 mb-5">
                <div class="row text-center">
                  <div class="col-12 tvs-circulo">
                    <img class="img-fluid rounded-circle" src="assets/images/website_general/fondo-circulo-2.png" alt=""
                      [ngStyle]="{'background' : circulos.productoBlock.color.contentItems[0].valorHex}" />
                    <h3 class="tvs-circulo-titulo tvs-text-bold tvs-text-white">{{circulos.productoBlock.titulo}}</h3>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 mt-2">
                    <p class="text-center tvs-circulo-texto">{{circulos.productoBlock.texto}}</p>
                  </div>
                </div>
                <div class="row"
                  *ngIf="circulos.productoBlock.imagen?.urls[0] && circulos.productoBlock.imagen?.urls[0] !== ''">
                  <div class="col-12">
                    <img [src]="getImg(baseCMSUrl.slice(0, -1) + circulos.productoBlock.imagen?.urls[0])" alt=""
                      class="text-center tvs-circulo-imagen img-fluid" />
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>
        <!--Círculos por producto (Solo Patrocinios Deportivos)-->
        <ng-container *ngIf="productoId == '7'">
          <!--Club América-->
          <ng-container *ngIf="productoContenido.idProducto === '7-1'">
            <div class="row" *ngIf="productoContenido.circuloProducto?.contentItems?.length > 0 ">
              <div class="col-12 mb-5">

                <div class="row d-flex d-md-none tvs-titulo-ofrecemos">
                  <div class="col-12 text-center">
                    <h3 class="tvs-text-orange tvs-text-bold">¿Qué ofrecemos?</h3>
                  </div>
                  <div class="col-12 text-center">
                    <img src="assets/images/carga_cms/productos/america_logo-2.jpg" alt="" />
                  </div>
                </div>
                <div class="row row-cols-2 d-none d-md-flex tvs-titulo-ofrecemos">
                  <div class="col text-right">
                    <h3 class="tvs-text-orange tvs-text-bold tvs-br-orange pr-4">¿Qué ofrecemos?</h3>
                  </div>
                  <div class="col text-left pl-0">
                    <img src="assets/images/carga_cms/productos/america_logo-2.jpg" alt="" />
                  </div>
                </div>
              </div>
              <div class="d-none d-xl-block col-xl-5"
                *ngIf="productoContenido.circuloProducto?.contentItems?.length == 1">
              </div>
              <div class="d-none d-xl-block col-xl-4"
                *ngIf="productoContenido.circuloProducto?.contentItems?.length == 2">
              </div>
              <div class="d-none d-xl-block col-xl-3"
                *ngIf="productoContenido.circuloProducto?.contentItems?.length == 3">
              </div>
              <div class="d-none d-xl-block col-xl-2"
                *ngIf="productoContenido.circuloProducto?.contentItems?.length == 4">
              </div>
              <div class="d-none d-xl-block col-xl-1"
                *ngIf="productoContenido.circuloProducto?.contentItems?.length >= 5">
              </div>
              <ng-container
                *ngFor="let circulos of productoContenido.circuloProducto.contentItems | slice: 0:5; let i = index;">
                <div
                  class="col-8 offset-2 col-sm-6 offset-sm-3 col-md-5 offset-md-1 col-lg-3 offset-lg-0 col-xl-2 px-2 mb-5">
                  <div class="row text-center">
                    <div class="col-12 tvs-circulo">
                      <img class="img-fluid rounded-circle" src="assets/images/website_general/fondo-circulo-2.png"
                        alt="" [ngStyle]="{'background' : circulos.productoBlock.color.contentItems[0].valorHex}" />
                      <h3 class="tvs-circulo-titulo tvs-text-bold tvs-text-white">{{circulos.productoBlock.titulo}}</h3>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 mt-2">
                      <p class="text-center tvs-circulo-texto">{{circulos.productoBlock.texto}}</p>
                    </div>
                  </div>
                  <div class="row"
                    *ngIf="circulos.productoBlock.imagen?.urls[0] && circulos.productoBlock.imagen?.urls[0] !== ''">
                    <div class="col-12">
                      <img [src]="getImg(baseCMSUrl.slice(0, -1) + circulos.productoBlock.imagen?.urls[0])" alt=""
                        class="text-center tvs-circulo-imagen img-fluid" />
                    </div>
                  </div>
                </div>
              </ng-container>
              <div class="d-none d-xl-block col-xl-5"
                *ngIf="productoContenido.circuloProducto?.contentItems?.length == 6">
              </div>
              <div class="d-none d-xl-block col-xl-4"
                *ngIf="productoContenido.circuloProducto?.contentItems?.length == 7">
              </div>
              <div class="d-none d-xl-block col-xl-3"
                *ngIf="productoContenido.circuloProducto?.contentItems?.length == 8">
              </div>
              <div class="d-none d-xl-block col-xl-2"
                *ngIf="productoContenido.circuloProducto?.contentItems?.length == 9">
              </div>
              <div class="d-none d-xl-block col-xl-1"
                *ngIf="productoContenido.circuloProducto?.contentItems?.length >= 10">
              </div>
              <ng-container
                *ngFor="let circulos of productoContenido.circuloProducto.contentItems | slice: 5:10; let i = index;">
                <div
                  class="col-8 offset-2 col-sm-6 offset-sm-3 col-md-5 offset-md-1 col-lg-3 offset-lg-0 col-xl-2 px-2 mb-5">
                  <div class="row text-center">
                    <div class="col-12 tvs-circulo">
                      <img class="img-fluid rounded-circle" src="assets/images/website_general/fondo-circulo-2.png"
                        alt="" [ngStyle]="{'background' : circulos.productoBlock.color.contentItems[0].valorHex}" />
                      <h3 class="tvs-circulo-titulo tvs-text-bold tvs-text-white">{{circulos.productoBlock.titulo}}</h3>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 mt-2">
                      <p class="text-center tvs-circulo-texto">{{circulos.productoBlock.texto}}</p>
                    </div>
                  </div>
                  <div class="row"
                    *ngIf="circulos.productoBlock.imagen?.urls[0] && circulos.productoBlock.imagen?.urls[0] !== ''">
                    <div class="col-12">
                      <img [src]="getImg(baseCMSUrl.slice(0, -1) + circulos.productoBlock.imagen?.urls[0])" alt=""
                        class="text-center tvs-circulo-imagen img-fluid" />
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-container>
          <!--Estadio Azteca-->
          <ng-container *ngIf="productoContenido.idProducto === '7-2'">
            <div class="row" *ngIf="productoContenido.circuloProducto?.contentItems?.length > 0 ">
              <div class="col-12 mb-5">
                <div class="row d-flex d-md-none tvs-titulo-ofrecemos">
                  <div class="col-12 text-center">
                    <h3 class="tvs-text-red tvs-text-bold">¿Qué ofrecemos?</h3>
                  </div>
                  <div class="col-12 text-center">
                    <img src="assets/images/carga_cms/productos/azteca_logo-2.jpg" alt="" />
                  </div>
                </div>
                <div class="row row-cols-2 d-none d-md-flex tvs-titulo-ofrecemos">
                  <div class="col text-right">
                    <h3 class="tvs-text-red tvs-text-bold tvs-br-red pr-4">¿Qué ofrecemos?</h3>
                  </div>
                  <div class="col text-left pl-0">
                    <img src="assets/images/carga_cms/productos/azteca_logo-2.jpg" alt="" />
                  </div>
                </div>
              </div>
              <div class="d-none d-xl-block col-xl-5"
                *ngIf="productoContenido.circuloProducto?.contentItems?.length == 1">
              </div>
              <div class="d-none d-xl-block col-xl-4"
                *ngIf="productoContenido.circuloProducto?.contentItems?.length == 2">
              </div>
              <div class="d-none d-xl-block col-xl-3"
                *ngIf="productoContenido.circuloProducto?.contentItems?.length == 3">
              </div>
              <div class="d-none d-xl-block col-xl-2"
                *ngIf="productoContenido.circuloProducto?.contentItems?.length == 4">
              </div>
              <div class="d-none d-xl-block col-xl-1"
                *ngIf="productoContenido.circuloProducto?.contentItems?.length >= 5">
              </div>
              <ng-container
                *ngFor="let circulos of productoContenido.circuloProducto.contentItems | slice: 0:5; let i = index;">
                <div
                  class="col-8 offset-2 col-sm-6 offset-sm-3 col-md-5 offset-md-1 col-lg-3 offset-lg-0 col-xl-2 px-2 mb-5">
                  <div class="row text-center">
                    <div class="col-12 tvs-circulo">
                      <img class="img-fluid rounded-circle" src="assets/images/website_general/fondo-circulo-2.png"
                        alt="" [ngStyle]="{'background' : circulos.productoBlock.color.contentItems[0].valorHex}" />
                      <h3 class="tvs-circulo-titulo tvs-text-bold tvs-text-white">{{circulos.productoBlock.titulo}}</h3>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 mt-2">
                      <p class="text-center tvs-circulo-texto">{{circulos.productoBlock.texto}}</p>
                    </div>
                  </div>
                  <div class="row"
                    *ngIf="circulos.productoBlock.imagen?.urls[0] && circulos.productoBlock.imagen?.urls[0] !== ''">
                    <div class="col-12">
                      <img [src]="getImg(baseCMSUrl.slice(0, -1) + circulos.productoBlock.imagen?.urls[0])" alt=""
                        class="text-center tvs-circulo-imagen img-fluid" />
                    </div>
                  </div>
                </div>
              </ng-container>
              <div class="d-none d-xl-block col-xl-5"
                *ngIf="productoContenido.circuloProducto?.contentItems?.length == 6">
              </div>
              <div class="d-none d-xl-block col-xl-4"
                *ngIf="productoContenido.circuloProducto?.contentItems?.length == 7">
              </div>
              <div class="d-none d-xl-block col-xl-3"
                *ngIf="productoContenido.circuloProducto?.contentItems?.length == 8">
              </div>
              <div class="d-none d-xl-block col-xl-2"
                *ngIf="productoContenido.circuloProducto?.contentItems?.length == 9">
              </div>
              <div class="d-none d-xl-block col-xl-1"
                *ngIf="productoContenido.circuloProducto?.contentItems?.length >= 10">
              </div>
              <ng-container
                *ngFor="let circulos of productoContenido.circuloProducto.contentItems | slice: 5:10; let i = index;">
                <div
                  class="col-8 offset-2 col-sm-6 offset-sm-3 col-md-5 offset-md-1 col-lg-3 offset-lg-0 col-xl-2 px-2 mb-5">
                  <div class="row text-center">
                    <div class="col-12 tvs-circulo">
                      <img class="img-fluid rounded-circle" src="assets/images/website_general/fondo-circulo-2.png"
                        alt="" [ngStyle]="{'background' : circulos.productoBlock.color.contentItems[0].valorHex}" />
                      <h3 class="tvs-circulo-titulo tvs-text-bold tvs-text-white">{{circulos.productoBlock.titulo}}</h3>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 mt-2">
                      <p class="text-center tvs-circulo-texto">{{circulos.productoBlock.texto}}</p>
                    </div>
                  </div>
                  <div class="row"
                    *ngIf="circulos.productoBlock.imagen?.urls[0] && circulos.productoBlock.imagen?.urls[0] !== ''">
                    <div class="col-12">
                      <img [src]="getImg(baseCMSUrl.slice(0, -1) + circulos.productoBlock.imagen?.urls[0])" alt=""
                        class="text-center tvs-circulo-imagen img-fluid" />
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </ng-container>          
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>

<!--Para Televisa Ads (1)-->
<div class="row tvs-bg-white px-2 px-sm-3 px-md-0 px-lg-4" *ngIf="content && productoId == '1'">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <h3 class="tvs-text-red text-center tvs-text-bold my-5">Lista de propiedades Televisa</h3>
      </div>
    </div>
    <div class="row pb-5 mb-5 tvs-ads-propiedades">
      <div class="col-6 col-md-4 col-lg-3">
        <div class="tvs-orange-pill">
          <h3 class="mb-0">Sitios Web</h3>
        </div>
      </div>
      <div class="col-6 col-md-8 col-lg-9">
        <ul class="small tvs-text-gray tvs-ul-column-list">
          <li>Bandamax.com</li>
          <li>Distritocomedia.com</li>
          <li>Micanal5.tv</li>
          <li>Elnueve.tv</li>
          <li>LasEstrellas.tv</li>
          <li>Nmas.com.mx</li>
          <li>Telehit.com</li>
          <li>Televisa.com</li>
          <li>TUDN.com</li>
          <li>Unicable.com</li>
        </ul>
      </div>
      <div class="tvs-ads-separador"></div>
    </div>
    <div class="row pb-5 mb-5 tvs-ads-propiedades">
      <div class="col-6 col-md-4 col-lg-3">
        <div class="tvs-orange-pill">
          <h3 class="mb-0">Apps</h3>
        </div>
      </div>
      <div class="col-6 col-md-8 col-lg-9">
        <ul class="small tvs-text-gray tvs-ul-column-list">
          <li>Las Estrellas</li>
          <li>TUDN</li>
        </ul>
      </div>
      <div class="tvs-ads-separador"></div>
    </div>
    <div class="row pb-5 mb-5 tvs-ads-propiedades">
      <div class="col-6 col-md-4 col-lg-3">
        <div class="tvs-orange-pill">
          <h3 class="mb-0">OTT</h3>
        </div>
      </div>
      <div class="col-6 col-md-8 col-lg-9">
        <img src="assets/images/carga_cms/televisa-digital/vix-vix-plus.png" alt="" />
      </div>
      <div class="tvs-ads-separador"></div>
    </div>
    <div class="row pb-5 mb-5 tvs-ads-propiedades">
      <div class="col-6 col-md-4 col-lg-3">
        <div class="tvs-orange-pill">
          <h3 class="mb-0">Principales cuentas</h3>
        </div>
      </div>
      <div class="col-6 col-md-8 col-lg-9">
        <ul class="small tvs-text-gray tvs-ul-column-list">
          <li>Bandamax</li>
          <li>Canal 5</li>
          <li>Como Dice el Dicho</li>
          <li>Cuéntamelo Ya</li>
          <li>De Noche con Yordi</li>
          <li>De Película</li>
          <li>Distrito Comedia</li>
          <li>El Nueve</li>
          <li>El Retador</li>
          <li>Faisy nights</li>
          <li>Foro TV</li>
          <li>Golden</li>
          <li>HOY</li>
          <li>La Rosa de Guadalupe</li>
          <li>Las Estrellas</li>
          <li>Más Noche</li>
          <li>MCDR</li>
          <li>Miembros al Aire</li>
          <li>Montse y Joe</li>
          <li>Netas Divinas</li>
          <li>Noche de Buenas</li>
          <li>NMás</li>
          <li>Noticieros Televisa</li>
          <li>Novelas Las Estrellas</li>
          <li>QELM</li>
          <li>Telehit</li>
          <li>Telehit Música</li>
          <li>Televisa Corporativo</li>
          <li>Televisa Digital</li>
          <li>Tlnovelas</li>
          <li>TUDN MEX</li>
          <li>Unicable</li>
        </ul>
      </div>
      <div class="tvs-ads-separador"></div>
    </div>
    <div class="row pb-5 mb-5 tvs-ads-propiedades">
      <div class="col-6 col-md-4 col-lg-3">
        <div class="tvs-orange-pill">
          <h3 class="mb-0">Canales de YouTube</h3>
        </div>
      </div>
      <div class="col-6 col-md-8 col-lg-9">
        <ul class="small tvs-text-gray tvs-ul-column-list">
          <li>Archivo Televisa News</li>
          <li>Bandamax</li>
          <li>Canal 5</li>
          <li>Como dice el dicho</li>
          <li>Distrito Comedia</li>
          <li>Hoy</li>
          <li>La Rosa de Guadalupe</li>
          <li>Las Estrellas</li>
          <li>Telehit</li>
          <li>Tlnovelas</li>
          <li>TUDN México</li>
          <li>Unicable</li>
        </ul>
      </div>
    </div>
  </div>
</div>

<!--Carrusel de programas-->
<div class="row tvs-bg-white px-2 px-sm-3 px-md-0 px-lg-4" *ngIf="content">
  <div class="container-fluid px-2 px-md-5 tvs-bg-white">
    <ng-container *ngIf="isProductoDetalleLoaded">
      <ng-container *ngFor="let productoContenido of productoDetalle; let i = index;">
        <ng-container *ngIf="plataformaAsociada != '0'">
          <div class="row mt-5" *ngIf="productoContenido.idProducto === productoId">
            <div class="col-12">
              <h3 class="tvs-text-red text-center tvs-text-bold mb-5">Conoce todos los contenidos de
                {{productoContenido.nombreProducto}}</h3>
            </div>
          </div>
          <div class="row" *ngIf="productoContenido.idProducto === productoId">
            <div class="col-10 offset-1 col-md-12 offset-md-0">
              <app-carrusel-programas [idPlataformaAsociada]="plataformaAsociada"
                [nombrePlataforma]="productoContenido.nombreProducto"></app-carrusel-programas>
            </div>
          </div>
          <div class="row" *ngIf="productoContenido.idProducto === productoId">
            <div class="col-12 text-center">
              <button class="btn btn-primary btn-orange tvs-btn"
                (click)="navigateTo('nuestra-oferta/plataforma/' + friendlyUrl(productoContenido.nombreProducto))"
                [attr.id]="dynamicId('ver-todos-programas-', productoContenido.nombreProducto)">Ver todos <fa-icon
                  [icon]="faPlus" class="pl-1 tvs-text-blue"></fa-icon></button>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>

<!--Productos por plataforma-->
<div class="row tvs-bg-white px-2 px-sm-3 px-md-0 px-lg-4" *ngIf="content">
  <div class="container-fluid px-2 px-md-5 tvs-bg-white">
    <ng-container *ngIf="isProductoDetalleLoaded">
      <div class="row py-5 tvs-bg-white">
        <div class="col-12 px-3 px-md-5 tvs-mas-productos">
          <div class="col-12 mt-5 pt-5">
            <h2 class="tvs-text-x-bold tvs-text-white py-3">Conoce más productos</h2>
          </div>
          <div class="row mb-5 pb-5">
            <div class="col-12 col-md-6 col-lg-4 col-xl-3" *ngFor="let producto of productos">
              <app-tarjeta-producto [attr.id]="dynamicId('tarjetaProducto-', producto.nombreTarjeta)"
                class="tarjetaProducto" [cardData]="producto"></app-tarjeta-producto>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>