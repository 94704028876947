import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router} from '@angular/router';
import { gsap } from "gsap/all";
import { GtmService } from 'ngx-gtm';
import { AuthService } from 'src/app/services/auth.service';
import { CmsPlanComercialService } from 'src/app/services/cms/cms.plan.comercial.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ContactEmail } from '../../../models/contact-emal.model';
import { AlertService } from '../../../services/alert.service';
import { faArrowRight, faPlus } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactoComponent implements OnInit {

  faArrowRight = faArrowRight;
  faPlus = faPlus;
  title: string = 'Contacto';

  bannerImage: string;
  bannerImageMobile: string;
  bannerTitle: string;
  baseCMSUrl: string;
  listaPaises: any[];
  validated: boolean;
  submitted : boolean;
  is_loading : boolean;
  contactEmail : ContactEmail;
  siteKey: string = '6LdEwQsaAAAAAEYnZzlmARhPWNMxYwbi52PKgan3'; // qa.televisallega.com
  //siteKey: string = '6Lcuvw4aAAAAALxZvQms_tRTWz8gpe3ZFnKTLNpU'; // localhost

  /*Loaders*/
  isBannerLoaded: boolean = false;

  constructor(
    private alertService: AlertService,
    private formBuilder: FormBuilder,
    public router: Router,
    private gtm: GtmService,
    private notificationService : NotificationService,
    private cmsPlanComercialService: CmsPlanComercialService,
    private configuration: ConfigurationService,
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private titleService: Title,
    private metaService: Meta
  ) {
    this.baseCMSUrl = this.configuration.baseCmsUrl;
    if(this.authService.isLoggedIn){
      this.getBannerImage();
      this.getPaises();
    }
    else{
      /* Me loggeo y luego voy por los banners */
      this.authService.loginByApp().subscribe(resp => {//console.log('Resp login:',resp)
      this.getBannerImage();
      this.getPaises();
      });
    }

  }

  public aFormGroup: FormGroup;

  ngOnInit(): void {
    console.log('Contact form 1.0');
    this.is_loading = false;
    this.validated = false;
    this.aFormGroup = this.formBuilder.group({
      subject: ['', Validators.required],
      name: ['', Validators.required],
      surnames: ['', Validators.required],
      country: ['', Validators.required],
      company: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', Validators.required],
      tv_abierta: [''],
      izzi_sky: [''],
      interests: [''],
      tv_paga: [''],
      tv_regional: [''],
      televisa_ads: [''],
      editorial: [''],
      patrocinios: [''],
      vix: [''],
      company_position: [''],
      message: ['', Validators.required],
      recaptcha: ['', Validators.required],
      terms: ['', Validators.required]
    });
    this.gtm.push({ event: 'TelevisaLlega Contacto' });

    this.titleService.setTitle(this.title);
    this.metaService.addTags([
      { name: 'keywords', content: 'contacto, contáctanos, publicitarte, publicidad, anúnciate, anunciarme en Televisa, publicidad en Televisa, información Televisa, directorio comercial Televisa, correo Televisa' },
      { name: 'description', content: 'Conoce nuestro directorio comercial o envíanos tus datos si te interesa conocer más o tener presencia publicitaria por nuestras plataformas.' },
      { name: 'robots', content: 'index, follow' }
    ]);
  }

  get f() {
    return this.aFormGroup.controls;
  }

  public getBannerImage() {
    this.cmsPlanComercialService.getBannerHero().subscribe(response => {
      for(let i = 0; i < response.data.bannersdePaquetes.length ; i++) {
        if(response.data.bannersdePaquetes[i].displayText === 'Contacto') {
          this.bannerImage = this.baseCMSUrl.slice(0, -1) + response.data.bannersdePaquetes[i].bannerImage.urls[0];
          this.bannerImageMobile = this.baseCMSUrl.slice(0, -1) + response.data.bannersdePaquetes[i].imagenMobile.urls[0];
          this.bannerTitle = response.data.bannersdePaquetes[i].bannerTitle;
          this.isBannerLoaded = true;
          this.cdr.markForCheck();
        }
      }
    });
  }

  public handleReset() { }
  public handleExpire() { 
    this.validated = false;
  }
  public handleLoad() { }
  public handleSuccess(event:any) { 
    this.validated = true;
  }

  public getPaises() {
    this.listaPaises = [
        {
          id: '1',
          name: 'Afganistán'
        },
        {
          id: '2',
          name: 'Islas Aland'
        },
        {
          id: '3',
          name: 'Albania'
        },
        {
          id: '4',
          name: 'Argelia'
        },
        {
          id: '5',
          name: 'Andorra'
        },
        {
          id: '6',
          name: 'Angola'
        },
        {
          id: '7',
          name: 'Anguila'
        },
        {
          id: '8',
          name: 'Antártida'
        },
        {
          id: '9',
          name: 'Antigua y Barbuda'
        },
        {
          id: '10',
          name: 'Argentina'
        },
        {
          id: '11',
          name: 'Armenia'
        },
        {
          id: '12',
          name: 'Aruba'
        },
        {
          id: '13',
          name: 'Australia'
        },
        {
          id: '14',
          name: 'Austria'
        },
        {
          id: '15',
          name: 'Azerbaiyán'
        },
        {
          id: '16',
          name: 'Bahamas'
        },
        {
          id: '17',
          name: 'Bahrein'
        },
        {
          id: '18',
          name: 'Bangladesh'
        },
        {
          id: '19',
          name: 'Barbados'
        },
        {
          id: '20',
          name: 'Bielorrusia'
        },
        {
          id: '21',
          name: 'Bélgica'
        },
        {
          id: '22',
          name: 'Belice'
        },
        {
          id: '23',
          name: 'Benin'
        },
        {
          id: '24',
          name: 'Islas Bermudas'
        },
        {
          id: '25',
          name: 'Bután'
        },
        {
          id: '26',
          name: 'Bolivia'
        },
        {
          id: '27',
          name: 'Bonaire, San Eustaquio y Saba'
        },
        {
          id: '28',
          name: 'Bosnia y Herzegovina'
        },
        {
          id: '29',
          name: 'Botsuana'
        },
        {
          id: '30',
          name: 'Isla Bouvet'
        },
        {
          id: '31',
          name: 'Brasil'
        },
        {
          id: '32',
          name: 'Territorio Británico del Océano Índico'
        },
        {
          id: '33',
          name: 'Brunei Darussalam'
        },
        {
          id: '34',
          name: 'Bulgaria'
        },
        {
          id: '35',
          name: 'Burkina Faso'
        },
        {
          id: '36',
          name: 'Burundi'
        },
        {
          id: '37',
          name: 'Camboya'
        },
        {
          id: '38',
          name: 'Camerún'
        },
        {
          id: '39',
          name: 'Canadá'
        },
        {
          id: '40',
          name: 'Cabo Verde'
        },
        {
          id: '41',
          name: 'Islas Caimán'
        },
        {
          id: '42',
          name: 'República Centroafricana'
        },
        {
          id: '43',
          name: 'Chad'
        },
        {
          id: '44',
          name: 'Chile'
        },
        {
          id: '45',
          name: 'China'
        },
        {
          id: '46',
          name: 'Isla de Navidad'
        },
        {
          id: '47',
          name: 'Islas Cocos (Keeling)'
        },
        {
          id: '48',
          name: 'Colombia'
        },
        {
          id: '49',
          name: 'Comoras'
        },
        {
          id: '50',
          name: 'Congo'
        },
        {
          id: '51',
          name: 'Congo, la República Democrática del'
        },
        {
          id: '52',
          name: 'Islas Cook'
        },
        {
          id: '53',
          name: 'Costa Rica'
        },
        {
          id: '54',
          name: 'Costa de Marfil'
        },
        {
          id: '55',
          name: 'Croacia'
        },
        {
          id: '56',
          name: 'Cuba'
        },
        {
          id: '57',
          name: 'Curazao'
        },
        {
          id: '58',
          name: 'Chipre'
        },
        {
          id: '59',
          name: 'Republica Checa'
        },
        {
          id: '60',
          name: 'Dinamarca'
        },
        {
          id: '61',
          name: 'Djibouti'
        },
        {
          id: '62',
          name: 'Dominica'
        },
        {
          id: '63',
          name: 'República Dominicana'
        },
        {
          id: '64',
          name: 'Ecuador'
        },
        {
          id: '65',
          name: 'Egipto'
        },
        {
          id: '66',
          name: 'El Salvador'
        },
        {
          id: '67',
          name: 'Guinea Ecuatorial'
        },
        {
          id: '68',
          name: 'Eritrea'
        },
        {
          id: '69',
          name: 'Estonia'
        },
        {
          id: '70',
          name: 'Etiopía'
        },
        {
          id: '71',
          name: 'Islas Malvinas (Falkland Islands)'
        },
        {
          id: '72',
          name: 'Islas Faroe'
        },
        {
          id: '73',
          name: 'Fiyi'
        },
        {
          id: '74',
          name: 'Finlandia'
        },
        {
          id: '75',
          name: 'Francia'
        },
        {
          id: '76',
          name: 'Guayana Francesa'
        },
        {
          id: '77',
          name: 'Polinesia francés'
        },
        {
          id: '78',
          name: 'Territorios Franceses del Sur'
        },
        {
          id: '79',
          name: 'Gabón'
        },
        {
          id: '80',
          name: 'Gambia'
        },
        {
          id: '81',
          name: 'Georgia'
        },
        {
          id: '82',
          name: 'Alemania'
        },
        {
          id: '83',
          name: 'Ghana'
        },
        {
          id: '84',
          name: 'Gibraltar'
        },
        {
          id: '85',
          name: 'Grecia'
        },
        {
          id: '86',
          name: 'Groenlandia'
        },
        {
          id: '87',
          name: 'Granada'
        },
        {
          id: '88',
          name: 'Guadalupe'
        },
        {
          id: '89',
          name: 'Guatemala'
        },
        {
          id: '90',
          name: 'Guernsey'
        },
        {
          id: '91',
          name: 'Guinea'
        },
        {
          id: '92',
          name: 'Guinea-Bissau'
        },
        {
          id: '93',
          name: 'Guayana'
        },
        {
          id: '94',
          name: 'Haití'
        },
        {
          id: '95',
          name: 'Islas Heard y McDonald'
        },
        {
          id: '96',
          name: 'Santa Sede (Estado de la Ciudad del Vaticano)'
        },
        {
          id: '97',
          name: 'Honduras'
        },
        {
          id: '98',
          name: 'Hong Kong'
        },
        {
          id: '99',
          name: 'Hungría'
        },
        {
          id: '100',
          name: 'Islandia'
        },
        {
          id: '101',
          name: 'India'
        },
        {
          id: '102',
          name: 'Indonesia'
        },
        {
          id: '103',
          name: 'Irán (República Islámica de Irán)'
        },
        {
          id: '104',
          name: 'Irak'
        },
        {
          id: '105',
          name: 'Irlanda'
        },
        {
          id: '106',
          name: 'Isla de Man'
        },
        {
          id: '107',
          name: 'Israel'
        },
        {
          id: '108',
          name: 'Italia'
        },
        {
          id: '109',
          name: 'Jamaica'
        },
        {
          id: '110',
          name: 'Japón'
        },
        {
          id: '111',
          name: 'Jersey'
        },
        {
          id: '112',
          name: 'Jordán'
        },
        {
          id: '113',
          name: 'Kazajstán'
        },
        {
          id: '114',
          name: 'Kenia'
        },
        {
          id: '115',
          name: 'Kiribati'
        },
        {
          id: '116',
          name: 'Corea'
        },
        {
          id: '117',
          name: 'Kuwait'
        },
        {
          id: '118',
          name: 'Kirguistán'
        },
        {
          id: '119',
          name: 'República Democrática Popular Lao'
        },
        {
          id: '120',
          name: 'Letonia'
        },
        {
          id: '121',
          name: 'Líbano'
        },
        {
          id: '122',
          name: 'Lesoto'
        },
        {
          id: '123',
          name: 'Liberia'
        },
        {
          id: '124',
          name: 'Jamahiriya Árabe Libia'
        },
        {
          id: '125',
          name: 'Liechtenstein'
        },
        {
          id: '126',
          name: 'Lituania'
        },
        {
          id: '127',
          name: 'Luxemburgo'
        },
        {
          id: '128',
          name: 'Macao'
        },
        {
          id: '129',
          name: 'Macedonia, (Ex República Yugoslava de Macedonia)'
        },
        {
          id: '130',
          name: 'Madagascar'
        },
        {
          id: '131',
          name: 'Malawi'
        },
        {
          id: '132',
          name: 'Malasia'
        },
        {
          id: '133',
          name: 'Maldivas'
        },
        {
          id: '134',
          name: 'Mali'
        },
        {
          id: '135',
          name: 'Malta'
        },
        {
          id: '136',
          name: 'Martinica'
        },
        {
          id: '137',
          name: 'Mauritania'
        },
        {
          id: '138',
          name: 'Mauricio'
        },
        {
          id: '139',
          name: 'Mayotte'
        },
        {
          id: '140',
          name: 'México'
        },
        {
          id: '141',
          name: 'Moldavia, República de'
        },
        {
          id: '142',
          name: 'Mónaco'
        },
        {
          id: '143',
          name: 'Mongolia'
        },
        {
          id: '144',
          name: 'Montenegro'
        },
        {
          id: '145',
          name: 'Montserrat'
        },
        {
          id: '146',
          name: 'Marruecos'
        },
        {
          id: '147',
          name: 'Mozambique'
        },
        {
          id: '148',
          name: 'Myanmar'
        },
        {
          id: '149',
          name: 'Namibia'
        },
        {
          id: '150',
          name: 'Nauru'
        },
        {
          id: '151',
          name: 'Nepal'
        },
        {
          id: '152',
          name: 'Países Bajos'
        },
        {
          id: '153',
          name: 'Nueva Caledonia'
        },
        {
          id: '154',
          name: 'Nueva Zelanda'
        },
        {
          id: '155',
          name: 'Nicaragua'
        },
        {
          id: '156',
          name: 'Níger'
        },
        {
          id: '157',
          name: 'Nigeria'
        },
        {
          id: '158',
          name: 'Niue'
        },
        {
          id: '159',
          name: 'Isla Norfolk'
        },
        {
          id: '160',
          name: 'Noruega'
        },
        {
          id: '161',
          name: 'Omán'
        },
        {
          id: '162',
          name: 'Pakistán'
        },
        {
          id: '163',
          name: 'Territorio Palestino'
        },
        {
          id: '164',
          name: 'Panamá'
        },
        {
          id: '165',
          name: 'Papúa Nueva Guinea'
        },
        {
          id: '166',
          name: 'Paraguay'
        },
        {
          id: '167',
          name: 'Perú'
        },
        {
          id: '168',
          name: 'Filipinas'
        },
        {
          id: '169',
          name: 'Pitcairn'
        },
        {
          id: '170',
          name: 'Polonia'
        },
        {
          id: '171',
          name: 'Portugal'
        },
        {
          id: '172',
          name: 'Puerto Rico'
        },
        {
          id: '173',
          name: 'Katar'
        },
        {
          id: '174',
          name: 'República de Palau'
        },
        {
          id: '175',
          name: 'Reunión'
        },
        {
          id: '176',
          name: 'Rumania'
        },
        {
          id: '177',
          name: 'Federación Rusa'
        },
        {
          id: '178',
          name: 'Ruanda'
        },
        {
          id: '179',
          name: 'San Bartolomé'
        },
        {
          id: '180',
          name: 'Santa Elena, Ascensión y Tristán de Cunha'
        },
        {
          id: '181',
          name: 'San Cristóbal y Nieves'
        },
        {
          id: '182',
          name: 'Santa Lucía'
        },
        {
          id: '183',
          name: 'San Martín (parte francesa)'
        },
        {
          id: '184',
          name: 'San Pedro y Miquelón'
        },
        {
          id: '185',
          name: 'San Vicente y las Granadinas'
        },
        {
          id: '186',
          name: 'Samoa'
        },
        {
          id: '187',
          name: 'San Marino'
        },
        {
          id: '188',
          name: 'Santo Tomé y Príncipe'
        },
        {
          id: '189',
          name: 'Arabia Saudita'
        },
        {
          id: '190',
          name: 'Senegal'
        },
        {
          id: '191',
          name: 'Serbia'
        },
        {
          id: '192',
          name: 'Seychelles'
        },
        {
          id: '193',
          name: 'Sierra Leona'
        },
        {
          id: '194',
          name: 'Singapur'
        },
        {
          id: '195',
          name: 'Sint Maarten (parte holandesa)'
        },
        {
          id: '196',
          name: 'Eslovaquia'
        },
        {
          id: '197',
          name: 'Eslovenia'
        },
        {
          id: '198',
          name: 'Islas Salomón'
        },
        {
          id: '199',
          name: 'Somalia'
        },
        {
          id: '200',
          name: 'Sudáfrica'
        },
        {
          id: '201',
          name: 'Georgia del sur y las islas Sandwich del sur'
        },
        {
          id: '202',
          name: 'Corea del Sur'
        },
        {
          id: '203',
          name: 'Sudán del Sur'
        },
        {
          id: '204',
          name: 'España'
        },
        {
          id: '205',
          name: 'Sri Lanka'
        },
        {
          id: '206',
          name: 'Sudán'
        },
        {
          id: '207',
          name: 'Surinam'
        },
        {
          id: '208',
          name: 'Svalbard y Jan Mayen'
        },
        {
          id: '209',
          name: 'Swazilandia'
        },
        {
          id: '210',
          name: 'Suecia'
        },
        {
          id: '211',
          name: 'Suiza'
        },
        {
          id: '212',
          name: 'República Árabe Siria'
        },
        {
          id: '213',
          name: 'Taiwán'
        },
        {
          id: '214',
          name: 'Tayikistán'
        },
        {
          id: '215',
          name: 'Tanzania, República Unida de'
        },
        {
          id: '216',
          name: 'Tailandia'
        },
        {
          id: '217',
          name: 'Los Estados Federados de Micronesia'
        },
        {
          id: '218',
          name: 'Timor-Leste'
        },
        {
          id: '219',
          name: 'Togo'
        },
        {
          id: '220',
          name: 'Tokelau'
        },
        {
          id: '221',
          name: 'Tonga'
        },
        {
          id: '222',
          name: 'Trinidad y Tobago'
        },
        {
          id: '223',
          name: 'Túnez'
        },
        {
          id: '224',
          name: 'Turquía'
        },
        {
          id: '225',
          name: 'Turkmenistán'
        },
        {
          id: '226',
          name: 'Islas Turcas y Caicos'
        },
        {
          id: '227',
          name: 'Tuvalu'
        },
        {
          id: '228',
          name: 'Uganda'
        },
        {
          id: '229',
          name: 'Ucrania'
        },
        {
          id: '230',
          name: 'Emiratos Árabes Unidos'
        },
        {
          id: '231',
          name: 'Reino Unido'
        },
        {
          id: '232',
          name: 'Estados Unidos'
        },
        {
          id: '233',
          name: 'Uruguay'
        },
        {
          id: '234',
          name: 'Uzbekistán'
        },
        {
          id: '235',
          name: 'Vanuatu'
        },
        {
          id: '236',
          name: 'Venezuela'
        },
        {
          id: '237',
          name: 'Vietnam'
        },
        {
          id: '238',
          name: 'Islas Vírgenes Británicas'
        },
        {
          id: '239',
          name: 'Wallis y Futuna'
        },
        {
          id: '240',
          name: 'Sahara Occidental'
        },
        {
          id: '241',
          name: 'Yemen'
        },
        {
          id: '242',
          name: 'Zambia'
        },
        {
          id: '243',
          name: 'Zimbabue'
        }
      ];
  }


  public sendContactEmail(){

    if(!this.is_loading){
      this.is_loading = true;
    }else{
      return;
    }

    this.submitted = true;

    if(!this.validated){
      this.is_loading = false;
      this.alertService.showDialog("Es obligatorio completar el captcha");
      return;
    }

    if(!this.f.terms.value){
      this.is_loading = false;
      this.alertService.showDialog("Es obligatorio aceptar los términos y condiciones del aviso de privacidad");
      return;
    }

    if(!this.aFormGroup.invalid){
      this.contactEmail = new ContactEmail();
      this.contactEmail.company = this.f.company.value;
      this.contactEmail.company_position = this.f.company_position.value;
      this.contactEmail.country = this.f.country.value;
      this.contactEmail.email = this.f.email.value;
      this.contactEmail.interests = [];

      if(this.f.tv_abierta.value==true){
        this.contactEmail.interests.push('Televisión Radiodifundida');
      }

      if(this.f.tv_paga.value==true){
        this.contactEmail.interests.push('Televisa Networks');
      }

      if(this.f.tv_regional.value==true){
        this.contactEmail.interests.push('Televisa Regional');
      }

      if (this.f.televisa_ads.value==true){
        this.contactEmail.interests.push('Televisa Ads');
      }

      if (this.f.izzi_sky.value == true) {
        this.contactEmail.interests.push('izzi + sky');
      }
      if(this.f.editorial.value==true){
        this.contactEmail.interests.push('Editorial Televisa');
      }

      if (this.f.patrocinios.value==true){
        this.contactEmail.interests.push('Patrocinios Deportivos');
      }

      if (this.f.vix.value == true) {
        this.contactEmail.interests.push('ViX');
      }

      this.contactEmail.message = this.f.message.value;
      this.contactEmail.name = this.f.name.value;
      this.contactEmail.surnames = this.f.surnames.value;
      this.contactEmail.phone = this.f.phone.value;
      this.contactEmail.subject = this.f.subject.value;

      //console.log('Datos a enviar:',this.contactEmail);

      this.notificationService.postSendEmailContact(this.contactEmail).subscribe(
        data =>{
          this.alertService.showDialog("Muchas gracias por tu interés, en breve uno de nuestros colaboradores se pondrá en contacto contigo");
          this.is_loading = false;
          this.submitted = false;
          this.aFormGroup.reset();
          this.cdr.detectChanges();
        },
        err => {
          this.alertService.showDialog("Ocurrió un error al intentar enviar tus datos, por favor intentalo nuevamente más tarde");
          this.is_loading = false;
          this.submitted = false;
          this.cdr.detectChanges();
        }
      );

    }
    else {
      this.is_loading = false;
      this.alertService.showDialog("Completa los campos obligatorios");
    }
  }

  public navigateTo(path: string): void {
    this.router.navigateByUrl(path);
    gsap.to(window, {
      scrollTo: 0,
      duration: 0.5
    });
  }

  public navigateToStill(path: string): void {
    this.router.navigateByUrl(path);
  }

}
