import { Injectable } from '@angular/core';
import { Observable, Subject, forkJoin, BehaviorSubject } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { SearchData } from '../models/search-data.model';
import { SearchResultData } from '../models/search-result-data.model';
import { ViewedCompany } from '../models/viewed-company.model';

import { SearchAutocomplete } from '../models/search-autocomplete.model';
import { SearchEndpoint } from './search-endpoint.service';
import { BuscadorTelevisa } from '../models/buscador-televisa.model';

@Injectable()
export class SearchService {

  private companyLogo = new Subject<string>();
  private companyBackground = new Subject<string>();
  private searchedData = new BehaviorSubject<SearchResultData[]>(new Array<SearchResultData>());

  // Observable string streams
  companyLogoCanged$ = this.companyLogo.asObservable();
  companyBackgroundCanged$ = this.companyBackground.asObservable();
  // Observable Object streams
  searchedData$ = this.searchedData.asObservable();
    
  constructor(
    private authService: AuthService,
    private SearchEndpoint: SearchEndpoint) {

  }

    
    changeLogo(newLogo: string) {
      this.companyLogo.next(newLogo);
    }
  
    changeBackground(newBackground: string) {
      this.companyBackground.next(newBackground);
    }

    setSearchedData(newSearchedData: SearchResultData[]){
      this.searchedData.next(newSearchedData);
    }

  getSearchResults(searchData: SearchData) {
    //console.log("Entre a Servicio Buscar");
    return this.SearchEndpoint.getSearchResultsEndpoint<BuscadorTelevisa[]>(searchData);
  }

  getAutocomplete(term?: string, language?: string) {
    return this.SearchEndpoint.getAutocompleteEndpoint<SearchAutocomplete[]>(term,language);
  }

  getAutocompleteSignIn(type?:string, term?: string) {
    return this.SearchEndpoint.getAutocompleteSignInEndpoint<SearchAutocomplete[]>(type,term);
  }

  getSearchTest(searchData: SearchData) {
    return this.SearchEndpoint.getSearchTestResultsEndpoint<SearchResultData[]>(searchData);
  }

  updateViewedProfile(viewedCompany : ViewedCompany){
    return this.SearchEndpoint.updateCountProfileAsViewedEndpoint<ViewedCompany[]>(viewedCompany);
  }

}
