import { Component, OnInit, Input } from '@angular/core';
import { CardDataContacto } from 'src/app/models/card-data-contacto.model';

@Component({
  selector: 'app-tarjeta-contacto',
  templateUrl: './tarjeta-contacto.component.html',
  styleUrls: ['./tarjeta-contacto.component.scss']
})
export class TarjetaContactoComponent implements OnInit {

  @Input() contactoData: CardDataContacto;

  constructor() { }

  ngOnInit(): void {

  }

  public getTel(numero: string) {
    var telefono: string = numero.replace(/ /g, '');;
    return telefono;
  }

}
