import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { EndpointBase } from './endpoint-base.service';
import { ConfigurationService } from './configuration.service';
import { ProfileCompanyGeneralData } from '../models/profile-company-general-data.model';
import { ProfileSaveBlockData } from '../models/profile-block-data.model';

@Injectable()
export class ProfileEndpoint extends EndpointBase {

  get startUpsUrl() { return this.configurations.baseUrl + '/api/Profile/start-ups'; }
  get investorsUrl() { return this.configurations.baseUrl + '/api/Profile/investors'; }
  get mostSearchedUrl() { return this.configurations.baseUrl + '/api/Profile/most-searched'; }
  get companyGeneralDataUrl() { return this.configurations.baseUrl + '/api/Profile/general-data'; }
  get companyContactsUrl() { return this.configurations.baseUrl + '/api/Profile/profile-contacts'; }
  get companyUnauthorizedContactsUrl() { return this.configurations.baseUrl + '/api/Profile/contacts-to-be-authorized'; }
  get profileBlockUrl() { return this.configurations.baseUrl + '/api/Profile/profile-blocks'; }
  get updateGeneralDataUrl() { return this.configurations.baseUrl + '/api/Profile/general-data'; }
  get followCompanyUrl() { return this.configurations.baseUrl + '/api/Profile/follow'; }
  get unfollowCompanyUrl() { return this.configurations.baseUrl + '/api/Profile/unfollow'; }
  
  constructor(private configurations: ConfigurationService, http: HttpClient, authService: AuthService) {
    super(http, authService);
  }

  getStartUpsEndpoint<T>(page?: number, pageSize?: number): Observable<T> {
    const endpointUrl = page && pageSize ? `${this.startUpsUrl}/${page}/${pageSize}` : this.startUpsUrl;

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getStartUpsEndpoint(page, pageSize));
      }));
  }

  getInvestorsEndpoint<T>(page?: number, pageSize?: number): Observable<T> {
    const endpointUrl = page && pageSize ? `${this.investorsUrl}/${page}/${pageSize}` : this.investorsUrl;

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getInvestorsEndpoint(page, pageSize));
      }));
  }

  getMostSearchedEndpoint<T>(page?: number, pageSize?: number): Observable<T> {
    const endpointUrl = page && pageSize ? `${this.mostSearchedUrl}/${page}/${pageSize}` : this.investorsUrl;

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getInvestorsEndpoint(page, pageSize));
      }));
  }

  getCompanyGeneralDataEndpoint<T>(idEmpresa?: string, isLoggedIn?: boolean): Observable<T> {
    const endpointUrl = idEmpresa ? `${this.companyGeneralDataUrl}/${idEmpresa}/${isLoggedIn}` : this.companyGeneralDataUrl;

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCompanyGeneralDataEndpoint(idEmpresa, isLoggedIn));
      }));
  }

  getCompanyContactsEndpoint<T>(idEmpresa?: string): Observable<T> {
    const endpointUrl = idEmpresa ? `${this.companyContactsUrl}/${idEmpresa}` : this.companyContactsUrl;

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCompanyContactsEndpoint(idEmpresa));
      }));
  }

  getCompanyUnauthorizedContactsEndpoint<T>(idEmpresa?: string): Observable<T> {
    const endpointUrl = idEmpresa ? `${this.companyUnauthorizedContactsUrl}/${idEmpresa}` : this.companyUnauthorizedContactsUrl;

    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCompanyContactsEndpoint(idEmpresa));
      }));
  }

  

  getProfileBlockDataEndpoint<T> (idEmp?: string, isLoggedIn?: boolean, page?: number, pSize?: number): Observable<T> {
    // Agregar isLoggedIn al servicio de la API , para agregarlo en esta llamada al servicio
    const endpointUrl = idEmp ? `${this.profileBlockUrl}/${idEmp}/StatusUpdate/${page}/${pSize}` : this.profileBlockUrl;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getProfileBlockDataEndpoint(idEmp, isLoggedIn, page, pSize));
      }));

  }

  getUpdateProfileGeneralDataCompany<T> (idEmp: string, companyGeneralData: ProfileCompanyGeneralData): Observable<ProfileCompanyGeneralData>{
    //console.log(companyGeneralData);
    
    let body = JSON.stringify(companyGeneralData);

    return this.http.put<ProfileCompanyGeneralData>(
        this.updateGeneralDataUrl, 
        body, 
        this.requestHeaders).pipe<ProfileCompanyGeneralData>(
      catchError(error => {
        return this.handleError(error, () => this.getUpdateProfileGeneralDataCompany(idEmp, companyGeneralData));
      }));

  }

  getCreateProfileGeneralDataCompany<T> (idEmp: string, companyGeneralData: ProfileCompanyGeneralData): Observable<ProfileCompanyGeneralData>{
    //console.log(companyGeneralData);
    
    let body = JSON.stringify(companyGeneralData);

    return this.http.post<ProfileCompanyGeneralData>(
        this.updateGeneralDataUrl, 
        body, 
        this.requestHeaders).pipe<ProfileCompanyGeneralData>(
      catchError(error => {
        return this.handleError(error, () => this.getUpdateProfileGeneralDataCompany(idEmp, companyGeneralData));
      }));

  }

  getBlockSaveDataEndPoint<T> (profileBlockData: ProfileSaveBlockData): Observable<T>{
    return this.http.post<T>(this.profileBlockUrl, JSON.stringify(profileBlockData), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getBlockSaveDataEndPoint(profileBlockData));
      }));
  }

  getBlockDeleteDataEndPoint<T> (idBlock: string): Observable<T>{
    const deleteEndpointUrl = idBlock ? `${this.profileBlockUrl}/${idBlock}` : this.profileBlockUrl;
    return this.http.delete<T>(deleteEndpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getBlockDeleteDataEndPoint(idBlock));
      }));
  }

  postFollowCompanyEndPoint<T> (userCompanyFollowing: any): Observable<T>{
    const followEndpointUrl = this.followCompanyUrl;
    return this.http.post<T>(followEndpointUrl, userCompanyFollowing, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.postFollowCompanyEndPoint(userCompanyFollowing));
      }));
  }

  putUnfollowCompanyEndPoint<T> (userCompanyFollowing: any): Observable<T>{
    const unfollowEndpointUrl = this.unfollowCompanyUrl;
    return this.http.put<T>(unfollowEndpointUrl, userCompanyFollowing, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.putUnfollowCompanyEndPoint(userCompanyFollowing));
      }));
  }

}
