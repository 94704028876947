
<div class="card tvs-card-contacto pb-3 mb-4 mx-1">
  <div class="card-body px-3">
    <!--Datos tarjeta-->
    <h2 class="card-title mt-3">{{contactoData.contactoNombre}}</h2>
    <p class="tvs-span-puesto" *ngIf="contactoData.contactoPuesto && contactoData.contactoPuesto != ''">{{contactoData.contactoPuesto}}</p>
    <span class="tvs-span-telefono" *ngIf="contactoData.contactoTelefono && contactoData.contactoTelefono != ''">Tel. {{contactoData.contactoTelefono}}</span>

  </div>
</div>

