import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { gsap} from "gsap/all";
import { CardDataProgram } from 'src/app/models/card-data-program.model';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { GtmService } from 'ngx-gtm';
import { DynamicHelper } from 'src/app/helpers/dynamic-helper';

@Component({
  selector: 'app-tarjeta-futurecast',
  templateUrl: './tarjeta-futurecast.component.html',
  styleUrls: ['./tarjeta-futurecast.component.scss']
})
export class TarjetaFuturecastComponent implements OnInit {

  @Input() cardData: CardDataProgram;
  baseCMSUrl: string;
  constructor(
    public router: Router,
    private configuration: ConfigurationService,
    private gtm: GtmService
  ) 
  { 
    this.baseCMSUrl = this.configuration.baseCmsUrl
  }

  ngOnInit(): void {

  }

  public getCardImg(imagen: string) {
    var urlImagen: string = imagen.replace(/ /g,'%20');
    return urlImagen;
  }

  public navigateTo(path: string): void {
    this.router.navigateByUrl(path);
    gsap.to(window, {
      scrollTo: 0,
      duration: 0.5
    });
  }

  public goToFuturecast(path: string, nombre: string, categoria: string) {
    window.open(path, '_blank');
    //console.log(path, nombre, categoria);
    this.gtm.push({
      event: 'click-futurecast',
      type: 'Caso de éxito',
      category: categoria,
      name: nombre
    });
  }

  public dynamicId(objeto: string, nombre: string) {
    return DynamicHelper.dynamicIdHelper(objeto, nombre);
  }

}
