import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CardData } from 'src/app/models/card-data.model';
import { ConfigurationService } from 'src/app/services/configuration.service';

@Component({
  selector: 'app-tarjeta',
  templateUrl: './tarjeta.component.html',
  styleUrls: ['./tarjeta.component.scss']
})
export class TarjetaComponent implements OnInit {

  
  @Input() cardData: CardData;
  baseCMSUrl : string;

  constructor(
    public router: Router,
    private configuration: ConfigurationService)
  {
    this.baseCMSUrl = this.configuration.baseCmsUrl;
  }

  ngOnInit(): void {
    
  }
  public getCardImg(imagen: string) {
    var urlImagen: string = imagen.replace(/ /g, '%20');
    return urlImagen;
  }

}
