import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from '../auth.service';
import { EndpointBase } from '../endpoint-base.service';
import { ConfigurationService } from '../configuration.service';

@Injectable()
export class CmsEndpoint extends EndpointBase {

  get cmsUrl() { return this.configurations.baseCmsUrl + 'api/graphql'; }
  
  constructor(private configurations: ConfigurationService, http: HttpClient, authService: AuthService) {
    super(http, authService);
  }

  getCmsDataEndPoint<T> (query: string): Observable<T>{
    const cmsGetDataUrl = this.cmsUrl;
    return this.http.post<T>(cmsGetDataUrl, query, this.requestGraphHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getCmsDataEndPoint(query));
      }));
  }

}
