import { NotFoundComponent } from '../components/not-found/not-found.component';
import { AuthService } from '../services/auth.service';
import { AuthGuard } from '../services/auth-guard.service';
import { Utilities } from '../services/utilities';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from '../components/Website/home/home.component';
import { PlanComercialComponent } from '../components/Website/plan-comercial/plan-comercial.component';
import { ContactoComponent } from '../components/Website/contacto/contacto.component';
import { NuestraOfertaComponent } from '../components/Website/nuestra-oferta/nuestra-oferta.component';
import { FuturecastComponent } from '../components/Website/futurecast/futurecast.component';
import { AcercaDeComponent } from '../components/Website/acerca-de/acerca-de.component';
import { EventosComponent } from '../components/Website/eventos/eventos.component';
import { PlataformaComponent } from '../components/Website/plataforma/plataforma.component';
import { PilaresComponent } from '../components/Website/pilares/pilares.component';
import { CalendarioComponent } from '../components/Website/calendario/calendario.component';
import { PaquetesDetalleComponent } from '../components/Website/paquetes-detalle/paquetes-detalle.component';
import { CartaEditorialComponent } from '../components/Website/carta-editorial/carta-editorial.component';
import { AvisoPrivacidadComponent } from '../components/Website/aviso-privacidad/aviso-privacidad.component';
import { GenerosComponent } from '../components/Website/generos/generos.component';
import { DetalleFuturecastComponent } from '../components/Website/detalle-futurecast/detalle-futurecast.component';
import { DetalleProgramaComponent } from '../components/Website/detalle-programa/detalle-programa.component';
import { CanalesRegionalComponent } from '../components/Website/canales-regional/canales-regional.component';
import { DetalleEventoComponent } from '../components/Website/detalle-evento/detalle-evento.component';
import { ResultadosBuscadorComponent } from '../components/Website/common/resultados-buscador/resultados-buscador.component';
import { PaquetesComponent } from '../components/Website/paquetes/paquetes.component';
import { ProductosComponent } from '../components/Website/productos/productos.component';
import { FormatosdigitalesComponent } from '../components/Website/formatosdigitales/formatosdigitales.component';
 
export const WEBSITE_ROUTES: Routes = [
  {
    path: '',
    component: HomeComponent, data: {
      //title: 'Televisa llega',
      breadcrumbs: false,
      text: ''
    }
  },
  {
    path: 'home',
    component: HomeComponent, data: {
      //title: 'Televisa llega',
      breadcrumbs: false,
      text: ''
    }
  },
  {
    path: 'contacto',
    component: ContactoComponent,
    data: {
      //title: 'Contacto',
      breadcrumbs: false,
      text: 'Contacto'
    }
  },
  {
    path: 'buscar',
    component: ResultadosBuscadorComponent,
    data: {
      //title: 'Contacto',
      breadcrumbs: false,
      text: 'Búsqueda'
    }
  },
  {
    path: 'plan-comercial',
    //component: PlanComercialComponent,
    data: {
      //title: 'Plan Comercial',
    },
    children: [
      {
        path: '',
        component: PlanComercialComponent,
        data: {
          //title: 'Plan Comercial',
          breadcrumbs: false,
          text: 'Plan Comercial'
        },
      },
      //{
      //  path: 'pilares',
      //  component: PilaresComponent,
      //  data: {
      //    //title: 'Pilares',
      //    breadcrumbs: false,
      //    text:  '> Pilares'
      //  }
      //},
      {
        path: 'carta-editorial',
        component: CartaEditorialComponent,
        data: {
          //title: 'Carta Editorial',
          breadcrumbs: false,
          text: '> Carta Editorial'
        }
      },
      {
        path: 'productos/:producto',
        component: ProductosComponent,
        data: {
          //title: 'Productos',
          breadcrumbs: false,
          text: '> Productos'
        }
      },
    ]
  },
  {
    path: 'paquetes',

    data: {
      //title: 'Televisa llega',
      //breadcrumbs: 'Paquetes'
    },
    children: [
      {
        path: '',
        component: PaquetesComponent,
        data: {
          //title: 'Paquetes',
          breadcrumbs: false,
          text: 'Paquetes'
        },
      },
      {
        path: 'paquete',
        component: PaquetesDetalleComponent,
        data: {
          //title: 'Paquetes',
          breadcrumbs: false,
          text: 'Paquetes'
        },
      },
      {
        path: 'paquete/:tipopaquete',
        component: PaquetesDetalleComponent,
        data: {
          //title: 'Paquetes',
          breadcrumbs: false,
          text: 'Paquetes'
        },
      },
    ],
  },
  {
    path: 'nuestra-oferta',
    data: {
      //title: 'Nuestra oferta',
      //breadcrumbs: 'Nuestra Oferta'
    },
    children: [
      {
        path: '',
        component: NuestraOfertaComponent,
        data: {
          breadcrumbs: false,
          text: 'Nuestra Oferta'
        }
      },
      //{
      //  path: 'generos/:genero',
      //  component: GenerosComponent,
      //  data: {
      //    //title: 'Géneros',
      //    breadcrumbs: false,
      //    text: 'Nuestra Oferta > Géneros'
      //  },
      //},
      {
        path: 'patrocinios-deportivos',
        component: NuestraOfertaComponent,
        data: {
          breadcrumbs: false,
          text: 'Nuestra Oferta'
        },
      },
      {
        path: 'generos',
        component: GenerosComponent,
        data: {
          //title: 'Géneros',
          breadcrumbs: false,
          text: 'Nuestra Oferta > Géneros'
        },
      },
      {
        path: 'plataforma',
        data: {
          //title: 'Plataformas',
          breadcrumbs: false,
          text: 'Nuestra Oferta > Plataformas'
        },
        children: [
          {
            path: '',
            component: PlataformaComponent,
          },
          {
            path: 'canales-tvregional',
            component: CanalesRegionalComponent,
            data: {
              //title: 'Televisión Regional',
              breadcrumbs: false,
              text: 'Nuestra Oferta > Plataformas > Canales TV Regional'
            },
          },
          {
            path: ':plataforma',
            component: PlataformaComponent,
          },
          {
            path: ':plataforma/:media',
            component: PlataformaComponent,
          },
        ]
      },
      {
        path: 'detalle-programa',
        component: DetalleProgramaComponent,
        data: {
          //title: 'Detalles de programa',
          breadcrumbs: false,
          text: '> Programa'
        }
      },
      {
        path: 'detalle-programa/:id',
        component: DetalleProgramaComponent,
        data: {
          //title: 'Detalles de programa',
          breadcrumbs: false,
          text: '> Programa'
        }
      },
    ]
  },
  {
    path: 'futurecast',
    data: {
      //title: 'Futurecast',
      breadcrumbs: false,
      text: 'Futurecast'
    },
    children: [
      {
        path: '',
        component: FuturecastComponent,
      },
      {
        path: 'detalle-futurecast',
        component: DetalleFuturecastComponent,
        data: {
          //title: 'Casos de éxito y análisis de industrias',
          breadcrumbs: false,
          text: '> Detalle'
        }
      },
      {
        path: 'detalle-futurecast/:id',
        component: DetalleFuturecastComponent,
        data: {
          //title: 'Casos de éxito y análisis de industrias',
          breadcrumbs: false,
          text: '> Detalle'
        }
      },
    ]
  },

  {
    path: 'acerca-de',
    component: AcercaDeComponent,
    data: {
      //title: 'Acerca de',
      breadcrumbs: false,
      text: 'Acerca de'
    }
  },
  {
    path: 'eventos',
    data: {
      //title: 'Eventos',
    },
    children: [
      {
        path: '',
        component: EventosComponent,
        data: {
          //title: 'Eventos',
          breadcrumbs: false,
          text: 'Eventos'
        },
      },
      {
        path: 'detalle-evento',
        component: DetalleEventoComponent,
        data: {
          //title: 'Detalles de eventos',
          breadcrumbs: false,
          text: '> Detalle'
        }
      },
      {
        path: 'detalle-evento/:id',
        component: DetalleEventoComponent,
        data: {
          //title: 'Detalles de eventos',
          breadcrumbs: false,
          text: '> Detalle'
        }
      },
    ]
  },

  {
    path: 'aviso-privacidad',
    children: [
      {
        path: '',
        component: AvisoPrivacidadComponent,
        data: {
          //title: 'Aviso de privacidad',
          breadcrumbs: false,
          text: 'Aviso de Privacidad'
        }
      },
      {
        path: 'cookies',
        component: AvisoPrivacidadComponent,
        data: {
          //title: 'Aviso de privacidad',
          breadcrumbs: false,
          text: 'Aviso de Privacidad'
        }
      }
    ]    
  },
/*   {
    path: 'formatosdigitales',
    component: FormatosdigitalesComponent,
    data: {
      //title: 'Formatos Digitales',
      breadcrumbs: false,
      text: 'Formatos Digitales'
    }
  }, */
  { path: '404', component: NotFoundComponent },
  { path: '**', redirectTo: '/404' }
];;
