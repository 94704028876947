import { Component, OnInit, ViewChildren, ChangeDetectionStrategy, ChangeDetectorRef, QueryList } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { MatVideoComponent } from 'mat-video/lib/video.component';
import { GtmService } from 'ngx-gtm';
import { FutureCastResponse, FutureCast } from 'src/app/models/cms/cms.detalle-futurecast.model';
import { CmsFuturecastService } from 'src/app/services/cms/cms.futurecast.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-detalle-futurecast',
  templateUrl: './detalle-futurecast.component.html',
  styleUrls: ['./detalle-futurecast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DetalleFuturecastComponent implements OnInit {
  title: string = 'Casos de éxito y análisis de industrias';

  bannerImage: string;
  bannerTitle: string;
  baseCMSUrl: string;

  content: boolean = false;
  futurecast: string;
  futurecastData: any[]; // model = FuturecastDataBlock;
  futurecastDataResponse: FutureCastResponse; // model = FuturecastDataBlock;
  futurecastSingleData: FutureCast[];
  futurecastDataCompleteResponse: FutureCastResponse; // model = FuturecastDataBlock;
  tipoFutureCast: string;

  videoCardData: any[];
  @ViewChildren('video') matVideo: QueryList<MatVideoComponent>;
  video: HTMLVideoElement;

  /*Loaders*/
  isBannerLoaded: boolean = false;
  isFuturecastLoaded: boolean = false;

  breadcrumbsData: any[];

  constructor(
    private gtm: GtmService,
    public router: Router,
    private route: ActivatedRoute,
    private _location: Location,
    private cmsFuturecastService: CmsFuturecastService,
    private configuration: ConfigurationService,
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private titleService: Title,
    private metaService: Meta
  ) {
    this.baseCMSUrl = this.configuration.baseCmsUrl;
    this.route.params.subscribe(routeParams => {
      if (routeParams['id']) {
        this.futurecast = routeParams['id'];
        this.content = true;
        this.cdr.markForCheck();
      }
      else {
        this.futurecast = "No encontrado";
        this.content = false;
      }
    });

    this.getBannerImage();
  }

  ngOnInit(): void {
    this.gtm.push({ event: 'TelevisaLlega Futurecast Detalle' });
    if(this.authService.isLoggedIn){
      this.getDataFromServices();
    }
    else{
      /* Me loggeo y luego voy por los banners */
      this.authService.loginByApp().subscribe(resp => {//console.log('Resp login:',resp)
      this.getDataFromServices();
      });
    }

    this.titleService.setTitle(this.title);
    this.metaService.addTags([
      { name: 'keywords', content: 'casos de éxito, industrias, industria publicitaria, programación, éxitos de televisa, eventos especiales, comunicación Televisa, estrategia de marcas, desarrollo de marcas,  fortalecimiento de marcas, detalle de industrias, tendencias publicitarias, tendencias de industrias' },
      { name: 'description', content: 'Conoce los casos de éxito que hemos alcanzado con las marcas con base en nuestras estrategias, los detalles más relevantes de las industrias y la programación.' },
      { name: 'robots', content: 'index, follow' }
    ]);
  }

  public getDataFromServices()
  {
    if (this.futurecast != "No encontrado") {
      this.getFullData(this.futurecast);
    }
  }

  public getFullData(id: string) {

    //console.log('Entro a getFuturecast');
    this.cmsFuturecastService.getFuturecastCompleteData().subscribe(dataResponse => {
      this.futurecastDataCompleteResponse = dataResponse;
      //console.log('Returned getFuturecastCompleteData:', this.futurecastDataCompleteResponse);
      /* Quito todos los id's que no correspondan al actual */
      this.futurecastSingleData = this.futurecastDataCompleteResponse.data.futureCast.filter(fc => fc.contentItemId == id).slice();
      //console.log('Converted this.futurecastSingleData:', this.futurecastSingleData);
      if (this.futurecastSingleData) {
        this.bannerTitle = this.futurecastSingleData[0].tituloBanner;
        this.tipoFutureCast = this.futurecastSingleData[0].tipodelFuturecast.contentItems[0].generoTarjeta;
        this.breadcrumbsData = [
          { nombre: 'Futurecast', link: 'futurecast' },
          { nombre: this.bannerTitle, link: '' }
        ];
      } else {
        this.bannerTitle = 'Futurecast';
      }
      this.isFuturecastLoaded = true;
      this.cdr.markForCheck();
    });
    
  }


  public getBannerImage() {
    /* Invocar servicio que recupera imagen de portada de la página*/
    this.bannerImage = '';
    //this.bannerTitle = 'Futurecast';
    this.isBannerLoaded = true;
    this.cdr.markForCheck();
  }

  public iOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }

  pauseAllVideos() {

    if (this.matVideo) {
      this.matVideo.forEach((item: MatVideoComponent) => {
        this.video = item.getVideoTag();
        //console.log('Eemento Video', this.video);
        this.video.pause();
      });
    }

  }

  customOptionsVideo: OwlOptions = {
    loop: false,
    rewind: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 500,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1,
      },
    },
    nav: true,
    autoplay: false,
    autoplayTimeout: 7000,
    autoplaySpeed: 1000,
    autoplayHoverPause: true,
    autoplayMouseleaveTimeout: 1000
  }

  public goBack() {
    this._location.back();
  }

}
