<ng-container *ngIf="!isCarruselLoaded">
  <div class="col-12">
    <div class="loader-min-3">
      <img class="loader-img z-20" src="assets/images/website_general/loader-2.svg" alt="" />
    </div>
  </div>
</ng-container>

<div class="tvs-dynamic-carousel" *ngIf="isCarruselLoaded">
  <owl-carousel-o class="blue-dots tvs-carrusel-programas" [options]="customOptions">
    <ng-container class="tvs-card-dynamic-color" *ngFor="let datosTarjeta of datosProgramacionCompleta | slice: 0:7; let i = index;">
      <ng-template carouselSlide>
        <div class="p-2">
          <a [attr.id]="dynamicId('tarjetaProgPlataforma-', datosTarjeta.nombreTarjeta)"
             class="tarjetaProgPlataforma c-pointer"
             (click)="goToProgram(datosTarjeta.linkTarjeta, datosTarjeta.nombreTarjeta , datosTarjeta.cintilloTarjeta, datosTarjeta.canalTarjetaContenido.contentItems[0].platNombre, datosTarjeta.genero.contentItems[0].generoTarjeta, datosTarjeta.fechaTarjeta, datosTarjeta.horaTarjeta)">
            <app-tarjeta-programa class="c-pointer" [cardData]="datosTarjeta"></app-tarjeta-programa>
          </a>
        </div>
      </ng-template>
    </ng-container>
    <ng-template carouselSlide>
      <div class="tvs-click-todos p-2 text-center" (click)="navigateTo('nuestra-oferta/plataforma/' + friendlyUrl(nombrePlataforma))">
        <p class="tvs-text-orange tvs-text-bold" *ngIf="plataformaProgramas !== '5'">Ver todos los programas</p>
        <p class="tvs-text-orange tvs-text-bold" *ngIf="plataformaProgramas == '5'">Ver todas las revistas</p>
      </div>
    </ng-template>
  </owl-carousel-o>
</div>
