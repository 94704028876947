import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, ViewChildren, QueryList} from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { Router, ActivatedRoute, Event, NavigationEnd } from '@angular/router';
import { ProductosPlancomercial, ProductoContenido, VideosPlanComercial } from 'src/app/models/cms/cms.plan.comercial.model';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { CmsPlanComercialService } from '../../../services/cms/cms.plan.comercial.service';
import { AuthService } from 'src/app/services/auth.service';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { gsap } from "gsap/all";
import { GtmService } from 'ngx-gtm';
import { DynamicHelper } from 'src/app/helpers/dynamic-helper';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { MatVideoComponent } from 'mat-video/lib/video.component';

@Component({
  selector: 'app-productos',
  templateUrl: './productos.component.html',
  styleUrls: ['./productos.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductosComponent implements OnInit {

  title: string = '';
  metaDescription: string = '';
  metaKeywords: string = '';

  faPlus = faPlus;

  content: boolean = false;
  isProductosLoaded: boolean = false;
  isProductoDetalleLoaded: boolean = false;
  baseCMSUrl: string;
/*Identificador producto*/
  routeId: string;
  productoId: string;
  plataformaAsociada: string = '0';

  productos: ProductosPlancomercial[];
  productoDetalle: ProductoContenido[];
  videoProducto: VideosPlanComercial[];
  videoData: any[];
  isVideoLoaded: boolean = false;
  @ViewChildren('video') matVideo: QueryList<MatVideoComponent>;
  video: HTMLVideoElement;

  breadcrumbsData: any[] = [{ nombre: 'Plan comercial', link: 'plan-comercial' }];

  constructor(
    private gtm: GtmService,
    public router: Router,
    private _location: Location,
    private cmsPlanComercialService: CmsPlanComercialService,
    private authService: AuthService,
    private configuration: ConfigurationService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private titleService: Title,
    private metaService: Meta,
  ) {
    this.baseCMSUrl = this.configuration.baseCmsUrl

    this.route.params.subscribe(routeParams => {
      if (routeParams['producto']) {
        switch (routeParams['producto']) {
          case '1':
          case 'televisa-digital':
          case 'televisa-ads':
            this.productoId = '1';
            this.breadcrumbsData.push({ nombre: 'Televisa Ads', link: '' });
            break;
          case '2':
          case 'television-radiodifundida':
            this.productoId = '2';
            this.breadcrumbsData.push({ nombre: 'Televisión Radiodifundida', link: '' });
            break;
          case '3':
          case 'televisa-regional':
            this.productoId = '3';
            this.breadcrumbsData.push({ nombre: 'Televisa Regional', link: '' });
            break;
          case '4':
          case 'televisa-networks':
            this.productoId = '4';
            this.breadcrumbsData.push({ nombre: 'Televisa Networks', link: '' });
            break;
          case '5':
          case 'izzi-sky':
            this.productoId = '5';
            this.breadcrumbsData.push({ nombre: 'izzi + sky', link: '' });
            break;
          case '6':
          case 'editorial-televisa':
            this.productoId = '6';
            this.breadcrumbsData.push({ nombre: 'Editorial Televisa', link: '' });
            break;
          case '7':
          case 'patrocinios-deportivos':
            this.productoId = '7';
            this.breadcrumbsData.push({ nombre: 'Patrocinios Deportivos', link: '' });
            break;
          case '8':
          case 'multiplataforma':
            this.productoId = '8';
            this.breadcrumbsData.push({ nombre: 'Multiplataforma', link: '' });
            break;
          default:
            this.productoId = "No encontrado";
            this.breadcrumbsData.push({ nombre: '', link: '' });
            break;
        };
        //this.productoId = routeParams['producto'];
        this.content = true;
        this.cdr.markForCheck();
      }
      else {
        this.productoId = "No encontrado";
        this.content = false;
      }
    });

    if (this.authService.isLoggedIn) {
      this.getProductos();
    } else {
      this.authService.loginByApp().subscribe(resp => {//console.log('Resp login:',resp)
        this.getProductos();
      });
    }

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

  }

  ngOnInit(): void {
    this.gtm.push({ event: 'TelevisaLlega Productos' });
  }

  public asignaMetaData() {

    switch (this.productoId) {
      case '1':
        this.title = 'Televisa Ads';
        this.metaDescription = 'Productos Televisa Ads';
        this.metaKeywords = 'Televisa Ads';
        break;
      case '2':
        this.title = 'Televisión Radiodifundida';
        this.metaDescription = 'Productos Televisión Radiodifundida';
        this.metaKeywords = 'Televisión Radiodifundida';
        break;
      case '3':
        this.title = 'Televisa Regional';
        this.metaDescription = 'Productos Televisa Regional';
        this.metaKeywords = 'Televisa Regional';
        break;
      case '4':
        this.title = 'Televisa Networks';
        this.metaDescription = 'Productos Televisa Networks';
        this.metaKeywords = 'Televisa Networks';
        break;
      case '5':
        this.title = 'izzi + sky';
        this.metaDescription = 'Productos izzi + sky';
        this.metaKeywords = 'izzi + sky';
        break;
      case '6':
        this.title = 'Editorial Televisa';
        this.metaDescription = 'Productos Editorial Televisa';
        this.metaKeywords = 'Editorial Televisa';
        break;
      case '7':
        this.title = 'Patrocinios Deportivos';
        this.metaDescription = 'Productos Patrocinios Deportivos';
        this.metaKeywords = 'Patrocinios Deportivos';
        break;
      case '8':
        this.title = 'Multiplataforma';
        this.metaDescription = 'Productos Multiplataforma';
        this.metaKeywords = 'Multiplataforma';
        break;
      default:
        this.title = 'Productos';
        this.metaDescription = 'Productos';
        this.metaKeywords = 'Productos';
        break;
    };



    this.titleService.setTitle(this.title);
    this.metaService.updateTag({ name: 'keywords', content: this.metaKeywords });
    this.metaService.updateTag({ name: 'description', content: this.metaDescription });
    this.metaService.updateTag({ name: 'robots', content: 'index, follow' });

  }

  ordenaArregloPropiedadOrden(obj1, obj2) {
    if (obj1.orden > obj2.orden) { return 1; }
    if (obj1.orden < obj2.orden) { return -1; }
    return 0;
  }

  public getProductos() {
    this.cmsPlanComercialService.getProductos().subscribe(response => {
      this.productos = response.data.productosPlancomercial;
      this.productos.sort(this.ordenaArregloPropiedadOrden);
      this.isProductosLoaded = true;
      this.cdr.markForCheck();
      //console.log('this.productos', this.productos);
    });

    this.cmsPlanComercialService.getProductosDetalle().subscribe(response => {
      this.productoDetalle = response.data.productoContenido;
      this.isProductoDetalleLoaded = true;
      this.getPlataformaAsociada();
      this.asignaMetaData();
      this.cdr.markForCheck();
      //console.log('Obtuve producto Detalle de plataforma:', this.plataformaAsociada);
      //console.log('Obtuve producto Detalle', this.productoDetalle);
    });
    /*video televisa ads (ViX)*/
    if (this.productoId == '1') {
      this.cmsPlanComercialService.getVideosProductos().subscribe(response => {
        this.videoProducto = response.data.videosPlanComercial;
        this.videoData = [];
        for (let i = 0; i < this.videoProducto.length; i++) {
          this.videoData.push(
            {
              displayText: this.videoProducto[i].displayText,
              orden: this.videoProducto[i].orden,
              producto: this.videoProducto[i].producto.contentItems[0].orden,
              urlVideo: this.baseCMSUrl.slice(0, -1) + this.videoProducto[0].eventoVideo?.video?.urls[0],
              urlthumbnail: this.baseCMSUrl.slice(0, -1) + this.videoProducto[0].eventoVideo?.thumbnail?.urls[0]
            }
          )
        }
        if (this.videoData.length > 0) {
          this.videoData.sort(this.ordenaArregloPropiedadOrden);
          this.isVideoLoaded = true;
          this.cdr.markForCheck();
        } else {
          this.videoData = [];
          this.isVideoLoaded = false;
          this.cdr.markForCheck();
        }
        //console.log('this.videoData', this.videoData);
      });
    }
  }

  public getPlataformaAsociada() {
    if (this.productoId != '5' && this.productoId != '7' && this.productoId != '8' ) {
      for (let productos of this.productoDetalle) {
        if (productos.idProducto === this.productoId) {
          this.plataformaAsociada = productos.plataformaAsociada?.contentItems[0]?.idPlataforma.toString();
        }
      }
    } else {
      this.plataformaAsociada = '0';
    }
    
    return this.plataformaAsociada;
  }

  public getImg(imagen: string) {
    if (!imagen)
      return imagen;

    var urlImagen: string = imagen.replace(/ /g, '%20');
    return urlImagen;
  }

  public navigateTo(path: string): void {
    this.router.navigateByUrl(path);
    gsap.to(window, {
      scrollTo: 0,
      duration: 0.5
    });
  }

  public goBack() {
    this._location.back();
  }

  public dynamicId(objeto: string, nombre: string) {
    return DynamicHelper.dynamicIdHelper(objeto, nombre);
  }

  public friendlyUrl(nombre: string) {
    return DynamicHelper.friendlyUrlHelper(nombre);
  }

  public iOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }

  pauseAllVideos() {
    var vids = document.getElementsByTagName('video');
    for (var i = 0; i < vids.length; i++) {
      this.video = vids.item(i);
      this.video.pause();
    }
  }

  customOptionsMobile: OwlOptions = {
    loop: false,
    rewind: true,
    mouseDrag: false,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 500,
    navText: ['', ''],
    items: 1,
    nav: true,
    autoplay: true,
    autoplayTimeout: 7000,
    autoplaySpeed: 1000,
  }

  customOptionsVideo: OwlOptions = {
    loop: false,
    rewind: true,
    mouseDrag: false,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 500,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1,
      },
    },
    nav: true,
    autoplay: false,
    autoplayTimeout: 7000,
    autoplaySpeed: 1000,
    autoplayHoverPause: true,
    autoplayMouseleaveTimeout: 1000
  }




}
