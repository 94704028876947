import { Component, OnInit } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { gsap } from "gsap/all";

@Component({
  selector: 'app-website-footer',
  templateUrl: './website-footer.component.html',
  styleUrls: ['./website-footer.component.scss']
})
export class WebsiteFooterComponent implements OnInit {

  currentYear: number = new Date().getFullYear();

  constructor(
    public router: Router,
  ) {
  }

  ngOnInit(): void {
  }

  public navigateTo(path: string): void {
    this.router.navigateByUrl(path);
    gsap.to(window, {
      scrollTo: 0,
      duration: 0.5
    });
  }

}
