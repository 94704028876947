import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router  } from '@angular/router';
import { gsap } from "gsap/all";
import { GtmService } from 'ngx-gtm';
import { ImagenTarjeta, Paquete, PaquetesPlancomercial, Tarjeta } from 'src/app/models/cms/cms.plan.comercial.model';
import { AuthService } from 'src/app/services/auth.service';
import { CmsPlanComercialService } from 'src/app/services/cms/cms.plan.comercial.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { DynamicHelper } from 'src/app/helpers/dynamic-helper';

@Component({
  selector: 'app-paquetes-detalle',
  templateUrl: './paquetes-detalle.component.html',
  styleUrls: ['./paquetes-detalle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaquetesDetalleComponent implements OnInit {

  title: string = 'Paquetes';
  metaDescription: string = '';
  metaKeywords: string = '';

  baseCMSUrl : string;
  paquete: string;
  bannerTitle: string;
  datosPaquete: Paquete[];

  datosPaqueteNuevos: Paquete[];
  datosPaqueteHistorico: Paquete[];
  datosPaqueteHistoricoCount: number = 0;
  datosPaqueteNuevosSlice: number = 5;
  paginaInicial: number = 1;
  paquetesPorPagina: number = 8;

  datosCarruselPaquete: PaquetesPlancomercial[];
  paquetes: PaquetesPlancomercial[];

  /*Loaders*/
  isBannerLoaded: boolean = false;
  isPaquetesLoaded: boolean = false;
  isPaquetesOtrosLoaded: boolean = false;

  breadcrumbsData: any[];

  constructor(
    private gtm: GtmService,
    private route: ActivatedRoute,
    public router: Router,
    private configuration: ConfigurationService,
    private cmsPlanComercialService: CmsPlanComercialService,
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private titleService: Title,
    private metaService: Meta
  ) {
    this.baseCMSUrl = this.configuration.baseCmsUrl;
    this.paquete = null;
    this.route.params.subscribe(routeParams => {
      if (routeParams['tipopaquete']) {
        this.paquete = routeParams['tipopaquete'];
        this.asignaMetaData(this.paquete);
        this.cdr.markForCheck();
      }
      else {
        this.paquete = "No encontrado";
        this.cdr.markForCheck();
      }
    });
      
  }

  ngOnInit(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.asignaBreadcrumbs(this.paquete);
    this.gtm.push({ event: 'TelevisaLlega Paquetes' });
    if(this.authService.isLoggedIn){
      this.getDataFromServices();
    }
    else{
      /* Me loggeo y luego voy por los banners */
      this.authService.loginByApp().subscribe(resp => {//console.log('Resp login:',resp)
      this.getDataFromServices();
      });
    }
  }
  
  public asignaBreadcrumbs(nomPaquete: string) {
    var breadcrumbTitle: string = "";
    switch(nomPaquete) {
      case "futbolsoccer": {
        this.bannerTitle = 'Fútbol Soccer';
        this.breadcrumbsData = [
          { nombre: 'Paquetes', link: 'paquetes' },
          { nombre: 'Fútbol Soccer', link: '' }
        ];
        break;
      }
      case "futbolamericano": {
        this.bannerTitle = 'Fútbol Americano';
        this.breadcrumbsData = [
          { nombre: 'Paquetes', link: 'paquetes' },
          { nombre: 'Fútbol Americano', link: '' }
        ];
        break;
      }
      case "beisbol": {
        this.bannerTitle = 'Béisbol';
        this.breadcrumbsData = [
          { nombre: 'Paquetes', link: 'paquetes' },
          { nombre: 'Béisbol', link: '' }
        ];
        break;
      }
      case "box": {
        this.bannerTitle = 'Box';
        this.breadcrumbsData = [
          { nombre: 'Paquetes', link: 'paquetes' },
          { nombre: 'Box', link: '' }
        ];
        break;
      }
      case "basquetbol": {
        this.bannerTitle = 'Basquetbol';
        this.breadcrumbsData = [
          { nombre: 'Paquetes', link: 'paquetes' },
          { nombre: 'Basquetbol', link: '' }
        ];
        break;
      }
      case "otros": {
        this.bannerTitle = 'Otros';
        this.breadcrumbsData = [
          { nombre: 'Paquetes', link: 'paquetes' },
          { nombre: 'Otros', link: '' }
        ];
        break;
      }
      default:{
        this.bannerTitle = '';
        this.breadcrumbsData = [
          { nombre: 'Paquetes', link: 'paquetes' },
          { nombre: '', link: '' }
        ];
        break;
      }
    }
    this.isBannerLoaded = true;
    this.cdr.markForCheck();
  }

  public asignaMetaData(nomPaquete: string) {
    if (nomPaquete && nomPaquete !== 'No encontrado') {
      if (nomPaquete.toLowerCase() == 'futbolsoccer') {
        this.title = 'Paquetes Fútbol Soccer';
        this.metaDescription = 'Conoce los paquetes deportivos y haz tu marca visible a la audiencia dentro de los mejores eventos deportivos.';
        this.metaKeywords = 'eventos, evento deportivos, partidos de fútbol, publicidad en fútbol soccer';
      } else if (nomPaquete.toLowerCase() == 'futbolamericano') {
        this.title = 'Paquetes Fútbol Americano';
        this.metaDescription = 'Conoce la gran oferta de los paquetes de fútbol americano y haz tu marca visible a la audiencia dentro de los mejores partidos de este deporte.';
        this.metaKeywords = 'eventos, evento deportivos, partidos de fútbol, publicidad en fútbol americano';
      } else if (nomPaquete.toLowerCase() == 'beisbol') {
        this.title = 'Paquetes Béisbol';
        this.metaDescription = 'Conoce la gran oferta de los paquetes de béisbol y haz tu marca visible a la audiencia dentro de los mejores encuentros de "El Rey de los deportes".';
        this.metaKeywords = 'eventos, evento deportivos, partidos de fútbol, publicidad en béisbol';
      } else if (nomPaquete.toLowerCase() == 'box') {
        this.title = 'Paquetes Box';
        this.metaDescription = 'Conoce toda la oferta de los paquetes de box y haz tu marca visible a la audiencia dentro de los mejores encuentros del pugilismo nacional y/o internacional.';
        this.metaKeywords = 'eventos, evento deportivos, partidos de fútbol, publicidad en box';
      } else if (nomPaquete.toLowerCase() == 'basquetbol') {
        this.title = 'Paquetes Basquetbol';
        this.metaDescription = 'Conoce la gran oferta de los paquetes de basquetbol  y haz tu marca visible a la audiencia dentro de los mejores encuentros de la duela del deporte "Ráfaga".';
        this.metaKeywords = 'eventos, evento deportivos, partidos de fútbol, publicidad en básquetbol';
      } else if (nomPaquete.toLowerCase() == 'otros') {
        this.title = 'Paquetes Otros';
        this.metaDescription = 'Conoce toda la oferta disponible de paquetes especiales y haz tu marca visible a todas las audiencias dentro de los mejores eventos especiales.';
        this.metaKeywords = 'eventos, evento deportivos, partidos de fútbol, publicidad en eventos deportivos, publicidad en programas de entretenimiento';
      }

      this.metaService.updateTag({ name: 'keywords', content: this.metaKeywords });
      this.metaService.updateTag({ name: 'description', content: this.metaDescription });
      this.metaService.updateTag({ name: 'robots', content: 'index, follow' });

    } else if (!nomPaquete || nomPaquete == 'No encontrado') {
      this.title = 'Paquetes';
      this.metaDescription = '';
      this.metaKeywords = '';

      this.metaService.removeTag("name = 'keywords'");
      this.metaService.removeTag("name = 'description'");
      this.metaService.updateTag({ name: 'robots', content: 'noindex, nofollow, noodp' });
    }

    this.titleService.setTitle(this.title);

  }

  getDataFromServices(){
    this.getCaruselPaquetes(this.paquete);
    this.getPaquetesSeccion();
  }

  public getCaruselPaquetes(paquete: string) {
    /* Invocar servicio que recupera datos de tarjeta por tipo de paquete*/
    this.cmsPlanComercialService.getPaquetes().subscribe(response => {
      this.datosCarruselPaquete = response.data.paquetesPlancomercial.filter(p => p.displayText.toLowerCase() == paquete.toLowerCase());
      //console.log('GetPaquetes filtrado:',response.data.paquetesPlancomercial);
      this.getPaquetes(paquete);
    });
  }

  public getPaquetes(paquete: string) {
    /* Invocar servicio que recupera datos de tarjeta por tipo de paquete*/
    this.cmsPlanComercialService.getPaquetesDetalle().subscribe(response => {
      //console.log('Response de getPaquetesDetalle:',response.data.paquetes);
      this.datosPaquete = response.data.paquetes.filter(p => p.paquetePadre.toLowerCase() == paquete.toLowerCase());
      this.datosPaquete.forEach(element => {
        if(element.tarjetaPaquetes)
        {
          element.tarjetaPaquetes.imagenTarjeta = new ImagenTarjeta();
          element.tarjetaPaquetes.imagenTarjeta.urls = new Array<string>();
          element.tarjetaPaquetes.imagenTarjeta.urls.push(this.datosCarruselPaquete[0]?.imagenInternos?.urls[0]);
        }
      });

      this.datosPaqueteNuevos = this.datosPaquete.slice(0, this.datosPaqueteNuevosSlice);
      this.datosPaqueteHistorico = this.datosPaquete.slice(this.datosPaqueteNuevosSlice, 10000);
      this.datosPaqueteHistoricoCount = this.datosPaqueteHistorico.length;
      this.isPaquetesLoaded = true;
      this.cdr.markForCheck();
    });
  }

  public getPaquetesSeccion() {
    this.cmsPlanComercialService.getPaquetes().subscribe(response => {
      this.paquetes = response.data.paquetesPlancomercial.map((v: PaquetesPlancomercial) => {
        v.linkTarjeta = `/paquetes/paquete/${v.displayText}`;
        this.isPaquetesLoaded = true;
        this.cdr.markForCheck();
        return v;
      });
      this.paquetes.sort(this.ordenaArregloPropiedadOrden);
      this.isPaquetesOtrosLoaded = true;
      //console.log('Esto traigo en getPaquetesSeccion()', this.paquetes);
    });
  }

  ordenaArregloPropiedadOrden(obj1, obj2) {
    if (obj1.orden > obj2.orden) { return 1; }
    if (obj1.orden < obj2.orden) { return -1; }
    return 0;
  }

  public goToPdf(path: string, nombre: string, tipo: string) {
    //console.log('Por abrir: ',path)
    window.open(path, '_blank');
    this.gtm.push({
      event: 'click-paquete',
      type: 'Paquetes',
      package: tipo,
      name: nombre
    });
  }
  public navigateTo(path: string): void {
    this.router.navigateByUrl(path);
    gsap.to(window, {
      scrollTo: 0,
      duration: 0.5
    });
  }

  public dynamicId(objeto: string, nombre: string) {
    return DynamicHelper.dynamicIdHelper(objeto, nombre);
  }

  public getCardImg(imagen: string) {
    var urlImagen: string = imagen.replace('%C2%A0', ' ').replace(/ /g, '%20');
    return urlImagen;
  }

}
