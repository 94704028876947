<div class="card tvs-card-programa mb-4 mx-1">
  <div>
    <div class="tvs-card-img-container tvs-o-hidden">
      <div [ngStyle]="{'background-image': 'url('+ getCardImg(baseCMSUrl.slice(0, -1) + cardData?.imagenTarjeta?.urls[0]) +')'}"
           *ngIf="cardData?.imagenTarjeta && cardData?.imagenTarjeta?.urls[0] != ''">
      </div>
      <div style="background-image: url('assets/images/website_general/paquetes_default.png')"
           *ngIf="!cardData.imagenTarjeta || cardData?.imagenTarjeta?.urls[0] == ''">
      </div>
    </div>
  </div>
  <div class="card-body p-3">

    <div class="tvs-img-canal">
      <img class="img-fluid" [src]="getCardImg(baseCMSUrl.slice(0, -1) + cardData.canalTarjetaContenido?.contentItems[0]?.platImg?.urls[0])" alt="" />
    </div>

    <p class="tvs-card-genero small" *ngIf="cardData.genero?.contentItems[0].generoTarjeta && cardData.genero?.contentItems[0].generoTarjeta != ''">{{cardData.genero?.contentItems[0].generoTarjeta}}</p>

    <div class="m-0 mt-1 tvs-cont-titulo">
      <h2 class="card-title m-0">{{cardData.programaNombreCorto}}</h2>
    </div>

    <div class="m-0 mt-1 tvs-cont-horario">
      <p class="tvs-card-horario small m-0" *ngIf="cardData.fechaTarjeta && cardData.fechaTarjeta != ''">{{cardData.fechaTarjeta}}&nbsp;{{cardData.horaTarjeta}}</p>
    </div>

  </div>
</div>

