<ng-container *ngIf="!isBannerLoaded">
  <div class="futurecast">
    <div class="row tvs-banner-unloaded">
      <img class="loader-img z-20" src="assets/images/website_general/loader-2.svg" alt="" />
    </div>
  </div>
</ng-container>
<ng-container *ngIf="isBannerLoaded">
  <app-header-image-new srcImage="{{bannerImage}}" srcImageMobile="{{bannerImageMobile}}" srcTitle="{{bannerTitle}}" srcTipo="3" class="futurecast"></app-header-image-new>
</ng-container>


<div class="row p-5 tvs-bg-white tvs-futurecast-top">
  <ng-container *ngIf="!isCasoPrincLoaded">
    <div class="container-fluid loader-min">
      <img class="loader-img z-20" src="assets/images/website_general/loader-2.svg" alt="" />
    </div>
  </ng-container>
  <div class="container-fluid">
    <ng-container *ngIf="isCasoPrincLoaded">

      <div class="row">

        <div class="col-12 mb-3 text-center d-block d-md-none">
          <img class="img-fluid text-center tvs-rounded-corner" src="{{casoExitoPrincipalImg}}" />
        </div>

        <div class="col-12 col-md-6 mb-3 text-left">
          <h3 class="tvs-text-orange tvs-text-bold mt-5 mt-3">{{casoExitoPrincipalTitle}}</h3>
          <p class="tvs-text-bold tvs-text-upper">{{casoExitoPrincipalDate}}</p>
          <p>{{casoExitoPrincipalText}}</p>

          <button [attr.id]="dynamicId('tarjetaFuturecast-', casoExitoPrincipalTitle)"
                  class="btn btn-primary btn-orange tvs-btn mt-3"
                  (click)="goToFuturecast(casoExitoPrincipalLink,casoExitoPrincipalTitle,casoExitoPrincipalGenero)">
            Ver más <fa-icon [icon]="faArrowRight" class="pl-2"></fa-icon>
          </button>
        </div>

        <div class="col-md-6 mb-3 text-center d-none d-md-block">
          <img class="img-fluid text-center tvs-rounded-corner" src="{{casoExitoPrincipalImg}}" />
        </div>

      </div>
    </ng-container>
  </div>

  <!--Filtros-->
  <div class="container-fluid">
    <div class="row d-none d-sm-flex my-5">
      <ng-container *ngIf="!isCasoLoaded">
        <div class="loader-min">
          <img class="loader-img z-20" src="assets/images/website_general/loader-2.svg" alt="" />
        </div>
      </ng-container>
      <ng-container *ngIf="isCasoLoaded">
        <div class="col-sm-2 col-xl-1">
          <h4 class="tvs-text-orange">Filtrar por</h4>
        </div>
        <div class="col-sm-10 col-xl-11">
          <div class="row tvs-plat-topdiv">
            <div class="col-2 col-sm-5 col-md-3 col-lg-3 col-xl-2 p-0 text-center form-check-categoria">
              <div class="form-check-label-categoria">
                <input class="form-check-input filter-check" type="radio" name="Grupo_Futurecast" value="0" id="genero_todos" [checked]='isFutureChecked("0")' (change)="changeFilterFutureCast(0,$event)">
                <div class="custom-check-categoria">
                  <div class="small text-center">Todos</div>
                </div>
              </div>
            </div>
            <div class="form-check col-2 col-sm-5 col-md-3 col-lg-3 col-xl-2 form-check-categoria" *ngFor="let filtro of catFuturecast">
              <div class="form-check-label-categoria">
                <input class="form-check-input filter-check" type="radio" name="Grupo_Futurecast" value="{{filtro.id}}" id="genero_{{filtro.value}}" [checked]='isFutureChecked(filtro.id)' (change)="changeFilterFutureCast(filtro.id,$event)">
                <div class="custom-check-categoria">
                  <div class="small text-center">{{filtro.value}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <!--Filtros mobile botón-->
    <div class="row d-flex d-sm-none my-5" *ngIf="isCasoLoaded">
      <ng-container>
        <div class="col-8 offset-2">
          <button id="filtro-mobile" class="btn btn-primary btn-orange tvs-btn btn-block" (click)="openFilter()"><fa-icon [icon]="faFilter" class="pr-1 tvs-text-white"></fa-icon>Filtrar</button>
        </div>
      </ng-container>
    </div>
    <!--filtros mobile-->
    <div class="tvs-mobile-filter">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 text-right">
            <button class="btn btn-link tvs-close-filter tvs-text-white" (click)="closeFilter()">Cerrar<fa-icon [icon]="faTimes" class="pl-1 tvs-text-white"></fa-icon></button>
          </div>
          <div class="col-12 pt-5">
            <ul class="tvs-mobile-filter-list">
              <!--filtros géneros-->
              <li class="tvs-text-white tvs-mobile-filter-sub tvs-mobile-filter-gen">
                <div class="col-12">Filtrar por <fa-icon [icon]="faMinus" class="float-right tvs-text-white"></fa-icon></div>
                <div class="col-12 p-0 my-4">
                  <div class="form-check col-12 form-check-genero">
                    <div class="form-check-label-genero">
                      <input class="form-check-input filter-check" type="radio" name="Grupo_Futurecast" value="0" id="genero_todos" [checked]='isFutureChecked("0")' (change)="changeFilterFutureCast(0,$event)">
                      <div class="custom-check-genero">
                        <div class="small">Todos</div>
                      </div>
                    </div>
                  </div>
                  <div class="form-check col-12 form-check-genero" *ngFor="let filtro of catFuturecast">
                    <div class="form-check-label-genero">
                      <input class="form-check-input filter-check" type="radio" value="{{filtro.id}}" name="Grupo_Futurecast" id="genero_{{filtro.value}}" [checked]='isFutureChecked(filtro.id)' (change)="changeFilterFutureCast(filtro.id,$event)">
                      <div class="custom-check-genero">
                        <div class="small">{{filtro.value}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--Cuadrícula casos de éxito-->
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 mb-5">
        <div class="row">
          <ng-container *ngIf="!isCasoLoaded">
            <div class="loader-min">
              <img class="loader-img z-20" src="assets/images/website_general/loader-2.svg" alt="" />
            </div>
          </ng-container>
          <ng-container *ngIf="isCasoLoaded">
            <div *ngFor="let datosTarjeta of datosFuturecast; let i = index;" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xxl-2 px-1">
              <a [attr.id]="dynamicId('tarjetaFuturecast-', datosTarjeta.tarjeta.nombreTarjeta)"
                  class="tarjetaFuturecast c-pointer"
                  (click)="goToFuturecast(datosTarjeta.tarjeta.verMasTarjeta,datosTarjeta.tarjeta.nombreTarjeta,datosTarjeta.tarjeta.cintilloTarjeta)">
                <app-tarjeta-futurecast class="tvs-tarjeta-programa tvs-tarjeta-futurecast c-pointer" [cardData]="datosTarjeta.tarjeta"></app-tarjeta-futurecast>
              </a>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>



</div>

