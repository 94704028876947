<ng-container *ngIf="!isBannerLoaded">
  <div class="resultados-buscador">
    <div class="row tvs-banner-unloaded">
      <img class="loader-img z-20" src="assets/images/website_general/loader-2.svg" alt="" />
    </div>
  </div>
</ng-container>
<ng-container *ngIf="isBannerLoaded">
  <app-header-image-new srcImage="{{bannerImage}}" srcTitle="{{bannerTitle}}" class="resultados-buscador"></app-header-image-new>
</ng-container>

<div class="row mt-3 px-2 px-md-5 py-5 tvs-bg-white">

  <!--Loader-->
  <section *ngIf="!isContentLoaded" class="w-100 mb-5 z-20" style="min-height:255px;">

      <div class="row my-5">
        <div class="col-12" style="height:600px;">
          <div class="loader-100">
            <img class="loader-img z-20" src="assets/images/website_general/loader-2.svg" alt="" />
          </div>
        </div>
      </div>

  </section>
  
  <section *ngIf="isContentLoaded" class="w-100 mb-5 z-20" style="min-height:255px;">

      <div class="row my-5 px-3 px-md-0">
        <div class="col-12 mt-3">
          <h3>{{textoBusqueda}}</h3>
        </div>

        <div *ngIf="resultProgramas" id="resultProgramas" class="col-12 mt-3">
          <h4 class="tvs-text-orange tvs-text-upper f-left">Programas</h4>

          <div class="resultProgramas f-left c-pointer" (click)="toggleCollapse(1)">
            <fa-icon class="tvs-expanded tvs-text-orange" [icon]="faChevronUp"></fa-icon>
            <fa-icon class="tvs-collapsed tvs-text-orange" [icon]="faChevronDown"></fa-icon>
          </div>

          <div class="row">
            <ng-container>
              <div *ngFor="let datosTarjeta of datosProgramacion; let i = index;" class="col-12 col-sm-6 col-lg-4 col-xl-3">
                <app-tarjeta-buscador class="tvs-tarjeta-programa tvs-tarjeta-futurecast" [cardData]="datosTarjeta"></app-tarjeta-buscador>
              </div>
            </ng-container>
          </div>

        </div>

        <div *ngIf="resultFuturecast" id="resultFuturecast" class="col-12 mt-3">
          <h4 class="tvs-text-orange tvs-text-upper f-left">Futurecast</h4>

          <div class="resultFuturecast f-left c-pointer" (click)="toggleCollapse(2)">
            <fa-icon class="tvs-expanded tvs-text-orange" [icon]="faChevronUp"></fa-icon>
            <fa-icon class="tvs-collapsed tvs-text-orange" [icon]="faChevronDown"></fa-icon>
          </div>

          <div class="row">
            <ng-container>
              <div *ngFor="let datosTarjeta of datosFuturecast; let i = index;" class="col-12 col-sm-6 col-lg-4 col-xl-3">
                <app-tarjeta-buscador class="tvs-tarjeta-programa tvs-tarjeta-futurecast" [cardData]="datosTarjeta"></app-tarjeta-buscador>
              </div>
            </ng-container>
          </div>

        </div>

        <div *ngIf="resultEventos" id="resultEventos" class="col-12 mt-3">
          <h4 class="tvs-text-orange tvs-text-upper f-left">Eventos</h4>

          <div class="resultEventos f-left c-pointer" (click)="toggleCollapse(3)">
            <fa-icon class="tvs-expanded tvs-text-orange" [icon]="faChevronUp"></fa-icon>
            <fa-icon class="tvs-collapsed tvs-text-orange" [icon]="faChevronDown"></fa-icon>
          </div>

          <div class="row">
            <ng-container>
              <div *ngFor="let datosTarjeta of datosEvento; let i = index;" class="col-12 col-sm-6 col-lg-4 col-xl-3">
                <app-tarjeta-buscador class="tvs-tarjeta-programa tvs-tarjeta-futurecast" [cardData]="datosTarjeta"></app-tarjeta-buscador>
              </div>
            </ng-container>
          </div>

        </div>

        <div *ngIf="resultPaquetes" id="resultPaquetes" class="col-12 mt-3">
          <h4 class="tvs-text-orange tvs-text-upper f-left">Paquetes</h4>

          <div class="resultPaquetes f-left c-pointer" (click)="toggleCollapse(4)">
            <fa-icon class="tvs-expanded tvs-text-orange" [icon]="faChevronUp"></fa-icon>
            <fa-icon class="tvs-collapsed tvs-text-orange" [icon]="faChevronDown"></fa-icon>
          </div>

          <div class="row">
            <ng-container>
              <div *ngFor="let datosTarjeta of datosPaquete; let i = index;" class="col-12 col-sm-6 col-lg-4 col-xl-3">
                <app-tarjeta-buscador class="tvs-tarjeta-programa tvs-tarjeta-futurecast" [cardData]="datosTarjeta"></app-tarjeta-buscador>
              </div>
            </ng-container>
          </div>

        </div>

        <div *ngIf="resultSecciones" id="resultSecciones" class="col-12 mt-3">
          <h4 class="tvs-text-orange tvs-text-upper f-left">Secciones</h4>

          <div class="resultSecciones f-left c-pointer" (click)="toggleCollapse(5)">
            <fa-icon class="tvs-expanded tvs-text-orange" [icon]="faChevronUp"></fa-icon>
            <fa-icon class="tvs-collapsed tvs-text-orange" [icon]="faChevronDown"></fa-icon>
          </div>

          <div class="row">
            <div class="col-12">
              <div *ngFor="let datosTarjeta of datosSecciones; let i = index;" class="col-12 mb-4 mx-1 tvs-card-sections">
                <app-tarjeta-buscador class="tvs-tarjeta-programa tvs-tarjeta-futurecast" [cardData]="datosTarjeta"></app-tarjeta-buscador>
              </div>
            </div>
          </div>

        </div>



      </div>

  </section>


  <div class="col-12 col-md-10 offset-md-1 my-4 text-center">
    <a class="tvs-regresar" href="javascript:history.back()">&nbsp;</a>
  </div>

</div>

