import { Injectable } from '@angular/core';
import { CmsEndpoint } from './cms-endpoint.service';
import { ComercialParrillaRegionalResponse, EventosResponse, OfertaCalendarioResponse, OfertaContenidoResponse, OfertaGenerosResponse, OfertaPlataformaResponse, TiposPlataformaResponse, CtaResponse } from '../../models/cms/cms.oferta.model';

@Injectable()
export class CmsOfertaService {

  constructor(
    private cmsendpoint: CmsEndpoint) {
  }

  getTiposDePlataforma() {
    var query = `query{
      ofertaTiposdePlataforma(status: PUBLISHED) {
        plataformaTexto
        idPlataforma
        displayText
        bannerTitle
        bannerImage {
          urls
          paths
        }
        orden
      }
    }`;
    return this.cmsendpoint.getCmsDataEndPoint<TiposPlataformaResponse>(query);
  }

  getPlataformas() {
    var query = `query{
      ofertaPlataformas(status: PUBLISHED) {
        platId
        platImg {
          urls
          paths
        }
        platMediaId
        platNombre
        numSecu
        generoodescripcioncorta
      }
    }`;
    return this.cmsendpoint.getCmsDataEndPoint<OfertaPlataformaResponse>(query);
  }

  getGeneros() {
    var query = `query{
      ofertaGeneros {
        idGeneroTarjeta
        generoTarjeta
        contentItemId
      }
    }`;
    return this.cmsendpoint.getCmsDataEndPoint<OfertaGenerosResponse>(query);
  }

  getOfertaCalendario() {
    var query = `query{
      ofertaCalendario(status: PUBLISHED) {
        contentItemId
        title
        start
        end
      }
    }`;
    return this.cmsendpoint.getCmsDataEndPoint<OfertaCalendarioResponse>(query);
  }

  getOfertaPDFParrilla() {
    var query = `query{
      comercialParrillaRegional(status: PUBLISHED) {
        pDF {
          paths
          urls
        }
        contentItemId
      }
    }`;
    return this.cmsendpoint.getCmsDataEndPoint<ComercialParrillaRegionalResponse>(query);
  }

  getOfertaContenidos() {
    var query = `query{
      ofertaContenidos(status: PUBLISHED, first: 3000) {
        contentItemId
        fechaTarjeta
        horaTarjeta
        numOrden
        imagenTarjeta {
          paths
          urls
        }
        nombreTarjeta
        programaDescripcion
        programaNombreCorto
        genero {
          contentItems {
            ... on OfertaGeneros {
              generoTarjeta
              idGeneroTarjeta
              contentItemId
            }
          }
        }
        canalTarjetaContenido {
          contentItems {
            ... on OfertaPlataformas {
              displayText
              platId
              platMediaId
              contentItemId
              platImg {
                urls
                paths
              }
              platNombre
            }
          }
        }
      }
    }`;
    return this.cmsendpoint.getCmsDataEndPoint<OfertaContenidoResponse>(query);
  }

  getEventos(){
    var query = `query{
      eventosEvento(status: PUBLISHED) {
        contentItemId
        displayText
        eventoCanalLeyenda
        eventoDescripcion
        eventoDescripcionCorta
        eventoNombre
        eventoCanal {
          contentItemIds
          contentItems {
            ... on OfertaPlataformas {
              createdUtc
              displayText
              platNombre
              platImg {
                paths
                urls
              }
            }
          }
        }
        eventoWebsite {
          url
          text
        }
        fechaEvento
        horarioEvento
        imagenEvento {
          paths
          urls
        }
        lugarEvento
        orden
        textoEvento
        videos {
          contentItems {
            ... on VideoThumbnail {
              createdUtc
              displayText
              eventoVideo {
                thumbnail {
                  paths
                  urls
                }
                video {
                  paths
                  urls
                }
              }
            }
          }
        }
      }
    }`;
    return this.cmsendpoint.getCmsDataEndPoint<EventosResponse>(query);    
  }

  getCta() {
    var query = `query  {
      plataformasCTA(status: PUBLISHED) {
        plataformaAsociada {
          contentItems {
        ...on OfertaTiposdePlataforma {
              displayText
              idPlataforma
            }
          }
        }
        imagen {
          urls
        }
        texto
      }
    }`;
    return this.cmsendpoint.getCmsDataEndPoint<CtaResponse>(query); 
  }


  

 }
