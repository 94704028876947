
export const environment = {
  production: true,
  //baseUrl: "https://apidev.televisallega.com",

  // NOTA NOTA NOTA NOTA NOTA NOTA NOTA NOTA
  // CONFIGURA GtmModule.forRoot en
  // src app app.module.ts
  baseUrl: "https://api.televisallega.com",
  //CMS PROD e INTERNO
  baseCMSUrl: "https://cms.televisallega.com/",

  //CMS QA y DEV
  // baseCMSUrl: "https://qacms.televisallega.com/",
  tokenUrl: null, // For IdentityServer/Authorization Server API. You can set to null if same as baseUrl
  loginUrl: '/login',
  linkedInClientId: "",
  linkedInRedirectUri: "",
  linkedInSecretId: "",
};
