import { Injectable } from '@angular/core';
import { Observable, Subject, forkJoin } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';

import { SignInEndpoint } from './sign-in-endpoint.service';
import { AuthService } from './auth.service';
import { UserPeople, CompanyIdAux, userPasswordReset } from '../models/user-people.model';
import { CompanyStartUp } from '../models/company-start-up.model';
import { CompanyInversor } from '../models/company-inversor.model';
import { CompanyOthers } from '../models/company-others.model';
import { PasswordRecovery } from '../models/password-recovery.model';
import { CompaniesFollowing } from '../models/companies-following.model';
import { CompanyFollowers } from '../models/company-followers.model';
import { User } from '../models/user.model';
import { UserEdit } from '../models/user-edit.model';
import { LinkedInCode, LinkedInData, LinkedInLogin, LinkedInTokenResponse } from '../models/linkedin.model';

@Injectable()
export class SignInService {
  constructor(
    private authService: AuthService,
    private SignInEndpoint: SignInEndpoint) {

  }

  newUserPeople(userPeople: UserPeople) {
    return this.SignInEndpoint.getNewUserPeopleEndpoint<UserPeople>(userPeople);
  }

  newCompanyStartUp(companyStartUp: CompanyStartUp) {
    return this.SignInEndpoint.getNewCompanyStartUpEndpoint<CompanyStartUp>(companyStartUp);
  }

  newCompanyInversor(companyInversor: CompanyInversor) {
    return this.SignInEndpoint.getNewCompanyInversorEndpoint<CompanyInversor>(companyInversor);
  }

  newCompanyOthers(companyOthers: CompanyOthers) {
    return this.SignInEndpoint.getNewCompanyOthersEndpoint<CompanyOthers>(companyOthers);
  }

  passwordRecovery(paswordRecovery: PasswordRecovery) {
    return this.SignInEndpoint.getPasswordRecoveryEndpoint<PasswordRecovery>(paswordRecovery);
  }

  getMyCompanyId(userId: string) {
    return this.SignInEndpoint.getMyCompanyIdEndpoint<CompanyIdAux>(userId);
  }

  getAllUserData(userId: string) {
    return this.SignInEndpoint.getAllUserDataEndpoint<UserPeople>(userId);
  }

  getMyFollowingCompanies(userId:string){
    return this.SignInEndpoint.getMyFollowingCompaniesEndpoint<CompaniesFollowing[]>(userId);
  }

  getMyFollowers(userId:string){
    return this.SignInEndpoint.getMyFollowersEndpoint<CompanyFollowers[]>(userId);
  }

  updateProfileUser(userObj:UserPeople){
    return this.SignInEndpoint.getUpdateUserProfileEndpoint<UserPeople>(userObj);
  }

  getIpAddress(){
    return this.SignInEndpoint.getIPAddress();
  }

  validateUserToken(token : string){
    return this.SignInEndpoint.validateUserToken<UserEdit>(token);
  }

  postUserPasswordReset(userPasswordreset : userPasswordReset){
    return this.SignInEndpoint.postUserPasswordReset<User>(userPasswordreset);
  }
  
  activateUserMail(token : string, user : string){
    return this.SignInEndpoint.activateUserMail<UserEdit>(token,user);
  }

  getLinkedinLogin(linkedIncode: LinkedInCode){
    return this.SignInEndpoint.getLinkedinLogin<LinkedInLogin>(linkedIncode);
  }


 }
