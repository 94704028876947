import { Component, OnInit, Input, QueryList, ViewChildren, Sanitizer, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { MatVideoComponent } from 'mat-video/lib/video.component';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { gsap } from "gsap/all";
import { BlockContent } from 'src/app/models/cms/cms.detalle-futurecast.model';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { DomSanitizer } from '@angular/platform-browser';
import { DynamicHelper } from 'src/app/helpers/dynamic-helper';

@Component({
  selector: 'app-blocks',
  templateUrl: './blocks.component.html',
  styleUrls: ['./blocks.component.scss']
})
export class BlocksComponent implements OnInit {

  @Input() dataBlock: BlockContent;

  tipoBloque: number;
  baseCMSUrl: string;

  image_secure_source: string;
  image_webp_source: string;

  @ViewChildren('video') matVideo: QueryList<any>;
  video: HTMLVideoElement;
  videoPlayId: string = '';
  videoPlayNuevoId: string = '';

  constructor(
    public router: Router,
    private configuration: ConfigurationService,
    public _sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {
    this.baseCMSUrl = this.configuration.baseCmsUrl;
  }

  public getWebpImage(imagen: string) {
    var that = this;
    if (imagen) {
      var urlImagenWebP: string = imagen.replace(/ /g, '%20') + '.webp';
      var urlImagen: string = imagen.replace(/ /g, '%20');
      fetch(this.baseCMSUrl.slice(0, -1) + urlImagenWebP, { method: 'HEAD', mode: 'cors' })
        .then(res => {
          if (res.ok) {
            //console.log('Image exists.' + urlImagenWebP);
            this.image_webp_source = urlImagenWebP;
            //console.log('1 - image_webp_source: ' + this.image_webp_source);
            this.cdr.markForCheck();
            return urlImagenWebP;
          } else {
            //console.log('Image does not exist.');
            this.image_webp_source = urlImagen;
            //console.log('2 - image_webp_source: ' + this.image_webp_source);
            this.cdr.markForCheck();
            return urlImagen;
          }
        }).catch(err => {
          //console.log('Error:', err)
          this.image_webp_source = urlImagen;
          //console.log('3 - image_webp_source: ' + this.image_webp_source);
          this.cdr.markForCheck();
          return urlImagen;
        }
        );

    }
    else {
      return null;
    }
  }

  public navigateTo(path: string): void {
    this.router.navigateByUrl(path);
    gsap.to(window, {
      scrollTo: 0,
      duration: 0.5
    });

  }

  public iOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }

  customOptionsVideo: OwlOptions = {
    loop: false,
    rewind: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    navSpeed: 500,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
    },
    nav: true,
    autoplay: false,
    autoplayMouseleaveTimeout: 1000
  }

  customOptionsSolo: OwlOptions = {
    loop: false,
    rewind: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    navSpeed: 500,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
    },
    nav: true,
    autoplay: true,
    autoplayTimeout: 7000,
    autoplaySpeed: 1000,
    autoplayHoverPause: true,
    autoplayMouseleaveTimeout: 1000
  }

  pauseAllVideos(){
    this.cdr.markForCheck();
    //console.log('Pauso todos los videos');
    var vids = document.getElementsByTagName('video');
    for (var i = 0; i < vids.length; i++) {
      this.video = vids.item(i);
      this.video.pause();
    }
    var that = this;
    setTimeout(function () {
      that.playPauseVideo();
    }, 1000);
  }
  playPauseVideo() {
    let videos = document.querySelectorAll("video");
    videos.forEach((video) => {
         // We can only control playback without insteraction if video is mute
        video.muted = true;
        // Play is a promise so we need to check we have it
        var playPromise = video.play();
        if (playPromise !== undefined) {
            playPromise.then(_ => {
                let observer = new IntersectionObserver(
                    (entries) => {
                        entries.forEach((entry) => {
                            if (
                                
                                entry.intersectionRatio !== 1 &&
                                !video.paused
                            ) {
                                //console.log('Video a pausar:', video);
                                video.pause();
                            } else if (entry.intersectionRatio == 1 && video.paused) {
                              this.videoPlayNuevoId = video.getAttribute('id');
                              if (this.videoPlayNuevoId !== this.videoPlayId) {
                                //console.log('le di play a:', this.videoPlayNuevoId);
                                this.videoPlayId = this.videoPlayNuevoId;
                              }
                                //console.log('Video a dar play:', video.getAttribute('id'));
                                video.play();
                            }
                        });
                    },
                    { threshold: 1 }
                );
                observer.observe(video);
            }).catch(error => {
              console.log('Slow network detected.', error);
                // Auto-play was prevented
                // Show paused UI.
              });
        }
    });
  }

  public dynamicId(objeto: string, nombre: string) {
    return DynamicHelper.dynamicIdHelper(objeto,nombre);
  }

}
