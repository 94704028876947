import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { gsap } from "gsap/all";
import { GtmService } from 'ngx-gtm';
import { CmsOfertaService } from 'src/app/services/cms/cms.oferta.service';
import { AuthService } from 'src/app/services/auth.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { OfertaContenido, OfertaTiposdePlataforma } from 'src/app/models/cms/cms.oferta.model';
import { faArrowRight, faPlus } from '@fortawesome/free-solid-svg-icons';
import { DynamicHelper } from 'src/app/helpers/dynamic-helper';

@Component({
  selector: 'app-detalle-programa',
  templateUrl: './detalle-programa.component.html',
  styleUrls: ['./detalle-programa.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DetalleProgramaComponent implements OnInit {

  faArrowRight = faArrowRight;
  faPlus = faPlus;

  title: string = 'Detalles de programa';

  content: boolean = false;
  programa: string;
  bannerImage: string;
  bannerTitle: string;
  baseCMSUrl: string;

  datosProgramacionServicio: OfertaContenido[];
  datosPlataformaServicio: OfertaTiposdePlataforma[];

  programaContent: any[];

  programaNombre: string;
  programaDescripcion: string;
  programaFecha: string;
  programaHora: string;
  programaCanal: string;
  programaCanalId: string;
  programaCanalImagen: string;
  programaWebsite: string;
  programaImagen: string;
  programaPlataforma: string;
  programaIdPlataforma: string;

  /*Loaders*/
  isBannerLoaded: boolean = false;
  isProgramaLoaded: boolean = false;

  dias: any[] = [
    { dia: 'INICIA', caso: 0 },
    { dia: 'INICIO', caso: 0 },
    { dia: 'ESTRENO', caso: 0 },
    { dia: 'PRÓXIMAMENTE', caso: 0 },
    { dia: 'LUNES A VIERNES', caso: 5 },
    { dia: 'LUNES A JUEVES', caso: 6 },
    { dia: 'LUNES, MARTES, JUEVES Y VIERNES', caso: 7 },
    { dia: 'MIÉRCOLES Y SÁBADOS', caso: 8 },
    { dia: 'LUNES ', caso: 0 }, //en caso de una fecha específica
    { dia: 'LUNES', caso: 9 },
    { dia: 'MARTES ', caso: 0 }, //en caso de una fecha específica
    { dia: 'MARTES', caso: 10 },
    { dia: 'MIÉRCOLES ', caso: 0 }, //en caso de una fecha específica
    { dia: 'MIÉRCOLES', caso: 11 },
    { dia: 'JUEVES ', caso: 0 }, //en caso de una fecha específica
    { dia: 'JUEVES', caso: 12 },
    { dia: 'VIERNES ', caso: 0 }, //en caso de una fecha específica
    { dia: 'VIERNES', caso: 13 },
    { dia: 'SÁBADO ', caso: 0 }, //en caso de una fecha específica
    { dia: 'SÁBADO', caso: 14 },
    { dia: 'SÁBADOS', caso: 14  },
    { dia: 'SÁBADOS Y DOMINGOS', caso: 15 },
    { dia: 'DOMINGO ', caso: 0 }, //en caso de una fecha específica
    { dia: 'DOMINGO', caso: 16 },
    { dia: 'DOMINGOS', caso: 16 },
  ];

  diasCuadro: any[];

  breadcrumbsData: any[];

  constructor(
    private gtm: GtmService,
    public router: Router,
    private route: ActivatedRoute,
    private _location: Location,
    private cmsOfertaService : CmsOfertaService,
    private authService: AuthService,
    private configuration: ConfigurationService,
    private cdr: ChangeDetectorRef,
    private titleService: Title,
    private metaService: Meta
  ) {
    this.baseCMSUrl = this.configuration.baseCmsUrl
    this.route.params.subscribe(routeParams => {
      if (routeParams['id']) {
        this.programa = routeParams['id'];
        this.content = true;
        this.cdr.markForCheck();
      }
      else {
        this.programa = "No encontrado";
        this.content = false;
        this.bannerTitle = '404 - NO ENCONTRADO';
        this.programaNombre = '';
        this.programaDescripcion = '';
        this.programaImagen = '';
        this.programaFecha = '';
        this.programaHora = '';
        this.programaCanal = '';
        this.programaCanalImagen = '';
        this.programaWebsite = '';
        this.programaPlataforma = '';
        this.programaIdPlataforma = '0';
      }
    });


    if(this.authService.isLoggedIn){
      if (this.content == true) { 
        this.getPrograma(this.programa);
        this.getBannerImage();
      }
    }
    else{
      /* Me loggeo y luego voy por los banners */
      this.authService.loginByApp().subscribe(resp => {
        //console.log('Resp login:',resp)
        if (this.content == true) { 
          this.getPrograma(this.programa);
          this.getBannerImage();
        }
      });
    }
  }

  ngOnInit(): void {
    this.gtm.push({ event: 'TelevisaLlega Programa Detalle' });

    this.titleService.setTitle(this.title);
    this.metaService.addTags([
      { name: 'keywords', content: 'programación Televisa, programas de televisión, horarios de programas, descripción de programas, sinopsos de programas' },
      { name: 'description', content: 'Conoce el catálogo de nuestra programación, aquí encontrarás los detalles de series, programas de entretenimiento, documentales, y diferentes programas en vivo.' },
      { name: 'robots', content: 'index, follow' }
    ]);
  }

  public getBannerImage() {
    /* Invocar servicio que recupera imagen de portada de la página*/
    this.bannerImage = '';
    this.isBannerLoaded = true;
    this.cdr.markForCheck();
  }

  public getPrograma(id: string) {
    /*Obtener programa*/
    //console.log('Entro a getPrograma por los datos específicos de ese programa: ', id);
    /* Pongo los datos en vacío de forma inicial */
    this.programaContent = [];
    this.content = false;
    this.bannerTitle = '404 - NO ENCONTRADO';
    this.programaNombre = '';
    this.programaDescripcion = '';
    this.programaImagen = '';
    this.programaFecha = '';
    this.programaHora = '';
    this.programaCanal = '';
    this.programaCanalId = '';
    this.programaCanalImagen = '';
    this.programaWebsite = '';
    this.programaIdPlataforma = '0';
    this.isProgramaLoaded = false;
    this.cmsOfertaService.getOfertaContenidos().subscribe(response => {
      //console.log('Resonse de getOfertaContenidos:',response);
      this.datosProgramacionServicio = response.data.ofertaContenidos;
      for(let i = 0; i < this.datosProgramacionServicio.length ; i++) {
        if(this.datosProgramacionServicio[i].contentItemId == id) {
          //console.log('Este es el programa: ', this.datosProgramacionServicio[i]);
          this.content = true;
          this.cdr.markForCheck();
          this.programaContent = [
            {
              programaId: this.datosProgramacionServicio[i].contentItemId,
              programaNombre: this.datosProgramacionServicio[i].nombreTarjeta,
              programaNombreCorto: this.datosProgramacionServicio[i].programaNombreCorto,
              programaDescripcion: this.datosProgramacionServicio[i].programaDescripcion,
              programaImagen: this.baseCMSUrl.slice(0, -1) + this.datosProgramacionServicio[i].imagenTarjeta?.urls[0],
              programaFecha: this.datosProgramacionServicio[i].fechaTarjeta,
              programaHora: this.datosProgramacionServicio[i].horaTarjeta,
              programaCanal: this.datosProgramacionServicio[i].canalTarjetaContenido?.contentItems[0].platNombre,
              programaCanalId: this.datosProgramacionServicio[i].canalTarjetaContenido?.contentItems[0].platMediaId,
              programaCanalImagen: this.baseCMSUrl.slice(0, -1) + this.datosProgramacionServicio[i].canalTarjetaContenido?.contentItems[0].platImg?.urls[0],
              programaIdPlataforma: this.datosProgramacionServicio[i].canalTarjetaContenido?.contentItems[0].platId,
            },
          ];
          if (this.programaContent.length > 0) {
            for (let obj of this.programaContent) {
              if (obj.programaNombre) {
                this.bannerTitle = obj.programaNombreCorto;
              } else {
                this.bannerTitle = 'PROGRAMA';
              }
              if (obj.programaNombre) {
                this.programaNombre = obj.programaNombre;
              } else {
                this.programaNombre = 'PROGRAMA';
              }
              if (obj.programaDescripcion) {
                this.programaDescripcion = obj.programaDescripcion;
              } else {
                this.programaDescripcion = '';
              }
              if (obj.programaImagen) {
                this.programaImagen = obj.programaImagen;
              } else {
                this.programaImagen = '';
              }
              if (obj.programaFecha) {
                this.programaFecha = obj.programaFecha;
              } else {
                this.programaFecha = '';
              }
              if (obj.programaHora) {
                this.programaHora = obj.programaHora;
              } else {
                this.programaHora = '';
              }
              if (obj.programaCanal) {
                this.programaCanal = obj.programaCanal;
              } else {
                this.programaCanal = '';
              }
              if (obj.programaCanal) {
                this.programaCanalId = obj.programaCanalId;
              } else {
                this.programaCanalId = '';
              }
              if (obj.programaCanalImagen) {
                this.programaCanalImagen = obj.programaCanalImagen;
              } else {
                this.programaCanalImagen = '';
              }
              if (obj.programaWebsite) {
                this.programaWebsite = obj.programaWebsite;
              } else {
                this.programaWebsite = '';
              }
              if (obj.programaIdPlataforma) {
                this.programaIdPlataforma = obj.programaIdPlataforma;
              } else {
                this.programaIdPlataforma = '0';
              }
            }
          }
        }
      }
      //console.log('Salgo de getPrograma por id', this.programaContent, id);
      if (this.programaIdPlataforma !== '0') {
        this.getPlataforma(this.programaIdPlataforma);
      }
      this.getDays();
      this.isProgramaLoaded = true;
      this.cdr.markForCheck();
    });

  }

  public getPlataforma(idPlataforma: any) {
    this.cmsOfertaService.getTiposDePlataforma().subscribe(response => {
      //console.log('Resonse de getTiposDePlataforma:',response);
      this.datosPlataformaServicio = response.data.ofertaTiposdePlataforma;
      for (let i = 0; i < this.datosPlataformaServicio.length; i++) {
        if (this.datosPlataformaServicio[i].idPlataforma == idPlataforma) {
          //console.log('Este es el programa: ', this.datosProgramacionServicio[i]);
          this.programaPlataforma = this.datosPlataformaServicio[i].displayText;
          this.breadcrumbsData = [
            { nombre: 'Nuestra oferta', link: 'nuestra-oferta' },
            { nombre: this.programaPlataforma, link: '' }
          ];
        }
      }
      //console.log('Salgo de getPlataforma', this.programaPlataforma);
      this.cdr.markForCheck();
    });
  }

  public getDays() {
    this.diasCuadro = [];
    if (this.programaFecha && this.programaFecha !== '') {
      for (let i = 0; i < this.dias.length; i++) {
        if (this.diasCuadro.length == 0) { 
          if (this.programaFecha.toUpperCase().includes(this.dias[i].dia)) {
            switch (this.dias[i].caso.toString()) {
              case '5':
                this.diasCuadro.push('L', 'M', 'M', 'J', 'V');
                break;
              case '6':
                this.diasCuadro.push('L', 'M', 'M', 'J');
                break;
              case '7':
                this.diasCuadro.push('L', 'Ma', 'J', 'V');
                break;
              case '8':
                this.diasCuadro.push('Mi', 'S');
                break;
              case '9':
                this.diasCuadro.push('L');
                break;
              case '10':
                this.diasCuadro.push('Ma');
                break;
              case '11':
                this.diasCuadro.push('Mi');
                break;
              case '12':
                this.diasCuadro.push('J');
                break;
              case '13':
                this.diasCuadro.push('V');
                break;
              case '14':
                this.diasCuadro.push('S');
                break;
              case '15':
                this.diasCuadro.push('S', 'D');
                break;
              case '16':
                this.diasCuadro.push('D');
                break;
              default:
                this.diasCuadro.push(this.programaFecha);
                break;
            }
          }
        }
      }
    }
    //console.log('getDays() esto traigo en díasCuadro', this.diasCuadro);
  }


  customOptionsMobile: OwlOptions = {
    loop: false,
    rewind: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 500,
    navText: ['', ''],
    items: 1,
    nav: true,
    autoplay: true,
    autoplayTimeout: 7000,
    autoplaySpeed: 1000,
  }

  public navigateTo(path: string): void {
    this.router.navigateByUrl(path);
    gsap.to(window, {
      scrollTo: 0,
      duration: 0.5
    });

  }
  public goBack() {
    this._location.back();
  }

  public dynamicId(objeto: string, nombre: string) {
    return DynamicHelper.dynamicIdHelper(objeto, nombre);
  }

  public friendlyUrl(nombre: string) {
    return DynamicHelper.friendlyUrlHelper(nombre);
  }

}
