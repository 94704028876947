
<div class="row tvs-bg-white pb-5 tvs-evento-top px-2 px-sm-3 px-md-0 px-lg-4" *ngIf="!isBannerLoaded">
  <div class="col-12 pt-5 px-5 tvs-bg-white">
    <div class="loader-min">
      <img class="loader-img z-20" src="assets/images/website_general/loader-2.svg" alt="" />
    </div>
  </div>
</div>

<div class="row tvs-bg-white pb-4 tvs-evento-top px-2 px-sm-3 px-md-0 px-lg-4" *ngIf="isBannerLoaded && content">
  <app-custom-breadcrumbs [breadcrumbsData]="breadcrumbsData"></app-custom-breadcrumbs>
  <div class="tvs-evento-backlogo">
    <div class="tvs-evento-backlogo-up"></div>
    <div class="tvs-evento-backlogo-down"></div>
  </div>
  <div class="container-fluid pt-5 px-2 px-md-5 tvs-bg-white">
    <!--Introducción-->
    <div class="row mb-5 pt-3">
      <div class="col-10 text-left">

        <div class="float-left tvs-producto-evento mr-3">
          <div class="tvs-evento-sidelogo-up"></div>
          <div class="tvs-evento-sidelogo-down"></div>
        </div>

        <div *ngFor="let evento of eventoContent">
          <h1 class="tvs-text-orange tvs-evento-titulo mb-2">{{evento.eventoNombre}}</h1>
          <div class="tvs-text-black tvs-text-bold tvs-evento-descrip" style="white-space: pre-line" [innerHTML]="evento.eventoDescripcionCorta"></div>
        </div>

      </div>
    </div>
  </div>
</div>

<div class="row tvs-bg-white pb-5 px-5" *ngIf="!content">
  <div class="col-12 pt-5 px-5">
    <div class="loader-min">
      <img class="loader-img z-20" src="assets/images/website_general/loader-2.svg" alt="" />
    </div>
  </div>
</div>


<div class="row tvs-bg-white pb-5 px-2 px-md-5" *ngIf="content">
  <ng-container *ngFor="let evento of eventoContent">

    <div class="col-12 text-center px-2 px-md-5 d-block d-md-none">
      <img class="img-fluid tvs-rounded-corner" src="{{evento.eventoImagen}}" *ngIf="evento.eventoImagen && evento.eventoImagen != ''" />
      <img class="img-fluid tvs-rounded-corner" src="assets/images/website_general/paquetes_default.png" *ngIf="!evento.eventoImagen || evento.eventoImagen == ''" />
    </div>

    <div class="col-12 col-md-6 pb-5 pt-5 pt-md-0">

        <div class="row text-left mb-3 pr-lg-5">
          <div class="col-12">
            <h4 class="tvs-text-orange tvs-text-bold">Descripción</h4>
          </div>
          <div class="col-12 mb-3">
            <div class="small" style="white-space: pre-line" [innerHTML]="evento.eventoDescripcion"></div>
          </div>

          <div class="col-12">
            <h4 class="tvs-text-orange tvs-text-bold">Horario</h4>
          </div>
          <div class="col-12 mb-3">
            <div class="small" *ngIf="evento.eventoFecha && evento.eventoFecha !== ''">{{evento.eventoFecha}}</div>
            <div class="small" *ngIf="evento.eventoHora && evento.eventoHora !== ''">{{evento.eventoHora}}</div>
          </div>

          <div class="col-12">
            <h4 class="tvs-text-red tvs-text-bold">Canal</h4>
          </div>
          <div class="col-12 mb-3">
            <img class="tvs-canal-mini float-left mr-3" src="{{evento.eventoCanalImagen}}" />
          </div>

          <div class="col-12" *ngIf="evento.eventoWebsite && evento.eventoWebsite !== '' ">
            <h4 class="tvs-text-orange tvs-text-bold">Sitio web</h4>
          </div>
          <div class="col-12 mb-3" *ngIf="evento.eventoWebsite && evento.eventoWebsite !== '' ">
            <a class="small" href="{{evento.eventoWebsite}}" target="_blank">{{evento.eventoWebsite}}</a>
          </div>

          <div class="col-12 my-3">
            <button [attr.id]="dynamicId('pautaEvento-',evento.eventoNombre)" class="pautaEvento btn btn-primary btn-orange tvs-btn d-none d-md-block" (click)="navigateTo('contacto')">Me interesa pautar aquí<fa-icon [icon]="faArrowRight" class="pl-2 tvs-text-white"></fa-icon></button>
                <button [attr.id]="dynamicId('pautaEvento-',evento.eventoNombre)" class="pautaEvento btn btn-primary btn-orange tvs-btn d-block d-md-none btn-block" (click)="navigateTo('contacto')">Me interesa pautar aquí<fa-icon [icon]="faArrowRight" class="pl-2 tvs-text-white"></fa-icon></button>
          </div>

        </div>

    </div>

    <div class="col-md-6 text-center px-2 px-md-5 d-none d-md-block">
      <img class="img-fluid tvs-rounded-corner" src="{{evento.eventoImagen}}" *ngIf="evento.eventoImagen && evento.eventoImagen != ''" />
      <img class="img-fluid tvs-rounded-corner" src="assets/images/website_general/paquetes_default.png" *ngIf="!evento.eventoImagen || evento.eventoImagen == ''" />
    </div>

  </ng-container>

  <ng-container>

    <div class="col-12 px-3 mt-5 tvs-slide-solo tvs-slide-video text-center">

      <ng-container *ngIf="!isVideosLoaded">
        <div class="loader-min text-left">
          <img class="loader-img z-20" src="assets/images/website_general/loader-2.svg" alt="" />
        </div>
      </ng-container>

      <ng-container *ngIf="isVideosLoaded">

        <div class="row tvs-videos-container py-5">
          <div class="mt-5 py-5 col-12 col-md-10 offset-md-1 col-xl-8 offset-xl-2">
            <owl-carousel-o [options]="customOptionsVideo" (change)="pauseAllVideos()">
              <ng-container *ngFor="let item of videoCardData; let i = index;">
                <ng-template carouselSlide>
                  <div class="tvs-jumbo-card-video">
                    <div class="row">
                      <div class="col-12">
                        <video controls
                               controlsList="nodownload"
                               [muted]="'muted'"
                               preload="none"
                               poster="{{item.videoThumb}}"
                               playsinline
                               style="width: 100%; height: auto; object-fit: contain;background-color:#000;"
                               disablepictureinpicture
                               [attr.id]="dynamicId('videoEvento-',eventoNombre)"
                               #video>
                          <source src="{{item.videoVideo}}" type="video/mp4">
                          Tu navegador no soporta la visualización de videos.
                        </video>

                      </div>
                    </div>
                  </div>
                </ng-template>
              </ng-container>
            </owl-carousel-o>
          </div>
        </div>

      </ng-container>
    </div>
  </ng-container>
</div>

<div class="row tvs-bg-white pb-5 px-2 px-md-5" *ngIf="content">
  <!--Carrusel otros eventos-->
  <div class="col-12 text-center my-4">
    <h1 class="tvs-text-orange">Más eventos</h1>
  </div>
  <div class="col-12 pb-5">
    <div class="row p-0">
      <div class="d-none d-xl-block col-xl-4" *ngIf="eventosService?.length == 2"></div>
      <div class="d-none d-xl-block col-xl-2" *ngIf="eventosService?.length == 3"></div>
      <ng-container *ngFor="let tarjeta of eventosService">

        <div class="col-12 col-md-6 col-xl-4 p-3" *ngIf="tarjeta.contentItemId != evento">

          <div class="tvs-tarjeta-evento-otros tvs-o-hidden">

            <div class="col-12 mb-3 p-0 tvs-o-hidden">
              <div class="tvs-img-container"
                   [ngStyle]="{'background-image': 'url(' + baseCMSUrl.slice(0, -1) + getCardImg(tarjeta.imagenEvento?.urls[0]) +')'}"
                   *ngIf="tarjeta.imagenEvento?.urls[0] && tarjeta.imagenEvento?.urls[0] != ''">
              </div>
              <div class="tvs-img-container"
                   style="background-image: url('assets/images/website_general/paquetes_default.png')"
                   *ngIf="!tarjeta.imagenEvento?.urls[0] || tarjeta.imagenEvento?.urls[0] == ''">
              </div>
            </div>

            <div class="col-12 mb-3">
              <h3 class="tvs-text-orange tvs-text-bold mb-3">{{tarjeta.eventoNombre}}</h3>
            </div>

            <div class="col-12 mb-3">
              <h3 class="tvs-text-orange tvs-text-bold pt-3" *ngIf="tarjeta.fechaEvento && tarjeta.fechaEvento != ''">Fecha</h3>
              <p *ngIf="tarjeta.fechaEvento && tarjeta.fechaEvento != ''">{{tarjeta.fechaEvento}}</p>
            </div>

            <div class="col-12 mb-3">
              <div class="small mb-3" style="white-space: pre-line" [innerHTML]="tarjeta.textoEvento" *ngIf="tarjeta.textoEvento && tarjeta.textoEvento != ''"></div>
            </div>

            <div class="col-12 text-right">
              <button class="btn btn-primary btn-orange tvs-btn" (click)="goToEvent('eventos/detalle-evento/' + tarjeta.contentItemId, tarjeta.eventoNombre)">
                Ver más<fa-icon [icon]="faArrowRight" class="pl-2"></fa-icon>
              </button>
            </div>

          </div>

        </div>

      </ng-container>
    </div>
  </div>
</div>
