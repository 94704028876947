<ng-container *ngIf="!isBannerLoaded">
  <div class="aviso-privacidad">
    <div class="row tvs-banner-unloaded">
      <img class="loader-img z-20" src="assets/images/website_general/loader-2.svg" alt="" />
    </div>
  </div>
</ng-container>
<ng-container *ngIf="isBannerLoaded">
  <app-header-image-new srcImage="{{bannerImage}}" srcTitle="{{bannerTitle}}" class="aviso-privacidad"></app-header-image-new>
</ng-container>
<div class="row px-2 px-md-5 py-5 tvs-bg-white">
  <div class="col-12 mt-5 mt-md-2">
      <div class="row mt-5">
        <div class="col-12 text-left">
          <h3 class="tvs-text-black">Aviso de Privacidad</h3>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 text-left">
          <h4 class="tvs-text-black">RESPONSABLE DE LOS DATOS PERSONALES.</h4>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col text-left">
          <p class="tvs-text-black text-justify">
            Televisa, S. de R.L. de C.V. (en adelante “Televisa”) con domicilio en Av. Vasco de Quiroga 2000, Colonia Santa Fe, Alcaldía Álvaro Obregón, C.P. 01210, Ciudad de México, México, tiene la convicción de proteger la información personal proporcionada por sus usuarios (en adelante “Datos Personales”) y es el responsable de su Tratamiento (término que se define más adelante) cuando sean recabados a través del sitio de <a href="https://televisallega.com" target="_blank">https://televisallega.com</a> (en adelante el “Sitio”).
          </p>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 text-left">
          <h4 class="tvs-text-black">FINALIDAD DEL TRATAMIENTO.</h4>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col text-left">
          <p class="tvs-text-black text-justify">
            Televisa podrá solicitar y/o recabar a través del Sitio, Datos Personales de los usuarios para los fines abajo señalados; así como para dar cumplimiento con disposiciones legales que así lo requieran (en adelante “Tratamiento”). Televisa y/o cualquier tercero que llegue a intervenir en cualquier fase del Tratamiento de Datos Personales, guardará confidencialidad respecto de los mismos conforme a las disposiciones legales aplicables en los Estados Unidos Mexicanos (en adelante “México”).
          </p>
          <p class="tvs-text-black text-justify">
            Los Datos Personales que los usuarios proporcionen al momento de su ingreso y/o registro al Sitio, tienen como finalidad i) generar bases de datos que se utilizarán con fines comerciales, de publicidad y promoción, y ii) hacer posible que diversos anunciantes promuevan sus productos y/o servicios.
          </p>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 text-left">
          <h4 class="tvs-text-black">DATOS PERSONALES A RECABAR.</h4>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col text-left">
          <p class="tvs-text-black text-justify">
            Los Datos Personales que se pueden solicitar del usuario son:
          </p>
          <ul class="tvs-text-black ">
            <li>
              <span class="tvs-text-black ">
                Nombre completo
              </span>
            </li>
            <li>
              <span class="tvs-text-black ">
                Correo electrónico
              </span>
            </li>
            <li>
              <span class="tvs-text-black ">
                Empresa
              </span>
            </li>
            <li>
              <span class="tvs-text-black ">
                País
              </span>
            </li>
            <li>
              <span class="tvs-text-black ">
                Teléfono
              </span>
            </li>
            <li>
              <span class="tvs-text-black ">
                Respuestas a preguntas con fines comerciales.
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 text-left">
          <h4 class="tvs-text-black">CONSENTIMIENTO.</h4>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col text-left">
          <p class="tvs-text-black text-justify">
            El registro a través del Sitio implica el consentimiento pleno y sin reservas de los usuarios para el Tratamiento de sus Datos Personales de acuerdo con el presente Aviso de Privacidad.
          </p>
          <p class="tvs-text-black text-justify">
            El titular de los Datos Personales manifiesta que es mayor de edad a la fecha en que se proporcionan los mismos y cuenta con plena capacidad jurídica. Si Televisa por algún error humano o técnico, o por inadvertencia del titular llegara a recopilar Datos Personales de menores de edad o incapaces sin consentimiento de sus padres o tutores, dichos datos serán eliminados de las bases de datos que los contengan en cuanto se tenga conocimiento de ello.
          </p>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 text-left">
          <h4 class="tvs-text-black">MEDIOS PARA LIMITAR EL USO O DIVULGACIÓN DE DATOS PERSONALES.</h4>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col text-left">
          <p class="tvs-text-black text-justify">
            Televisa cuenta con medidas de seguridad físicas, técnicas y administrativas para la protección de Datos Personales proporcionados por los usuarios.
          </p>
          <p class="tvs-text-black text-justify">
            Si usted desea dejar de recibir mensajes promocionales vía correo electrónico por parte de Televisa puede solicitarlo a través de:  <a href="mailto:satisfaccioncliente@televisaunivision.com" target="_blank">satisfaccioncliente@televisaunivision.com</a>
          </p>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 text-left">
          <h4 class="tvs-text-black">EXCLUSIÓN DE RESPONSABILIDAD DEL SITIO.</h4>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col text-left">
          <p class="tvs-text-black text-justify">
            El Sitio podría contener hipervínculos o hipertextos “links”, banners, botones y/o herramientas de búsquedas en la “World Wide Web” que al ser utilizados por los usuarios transportan a otros portales o sitios de Internet que podrían ser propiedad de terceros. Los Datos Personales que los usuarios llegaren a proporcionar a través de estos portales o sitios de Internet no se encuentran contemplados por este aviso de privacidad y su Tratamiento no es responsabilidad de la Responsable. Recomendamos a los usuarios verificar los avisos de privacidad desplegados y aplicables a estos portales y sitios de Internet.
          </p>
          <p class="tvs-text-black text-justify">
            Los usuarios podrán encontrar dentro del Sitio, páginas, promociones, micrositios, tiendas virtuales, encuestas, bolsas de trabajo y otros servicios que pueden estar compartidos con terceros y que podrían solicitar sus Datos Personales (en adelante “Sitios de Terceros”). Cualquier información relacionada con los Datos Personales que se proporcionen a través de los Sitios de Terceros se sujetará a los respectivos avisos de privacidad que se contengan en cada uno de ellos, salvo que la Responsable señale lo contrario.
          </p>
          <p class="tvs-text-black text-justify">
            La Responsable advierten a sus usuarios que algunos servicios del Sitio podrían incluir, de manera enunciativa más no limitativa, foros de discusión, páginas de Internet personales, avisos clasificados, correos electrónicos, salas de chat, entre otros. Por lo tanto, los Datos Personales que se revelen a través de dichos medios podrían en algunos casos ser accesibles por terceras personas. La Responsable no será responsable por el uso, publicación, revelación y/o divulgación que se haga respecto de los Datos Personales usados, publicados, revelados y/o divulgados a través de los medios antes enunciados. Recomendamos a los usuarios ser cuidadosos y responsables sobre toda información de carácter personal que proporcionen en dichos medios.
          </p>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 text-left">
          <h4 class="tvs-text-black">TRANSFERENCIA DE DATOS PERSONALES.</h4>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col text-left">
          <p class="tvs-text-black text-justify">
            Televisa podrá, sin consentimiento del titular, revelar, divulgar y/o transferir dentro y fuera del país los Datos Personales proporcionados por los usuarios, únicamente a sus empresas filiales, subsidiarias y/o relacionadas, así como para dar cumplimiento con disposiciones legales que así lo requieran, o bien cuando sea solicitado por autoridades competentes.
          </p>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 text-left">
          <h4 class="tvs-text-black">MEDIOS PARA EJERCER LOS DERECHOS DE LOS TITULARES DE DATOS PERSONALES Y REVOCACIÓN DEL CONSENTIMIENTO.</h4>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col text-left">
          <p class="tvs-text-black text-justify">
            Los usuarios podrán solicitar el ejercicio de sus derechos a:
          </p>
          <ul class="tvs-text-black ">
            <li>
              <span class="tvs-text-black ">
                Acceder a sus Datos Personales;
              </span>
            </li>
            <li>
              <span class="tvs-text-black ">
                Rectificar sus Datos Personales cuando sean inexactos o incompletos;
              </span>
            </li>
            <li>
              <span class="tvs-text-black ">
                Cancelar sus Datos Personales;
              </span>
            </li>
            <li>
              <span class="tvs-text-black ">
                Oponerse por causa legítima al Tratamiento de sus Datos Personales, y/o
              </span>
            </li>
            <li>
              <span class="tvs-text-black ">
                Revocar su consentimiento para el Tratamiento de sus Datos Personales en cualquier momento, a fin de que se deje de hacer uso de los mismos.
              </span>
            </li>
          </ul>
          <p class="tvs-text-black text-justify">
            Sin embargo, es importante que tenga en cuenta que no en todos los casos podremos atender su solicitud para cancelar sus Datos Personales, oponerse al Tratamiento o revocar su consentimiento, ya que es posible que por alguna obligación legal requiramos seguir tratando sus Datos Personales.
          </p>
          <p class="tvs-text-black text-justify">
            El ejercicio de los derechos de Acceso, Rectificación, Cancelación u Oposición de Datos Personales y Revocación del consentimiento que realicen los usuarios deberá realizarse previa solicitud a Televisa y contener al menos lo siguiente:
          </p>
          <ul class="tvs-text-black ">
            <li>
              <span class="tvs-text-black ">
                El nombre del titular, domicilio y correo electrónico para comunicarle la respuesta a su solicitud;
              </span>
            </li>
            <li>
              <span class="tvs-text-black ">
                Los documentos que acrediten la identidad o, en su caso, la representación legal del titular;
              </span>
            </li>
            <li>
              <span class="tvs-text-black ">
                La descripción clara y precisa de los Datos Personales respecto de los que se busca ejercer alguno de los derechos antes mencionados (en el caso de rectificación se deberá indicar las modificaciones a realizar y aportar la documentación que sustente su petición);
              </span>
            </li>
            <li>
              <span class="tvs-text-black ">
                Cualquier otro elemento o documento que facilite la localización de los Datos Personales.
              </span>
            </li>
          </ul>
          <p class="tvs-text-black text-justify">
            Para efecto de lo anterior Televisa pone a disposición de los usuarios el siguiente correo electrónico: <a href="mailto:satisfaccioncliente@televisaunivision.com">satisfaccioncliente@televisaunivision.com</a>.
          </p>
          <p class="tvs-text-black text-justify">
            Televisa dará respuesta a la solicitud de los usuarios en un plazo no mayor a 20 días hábiles contados a partir de la fecha en que reciba la petición correspondiente, a través del correo electrónico que haya sido proporcionado para tal efecto.
            <br />
            El ejercicio por parte de los usuarios de los derechos antes mencionados deberá sujetarse a las leyes y reglamentos aplicables vigentes en México.
          </p>
        </div>
      </div>
    <div class="row" id="cookies"></div>
      <div class="row mt-4">
        <div class="col-12 text-left">
          <h4 class="tvs-text-black">COOKIES Y WEB BEACONS. </h4>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col text-left">
          <p class="tvs-text-black text-justify">
            Con el objetivo de mejorar la experiencia de sus usuarios en el Sitio, Televisa podrá utilizar “cookies”. Para efectos del presente Aviso de Privacidad “cookies” se identificará como los archivos de texto de información que un sitio web transfiere al disco duro de la computadora de los usuarios con el objeto de almacenar ciertos registros y preferencias. Televisa puede utilizar “cookies” para mejor entendimiento de la interacción de los usuarios con el Sitio y los servicios que se proporcionan. El Sitio puede permitir publicidad o funciones de terceros que envíen “cookies” a las computadoras de los usuarios. Mediante el uso de “cookies” no se identifica personalmente a los usuarios, únicamente a sus computadoras.
          </p>
          <p class="tvs-text-black text-justify">
            Por su parte las “web beacons” son imágenes insertadas en una página de Internet o correo electrónico que pueden ser utilizadas para monitorear el comportamiento de un visitante, como almacenar información sobre la dirección IP del usuario, horario de navegación, duración del tiempo de interacción en el Sitio, secciones consultadas, páginas de Internet accedidas previo a la nuestra y el tipo de navegador utilizado.
          </p>
          <p class="tvs-text-black text-justify">
            Los usuarios podrán cambiar sus opciones a través de sus equipos de cómputo y/o navegadores para dejar de aceptar “cookies” y/o “web beacons” o bien confirmar si aceptan o no las mismas.
          </p>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 text-left">
          <h4 class="tvs-text-black" id="cookies">DATOS PERSONALES SENSIBLES.</h4>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col text-left">
          <p class="tvs-text-black text-justify">
            De conformidad con la legislación mexicana vigente, se conocen como “Datos Personales Sensibles” a “aquellos que puedan revelar aspectos como origen racial o étnico, estado de salud presente y futuro, información genética, creencias religiosas, filosóficas y/o morales, afiliación sindical, opiniones políticas, preferencia sexual”.
          </p>
          <p class="tvs-text-black text-justify">
            En caso de que Televisa llegue a requerir Datos Personales Sensibles de los usuarios, estos deberán proporcionar su consentimiento expreso.
          </p>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 text-left">
          <h4 class="tvs-text-black" id="cookies">CAMBIOS AL AVISO DE PRIVACIDAD.</h4>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col text-left">
          <p class="tvs-text-black text-justify">
            Televisa se reserva el derecho de modificar en cualquier momento el contenido del presente Aviso de Privacidad. Cualquier cambio en el Aviso de Privacidad le será informado a sus usuarios a través del Sitio.
          </p>
          <p class="tvs-text-black text-justify">
            Una vez que se publique el Aviso de Privacidad en el Sitio entrará en vigor automáticamente.
          </p>
          <p class="tvs-text-black text-justify">
            La fecha de actualización de este aviso es el 31 de diciembre de 2021.
          </p>
        </div>
      </div>
  </div>
</div>
