import { Injectable } from '@angular/core';
import { AuthService } from './../auth.service';
import { CmsEndpoint } from './cms-endpoint.service';
import { BannerHomeResponse, CasosExitoHomeResponse, CarrouselVideosHomeResponse, CarruselPlataformasDesktopResponse, CarruselPlataformasMobileResponse, HomeAvisomodalResponse, NewBannerHomeResponse } from '../../models/cms/cms.home.model';

@Injectable()
export class CmsHomeService {

  constructor(
    private authService: AuthService,
    private cmsendpoint: CmsEndpoint) {
  }

  getBannersHome() {
    var query;
    query = `query{
      bannerHome(status: PUBLISHED, orderBy: {publishedUtc: ASC}) {
        bannerId
        bannerImage {
          paths
          urls
        }
        bannerLink
        bannerText
        bannerLinkDescription
        published
      }
    }`

    return this.cmsendpoint.getCmsDataEndPoint<BannerHomeResponse>(query);
  }

  getNewBannerHome() {
    var query;
    query = `query {
      carruselBannerHome(status: PUBLISHED, orderBy: {publishedUtc: ASC}) {
        linkBoton
        orden
        restricciones
        texto
        textoBoton
        tituloSlide
        imagensintransparencia
        canalHorario {
          contentItems {
        ...on CanalHorario {
              displayText
              fecha
              horario
              canal {
                contentItems {
                  displayText
              ...on OfertaPlataformas {
                    displayText
                    platImg {
                      paths
                      urls
                    }
                  }
                }
              }
            }
          }
        }
        imagen {
          urls
        }
        colordeFondo {
          contentItems {
        ...on DegradadoHomeBanner {
              color
              identificador
            }
          }
        }
      }
    }`
    return this.cmsendpoint.getCmsDataEndPoint<NewBannerHomeResponse>(query);
  }

  getCasosExitoHome() {
    var query;
    query = `query {
      casosdeexitoHome(status: PUBLISHED, orderBy: {publishedUtc: ASC}) {
        casosExitoImage {
          urls
        }
        casosExitoTitulo
        
        casosExitoQuote
        casosExitoLink
        casosExitoFecha
        orden
        casosExitoTextoE
      }
    }`

    return this.cmsendpoint.getCmsDataEndPoint<CasosExitoHomeResponse>(query);
  }

  getCarrouselVideosHome() {
    var query = `query{
        carruselVideosHome(status: PUBLISHED, orderBy: {publishedUtc: ASC}) {
            createdUtc
            displayText
            modifiedUtc
            publishedUtc
            render
            videoTitulo
            orden
            videoThumb {
                paths
                urls
            }
            videoVideo {
              urls
            }
        }
      }`;

    return this.cmsendpoint.getCmsDataEndPoint<CarrouselVideosHomeResponse>(query);
  }

  /* Este servicio se va */
  getCarruselPlataformasDesktopHome() {
    var query;
    query = `query{
      homeCarruselPlataformasDesktop(orderBy: {publishedUtc: ASC}) {
        plataforma {
          orden
          plataformaImage {
            paths
            urls
          }
          plataformaNombre
        }
      }
    }
    `
    return this.cmsendpoint.getCmsDataEndPoint<CarruselPlataformasDesktopResponse>(query);
  }

/* Este servicio se va */
  getCarruselPlataformasMobileHome() {
    var query;
    query = `query{
      homeCarruselPlataformasMoviles(orderBy: {publishedUtc: ASC}) {
        plataforma {
          orden
          plataformaImage {
            paths
            urls
          }
          plataformaNombre
        }
      }
    }
    `
    return this.cmsendpoint.getCmsDataEndPoint<CarruselPlataformasMobileResponse>(query);
  }

  getModalHome() {
    var query;
    query = `query {
      homeAvisomodal {
        contentItemId
        contentType
        imagenModal {
          paths
          urls
        }
        linkModal
        habilitaModal
      }
    }    
    `
    return this.cmsendpoint.getCmsDataEndPoint<HomeAvisomodalResponse>(query);
  }

}
