import { Injectable } from '@angular/core';
import { CmsEndpoint } from './cms-endpoint.service';
import { PlanComercialPaquetesReponse, PlanComercialProductosResponse, 
  PilaresResponse, PlanComercialHeroResponse, PaquetesDetalleResponse, AcercaDeResponse, PlanComercialProductoDetalleResponse, VideosProductosResponse } from './../../models/cms/cms.plan.comercial.model';

@Injectable()
export class CmsPlanComercialService {

  constructor(
    private cmsendpoint: CmsEndpoint) {
  }

  getPaquetes() {
    var query = `query {
      paquetesPlancomercial(status: PUBLISHED, orderBy: {createdUtc: ASC}) {
        displayText
        nombreTarjeta
        orden
        imagenTarjeta {
          urls
        }
        imagenInternos {
          urls
        }
      }
    }`;

    return this.cmsendpoint.getCmsDataEndPoint<PlanComercialPaquetesReponse>(query);
  }

  getPaquetesDetalle() {
    var query = `query{
      paquetes(status: PUBLISHED, first: 1000) {
        author
        paquetePadre
        tarjetaPaquetes {
          nombreTarjeta
          textoTarjeta
          fechaTarjeta
          linkTarjeta {
            paths
            urls
          }
        }
      }
    }`;

    return this.cmsendpoint.getCmsDataEndPoint<PaquetesDetalleResponse>(query);
  }

  getProductos() {
    var query = `query {
      productosPlancomercial(orderBy: {createdUtc: ASC}, status: PUBLISHED) {
        displayText
        nombreTarjeta
        orden
        imagenTarjeta {
          urls
        }
        linkTarjeta {
          urls
        }
        linkProducto
        descripcionTarjeta
        plataformaAsociada {
          contentItems {
            ... on OfertaTiposdePlataforma {
              idPlataforma
            }
          }
        }
      }
    }`;

    return this.cmsendpoint.getCmsDataEndPoint<PlanComercialProductosResponse>(query);
  }

  getProductosDetalle() {
    var query = `query {
  productoContenido (orderBy: {displayText: ASC}, status: PUBLISHED) {
    nombreProducto
    idProducto
    descripcion
    displayText
    subtituloInfografia
    textoInfografia
    tituloInfografia
    linkDestino
    imagen {
      urls
      paths
    }
    imagenInfografia {
      paths
      urls
    }
    plataformaAsociada {
      contentItems {
        ... on OfertaTiposdePlataforma {
          idPlataforma
        }
      }
    }
    circuloProducto {
      contentItems {
        ... on CirculoProducto {
          productoBlock {
            color {
              contentItems {
                ... on ColorProducto {
                  valorHex
                  nombre
                }
              }
            }
            titulo
            texto
            imagen {
              paths
              urls
            }
          }
        }
      }
    }
    published
  }
}`;

    return this.cmsendpoint.getCmsDataEndPoint<PlanComercialProductoDetalleResponse>(query);
  }

  getBannerHero() {
    var query = `query{
      bannersdePaquetes(status: PUBLISHED) {
        displayText
        bannerTitle
        bannerImage {
          paths
          urls
        }
        imagenMobile {
          paths
          urls
        }
      }
    }`;

    return this.cmsendpoint.getCmsDataEndPoint<PlanComercialHeroResponse>(query);
  }

  getPilares() {
    var query = `query {
      jumboCardPilares(orderBy: {createdUtc: ASC}, status: PUBLISHED) {
        pilaresTitulo
        pilaresImage {
          urls
        }
        pilaresTexto1
        pilaresTexto2
        pilaresQuote1
        pilaresQuote2
      }
    }`;
    return this.cmsendpoint.getCmsDataEndPoint<PilaresResponse>(query);
  }

  getAcercaDe() {
    var query = `query {
      acercade(status: PUBLISHED) {
        orden
        contactoNombre
        contactoPuesto
        contactoTelefono
        contentItemId
      }
    }  
    `
    return this.cmsendpoint.getCmsDataEndPoint<AcercaDeResponse>(query);
  }
  /* Videos ViX Televisa Ads */
  getVideosProductos(){
    var query = `query {
      videosPlanComercial(status: PUBLISHED) {
        displayText
        orden
        producto {
          contentItems {
            ... on ProductosPlancomercial {
              orden
              displayText
            }
          }
        }
        eventoVideo {
          video {
            urls
          }
          thumbnail {
            urls
          }
        }
      }
    }`;
    return this.cmsendpoint.getCmsDataEndPoint<VideosProductosResponse>(query);    
  }


}
