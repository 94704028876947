import { Injectable } from '@angular/core';
import { CmsEndpoint } from './cms-endpoint.service';
import { FutureCastResponse } from 'src/app/models/cms/cms.detalle-futurecast.model';

@Injectable()
export class CmsFuturecastService {

  constructor(
    private cmsendpoint: CmsEndpoint) {
  }

  getFuturecastCompleteData() {
    var query = `query{
      futureCast(status: PUBLISHED, first: 3000) {
        bloque {
          contentItems {
            ... on FuturecastBlock {
              createdUtc
              displayText
              futureCastBlock {
                casoImagen {
                  paths
                  urls
                }
                casoQuote
                casoTitulo
                linkBoton
                texto
                textoBoton
                tipodeBloque
              }
              galeria {
                contentItems {
                  ... on ElementoGaleria {
                    createdUtc
                    displayText
                    imagen {
                      paths
                      urls
                    }
                    imagenThumb {
                      paths
                      urls
                    }
                  }
                }
              }
            }
          }
        }
        contentItemId
        tituloFuturcast
        tituloBanner
        esPrincipal
        ordenTarjeta
        tarjeta {
          fechaTarjeta
          imagenTarjeta {
            paths
            urls
          }
          nombreTarjeta
          textoTarjeta
        }
        tipodelFuturecast {
          contentItems {
            contentItemId
            ... on FuturecastGeneros {
              createdUtc
              displayText
              generoTarjeta
              orden
            }
          }
        }
      }
    }
    `;
    return this.cmsendpoint.getCmsDataEndPoint<FutureCastResponse>(query);
  }
 }
