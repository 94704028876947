import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { EndpointBase } from './endpoint-base.service';
import { ConfigurationService } from './configuration.service';

import { ContactMessage } from './../models/contact-message.model';
import { catchError } from 'rxjs/operators';
import { ContactEmail } from '../models/contact-emal.model';

@Injectable()
export class NotificationEndpoint extends EndpointBase {

  get getNotificationsByUserUrl() { return this.configurations.baseUrl + '/api/Notification/notifications-by-user'; }
  get getPostContactMessageUrl() { return this.configurations.baseUrl + '/api/Notification/notification-contact'; }
  get postMessageReplyUrl() { return this.configurations.baseUrl + '/api/Notification/reply-message'; }
  get getMarkNotificationAsReadUrl() { return this.configurations.baseUrl + '/api/Notification/mark-read'; }
  get postNewUserContactUrl() { return this.configurations.baseUrl + '/api/Notification/notification-new-user'; }
  get putAcceptUserAdminUrl() { return this.configurations.baseUrl + '/api/Notification/admin-approval'; }
  get getMessagesByUserUrl() { return this.configurations.baseUrl + '/api/Notification/list-messages'; }
  get sendContactEmailUrl() { return this.configurations.baseUrl + '/api/Notification/contact-email'; }

  constructor(private configurations: ConfigurationService, http: HttpClient, authService: AuthService) {
    super(http, authService);
  }

  getNotificationsByUserEndpoint<T>(app_id?:number, user_id?: string): Observable<T> {
    const notificationsByUserUrl = user_id ? `${this.getNotificationsByUserUrl}/${app_id}/${user_id}` : this.getNotificationsByUserUrl;

    //console.log("Notifications|Get", app_id, user_id);
    return this.http.get<T>(notificationsByUserUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getNotificationsByUserEndpoint(app_id, user_id));
      }));
  }

  getPostMessageContactEndpoint<T>(notification: ContactMessage): Observable<T> {

    //console.log("Notifications|Post", JSON.stringify(notification));
    return this.http.post<T>(this.getPostContactMessageUrl, JSON.stringify(notification), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getPostMessageContactEndpoint(notification));
      })
    );
  }

  postSendEmailContactEndpoint<T>(notification: ContactEmail): Observable<T> {

    //console.log("emailContacto|Post", JSON.stringify(notification));
    return this.http.post<T>(this.sendContactEmailUrl, JSON.stringify(notification), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.postSendEmailContactEndpoint(notification));
      })
    );
  }

  postMessageReplyContactEndpoint<T>(notification: ContactMessage): Observable<T> {

    //console.log("ReplyMessage", JSON.stringify(notification));
    return this.http.post<T>(this.postMessageReplyUrl, JSON.stringify(notification), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.postMessageReplyContactEndpoint(notification));
      })
    );
  }

  getPutNotificationAsRead<T>(app_id: number, notification_id:string, user_id: string): Observable<T> {
    const markNotificationAsReadUrl = user_id ? `${this.getMarkNotificationAsReadUrl}/${app_id}/${notification_id}/${user_id}` : this.getMarkNotificationAsReadUrl;

    return this.http.put<T>(markNotificationAsReadUrl, null, this.requestHeaders).pipe<T>(
      catchError(error =>{
        return this.handleError(error, () => this.getPutNotificationAsRead(app_id, notification_id, user_id));
      })
    );
  }

  getPostNewUserContactEndpoint<T>(notification: ContactMessage): Observable<T> {

    //console.log("Notifications new user|Post", JSON.stringify(notification));
    return this.http.post<T>(this.postNewUserContactUrl, JSON.stringify(notification), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getPostNewUserContactEndpoint(notification));
      })
    );
  }

  getPutAcceptUserAsAdminEndpoint<T>(app_id: number, user_id: string, company_id:string, is_accepted: number, company_role: string): Observable<T> {
    const acceptUserAdminUrl = user_id && company_id ? `${this.putAcceptUserAdminUrl}/${app_id}/${user_id}/${company_id}/${is_accepted}/${company_role}` : this.putAcceptUserAdminUrl;

    //console.log("Notifications accept user|Put, ", user_id, company_id, is_accepted);
    return this.http.put<T>(acceptUserAdminUrl, null, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getPutAcceptUserAsAdminEndpoint(app_id, user_id, company_id, is_accepted, company_role));
      })
    );
  }

  getMessagesByUserEndpoint<T>(app_id?:number, user_id?: string, search_type?: string): Observable<T> {
    const messagesByUserUrl = user_id ? `${this.getMessagesByUserUrl}/${app_id}/${user_id}/${search_type}` : this.getMessagesByUserUrl;

    /*console.log("Messages|Get", app_id, user_id);*/
    return this.http.get<T>(messagesByUserUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getNotificationsByUserEndpoint(app_id, user_id));
      }));
  }

}
