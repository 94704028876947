<ng-container *ngIf="!isBannerLoaded">
  <div class="acerca-de mb-5">
    <div class="row tvs-banner-unloaded">
      <img class="loader-img z-20" src="assets/images/website_general/loader-2.svg" alt="" />
    </div>
  </div>
</ng-container>
<ng-container *ngIf="isBannerLoaded">
  <app-header-image-new srcImage="{{bannerImage}}" srcTitle="{{bannerTitle}}" class="canales-regionales"></app-header-image-new>
</ng-container>


<div class="row tvs-bg-white px-2 px-md-5 py-5">
  <div class="col-12 col-md-10 offset-md-1">
    <ng-container *ngIf="!isCanalesLoaded">
      <div class="loader-min">
        <img class="loader-img z-20" src="assets/images/website_general/loader-2.svg" alt="" />
      </div>
    </ng-container>
    <ng-container *ngIf="isCanalesLoaded">
      <div class="p-5 tvs-bg-white">
        <div class="row mt-5">
          <div class="col-10 col-md-8 col-lg-6 offset-lg-2 px-0">
            <h4 class="tvs-text-upper tvs-text-orange">Nombre del Canal</h4>
            <ng-container *ngFor="let canal of canalesRegional; let i = index;">
              <p>{{canal.nombreCanal}}</p>
            </ng-container>
          </div>
          <div class="col-2 col-md-4 px-0">
            <h4 class="tvs-text-upper tvs-text-orange">Canal</h4>
            <ng-container *ngFor="let canal of canalesRegional; let i = index;">
              <p>{{canal.numeroCanal}}</p>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>
    <div class="container p-5">
      <div class="row mt-5">
        <div class="col-12 col-md-10 offset-md-1 my-4 text-center">
          <a class="tvs-regresar" href="javascript:history.back()">&nbsp;</a>
        </div>
      </div>
    </div>
  </div>
</div>
