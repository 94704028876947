import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from './auth.service';
import { EndpointBase } from './endpoint-base.service';
import { ConfigurationService } from './configuration.service';
import { LinkedInCode, LinkedInLogin } from '../models/linkedin.model';


@Injectable()
export class SignInEndpoint extends EndpointBase {

  get stepOneUrl() { return this.configurations.baseUrl + '/api/Signin/users/StepOne'; }
  get companyStratUpUrl() { return this.configurations.baseUrl + '/api/Signin/users/StartUp'; }
  get companyInversorUrl() { return this.configurations.baseUrl + '/api/Signin/users/Inversor'; }
  get companyOthersUrl() { return this.configurations.baseUrl + '/api/Signin/users/Others'; }
  get passwordRecoveryUrl() { return this.configurations.baseUrl + '/api/Signin/users/PasswordRecovery'; }
  get myCompanyIdUrl() { return this.configurations.baseUrl + '/api/Signin/getCompanyId'; }
  get myAllUserDataUrl() { return this.configurations.baseUrl + '/api/Signin/getAllUserData'; }
  get myFollowingCompaniesUrl() { return this.configurations.baseUrl + '/api/Profile/following'; }
  get myFollowersUrl() { return this.configurations.baseUrl + '/api/Profile/my-followers'; }
  get updateProfileUserUrl() { return this.configurations.baseUrl + '/api/Signin/users/edit-user'; }
  get validateTokenUrl() { return this.configurations.baseUrl + '/api/Signin/users/user-from-token'; }
  get passwordResetUrl() { return this.configurations.baseUrl + '/api/Signin/users/PasswordReset'; }
  get activateUserUrl() { return this.configurations.baseUrl + '/api/Signin/users/activate-user'; }
  get linkedInLoginUrl() { return this.configurations.baseUrl + '/api/Signin/users/login-linked-in'; }
  get linkedinAccessToken() { return 'https://www.linkedin.com/oauth/v2/accessToken';}
  
  constructor(private configurations: ConfigurationService, http: HttpClient, authService: AuthService) {
    super(http, authService);
  }

  getNewUserPeopleEndpoint<T>(userPeopleObject: any): Observable<T> {

    return this.http.post<T>(this.stepOneUrl, JSON.stringify(userPeopleObject), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getNewUserPeopleEndpoint(userPeopleObject));
      }));
  }

  getNewCompanyStartUpEndpoint<T>(companyStartUpObject: any): Observable<T> {

    return this.http.post<T>(this.companyStratUpUrl, JSON.stringify(companyStartUpObject), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getNewCompanyStartUpEndpoint(companyStartUpObject));
      }));
  }

  getNewCompanyInversorEndpoint<T>(companyInversorObject: any): Observable<T> {

    return this.http.post<T>(this.companyInversorUrl, JSON.stringify(companyInversorObject), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getNewCompanyInversorEndpoint(companyInversorObject));
      }));
  }

  getNewCompanyOthersEndpoint<T>(companyOthersObject: any): Observable<T> {

    return this.http.post<T>(this.companyOthersUrl, JSON.stringify(companyOthersObject), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getNewCompanyOthersEndpoint(companyOthersObject));
      }));
  }

  getPasswordRecoveryEndpoint<T>(passwordRecovery: any): Observable<T> {

    return this.http.post<T>(this.passwordRecoveryUrl, JSON.stringify(passwordRecovery), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getPasswordRecoveryEndpoint(passwordRecovery));
      }));
  }

  getMyCompanyIdEndpoint<T>(UserId: any): Observable<T> {
    const endpointUrl = UserId ? `${this.myCompanyIdUrl}/${UserId}` : this.myCompanyIdUrl;
    //console.log('URL:',endpointUrl);
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getMyCompanyIdEndpoint(UserId));
      }));
  }

  getAllUserDataEndpoint<T>(UserId: any): Observable<T> {
    const endpointUrl = UserId ? `${this.myAllUserDataUrl}/${UserId}` : this.myAllUserDataUrl;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getAllUserDataEndpoint(UserId));
      }));
  }

  getMyFollowingCompaniesEndpoint<T>(UserId: any): Observable<T> {
    const endpointUrl = UserId ? `${this.myFollowingCompaniesUrl}/${UserId}` : this.myFollowingCompaniesUrl;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getAllUserDataEndpoint(UserId));
      }));
  }

  getMyFollowersEndpoint<T>(UserId: any): Observable<T> {
    const endpointUrl = UserId ? `${this.myFollowersUrl}/${UserId}` : this.myFollowersUrl;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getMyFollowersEndpoint(UserId));
      }));
  }

  getUpdateUserProfileEndpoint<T>(peopleUserObject: any): Observable<T> {

    return this.http.put<T>(this.updateProfileUserUrl, JSON.stringify(peopleUserObject), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getUpdateUserProfileEndpoint(peopleUserObject));
      }));
  }

  validateUserToken<T>(token : string): Observable<T> {
    const endpointUrl = token ? `${this.validateTokenUrl}/${token}` : this.validateTokenUrl;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.validateUserToken(token));
      }));
  }

  activateUserMail<T>(token : string, user:string): Observable<T> {
    const endpointUrl = token ? `${this.activateUserUrl}/${token}/${user}` : this.activateUserUrl;
    return this.http.get<T>(endpointUrl, this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.activateUserMail(token, user));
      }));
  }

  

  postUserPasswordReset<T>(userPasswordreset : any): Observable<T> {
    return this.http.post<T>(this.passwordResetUrl, JSON.stringify(userPasswordreset), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.postUserPasswordReset(userPasswordreset));
      }));
  }

  getLinkedinLogin<T>(linkedIncode: LinkedInCode): Observable<T>{
    return this.http.post<T>(this.linkedInLoginUrl, JSON.stringify(linkedIncode), this.requestHeaders).pipe<T>(
      catchError(error => {
        return this.handleError(error, () => this.getLinkedinLogin(linkedIncode));
      }));  
  }

  public getIPAddress(): Observable<string>
  {  
    return this.http.get("http://api.ipify.org/?format=json").pipe<string>(
      catchError(error => {
        return this.handleError(error, () => this.getIPAddress());
      }));  
  } 
}
