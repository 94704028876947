<div class="row tvs-bg-white pb-5 tvs-generos-top px-2 px-sm-3 px-md-0 px-lg-4">
  <app-custom-breadcrumbs [breadcrumbsData]="breadcrumbsData"></app-custom-breadcrumbs>
  <div class="tvs-generos-backlogo">
    <div class="tvs-generos-backlogo-up"></div>
    <div class="tvs-generos-backlogo-down"></div>
  </div>
  <div class="container-fluid pt-5 px-2 px-md-5 tvs-bg-white">
    <div class="row mb-5 pt-5">
      <div class="col-12 col-md-7 col-lg-6 col-xl-5 text-left">
        <div class="float-left tvs-generos-sidelogo mr-3 tvs-generos-sidelogo-0">
          <div class="tvs-generos-sidelogo-up"></div>
          <div class="tvs-generos-sidelogo-down"></div>
        </div>
        <h4 class="tvs-text-upper tvs-generos-contenidos-0 mb-0">Contenidos</h4>
        <h1 class="tvs-text-orange tvs-generos-tituloplat">Búsqueda por géneros</h1>
      </div>
      <div class="col-12 col-md-5 col-lg-6 col-xl-5 text-left">
        <p class="tvs-descripcion-general">{{textoGenero}}</p>
      </div>
    </div>

    <div class="row d-none d-sm-flex mb-5">
      <div class="col-12">
        <h4 class="tvs-text-orange">Búsqueda por plataforma:</h4>
      </div>
      <div class="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3 pos-relative mt-3 mx-0 pl-0 text-center">
        <div class="row ml-3 tvs-combo-plat" (click)="togglePlataformas()">
          <span>Selecciona una plataforma <fa-icon [icon]="faChevronDown" class="pl-2 tvs-text-gray" *ngIf="!isPlataformasOpen"></fa-icon><fa-icon [icon]="faChevronUp" class="pl-2 tvs-text-gray" *ngIf="isPlataformasOpen"></fa-icon></span>
        </div>
        <div class="tvs-combo-plat-menu">
          <ul>
            <ng-container *ngFor="let plataformas of plataformasLinks; let i = index">
              <!--Todos, excepto izzi+sky y patrocinios deportivos-->
              <li *ngIf="plataformas.idPlataforma !== 6 && plataformas.idPlataforma !== 7">
                <span (click)="navigateTo('nuestra-oferta/plataforma/' + friendlyUrl(plataformas.displayText))">{{plataformas.displayText}}</span>
              </li>
              <!--excepción para izzi+sky -->
              <li *ngIf="plataformas.idPlataforma == 7">
                <span (click)="navigateToExternal(linkIzziSky)">{{plataformas.displayText}}</span>
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>

  </div>

  <ng-container *ngIf="!content">
    <div class="container-fluid loader-min">
      <img class="loader-img z-20" src="assets/images/website_general/loader-2.svg" alt="" />
    </div>
  </ng-container>

  <ng-container *ngIf="content">
    <div class="container-fluid px-2 px-md-5 tvs-bg-white">
      <!--Introducción-->
      <div class="row mb-5">

        <!--Navegación de plataformas-->
      </div>
      <!--Géneros-->
      <div class="row d-none d-sm-flex mb-5" *ngIf="isProgramasLoaded">
        <div class="col-sm-2 col-xl-1">
          <h4 class="tvs-text-orange">Géneros</h4>
        </div>
        <div class="col-sm-10 col-xl-11">
          <div class="row tvs-gen-topdiv">
            <!-- Botón todos  -->
            <div class="form-check col-2 col-sm-5 col-md-3 col-lg-3 col-xl-2 form-check-genero">
              <div class="form-check-label-genero">
                <input class="form-check-input filter-check" type="radio" value="0" id="genero_todos" name="Grupo_Generos" [checked]='isTodosSelected()' (change)="changeFilterGeneros('0',$event)">
                <div class="custom-check-genero">
                  <div class="small text-center">Todos</div>
                </div>
              </div>
            </div>
            <div class="form-check col-2 col-sm-5 col-md-3 col-lg-3 col-xl-2 form-check-genero" *ngFor="let genero of catGeneros">
              <div class="form-check-label-genero">
                <input class="form-check-input filter-check" type="radio" value="{{genero.id}}" id="genero_{{genero.id}}" name="Grupo_Generos" [checked]='isGeneroChecked(genero.id)' (change)="changeFilterGeneros(genero.id,$event)">
                <div class="custom-check-genero">
                  <div class="small text-center">{{genero.value}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--Filtros mobile botón-->
      <div class="row d-flex d-sm-none mb-5" *ngIf="content">
        <ng-container>
          <div class="col-8 offset-2">
            <button id="filtro-mobile" class="btn btn-primary btn-orange tvs-btn btn-block tvs-btn-faicon-fixed" (click)="openFilter()"><fa-icon [icon]="faFilter" class="pr-1 tvs-text-white"></fa-icon>Filtrar</button>
          </div>
        </ng-container>
      </div>

      <!--filtros mobile-->
      <div class="tvs-mobile-filter">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 text-right">
              <button class="btn btn-link tvs-close-filter tvs-text-white" (click)="closeFilter()">Cerrar<fa-icon [icon]="faTimes" class="pl-1 tvs-text-white"></fa-icon></button>
            </div>
            <div class="col-12 pt-5">
              <ul class="tvs-mobile-filter-list">
                <!--filtros plataformas-->
                <li class="tvs-text-white tvs-mobile-filter-sub tvs-mobile-filter-plat">
                  <div class="col-12" (click)="toggleMobilePlataforma()">Plataforma <fa-icon [icon]="faPlus" class="float-right tvs-text-white" *ngIf="!isPlataformaMobileOpen"></fa-icon><fa-icon [icon]="faMinus" class="float-right tvs-text-white" *ngIf="isPlataformaMobileOpen"></fa-icon></div>
                  <ul class="tvs-mobile-plataforma-list my-4">
                    <ng-container *ngFor="let plataformas of plataformasLinks; let i = index">
                      <li *ngIf="plataformas.idPlataforma !== 6 && plataformas.idPlataforma !== 7">
                        <span class="tvs-mobile-plataforma-check" (click)="navigateTo('nuestra-oferta/plataforma/' + friendlyUrl(plataformas.displayText))">{{plataformas.displayText}}</span>
                      </li>
                      <li *ngIf="plataformas.idPlataforma == 7">
                        <span class="tvs-mobile-plataforma-check" (click)="navigateToExternal(linkIzziSky)">{{plataformas.displayText}}</span>
                      </li>
                    </ng-container>
                  </ul>
                </li>
                <!--filtros géneros-->
                <li class="tvs-text-white tvs-mobile-filter-sub tvs-mobile-filter-gen">
                  <div class="col-12">Géneros <fa-icon [icon]="faMinus" class="float-right tvs-text-white"></fa-icon></div>
                  <div class="col-12 p-0 my-4">
                    <div class="form-check col-12 form-check-genero">
                      <div class="form-check-label-genero">
                        <input class="form-check-input filter-check" type="radio" value="0" id="genero_todos" name="Grupo_Generos" [checked]='isGeneroChecked("0")' (change)="changeFilterGeneros('0',$event)">
                        <div class="custom-check-genero">
                          <div class="small">Todos</div>
                        </div>
                      </div>
                    </div>
                    <div class="form-check col-12 form-check-genero" *ngFor="let genero of catGeneros">
                      <div class="form-check-label-genero">
                        <input class="form-check-input filter-check" type="radio" value="{{genero.id}}" id="genero_{{genero.id}}" name="Grupo_Generos" [checked]='isGeneroChecked(genero.id)' (change)="changeFilterGeneros(genero.id,$event)">
                        <div class="custom-check-genero">
                          <div class="small">{{genero.value}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <!--Contenidos-->
      <div class="container-fluid pt-5 px-0 tvs-bg-white">
        <div class="col-12">
          <div class="row card-{{screenRes}}">
            <ng-container *ngIf="!isProgramasLoaded">
              <div class="loader-min">
                <img class="loader-img z-20" src="assets/images/website_general/loader-2.svg" alt="" />
              </div>
            </ng-container>
            <ng-container *ngIf="isProgramasLoaded">
              <div *ngFor="let datosTarjeta of datosProgramacion; let i = index;" class="col-12 col-sm-6 col-md-4 col-lg-3 col-xxl-2 px-1 tvs-card-dynamic-color">
                <a [attr.id]="dynamicId('tarjetaProgGenero-', datosTarjeta.nombreTarjeta)"
                   class="tarjetaProgGenero c-pointer"
                   (click)="goToProgram(datosTarjeta.linkTarjeta, datosTarjeta.nombreTarjeta , datosTarjeta.cintilloTarjeta, datosTarjeta.canalTarjetaContenido.contentItems[0].platNombre, datosTarjeta.genero.contentItems[0].generoTarjeta, datosTarjeta.fechaTarjeta, datosTarjeta.horaTarjeta)">
                  <app-tarjeta-programa class="c-pointer" [cardData]="datosTarjeta"></app-tarjeta-programa>
                </a>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
