import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { GtmService } from 'ngx-gtm';
import { EventosEvento } from 'src/app/models/cms/cms.oferta.model';
import { AuthService } from 'src/app/services/auth.service';
import { CmsOfertaService } from 'src/app/services/cms/cms.oferta.service';
import { CmsPlanComercialService } from 'src/app/services/cms/cms.plan.comercial.service';
import { ConfigurationService } from 'src/app/services/configuration.service';

@Component({
  selector: 'app-eventos',
  templateUrl: './eventos.component.html',
  styleUrls: ['./eventos.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventosComponent implements OnInit {

  title: string = 'Eventos';

  bannerImage: string;
  bannerImageMobile: string;
  bannerTitle: string;
  baseCMSUrl: string;

  public eventos: any[];
  public eventosService : EventosEvento[];

  /*Loaders*/
  isBannerLoaded: boolean = false;
  isEventosLoaded: boolean = false;

  constructor(
    private gtm: GtmService,
    private cmsPlanComercialService: CmsPlanComercialService,
    private cmsOfertaService: CmsOfertaService,
    private configuration: ConfigurationService,
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private titleService: Title,
    private metaService: Meta
  ) {
    this.baseCMSUrl = this.configuration.baseCmsUrl;
    if(this.authService.isLoggedIn){
      this.getBannerImage();
      this.getEventos();
    }
    else{
      /* Me loggeo y luego voy por los banners */
      this.authService.loginByApp().subscribe(resp => {//console.log('Resp login:',resp)
      this.getBannerImage();
      this.getEventos();
      });
    }
    
  }

  ngOnInit(): void {
    this.gtm.push({ event: 'TelevisaLlega Eventos' });

    this.titleService.setTitle(this.title);
    this.metaService.addTags([
      { name: 'keywords', content: 'eventos, eventos deportivos, eventos especiales, programas de televisón, argumentos de programas' },
      { name: 'description', content: 'Descubre el contenido de los eventos más importantes para alcanzar tus objetivos y fortalecer tu marca.' },
      { name: 'robots', content: 'index, follow' }
    ]);
  }

  public getBannerImage() {
    this.cmsPlanComercialService.getBannerHero().subscribe(response => {
      for(let i = 0; i < response.data.bannersdePaquetes.length ; i++) {
        if(response.data.bannersdePaquetes[i].displayText === 'Eventos') {
          this.bannerImage = this.baseCMSUrl.slice(0, -1) + response.data.bannersdePaquetes[i].bannerImage.urls[0];
          this.bannerImageMobile = this.baseCMSUrl.slice(0, -1) + response.data.bannersdePaquetes[i].imagenMobile.urls[0];
          this.bannerTitle = response.data.bannersdePaquetes[i].bannerTitle;
          this.isBannerLoaded = true;
          this.cdr.markForCheck();
        }
      }
    });
  }

  public getEventos() {
    this.cmsOfertaService.getEventos().subscribe(response => {
      this.eventosService = response?.data.eventosEvento;
      this.eventos = [];
      for(let i = 0; i < this.eventosService.length ; i++) {
        this.eventos.push(
          {
            idEvento: this.eventosService[i].contentItemId,
            nombreEvento: this.eventosService[i].eventoNombre,
            imagenEvento: this.baseCMSUrl.slice(0, -1) + this.eventosService[i].imagenEvento.urls[0],
            fechaEvento: this.eventosService[i].fechaEvento,
            horarioEvento: this.eventosService[i].horarioEvento,
            textoEvento: this.eventosService[i].textoEvento,
            lugarEvento: this.eventosService[i].lugarEvento,
            linkEvento: 'eventos/detalle-evento/' + this.eventosService[i].contentItemId,
            orden: this.eventosService[i].orden
          }
        );
      }
      this.eventos.sort(this.ordenaArregloPropiedadOrden);
      this.isEventosLoaded = true;
      this.cdr.markForCheck();
    });
  }
  
  ordenaArregloPropiedadOrden(obj1 ,obj2 ){
    if(obj1.orden > obj2.orden) { return 1;}
    if(obj1.orden < obj2.orden) { return -1;}
    return 0;
  }

}

