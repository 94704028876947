import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { gsap } from "gsap/all";
import { EventData } from 'src/app/models/event-data.model';
import { GtmService } from 'ngx-gtm';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-tarjeta-evento',
  templateUrl: './tarjeta-evento.component.html',
  styleUrls: ['./tarjeta-evento.component.scss']
})
export class TarjetaEventoComponent implements OnInit {

  faArrowRight = faArrowRight;

  @Input() eventData: EventData;

  constructor(
    public router: Router,
    private gtm: GtmService
  ) {}

  ngOnInit(): void {
  }

  public getCardImg(imagen: string) {
    var urlImagen: string = imagen.replace(/ /g, '%20');
    return urlImagen;
  }

  public navigateTo(path: string): void {
    if (path && path !== '') {
      this.router.navigateByUrl(path);
      gsap.to(window, {
        scrollTo: 0,
        duration: 0.5
      });
    }
  }

  public goToEvent(path: string, nombre: string) {
    window.open(path, '_self');
    //console.log(path, nombre);
    this.gtm.push({
      event: 'click-evento',
      type: 'Eventos',
      name: nombre
    });
  }

}
