import { Component, ViewEncapsulation, OnInit, OnDestroy, ViewChildren, AfterViewInit, QueryList, ElementRef } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { GtmService } from 'ngx-gtm';
import { AuthService } from '../services/auth.service';
import { OidcHelperService } from '../services/oidc-helper.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(
    private gtm: GtmService,
    private auth: AuthService
  ){
    this.gtm.push({event: 'TelevisaLlega'});
    this.auth.loginByApp().subscribe(resp => {
      //console.log('Resp login:', resp)
    });
  }
}
