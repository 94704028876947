<ng-container *ngIf="!isBannerLoaded">
  <div class="nuestra-oferta mb-5">
    <div class="row tvs-banner-unloaded">
      <img class="loader-img z-20" src="assets/images/website_general/loader-2.svg" alt="" />
    </div>
  </div>
</ng-container>
  <ng-container *ngIf="isBannerLoaded">
  <app-header-image-new srcImage="{{bannerImage}}" srcImageMobile="{{bannerImageMobile}}" srcTitle="{{bannerTitle}}" srcTipo="2" class="nuestra-oferta"></app-header-image-new>
  </ng-container>
<div>

</div>
<div class="row tvs-bg-white px-2 px-md-5 py-5">
  <div class="container-fluid">
    <!--Orden: 1 = Digital-->
    <ng-container *ngIf="!isLoadedPlataformaDigital">
      <div class="container-fluid loader-min">
        <img class="loader-img z-20" src="assets/images/website_general/loader-2.svg" alt="" />
      </div>
    </ng-container>
    <ng-container *ngIf="isLoadedPlataformaDigital">
      <div class="row my-5 pt-4">
        <div class="col-12 col-md-6 col-lg-5">
          <div class="float-left tvs-oferta-sidelogo mr-1 mr-md-3">
            <div class="tvs-oferta-sidelogo-up"></div>
            <div class="tvs-oferta-sidelogo-down"></div>
          </div>
          <h1 class="tvs-text-orange tvs-oferta-tituloplat">{{objPlataformaDigital[0].displayText}}</h1>
          <div class="tvs-oferta-div-titulo d-none d-md-block">&nbsp;</div>
        </div>
        <div class="mt-4 mt-md-0 col-12 col-md-6 col-lg-7">
          <p>{{objPlataformaDigital[0].plataformaTexto}}</p>
        </div>
      </div>
      <div class="row">
        <!--tarjetas custom (4)-->
        <div class="col-6 col-sm-4 col-md-3 col-lg-2 p-3">
          <a class="tvs-link-canal c-pointer" (click)="navigateTo(baseUrlPlataformas + friendlyUrl(objPlataformaDigital[0].displayText))">
            <div class="mb-3 tvs-oferta-img-canal">
              <img class="img-fluid" src="assets/images/carga_cms/nuestra-oferta/ott-001.png" alt="" />
            </div>
            <h3 class="tvs-oferta-nom-canal">OTT</h3>
            <p class="tvs-oferta-txt-canal">Entretenimiento, estilo de vida/humor, música, deportes y noticias.</p>
          </a>
        </div>

        <div class="col-6 col-sm-4 col-md-3 col-lg-2 p-3">
          <a class="tvs-link-canal c-pointer" (click)="navigateTo(baseUrlPlataformas + friendlyUrl(objPlataformaDigital[0].displayText))">
            <div class="mb-3 tvs-oferta-img-canal">
              <img class="img-fluid" src="assets/images/carga_cms/nuestra-oferta/sitios-001.png" alt="" />
            </div>
            <h3 class="tvs-oferta-nom-canal">Sitios</h3>
            <p class="tvs-oferta-txt-canal">Entretenimiento, estilo de vida/humor, música, deportes y noticias.</p>
          </a>
        </div>

        <div class="col-6 col-sm-4 col-md-3 col-lg-2 p-3">
          <a class="tvs-link-canal c-pointer" (click)="navigateTo(baseUrlPlataformas + friendlyUrl(objPlataformaDigital[0].displayText))">
            <div class="mb-3 tvs-oferta-img-canal">
              <img class="img-fluid" src="assets/images/carga_cms/nuestra-oferta/social-media-001.png" alt="" />
            </div>
            <h3 class="tvs-oferta-nom-canal">Social Media</h3>
            <p class="tvs-oferta-txt-canal">Entretenimiento, estilo de vida/humor, música, deportes y noticias.</p>
          </a>
        </div>

        <div class="col-6 col-sm-4 col-md-3 col-lg-2 p-3">
          <a class="tvs-link-canal c-pointer" (click)="navigateTo(baseUrlPlataformas + friendlyUrl(objPlataformaDigital[0].displayText))">
            <div class="mb-3 tvs-oferta-img-canal">
              <img class="img-fluid" src="assets/images/carga_cms/nuestra-oferta/apps-002.png" alt="" />
            </div>
            <h3 class="tvs-oferta-nom-canal">Apps</h3>
            <p class="tvs-oferta-txt-canal">Entretenimiento, estilo de vida/humor, música, deportes y noticias.</p>
          </a>
        </div>

      </div>
      <div class="row my-4">
        <div class="col-12 col-md-6 col-lg-4 offset-lg-2 col-xl-3 offset-xl-3  text-center">
          <button class="btn btn-primary btn-orange tvs-btn m-2 btn-block" (click)="navigateTo(baseUrlPlataformas + friendlyUrl(objPlataformaDigital[0].displayText))">Ver más contenidos <fa-icon [icon]="faPlus" class="pl-1"></fa-icon></button>
        </div>
        <div class="col-12 col-md-6 col-lg-4 col-xl-3 text-center">
          <button class="btn btn-primary btn-orange tvs-btn m-2 btn-block" (click)="navigateTo(baseUrlProductos + friendlyUrl(objPlataformaDigital[0].displayText))">
            Ver plan comercial <fa-icon [icon]="faPlus" class="pl-1"></fa-icon>
          </button>
        </div>
      </div>
      <div class="row mb-5 mt-4 d-none d-md-flex">
        <div class="tvs-oferta-lower-div"></div>
      </div>
    </ng-container>
    <!--Orden: 2 = TV Radiodifundida-->
    <ng-container *ngIf="!isLoadedPlataformaRadiodif">
      <div class="container-fluid loader-min">
        <img class="loader-img z-20" src="assets/images/website_general/loader-2.svg" alt="" />
      </div>
    </ng-container>
    <ng-container *ngIf="isLoadedPlataformaRadiodif">
      <div class="row my-5 pt-4">
        <div class="col-12 col-md-6 col-lg-5">
          <div class="float-left tvs-oferta-sidelogo mr-1 mr-md-3">
            <div class="tvs-oferta-sidelogo-up"></div>
            <div class="tvs-oferta-sidelogo-down"></div>
          </div>
          <h1 class="tvs-text-orange tvs-oferta-tituloplat">{{objPlataformaRadiodif[0].displayText}}</h1>
          <div class="tvs-oferta-div-titulo d-none d-md-block">&nbsp;</div>
        </div>
        <div class="mt-4 mt-md-0 col-12 col-md-6 col-lg-7">
          <p>{{objPlataformaRadiodif[0].plataformaTexto}}</p>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="isLoadedCanalesRadiodif">
      <div class="row">
        <div class="col-6 col-sm-4 col-md-3 col-lg-2 p-3" *ngFor="let canalesPlat of objCanalesRadiodif; let j = index;">
          <a class="tvs-link-canal c-pointer" (click)="navigateTo(baseUrlPlataformas + friendlyUrl(objPlataformaRadiodif[0].displayText) + '/' + canalesPlat.platMediaId)">
            <div class="px-2 py-4 mb-3 tvs-oferta-img-canal">
              <img class="img-fluid" src="{{canalesPlat.platImg}}" alt="" />
            </div>
            <h3 class="tvs-oferta-nom-canal">{{canalesPlat.platNombre}}</h3>
            <p class="tvs-oferta-txt-canal">{{canalesPlat.genero}}</p>
          </a>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="isLoadedPlataformaRadiodif">
      <div class="row my-4">
        <div class="col-12 col-md-6 col-lg-4 offset-lg-2 col-xl-3 offset-xl-3  text-center">
          <button class="btn btn-primary btn-orange tvs-btn m-2 btn-block" (click)="navigateTo(baseUrlPlataformas + friendlyUrl(objPlataformaRadiodif[0].displayText))">Ver más contenidos <fa-icon [icon]="faPlus" class="pl-1"></fa-icon></button>
        </div>
        <div class="col-12 col-md-6 col-lg-4 col-xl-3 text-center">
          <button class="btn btn-primary btn-orange tvs-btn m-2 btn-block" (click)="navigateTo(baseUrlProductos + friendlyUrl(objPlataformaRadiodif[0].displayText))">
            Ver plan comercial <fa-icon [icon]="faPlus" class="pl-1"></fa-icon>
          </button>
        </div>
      </div>
      <div class="row mb-5 mt-4 d-none d-md-flex">
        <div class="tvs-oferta-lower-div"></div>
      </div>
    </ng-container>
    <!--Orden: 3 = TV Regional-->
    <ng-container *ngIf="!isLoadedPlataformaRegional">
      <div class="container-fluid loader-min">
        <img class="loader-img z-20" src="assets/images/website_general/loader-2.svg" alt="" />
      </div>
    </ng-container>
    <ng-container *ngIf="isLoadedPlataformaRegional">
      <div class="row my-5 pt-4">
        <div class="col-12 col-md-6 col-lg-5">
          <div class="float-left tvs-oferta-sidelogo mr-1 mr-md-3">
            <div class="tvs-oferta-sidelogo-up"></div>
            <div class="tvs-oferta-sidelogo-down"></div>
          </div>
          <h1 class="tvs-text-orange tvs-oferta-tituloplat">{{objPlataformaRegional[0].displayText}}</h1>
          <div class="tvs-oferta-div-titulo d-none d-md-block">&nbsp;</div>
        </div>
        <div class="mt-4 mt-md-0 col-12 col-md-6 col-lg-7">
          <p>{{objPlataformaRegional[0].plataformaTexto}}</p>
        </div>
      </div>
      <div class="row">
        <!--tarjeta custom-->
        <div class="col-6 col-sm-4 col-md-3 col-lg-2 p-3">
          <a class="tvs-link-canal c-pointer" (click)="navigateTo(baseUrlPlataformas + friendlyUrl(objPlataformaRegional[0].displayText))">
            <div class="px-2 mb-3 tvs-oferta-img-canal">
              <img class="img-fluid" src="assets/images/website_general/televisa-regional-001.png" alt="" />
            </div>
            <h3 class="tvs-oferta-nom-canal">Televisa Regional</h3>
            <p class="tvs-oferta-txt-canal">Entretenimiento, estilo de vida, humor, música, deportes, noticias. Monterrey, Guadalajara, Puebla, Tijuana y Veracruz.</p>
          </a>
        </div>
      </div>
      <div class="row my-4">
        <div class="col-12 col-md-6 col-lg-4 offset-lg-2 col-xl-3 offset-xl-3  text-center">
          <button class="btn btn-primary btn-orange tvs-btn m-2 btn-block" (click)="navigateTo(baseUrlPlataformas + friendlyUrl(objPlataformaRegional[0].displayText))">Ver más contenidos <fa-icon [icon]="faPlus" class="pl-1"></fa-icon></button>
        </div>
        <div class="col-12 col-md-6 col-lg-4 col-xl-3 text-center">
          <button class="btn btn-primary btn-orange tvs-btn m-2 btn-block" (click)="navigateTo(baseUrlProductos + friendlyUrl(objPlataformaRegional[0].displayText))">
            Ver plan comercial <fa-icon [icon]="faPlus" class="pl-1"></fa-icon>
          </button>
        </div>
      </div>
      <div class="row mb-5 mt-4 d-none d-md-flex">
        <div class="tvs-oferta-lower-div"></div>
      </div>
    </ng-container>
    <!--Orden: 4 = Televisa Networks-->
    <ng-container *ngIf="!isLoadedPlataformaNetworks">
      <div class="container-fluid loader-min">
        <img class="loader-img z-20" src="assets/images/website_general/loader-2.svg" alt="" />
      </div>
    </ng-container>
    <ng-container *ngIf="isLoadedPlataformaNetworks">
      <div class="row my-5 pt-4">
        <div class="col-12 col-md-6 col-lg-5">
          <div class="float-left tvs-oferta-sidelogo mr-1 mr-md-3">
            <div class="tvs-oferta-sidelogo-up"></div>
            <div class="tvs-oferta-sidelogo-down"></div>
          </div>
          <h1 class="tvs-text-orange tvs-oferta-tituloplat">{{objPlataformaNetworks[0].displayText}}</h1>
          <div class="tvs-oferta-div-titulo d-none d-md-block">&nbsp;</div>
        </div>
        <div class="mt-4 mt-md-0 col-12 col-md-6 col-lg-7">
          <p>{{objPlataformaNetworks[0].plataformaTexto}}</p>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="isLoadedCanalesNetworks">
      <div class="row">
        <div class="col-6 col-sm-4 col-md-3 col-lg-2 p-3" *ngFor="let canalesPlat of objCanalesNetworks; let j = index;">
          <a class="tvs-link-canal c-pointer" (click)="navigateTo(baseUrlPlataformas + friendlyUrl(objPlataformaNetworks[0].displayText) + '/' + canalesPlat.platMediaId)">
            <div class="px-2 py-4 mb-3 tvs-oferta-img-canal">
              <img class="img-fluid" src="{{canalesPlat.platImg}}" alt="" />
            </div>
            <h3 class="tvs-oferta-nom-canal">{{canalesPlat.platNombre}}</h3>
            <p class="tvs-oferta-txt-canal">{{canalesPlat.genero}}</p>
          </a>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="isLoadedPlataformaNetworks">
      <div class="row my-4">
        <div class="col-12 col-md-6 col-lg-4 offset-lg-2 col-xl-3 offset-xl-3  text-center">
          <button class="btn btn-primary btn-orange tvs-btn m-2 btn-block" (click)="navigateTo(baseUrlPlataformas + friendlyUrl(objPlataformaNetworks[0].displayText))">Ver más contenidos <fa-icon [icon]="faPlus" class="pl-1"></fa-icon></button>
        </div>
        <div class="col-12 col-md-6 col-lg-4 col-xl-3 text-center">
          <button class="btn btn-primary btn-orange tvs-btn m-2 btn-block" (click)="navigateTo(baseUrlProductos + friendlyUrl(objPlataformaNetworks[0].displayText))">
            Ver plan comercial <fa-icon [icon]="faPlus" class="pl-1"></fa-icon>
          </button>
        </div>
      </div>
      <div class="row mb-5 mt-4 d-none d-md-flex">
        <div class="tvs-oferta-lower-div"></div>
      </div>
    </ng-container>
    <!--Orden: 5 = Izzi + Sky-->
    <ng-container *ngIf="!isLoadedPlataformaIzzi">
      <div class="container-fluid loader-min">
        <img class="loader-img z-20" src="assets/images/website_general/loader-2.svg" alt="" />
      </div>
    </ng-container>
    <ng-container *ngIf="isLoadedPlataformaIzzi">
      <div class="row my-5 pt-4">
        <div class="col-12 col-md-6 col-lg-5">
          <div class="float-left tvs-oferta-sidelogo mr-1 mr-md-3">
            <div class="tvs-oferta-sidelogo-up"></div>
            <div class="tvs-oferta-sidelogo-down"></div>
          </div>
          <h1 class="tvs-text-orange tvs-oferta-tituloplat tvs-oferta-titulosky">{{objPlataformaIzzi[0].displayText}}</h1>
          <div class="tvs-oferta-div-titulo d-none d-md-block">&nbsp;</div>
        </div>
        <div class="mt-4 mt-md-0 col-12 col-md-6 col-lg-7">
          <p>{{objPlataformaIzzi[0].plataformaTexto}}</p>
        </div>
      </div>
      <div class="row">
        <!--tarjeta custom-->
        <div class="col-6 col-sm-4 col-md-3 col-lg-2 p-3">
          <a class="tvs-link-canal c-pointer" href="https://izzisky.goodbarber.app/" target="_blank">
            <div class="mb-3 tvs-oferta-img-canal">
              <img class="img-fluid" src="assets/images/carga_cms/nuestra-oferta/izzi-sky-televisa-001.png" alt="" />
            </div>
            <h3 class="tvs-oferta-nom-canal">izzi + sky</h3>
            <p class="tvs-oferta-txt-canal">Diversidad de géneros y contenidos</p>
          </a>
        </div>
      </div>
      <div class="row my-4">
        <div class="col-12 col-md-6 col-lg-4 offset-lg-2 col-xl-3 offset-xl-3  text-center">
          <a class="btn btn-primary btn-orange tvs-btn m-2 btn-block" href="https://izzisky.goodbarber.app/" target="_blank">Ver más contenidos <fa-icon [icon]="faPlus" class="pl-1"></fa-icon></a>
        </div>
        <div class="col-12 col-md-6 col-lg-4 col-xl-3 text-center">
          <button class="btn btn-primary btn-orange tvs-btn m-2 btn-block" (click)="navigateTo(baseUrlProductos + friendlyUrl(objPlataformaIzzi[0].displayText))">
            Ver plan comercial <fa-icon [icon]="faPlus" class="pl-1"></fa-icon>
          </button>
        </div>
      </div>
      <div class="row mb-5 mt-4 d-none d-md-flex">
        <div class="tvs-oferta-lower-div"></div>
      </div>
    </ng-container>
    <!--Orden: 6 = Editorial-->
    <ng-container *ngIf="!isLoadedPlataformaEditorial">
      <div class="container-fluid loader-min">
        <img class="loader-img z-20" src="assets/images/website_general/loader-2.svg" alt="" />
      </div>
    </ng-container>
    <ng-container *ngIf="isLoadedPlataformaEditorial">
      <div class="row my-5 pt-4">
        <div class="col-12 col-md-6 col-lg-5">
          <div class="float-left tvs-oferta-sidelogo mr-1 mr-md-3">
            <div class="tvs-oferta-sidelogo-up"></div>
            <div class="tvs-oferta-sidelogo-down"></div>
          </div>
          <h1 class="tvs-text-orange tvs-oferta-tituloplat">{{objPlataformaEditorial[0].displayText}}</h1>
          <div class="tvs-oferta-div-titulo d-none d-md-block">&nbsp;</div>
        </div>
        <div class="mt-4 mt-md-0 col-12 col-md-6 col-lg-7">
          <p>{{objPlataformaEditorial[0].plataformaTexto}}</p>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="isLoadedCanalesEditorial">
      <div class="row">
        <div class="col-6 col-sm-4 col-md-3 col-lg-2 p-3" *ngFor="let canalesPlat of objCanalesEditorial; let j = index;">
          <a class="tvs-link-canal c-pointer" (click)="navigateTo(baseUrlPlataformas + friendlyUrl(objPlataformaEditorial[0].displayText) + '/' + canalesPlat.platMediaId)">
            <div class="px-2 py-4 mb-3 tvs-oferta-img-canal">
              <img class="img-fluid" src="{{canalesPlat.platImg}}" alt="" />
            </div>
            <h3 class="tvs-oferta-nom-canal">{{canalesPlat.platNombre}}</h3>
            <p class="tvs-oferta-txt-canal">{{canalesPlat.genero}}</p>
          </a>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="isLoadedPlataformaEditorial">
      <div class="row my-4">
        <div class="col-12 col-md-6 col-lg-4 offset-lg-2 col-xl-3 offset-xl-3  text-center">
          <button class="btn btn-primary btn-orange tvs-btn m-2 btn-block" (click)="navigateTo(baseUrlPlataformas + friendlyUrl(objPlataformaEditorial[0].displayText))">Ver más contenidos <fa-icon [icon]="faPlus" class="pl-1"></fa-icon></button>
        </div>
        <div class="col-12 col-md-6 col-lg-4 col-xl-3 text-center">
          <button class="btn btn-primary btn-orange tvs-btn m-2 btn-block" (click)="navigateTo(baseUrlProductos + friendlyUrl(objPlataformaEditorial[0].displayText))">
            Ver plan comercial <fa-icon [icon]="faPlus" class="pl-1"></fa-icon>
          </button>
        </div>
      </div>
      <div class="row mb-5 mt-4 d-none d-md-flex">
        <div class="tvs-oferta-lower-div"></div>
      </div>
    </ng-container>
    <!--Orden: 7 = Estadio Azteca y Club América (Patrocinios deportivos)-->
    <ng-container *ngIf="!isLoadedPlataformaPatrocinios">
      <div class="container-fluid loader-min">
        <img class="loader-img z-20" src="assets/images/website_general/loader-2.svg" alt="" />
      </div>
    </ng-container>
    <div class="row" id="patrocinios-deportivos"></div>
    <ng-container *ngIf="isLoadedPlataformaPatrocinios">
      <div class="row my-5 pt-4">
        <div class="col-12 col-md-6 col-lg-5">
          <div class="float-left tvs-oferta-sidelogo mr-1 mr-md-3">
            <div class="tvs-oferta-sidelogo-up"></div>
            <div class="tvs-oferta-sidelogo-down"></div>
          </div>
          <h1 class="tvs-text-orange tvs-oferta-tituloplat">{{objPlataformaPatrocinios[0].displayText}}</h1>
          <div class="tvs-oferta-div-titulo d-none d-md-block">&nbsp;</div>
        </div>
        <div class="mt-4 mt-md-0 col-12 col-md-6 col-lg-7">
          <p>{{objPlataformaPatrocinios[0].plataformaTexto}}</p>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="isLoadedCanalesPatrocinios">
      <div class="row">
        <div class="col-6 col-sm-4 col-md-3 col-lg-2 p-3" *ngFor="let canalesPlat of objCanalesPatrocinios; let j = index;">
          <a class="tvs-link-canal c-pointer" (click)="navigateTo(baseUrlProductos + friendlyUrl(objPlataformaPatrocinios[0].displayText))">
            <div class="px-2 py-4 mb-3 tvs-oferta-img-canal">
              <img class="img-fluid" src="{{canalesPlat.platImg}}" alt="" />
            </div>
            <h3 class="tvs-oferta-nom-canal">{{canalesPlat.platNombre}}</h3>
            <p class="tvs-oferta-txt-canal">{{canalesPlat.genero}}</p>
          </a>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="isLoadedPlataformaPatrocinios">
      <div class="row my-4">
        <div class="col-12 col-md-6 col-lg-4 offset-lg-2 col-xl-3 offset-xl-3  text-center">
        </div>
        <div class="col-12 col-md-6 col-lg-4 col-xl-3 text-center">
          <button class="btn btn-primary btn-orange tvs-btn m-2 btn-block" (click)="navigateTo(baseUrlProductos + friendlyUrl(objPlataformaPatrocinios[0].displayText))">Ver plan comercial <fa-icon [icon]="faPlus" class="pl-1"></fa-icon></button>
        </div>
      </div>

    </ng-container>
  </div>
</div>

