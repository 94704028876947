<div class="row tvs-card-producto my-3 mx-0">
  <div class="col-12 tvs-o-hidden p-0">
    <div class="col-4 offset-8 tvs-producto-imagen"
         *ngIf="cardData.imagenTarjeta && cardData?.imagenTarjeta?.urls?.length > 0"
         [ngStyle]="{'background-image': 'url('+ getCardImg(baseCMSUrl.slice(0, -1) + cardData?.imagenTarjeta?.urls[0]) +')'}">
    </div>
    <div class="col-4 offset-8 offset-md-8 tvs-producto-imagen"
         *ngIf="!cardData.imagenTarjeta || cardData?.imagenTarjeta?.urls?.length <= 0"
         style="background-image: url('assets/images/website_general/paquetes_default.png')">
    </div>
  </div>
  <div class="col-8 tvs-card-title">
    <h3 class="tvs-text-orange">{{cardData.nombreTarjeta}}</h3>
  </div>
  <div class="col-12 mb-4 tvs-card-text">
      <p [innerHTML]="cardData.descripcionTarjeta" class="tvs-ul"></p>
  </div>
  <div class="col-6 pr-0 pl-2 pb-2 text-left">
    <a class="btn btn-link tvs-text-orange pr-0 tvs-link-producto" (click)="navigateTo('plan-comercial/productos/' + friendlyUrl(cardData.nombreTarjeta) )">Conoce más<fa-icon [icon]="faChevronRight" class="pl-1 tvs-text-orange"></fa-icon></a>
  </div>
  <div class="col-6 pl-0 pr-2 pb-2 text-right" *ngIf="cardData.plataformaAsociada?.contentItems[0]?.idPlataforma && cardData.orden!== 5">
    <a class="btn btn-link tvs-text-orange pl-0 tvs-link-producto" (click)="navigateTo('nuestra-oferta/plataforma/' + friendlyUrl(cardData.nombreTarjeta) )">Ver contenidos<fa-icon [icon]="faChevronRight" class="pl-1 tvs-text-orange"></fa-icon></a>
  </div>
  <!--excepcion para izzi-->
  <div class="col-6 pl-0 pr-2 pb-2 text-right" *ngIf="cardData.orden == 5">
    <a class="btn btn-link tvs-text-orange pl-0 tvs-link-producto" href="{{cardData.linkProducto}}" target="_blank">Ver contenidos<fa-icon [icon]="faChevronRight" class="pl-1 tvs-text-orange"></fa-icon></a>
  </div>
</div>
