<div class="row tvs-custom-breadcrumbs tvs-custom-breadcrumbs-{{srcTipo}}">
  <div class="col-12 mt-3">
    <ul>
      <ng-container *ngFor="let datos of breadcrumbsData">
        <li *ngIf="datos.link !== ''">
          <a class="small tvs-text-bold c-pointer" (click)="navigateTo(datos.link)">{{datos.nombre}}<fa-icon [icon]="faChevronRight" class="pl-2"></fa-icon></a>
        </li>
        <li *ngIf="!datos.link || datos.link == ''">
          <span class="small tvs-text-bold">{{datos.nombre}}</span>
        </li>
      </ng-container>
    </ul>
  </div>
</div>

