<ng-container *ngIf="!isBannerLoaded">
  <div class="paquetes">
    <div class="row tvs-banner-unloaded">
      <img class="loader-img z-20" src="assets/images/website_general/loader-2.svg" alt="" />
    </div>
  </div>
</ng-container>
<ng-container *ngIf="isBannerLoaded">
  <app-header-image-new srcImage="{{bannerImage}}" srcImageMobile="{{bannerImageMobile}}" srcTitle="{{bannerTitle}}" srcTipo="3" class="paquetes"></app-header-image-new>
</ng-container>


<div class="row tvs-bg-white px-2 px-md-5 py-5 tvs-paquetes-top" *ngIf="!isPaquetesLoaded">
  <div class="col-12" style="height:600px;">
    <div class="loader-100">
      <img class="loader-img z-20" src="assets/images/website_general/loader-2.svg" alt="" />
    </div>
  </div>
</div>
<div class="row px-2 px-md-5 py-5 tvs-bg-white" *ngIf="isPaquetesLoaded">
  <div class="container-fluid pt-3">
    <div class="row my-5">
      <div class="col-12 col-sm-6 col-lg-4 col-xl-3 tvs-card-paquete-selector" *ngFor="let paquete of paquetes">
        <app-tarjeta-simple [attr.id]="dynamicId('tarjetaPaquete-', paquete.nombreTarjeta)" class="tarjetaPaquete c-pointer" [cardData]="paquete" (click)="navigateTo(paquete.linkTarjeta)"></app-tarjeta-simple>
      </div>
    </div>
  </div>
</div>