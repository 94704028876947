import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, HostListener } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { gsap } from "gsap/all";
import { faChevronDown, faChevronUp, faTimes, faChevronRight, faCheck, faPlus, faFilter, faMinus } from '@fortawesome/free-solid-svg-icons';
import { GtmService } from 'ngx-gtm';
import { AuthService } from 'src/app/services/auth.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { CmsOfertaService } from 'src/app/services/cms/cms.oferta.service';
import { OfertaContenido, OfertaPlataforma, TiposPlataformaResponse, PlataformasCTA } from 'src/app/models/cms/cms.oferta.model';
import { CustomOrder } from 'src/app/helpers/custom-order';
import { CmsPlanComercialService } from 'src/app/services/cms/cms.plan.comercial.service';
import { ProductosPlancomercial } from 'src/app/models/cms/cms.plan.comercial.model';
import { DynamicHelper } from 'src/app/helpers/dynamic-helper';

@Component({
  selector: 'app-plataforma',
  templateUrl: './plataforma.component.html',
  styleUrls: ['./plataforma.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlataformaComponent implements OnInit {

  title: string = '';
  metaDescription: string = '';
  metaKeywords: string = '';

  public screenWidth: number = 0;
  public screenRes: string = '';

  faChevronDown = faChevronDown;
  faChevronUp = faChevronUp;
  faTimes = faTimes;
  faChevronRight = faChevronRight;
  faCheck = faCheck;
  faPlus = faPlus;
  faMinus = faMinus;
  faFilter = faFilter;
  content: boolean = false;
  plataforma: string;
  plataformaMedia: string;
  bannerImage: string;
  bannerTitle: string;
  baseCMSUrl: string;
  urlParrillaDescargable: string;

  plataformaActual: any[];
  plataformaId: string;
  plataformaTitle: string;
  plataformaText: string;
  canalesPlataforma: any[];
  datosProgramacion: OfertaContenido[];
  datosProgramacionServicio: OfertaContenido[];
  datosProgramacionCompleta: OfertaContenido[];

  bannersPlataformaResponse: TiposPlataformaResponse;
  plataformasLinks: any[];

  plat: boolean;
  platMedia: boolean = false;
  platNombre: string = '';

  /*Catálogos*/
  catMediosCompletos: OfertaPlataforma[];
  catCanalesRadiodif: any[];
  catCanalesPaga: any[];
  catCanalesRegional: any[];
  catEditorial: any[];
  catPlataformas: any[];
  catDigital: any[];
  catGeneros: any[];
  catGenerosCompletos: any[];
  catGenerosSinFiltrar: any[];

  /*Filtros*/
  fltCanalesRadiodif: boolean = false;
  fltCanalesPaga: boolean = false;
  fltCanalesRegional: boolean = false;
  fltEditorial: boolean = false;
  fltPlataformas: boolean = false;
  fltGeneros: boolean = false;
  fltDigital: boolean = false;

  canalesFiltrados: any[];
  canalesPrevioFiltro: any[];
  generosFiltrados: any[];

  genero: string;

  checkbox: boolean;
  borreFiltros: boolean;

/*filtros mobile*/
  isFilterMenuOpen: boolean = false;
  isPlataformaMobileOpen: boolean = false;
  isCanalesMobileOpen: boolean = false;
  isGenerosMobileOpen: boolean = false;
  isBuscaGenMobileOpen: boolean = false;

  /*Loaders*/
  isBannerLoaded: boolean = false;
  isProgramasLoaded: boolean = false;
  isCanalesLoaded: boolean = false;

  isPlataformasOpen: boolean = false;
  isGenerosOpen: boolean = false;

  /* CTA */
  ctaTitle: string;
  ctaText: string;
  ctaLink: string;
  ctaImg: string;
  /* Objeto CTA */
  objCta: any[];
  dataCta: PlataformasCTA[];
  isCtaLoaded: boolean = false;

  productos: ProductosPlancomercial[];
  linkIzziSky: string;
  isProductosLoaded: boolean = false;

  breadcrumbsData: any[];

  constructor(
    private gtm: GtmService,
    public router: Router,
    private route: ActivatedRoute,
    private _location: Location,
    private cmsOfertaService : CmsOfertaService,
    private authService: AuthService,
    private configuration: ConfigurationService,
    private cdr: ChangeDetectorRef,
    private titleService: Title,
    private metaService: Meta,
    private cmsPlanComercialService: CmsPlanComercialService,
  ) {
    this.plataformaMedia = null;
    this.genero = '0';
    this.route.params.subscribe(routeParams => {
      if (routeParams['plataforma']) {
        switch (routeParams['plataforma']) {
          case '1':
          case 'television-radiodifundida':
            this.plataforma = '1';
            break;
          case '2':
          case 'televisa-networks':
            this.plataforma = '2';
            break;
          case '3':
          case 'televisa-regional':
            this.plataforma = '3';
            break;
          case '4':
          case 'televisa-digital':
          case 'televisa-ads': 
            this.plataforma = '4';
            break;
          case '5':
          case 'editorial-televisa':
            this.plataforma = '5';
            break;
          default:
            this.plataforma = "No encontrado";
        };
        this.plat = true;
        this.content = true;
        this.cdr.markForCheck();
        this.platNombre = routeParams['plataforma'];
      }
      else {
        this.plataforma = "No encontrado";
        this.platNombre = '';
        this.plat = false;
        this.content = false;
      }
    });
    this.baseCMSUrl = this.configuration.baseCmsUrl
    this.route.params.subscribe(routeParams => {
      if (routeParams['media']) {
        this.plataformaMedia = routeParams['media'];
        this.platMedia = true;
        this.content = true;
        this.cdr.markForCheck();
      } else {
        this.platMedia = false;
      }

      if(this.authService.isLoggedIn){
        if(this.platMedia)
        {
          this.getDataFromServices(this.plataforma, this.plataformaMedia);
          //console.log('isLoggedIn. Plataforma: ' + this.plataforma + ', Media: ' + this.plataformaMedia );
        }
        else{
          this.getDataFromServices(this.plataforma, '0');
        }
      }
      else{
        /* Me loggeo y luego voy por los banners */
        this.authService.loginByApp().subscribe(resp => {//console.log('Resp login:',resp)
        if(this.platMedia)
        {
          this.getDataFromServices(this.plataforma, this.plataformaMedia);
          //console.log('!isLoggedIn. Plataforma: ' + this.plataforma + ', Media: ' + this.plataformaMedia);
        }
        else{
          this.getDataFromServices(this.plataforma, '0');
        }
        });
      }
    });
  }

  public getDataFromServices(plataforma: string, media: string)
  {
    //console.log('Estoy en getDataFromServices',plataforma, media);
    this.getCatalogos(plataforma, media);
    if(plataforma == "3")
    {
      this.getPDFParrilla();
    }
    
  }

  ngOnInit(): void {
    this.gtm.push({ event: 'TelevisaLlega Plataformas' });
    this.screenWidth = window.innerWidth;
    this.getScreenRes();
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.screenWidth = window.innerWidth;
    this.getScreenRes();
  }

  public getScreenRes () {
    if (this.screenWidth < 576) {
      this.screenRes = 'xs';
    } 
    else if (this.screenWidth >= 576 && this.screenWidth < 768) {
      this.screenRes = 'sm';
    }
    else if (this.screenWidth >= 768 && this.screenWidth < 992) {
      this.screenRes = 'md';
    }
    else if (this.screenWidth >= 992 && this.screenWidth < 1200) {
      this.screenRes = 'lg';
    }
    else if (this.screenWidth >= 1200 && this.screenWidth < 1440) {
      this.screenRes = 'xl';
    }
    else if (this.screenWidth >= 1440) {
      this.screenRes = 'xxl';
    }
    //console.log('bootstrap res:', this.screenRes);
  }

  public asignaMetaData(idPlataforma: string, media: string) {
    //console.log('Estoy en asignaMetaData. Plataforma: ' + idPlataforma + ', Media: ' + media);
    if (idPlataforma == '1') {
      if ( !media || media == '0') {
        this.title = 'Televisión Radiodifundida';
        this.metaDescription = 'Nuestra televisión abierta transmite las mejores novelas, noticias, deportes, concursos, reality shows y más. Una ventana donde podrás dar a conocer tu marca.';
        this.metaKeywords = 'canales nacionales, televisión abierta, telenovelas, noticieros, programas, deportes, series, entretenimiento, noticias, editorial, editorial Televisa, Las Estrellas, Canal 5, foroTV, Canal 9';
      } else if (media == '1') {
        this.title = 'Las Estrellas';
        this.metaDescription = 'Las Estrellas, es el canal de televisión donde podrás dar a conocer tu marca entre novelas, noticias, deportes, concursos, reality shows y más.';
        this.metaKeywords = 'canal 2, canal dos, canal de las estrellas, Las Estrellas, Televisa, novelas, novelas mexicanas, novelas de habla hispana, deportes, Televisa deportes, novelas latinas, concursos, reality shows, televisión, noticias, En Punto, Hoy, La Rosa de Guadalupe, programación de Las estrellas';
      } else if (media == '2') {
        this.title = 'Foro TV';
        this.metaDescription = 'FOROtv, canal de televisión donde podrás dar a conocerte, entre noticias y la transmisión de los acontecimientos más relevantes de México y el mundo.';
        this.metaKeywords = 'Foro TV, noticias, noticias locales, noticias internacionales, nota periodística, noticias de México, noticieros, canal 4, acontecimientos, noticias del mundo, programación de Foro TV';
      } else if (media == '3') {
        this.title = 'Canal 5';
        this.metaDescription = 'Canal 5, canal de televisión donde podrás tener presencia entre transmisiones de caricaturas, series, películas, deportes y programas de entretenimiento y más.';
        this.metaKeywords = 'Canal 5, programación, programas, entretenimiento, series, películas, anime, caricaturas, animes de canal 5, caricaturas de canal 5, caricaturas para niños, programación de Canal 5';
      } else if (media == '4') {
        this.title = 'Canal Nueve';
        this.metaDescription = 'Canal nu9ve transmite telenovelas, películas mexicanas, deportes, programas de comedia y contenido variado, donde podrás anunciarte.';
        this.metaKeywords = 'Canal 9, novelas, telenovelas, deportes, fútbol, películas mexicanas, cine de oro, programas de comedia, comedia, programación de Canal Nueve.';
      }
    } else if (idPlataforma == '2') {
      if (!media || media == '0') {
        this.title = 'Televisa Networks';
        this.metaDescription = 'La mejor oferta de TV de paga transmitiendo diferentes géneros: deportes, entretenimiento, música, cine, etc. Ideal para que la audiencia visualice tu marca.';
        this.metaKeywords = 'televisión de paga, TV Paga, programación, programas, entretenimiento, series, películas, anime, caricaturas, música, cine';
      } else if (media == '1') {
        this.title = 'Unicable';
        this.metaDescription = 'Unicable transmite programas de entretenimiento donde podrás darte a conocer entre contenidos de estilo de vida, moda, salud, espectáculos, series y más.';
        this.metaKeywords = 'Estilo, moda, salud, Unicable, entretenimiento, Montse y Joe, Mojoe, Netas Divinas, Miembros al aire, espectáculos, series de Unicable, programas de Unicable, programación de Unicable';
      } else if (media == '2') {
        this.title = 'Tlnovelas';
        this.metaDescription = 'Anúnciate entre programas especiales, entrevistas, compilaciones y los mejores títulos de novelas de amor, comedia y drama las 24 horas del día.';
        this.metaKeywords = 'telenovela, novela, comedia, drama, entretenimiento, repeticiones de telenovelas, programación de Tlnovelas';
      } else if (media == '3') {
        this.title = 'Distrito Comedia';
        this.metaDescription = 'Promociona tu marca entre la mejor selección de programas clásicos y contemporáneos de comedia y entretenimiento de la televisión mexicana.';
        this.metaKeywords = 'chistes, programa de chistes, comedia, programas de comedia, Familia P.Luche, comediantes, programación de Distrito Comedia';
      } else if (media == '4') {
        this.title = 'BitMe';
        this.metaDescription = 'Contenidos de los mejores animes, videojuegos, k-pop, cultura geek y mucho más; entretenimiento con la mejor programación para anunciar tu marca.';
        this.metaKeywords = 'Videojuegos, entretenimiento, programación k-pop, anime, esports, cultura geek, programación de bitMe';
      } else if (media == '5') {
        this.title = 'Telehit';
        this.metaDescription = 'Telehit es un canal musical que reúne a la audiencia juvenil transmitiendo música y la información más actualizada sobre artistas, conciertos y entretenimiento.';
        this.metaKeywords = 'canal de música, Telehit, videos, artistas, programas, conciertos, entretenimiento, Telehit programas, programas de Telehit, música, programación de Telehit';
      } else if (media == '6') {
        this.title = 'Bandamax';
        this.metaDescription = 'Bandamax es el único canal que transmite lo mejor de la música regional mexicana; a través de él podrás conectar con una audiencia específica.';
        this.metaKeywords = 'Música regional, Bandamax, videos Bandamax, conciertos, conciertos de Bandamax, programas de Bandamax, programación de Bandamax, ranchera, grupera';
      } else if (media == '7') {
        this.title = 'Telehit música';
        this.metaDescription = 'Telehit música es el canal que te permite conectar con aquellas personas que gustan de la música pop, rock, urbano, k-pop y muchos géneros más.';
        this.metaKeywords = 'canal de música, Telehit, videos, artistas, programas, conciertos, entretenimiento, Telehit música, programación de Telehit Música, programas de Telehit';
      } else if (media == '8') {
        this.title = 'Golden';
        this.metaDescription = 'Golden es un canal con los mejores títulos cinematográficos, su amplia oferta de géneros permite a cada integrante de la familia disfrutar de grandes películas.';
        this.metaKeywords = 'Programación de Golden, canal de películas, Golden, entretenimiento, película, películas, películas de acción, películas de drama, películas de comedia, películas de ciencia ficción';
      } else if (media == '9') {
        this.title = 'De película';
        this.metaDescription = 'De película, un canal dedicado al cine mexicano con lo mejor de la época de oro y contemporánea; día con día reúne a cientos de fanáticos del cine.';
        this.metaKeywords = 'De película, cine de oro, películas viejitas, películas mexicanas, películas de Cantinflas, películas de Pedro Infante, películas de TinTan, películas de Silvia Pinal, películas de Jorge Negrete, época de oro, cine nacional, canal de películas';
      } else if (media == '10') {
        this.title = 'Golden Edge';
        this.metaDescription = 'Golden Edge lleva las emociones al límite con las mejores películas de acción, suspenso y otros géneros cinematográficos en su idioma original.';
        this.metaKeywords = 'Programación de Golden Edge, Golden Edge, entretenimiento, película, películas, películas de acción, películas de suspenso, canal de películas, películas en inglés';
      } else if (media == '11') {
        this.title = 'Adrenalina';
        this.metaDescription = 'Adrenalina cuenta con lo mejor de los deportes extremos y de contacto, desde el box hasta las MMA. Conecta con audiencias que gustan de estas disciplinas.';
        this.metaKeywords = 'Canal de deportes extremos, deportes de contacto, deportes extremos, box, lucha libre, MMA, kickboxing, canal Adrenalina, adrenalina, combate';
      } else if (media == '12') {
        this.title = 'TUDN';
        this.metaDescription = 'TUDN un espacio que reúne la mejor información del fútbol y de otros deportes nacionales y del mundo. Conecta con la mayor audiencia deportiva del país.';
        this.metaKeywords = 'canal de deportes, deportes, ligas, ligas de fútbol, fútbol de México, reportajes deportivos, Copa América, Copa europea, liga Mx, tri, Olimpiadas, juegos olímpicos, UEFA, liga europea, fútbol, natación, tenis, fútbol femenil, liga BBVA Mx, box, básquetbol, fútbol femenil, Champions League, Liga de campeones';
      }
    } else if (idPlataforma == '3') {
      if (!media || media == '0') {
        this.title = 'Televisa Regional';
        this.metaDescription = 'Lleva tu marca a cada rincón del país a través de programación exclusiva y segmentada para cualquier ciudad o macroplaza de la República Mexicana.';
        this.metaKeywords = 'Televisa Regional, noticieros televisa, televisión Monterrey, televisión Guadalajara, televisión Puebla, televisión Veracruz, televisión por zonas geográficas';
      } else if (media == '1') {
        this.title = 'Televisa Monterrey';
        this.metaDescription = 'Programación exclusiva de entretenimiento, noticias, deportes, novelas y comedia que permiten llevar tu marca a la audiencia del norte del país.';
        this.metaKeywords = 'Televisa, noticieros televisa, programación Televisa Monterrey, televisión en Monterrey, publicidad en Monterrey, programa de noticias en Monterrey, entretenimiento en Monterrey';
      } else if (media == '2') {
        this.title = 'Televisa Guadalajara';
        this.metaDescription = 'Programación exclusiva de entretenimiento, noticias, deportes, novelas y comedia que permiten llevar tu marca a una de las plazas más importantes del país.';
        this.metaKeywords = 'Televisa, noticieros televisa, programación Televisa Guadalajara, televisión en Guadalajara, publicidad en Guadalajara, programa de noticias en Guadalajara, entretenimiento en Guadalajara';
      } else if (media == '3') {
        this.title = 'Televisa Puebla';
        this.metaDescription = 'Programación exclusiva de entretenimiento, noticias, deportes, novelas y comedia a través de la cual tu marca llegará a cada rincón de Puebla.';
        this.metaKeywords = 'Televisa, noticieros televisa, programación Televisa Puebla, televisión en Puebla, publicidad en Puebla, programa de noticias en Puebla, entretenimiento en Puebla';
      } else if (media == '4') {
        this.title = 'Televisa Tijuana';
        this.metaDescription = 'Lleva tu marca a la audiencia de Tijuana, a través de la mejor programación de entretenimiento, noticias, deportes, novelas, comedia y mucho más.';
        this.metaKeywords = 'Televisa, noticieros televisa, programación Televisa Tijuana, televisión en Tijuana, publicidad en Tijuana, programa de noticias en Tijuana, entretenimiento en Tijuana';
      } else if (media == '5') {
        this.title = 'Televisa Veracruz';
        this.metaDescription = 'Lleva tu marca a la audiencia de Veracruz, a través de la mejor programación de entretenimiento, noticias, deportes, novelas, comedia y mucho más.';
        this.metaKeywords = 'Televisa, noticieros televisa, programación Televisa Veracruz, televisión en Veracruz, publicidad en Veracruz, programa de noticias en Veracruz, entretenimiento en Veracruz';
      }
    } else if (idPlataforma == '4') {
      if (!media || media == '0') {
        this.title = 'Televisa Ads';
        this.metaDescription = 'Conecta con la audiencia a través de uno de los grupos top en el mundo digital, con perfiles de audiencias identificados y listos para ser activados.';
        this.metaKeywords = 'plataforma digital, plataforma virtual, redes, redes sociales, programa por internet, radio por internet, programas en internet, plataformas en internet';
      } else if (media == '1') {
        this.title = 'Entretenimiento';
        this.metaDescription = 'Lleva tu marca a una gran audiencia a través de los programas de entretenimiento más vistos y de los sitios web más visitados del género en México.';
        this.metaKeywords = 'programas de entretenimiento, contenido de entretenimiento, concursos de televisión, publicidad en contenidos de entretenimiento, programas de televisión';
      } else if (media == '2') {
        this.title = 'Estilo de vida/humor';
        this.metaDescription = 'Lleva tu marca a conectar con una gran audiencia a través de los programas de estilo de vida y humor más vistos y de los sitios web más visitados en México.';
        this.metaKeywords = 'programas matutinos, talk shows, contenido de estilo de vida, programas de comedia, publicidad en programas de revista, programas de televisión';
      } else if (media == '3') {
        this.title = 'Música';
        this.metaDescription = 'Conecta con una audiencia que busca la información y los mejores contenidos musicales en México tanto en televisión como en el mundo digital.';
        this.metaKeywords = 'canal de televisión de música, música,  videos musicales, programas de videos musicales, canales musicales de televisión, canales musicales, sitios musicales';
      } else if (media == '4') {
        this.title = 'Deportes';
        this.metaDescription = 'Transmite tu mensaje a través de los mejores programas y reportajes de fútbol así como de las coberturas más completas del deporte en México y el mundo.';
        this.metaKeywords = 'Deportes, ligas, ligas de fútbol, fútbol de México, fútbol mundial, reportajes deportivos, Copa América, Copa europea, liga Mx, tri, Tokio 2021, UEFA, liga europea, fútbol, natación, tenis, fútbol femenil, liga BBVA Mx, box, básquetbol, fútbol femenil, programas de televisión deportivos, publicidad en programas deportivos';
      } else if (media == '5') {
        this.title = 'Noticieros';
        this.metaDescription = 'Llega a una audiencia interesada en conocer minuto a minuto las noticias más relevantes tanto de México como del mundo: Política, Economía, Tendencias y más.';
        this.metaKeywords = 'noticias, noticias locales, noticias internacionales, nota periodística, noticias de México, noticieros, programas de noticias, noticias en televisión, publicidad en noticias, publicidad en noticieros';
      }
    } else if (idPlataforma == '5') {
      if (!media || media == '0') {
        this.title = 'Editorial Televisa';
        this.metaDescription = 'Hemos llegado a millones de lectores por más de 60 años. Llevamos tu marca a las audiencias a través de 13 títulos especializados, impresos y digitales.';
        this.metaKeywords = "revista tú, revista Esquire, revista Cocina fácil, revista Tv y novelas, revista Smash, revista Muy Interesante, revista National Geographic en español, revista National Geographic Traveller, revista Caras, revista Harper's Bazaar, revista Muy interesante junior, revista Vanidades, revista Cosmopolitan, editorial Televisa, revisas impresa, revista digital, publicidad en revistas";
      } else if (media == '1') {
        this.title = "Harper's Bazaar";
        this.metaDescription = 'Es el lugar indicado para conectar con una audiencia interesada en moda y lujo. La revista y el sitio permiten comunicar adecuadamente el mensaje de tu marca.';
        this.metaKeywords = "Moda, salud, belleza, estilo de vida, pasarela, entrevistas, revista, celebridades, reportajes, mundo de la moda, publicidad en contenidos de moda, lujo, publicidad en revistas, publicidad en revista Harper's Bazaar, editorial Televisa";
      } else if (media == '2') {
        this.title = 'Caras';
        this.metaDescription = 'Es la revista favorita de estilo de vida entre los mexicanos. Coloca tu marca entre contenidos de moda, espectáculos, cultura, eventos sociales y más.';
        this.metaKeywords = 'sociales, celebridades mexicanas, moda, belleza, estilo de vida, pasarela, entrevistas, revista, celebridades, reportajes, publicidad en revistas, publicidad en revista Caras, editorial Televisa';
      } else if (media == '3') {
        this.title = 'Muy Interesante Junior';
        this.metaDescription = 'La revista con los de temas de mayor interés científico para audiencias juveniles; reportajes y fotografías de naturaleza, historia, viajes y aventura.';
        this.metaKeywords = 'revista, científica, científico, reportaje, cultura, cultura general, fotografías, muy interesante, ciencias, biología, artes, publicidad en revistas, publicidad en revista Muy interesante Junior, editorial Televisa';
      } else if (media == '4') {
        this.title = 'Vanidades / Cosmopolitan';
        this.metaDescription = 'Revista con los contenidos más actualizados sobre moda, belleza, salud y estilo de vida; un espacio excelente para las audiencias que gustan de estos temas.';
        this.metaKeywords = 'sociales, celebridades mexicanas, moda, belleza, estilo de vida, pasarela, celebridades, reportajes, publicidad en revistas, publicidad en revista Vanidades, publicidad en revista Cosmopolitan, editorial Televisa';
      } else if (media == '6') {
        this.title = 'Tú';
        this.metaDescription = 'Una revista exclusiva para impactar a las audiencias más jovenes con la información más relevante de artistas, moda, belleza, horóscopos, test, películas y más.';
        this.metaKeywords = 'revista para jóvenes, publicidad en revistas, publicidad en revista Tú, artistas, moda, belleza, horóscopos, tests en revistas, editorial Televisa';
      } else if (media == '7') {
        this.title = 'Esquire';
        this.metaDescription = 'Una revista con los temas de interés más relevantes sobre moda, cultura, restaurantes, películas y deportes perfilada hacia una audiencia masculina.';
        this.metaKeywords = 'restaurantes, deportes, eventos deportivos, sociales, celebridades mexicanas, publicidad en revistas, publicidad en revista Esquire, contenido cultural, editorial Televisa';
      } else if (media == '8') {
        this.title = 'Muy Interesante / National Geographic en español / National Geographic Traveller';
        this.metaDescription = 'Revistas donde encontrarás audiencias interesadas en temas científicos, reportajes y fotografías de naturaleza, historia, viajes y aventura.';
        this.metaKeywords = 'revista, científica, científico, reportaje, cultura, cultura general, fotografías, naturaleza, viajes, aventura, muy interesante, ciencias, biología, artes, publicidad en revistas, publicidad en revista Muy interesante, publicidad en revista National Geographic, editorial Televisa';
      } else if (media == '9') {
        this.title = 'TV y Novelas';
        this.metaDescription = 'Revista con la mejor información y noticias del medio del espectáculo; encuentra audiencias con interés específico en famosos y telenovelas.';
        this.metaKeywords = 'revista, telenovelas, famosos, chismes, farándula, pasarela, reportajes, publicidad en revistas, publicidad en revista Tv y novelas, editorial Televisa';
      } else if (media == '10') {
        this.title = 'Cocina Fácil';
        this.metaDescription = 'Una revista de género culinario con contenido como recetas fáciles, comida mexicana, entradas, postres y más, ideal para audiencias interesadas en estos temas.';
        this.metaKeywords = 'comida, revista culinaria, revista de cocina, comida mexicana, recetas, recetas de cocina, recetas fáciles, gourmet, platillos, platillos mexicanos, plato fuerte, postres, publicidad en revistas, publicidad en revista Cocina fácil, editorial Televisa';
      } else if (media == '11') {
        this.title = 'Smash';
        this.metaDescription = 'Una revista que proporciona un espacio ideal para las audiencias que gustan de comics, videojuegos, tecnología, cine, tv, cultura pop y mucho más.';
        this.metaKeywords = 'colección, coleccionistas, Marvel, super héroes, cómics, DC comics, videojuegos, cultura pop, manga, anime, publicidad en revistas, publicidad en revista Smash, editorial Televisa, contenido de cómics';
      }
    } else if (idPlataforma == '6') {
      this.title = 'Patrocinios Deportivos';
      this.metaDescription = 'Tu publicidad llegará a millones de personas a través del Club América, el equipo más grande de México y del Estadio Azteca, el recinto más importante del país.';
      this.metaKeywords = 'Estadio Azteca, Club América, publicidad en estadios, publicidad deportiva, publicidad en el Estadio Azteca';
    }
    

    if (idPlataforma !== 'No encontrado') {
      this.metaService.updateTag( { name: 'keywords', content: this.metaKeywords } );
      this.metaService.updateTag( { name: 'description', content: this.metaDescription } );
      this.metaService.updateTag( { name: 'robots', content: 'index, follow' } );
    } else if (!this.plataforma || this.plataforma == 'No encontrado') {
      this.title = 'Plataformas';
      this.metaDescription = '';
      this.metaKeywords = '';
      this.metaService.removeTag( "name = 'keywords'" );
      this.metaService.removeTag( "name = 'description'" );
      this.metaService.updateTag({ name: 'robots', content: 'noindex, nofollow, noodp' });
    }
    this.titleService.setTitle(this.title);

  }

  checkFiltersFromParams() {
    if (this.plataformaMedia) {
      this.getBannerImage(this.plataforma, this.plataformaMedia);
      this.canalesFiltrados = [];
      this.canalesFiltrados.push({ value: 'Canal filtrado - media', id: this.plataformaMedia });
      if(this.datosProgramacionCompleta)
      {
        this.datosProgramacion = this.datosProgramacionCompleta.filter(programa => this.canalExiste(programa.canalTarjetaContenido?.contentItems[0]?.platMediaId.toString()));
        this.datosProgramacion.sort(CustomOrder.ordenaProgramacion);
        //console.log('this.datosProgramacionCompleta', this.datosProgramacionCompleta);
      }
      else
      {
        //console.log('No existen canales, metodo checkFiltersFromParams');
      }
    }
  }

  public getPDFParrilla(){
    this.cmsOfertaService.getOfertaPDFParrilla().subscribe(response => {
      this.urlParrillaDescargable = this.baseCMSUrl.slice(0, -1) + response?.data?.comercialParrillaRegional[0]?.pDF?.urls[0];
    });
  }

  public getCatalogos(idPlataforma: string, media: string) {

    this.catPlataformas = [
      { value: 'Televisión Radiodifundida', id: '1' },
      { value: 'Televisa Networks', id: '2' },
      { value: 'Televisa Regional', id: '3' },
      { value: 'Televisa Ads', id: '4' },
      { value: 'Editorial Televisa', id: '5' },
      { value: 'Patrocinios deportivos', id: '6' },
    ];
   
  /* asigno variables de Objeto CTA por plataforma (falta CMS, modelo y servicio) */
    if (this.platNombre !== '') {
      this.cmsOfertaService.getCta().subscribe(response => {
        this.dataCta = response.data.plataformasCTA;
        for (let platCta of this.dataCta) {
          if (platCta.plataformaAsociada.contentItems[0].idPlataforma == this.plataforma) {
            this.ctaText = platCta.texto;
            this.ctaImg = this.baseCMSUrl.slice(0, -1) + platCta.imagen.urls[0];
            this.isCtaLoaded = true;
            this.cdr.markForCheck();
          }
        }
      });
    } else {
      this.isCtaLoaded = false;
    }
    

    this.cmsOfertaService.getPlataformas().subscribe(response => {
      this.catEditorial=[];
      this.catDigital= [];
      this.catCanalesRegional = [];
      this.catCanalesPaga = [];
      this.catCanalesRadiodif = [];

      this.catMediosCompletos = response.data.ofertaPlataformas;
      //console.log('Respuesta plataformas:',response,this.catMediosCompletos);

      for(let i = 0; i < this.catMediosCompletos?.length ; i++) {
        if(this.catMediosCompletos[i].platId=='1')
        {
          this.catCanalesRadiodif.push(
            {
              value: this.catMediosCompletos[i].platNombre,
              id: this.catMediosCompletos[i].platMediaId.toString(),
              orden: this.catMediosCompletos[i].numSecu,
              imagen: this.baseCMSUrl.slice(0, -1) + this.catMediosCompletos[i].platImg.urls[0]
            });
        }
        if(this.catMediosCompletos[i].platId=='2')
        {
          this.catCanalesPaga.push(
            {
              value: this.catMediosCompletos[i].platNombre,
              id: this.catMediosCompletos[i].platMediaId.toString(),
              orden: this.catMediosCompletos[i].numSecu,
              imagen: this.baseCMSUrl.slice(0, -1) + this.catMediosCompletos[i].platImg.urls[0]
            });
        }
        if(this.catMediosCompletos[i].platId=='3')
        {
          this.catCanalesRegional.push(
            {
              value: this.catMediosCompletos[i].platNombre,
              id: this.catMediosCompletos[i].platMediaId.toString(),
              orden: this.catMediosCompletos[i].numSecu,
              imagen: this.baseCMSUrl.slice(0, -1) + this.catMediosCompletos[i].platImg.urls[0]
            });
        }
        if(this.catMediosCompletos[i].platId=='4')
        {
          this.catDigital.push(
            {
              value: this.catMediosCompletos[i].platNombre,
              id: this.catMediosCompletos[i].platMediaId.toString(),
              orden: this.catMediosCompletos[i].numSecu,
              imagen: this.baseCMSUrl.slice(0, -1) + this.catMediosCompletos[i].platImg.urls[0]
            });
        }
        if(this.catMediosCompletos[i].platId=='5')
        {
          this.catEditorial.push(
            {
              value: this.catMediosCompletos[i].platNombre,
              id: this.catMediosCompletos[i].platMediaId.toString(),
              orden: this.catMediosCompletos[i].numSecu,
              imagen: this.baseCMSUrl.slice(0, -1) + this.catMediosCompletos[i].platImg.urls[0]
            });
        }
      }

      this.catCanalesRadiodif.sort(CustomOrder.ordenaArregloPropiedadOrden);
      this.catCanalesPaga.sort(CustomOrder.ordenaArregloPropiedadOrden);
      this.catCanalesRegional.sort(CustomOrder.ordenaArregloPropiedadOrden);
      this.catDigital.sort(CustomOrder.ordenaArregloPropiedadOrden);
      this.catEditorial.sort(CustomOrder.ordenaArregloPropiedadOrden);

      this.getPlataformasFullData(idPlataforma, media);
      this.getProductos();
      this.cdr.markForCheck();
    });

    this.catGeneros = [];
    this.cmsOfertaService.getGeneros().subscribe(response => {
      this.catGeneros = [];
      this.catGenerosCompletos = [];
      
      for(let i = 0; i < response.data.ofertaGeneros.length ; i++) {
        this.catGenerosCompletos.push({ value: response.data.ofertaGeneros[i].generoTarjeta, id: response.data.ofertaGeneros[i].contentItemId, orden: response.data.ofertaGeneros[i].idGeneroTarjeta });
      }
      this.catGeneros = this.catGenerosCompletos.slice();
      this.catGeneros.sort(CustomOrder.ordenaArregloPropiedadOrden);
      this.catGenerosSinFiltrar = this.catGeneros;
      //console.log('catGenerosSinFiltrar: ', this.catGenerosSinFiltrar);
      this.cdr.markForCheck();
    });

    this.asignaMetaData(idPlataforma, media);

  }

  getGenerosDesdeContenido(){
    if(this.datosProgramacionCompleta){
      this.catGeneros= []
      this.datosProgramacionCompleta.forEach(element => {
        var existe = false;
        this.catGeneros.forEach((item, index) => {
          if (item.id == element.genero.contentItems[0].contentItemId) existe = true;
        });
        if (!existe){
          this.catGeneros.push({ value: element.genero.contentItems[0].generoTarjeta, id: element.genero.contentItems[0].contentItemId, orden: element.genero.contentItems[0].idGeneroTarjeta });
        }
      });
    }
  }

  getGenerosDesdeContenidoCanales(){
    //console.log("Entro a validar el contenido: ", this.plataformaMedia);
    if(this.datosProgramacion){
      this.catGeneros= []
      this.datosProgramacion.forEach(element => {
        var existe = false;
        this.catGeneros.forEach((item, index) => {
          if (item.id == element.genero.contentItems[0].contentItemId) existe = true;
        });
        if (!existe){
          this.catGeneros.push({ value: element.genero.contentItems[0].generoTarjeta, id: element.genero.contentItems[0].contentItemId, orden: element.genero.contentItems[0].idGeneroTarjeta });
        }
      });
    }
  }
  

  ordenaArregloPropiedadNumSecu(obj1 ,obj2 ){
    if(obj1.numSecu > obj2.numSecu) { return 1;}
    if(obj1.numSecu < obj2.numSecu) { return -1;}
    return 0;
  }

  public isCanalChecked(canalId) {
    //console.log('Entré a isCanalChecked', canalId);
      if (this.canalesFiltrados.map(function (e) { return e.id; }).indexOf(canalId) != -1)
      {
        return true;
      }
      else {
        //console.log(id, 'no encontrado');
        return false;
      }

  }

  public getPlataformasFullData(idPlataforma: string, media: string) {
  /* Invocar servicio que recupera imagen de portada de la página*/
  this.bannerImage = 'assets/images/carga_cms/HERO_NUESTRAOFERTA.png';
  this.bannerTitle = 'Plataformas';
  this.plataformaActual = [];
  var that = this;
  //console.log('Estoy en getPlataformasFullData',idPlataforma, media);
  this.cmsOfertaService.getTiposDePlataforma().subscribe(response => {
    //console.log('Resonse de getTiposDePlataforma:',response);
    this.bannersPlataformaResponse = response;
    this.plataformasLinks = response.data.ofertaTiposdePlataforma;
    this.plataformasLinks.sort(this.ordenaArregloPropiedadOrdenPlataforma);

    if (this.plat === true && this.platMedia === false) {
      this.getBannerImage(idPlataforma, '0');
      this.checkFiltersFromParams();
    }
    if (this.plat === true && this.platMedia === true) {
      this.getBannerImage(idPlataforma, media);
      this.checkFiltersFromParams();
    }
  });
  }

  public ordenaArregloPropiedadOrdenPlataforma(obj1, obj2) {
    if (obj1.orden > obj2.orden) { return 1; }
    if (obj1.orden < obj2.orden) { return -1; }
    return 0;
  }

  public ordenaArregloPropiedadIdPlataforma(obj1, obj2) {
    if (obj1.idPlataforma > obj2.idPlataforma) { return 1; }
    if (obj1.idPlataforma < obj2.idPlataforma) { return -1; }
    return 0;
  }

  public getProductos() {
    this.cmsPlanComercialService.getProductos().subscribe(response => {
      this.productos = response.data.productosPlancomercial;
      this.productos.sort(this.ordenaArregloPropiedadOrden);
      this.isProductosLoaded = true;
      this.cdr.markForCheck();
      //console.log('this.productos', this.productos);
      for (let i = 0; i < this.productos?.length; i++) {
        if (this.productos[i].nombreTarjeta.includes('izzi')) {
          this.linkIzziSky = this.productos[i].linkProducto;
          //console.log('this.linkIzziSky', this.linkIzziSky);
        }
      }
    });

  }
  ordenaArregloPropiedadOrden(obj1, obj2): number {
    if (obj1.orden > obj2.orden) { return 1; }
    if (obj1.orden < obj2.orden) { return -1; }
    return 0;
  }



  public getBannerImage(idPlataforma: string, media: string){
    for(let i = 0; i < this.bannersPlataformaResponse.data.ofertaTiposdePlataforma.length ; i++) {
      if(this.bannersPlataformaResponse.data?.ofertaTiposdePlataforma[i]?.idPlataforma?.toString() === idPlataforma) {
        this.bannerImage = this.baseCMSUrl.slice(0, -1) + this.bannersPlataformaResponse.data.ofertaTiposdePlataforma[i].bannerImage.urls[0];
        this.bannerTitle = this.bannersPlataformaResponse.data.ofertaTiposdePlataforma[i].bannerTitle;
        this.isBannerLoaded = true;
        this.cdr.markForCheck();
        this.plataformaActual = [
          { plataformaId: this.bannersPlataformaResponse.data.ofertaTiposdePlataforma[i].idPlataforma, 
            plataformaNombre: this.bannersPlataformaResponse.data.ofertaTiposdePlataforma[i].displayText, 
            plataformaTexto: this.bannersPlataformaResponse.data.ofertaTiposdePlataforma[i].plataformaTexto }
        ];
      }
    }
    if (this.plataformaActual.length > 0) {
      //console.log('Por validar y traer datos this.plataformaActual.length:'),this.plataformaActual.length;
      for (let obj of this.plataformaActual) {
        this.plataformaId = obj.plataformaId;
        this.plataformaTitle = obj.plataformaNombre;
        this.plataformaText = obj.plataformaTexto;
        this.getPlataformaCanales(obj.plataformaId);
        
        this.filterCheck(idPlataforma, media);
      }
      if(media != '0')
      {
        this.canalesFiltrados = [];
        this.canalesFiltrados.push({ value: 'Canal filtrado - media', id: media });  
      }
      if(this.datosProgramacionCompleta)
      {
        this.datosProgramacion = this.datosProgramacionCompleta.filter(programa => this.canalExiste(programa.canalTarjetaContenido?.contentItems[0]?.platMediaId.toString()));
        this.datosProgramacion.sort(CustomOrder.ordenaProgramacion);
      }
      else
      {
        //console.log('No existen canales, metodo getBannerImage');
      }
    }
    this.breadcrumbsData = [
      { nombre: 'Nuestra oferta', link: 'nuestra-oferta' },
      { nombre: this.plataformaTitle, link: '' }
    ];
  }
  
   
  public initFilters(plataformaId: string) {
    if (plataformaId == '1') {
      this.fltCanalesRadiodif = true;
      this.fltGeneros = true;
      this.canalesFiltrados = this.catCanalesRadiodif.slice();
      this.generosFiltrados = this.catGeneros.slice();
      //console.log('canalesFiltrados',this.canalesFiltrados);
    }
    else if (plataformaId == '2') {
      this.fltCanalesPaga = true;
      this.fltGeneros = true;
      this.canalesFiltrados = this.catCanalesPaga.slice();
      this.generosFiltrados = this.catGeneros.slice();
    }
    else if (plataformaId == '3') {
      this.fltCanalesRegional = true;
      this.fltGeneros = true;
      this.canalesFiltrados = this.catCanalesRegional.slice();
      this.generosFiltrados = this.catGeneros.slice();
    }
    else if (plataformaId == '4') {
      this.fltDigital = true;
      this.fltGeneros = true;
      this.canalesFiltrados = this.catDigital.slice();
      this.generosFiltrados = this.catGeneros.slice();
    }
    else if (plataformaId == '5') {
      this.fltEditorial = true;
      this.fltGeneros = true;
      this.canalesFiltrados = this.catEditorial.slice();
      this.generosFiltrados = this.catGeneros.slice();
    }
    else if (plataformaId == '6') {
      this.canalesFiltrados = [];
      this.generosFiltrados = [];
    }
  }

  isGeneroChecked(id) {

    //console.log('Entré a isGeneroChecked', id);

    if(this.genero == '0')
    {
      return false;
    }
    if (this.generosFiltrados.map(function (e) { return e.id; }).indexOf(id) != -1)
    {
      return true;
    }
    else {
      //console.log(id, 'no encontrado');
      return false;
    }
  }

  changeFilterCanales(idCheck, e) {
    var status = e.target.checked;
    this.genero = '0';
    this.getGenerosDesdeContenido();
    this.generosFiltrados = this.catGeneros.slice();

    if (!status) {
      this.canalesFiltrados.forEach((item, index) => {
        if (item.id == idCheck) this.canalesFiltrados.splice(index, 1);
      });
    } else {
      var existe = false;
      this.canalesFiltrados.forEach((item, index) => {
        if (item.id == idCheck) existe = true;
      });
      if (!existe) {
        this.canalesFiltrados.push({ value: 'Canal filtrado', id: idCheck.toString() })
      }
    }
    //console.log(this.canalesFiltrados);
    if(this.datosProgramacionCompleta)
    {
      this.datosProgramacion = this.datosProgramacionCompleta.filter(programa => this.canalExiste(programa.canalTarjetaContenido?.contentItems[0]?.platMediaId.toString()));
      this.datosProgramacion = this.datosProgramacion.filter(programa => this.generoExiste(programa.genero?.contentItems[0]?.contentItemId.toString()));
      if(this.plataforma == '5'){
        this.datosProgramacion.sort(CustomOrder.ordenaNumOrden);
      } else {
        this.datosProgramacion.sort(CustomOrder.ordenaProgramacion);
      }
      this.getGenerosDesdeContenidoCanales();
    }
    else
    {
      //console.log('No existen canales, metodo changeFilterCanales');
    }
    //console.log(this.datosProgramacion);
  }


  changeFilterGeneros(idCheck, e) {
    this.genero = idCheck;
    if (idCheck == '0')
    {
      this.generosFiltrados = this.catGeneros.slice(); 
    }
    else{
      this.generosFiltrados = [];
      this.generosFiltrados.push({ value: 'Genero filtrado', id: idCheck });
    }

    var status = e.target.checked;
    this.borreFiltros = false;
    //console.log(this.canalesFiltrados);
    if(this.datosProgramacionCompleta)
    {
      this.datosProgramacion = this.datosProgramacionCompleta.filter(programa => this.generoExiste(programa.genero?.contentItems[0]?.contentItemId.toString()));
      //console.log('Filtro genero despues de genero',this.datosProgramacion);
      this.datosProgramacion = this.datosProgramacion.filter(programa => this.canalExiste(programa.canalTarjetaContenido?.contentItems[0]?.platMediaId.toString()));
      //console.log('Filtro genero despues de canal',this.datosProgramacion);
      if(this.plataforma == '5'){
        this.datosProgramacion.sort(CustomOrder.ordenaNumOrden);
      } else {
        this.datosProgramacion.sort(CustomOrder.ordenaProgramacion);
      }
    }
    else
    {
      //console.log('No existen canales, metodo changeFilterGeneros');
    }
    //console.log('datosProgramacion',this.datosProgramacion);
  }

  quitarFiltros() {
      this.generosFiltrados = [];
      this.datosProgramacion = [];
      this.borreFiltros = true;
     //Recorrer arreglos de checks para asignar checked = false
  }

  borrarFiltros() {
    if (this.platMedia) {
      this.getBannerImage(this.plataforma, this.plataformaMedia);
      //console.log('this.canalesFiltrados antes',this.canalesFiltrados);
      this.canalesFiltrados = [];
      this.canalesFiltrados.push({ value: 'Canal filtrado - media', id: this.plataformaMedia });
      //console.log('this.canalesFiltrados despues',this.canalesFiltrados);
      if(this.datosProgramacionCompleta)
      {
        this.datosProgramacion = this.datosProgramacionCompleta.filter(programa => this.canalExiste(programa.canalTarjetaContenido?.contentItems[0]?.platMediaId.toString()));
        if(this.plataforma == '5'){
          this.datosProgramacion.sort(CustomOrder.ordenaNumOrden);
        } else {
          this.datosProgramacion.sort(CustomOrder.ordenaProgramacion);
        }
      }
      else{
        //console.log('No existen canales, metodo borrarFiltros');
      }
    }
    else {
      this.canalesPrevioFiltro = this.canalesFiltrados.slice();
      this.getBannerImage(this.plataforma, '0');
      this.canalesFiltrados = this.canalesPrevioFiltro.slice();
      if(this.datosProgramacionCompleta)
      {
      this.datosProgramacion = this.datosProgramacionCompleta.filter(programa => this.canalExiste(programa.canalTarjetaContenido?.contentItems[0]?.platMediaId.toString()));
      this.datosProgramacion.sort(CustomOrder.ordenaProgramacion);
      }
    }
  }

  isGeneroTodosSelected()
  {
    if (this.genero == '0')
    {
      return true;
    }
    else
    {
      return false;
    }
  }

  canalesTodos() {
  //console.log('Click en todos los canales');
    this.getBannerImage(this.plataforma, '0');
    // Agrego consideración para que coloque todos los géneros
    this.genero = '0';
    this.getGenerosDesdeContenido();
    this.generosFiltrados = this.catGeneros.slice();

    this.canalesFiltrados = [];
    if (this.plataforma == '1') {
      this.canalesFiltrados = this.catCanalesRadiodif.slice();
      //console.log('canalesFiltrados',this.canalesFiltrados);
    }
    else if (this.plataforma == '2') {
      this.canalesFiltrados = this.catCanalesPaga.slice();
    }
    else if (this.plataforma == '3') {
      this.canalesFiltrados = this.catCanalesRegional.slice();
    }
    else if (this.plataforma == '4') {
      this.canalesFiltrados = this.catDigital.slice();
    }
    else if (this.plataforma == '5') {
      this.canalesFiltrados = this.catEditorial.slice();
    }
    else if (this.plataforma == '6') {
      this.canalesFiltrados = [];
    }
    if(this.datosProgramacionCompleta)
    {
      this.datosProgramacion = this.datosProgramacionCompleta.filter(programa => this.canalExiste(programa.canalTarjetaContenido?.contentItems[0]?.platMediaId.toString()));
      this.datosProgramacion = this.datosProgramacion.filter(programa => this.generoExiste(programa.genero?.contentItems[0]?.contentItemId.toString()));
      if(this.plataforma == '5'){
        this.datosProgramacion.sort(CustomOrder.ordenaNumOrden);
      } else {
        this.datosProgramacion.sort(CustomOrder.ordenaProgramacion);
      }
    }
  }

  canalExiste(id: string) {
    //console.log('En canal existe: ',id,this.canalesFiltrados) ;
    if (this.canalesFiltrados.map(function (e) { return e.id; }).indexOf(id) != -1)
      {
        return true;
      }
      else {
        //console.log(id, 'no encontrado');
        return false;
      }
  }

  generoExiste(id) {
    //console.log('En generoExiste: ',id);
    if (this.generosFiltrados.map(function (e) { return e.id; }).indexOf(id) != -1)
    {
      return true;
    }
    else {
      //console.log(id, 'no encontrado');
      return false;
    }
  }

  public filterCheck(plataforma: string, media: string) {
    if (plataforma == '1') {
      this.fltCanalesRadiodif = true;
      this.fltGeneros = true;
    }
    else if (plataforma == '2') {
      this.fltCanalesPaga = true;
      this.fltGeneros = true;
    }
    else if (plataforma == '3') {
      this.fltCanalesRegional = true;
      this.fltGeneros = true;
    }
    else if (plataforma == '4') {
      this.fltDigital = true;
      this.fltGeneros = true;
    }
    else if (plataforma == '5') {
      this.fltEditorial = true;
      this.fltGeneros = true;
    }
    else if (plataforma == '6') {
    }
  }

  public getPlataformaCanales(plataformaId: string) {
    //console.log('Estoy en getPlataformaCanales', plataformaId);
    this.canalesPlataforma = [];
    for(let i = 0; i < this.catMediosCompletos?.length ; i++) {
      if(this.catMediosCompletos[i].platId==plataformaId)
      {
        this.canalesPlataforma.push(
          {
            platId: this.catMediosCompletos[i].platId,
            platMediaId: this.catMediosCompletos[i].platMediaId,
            platNombre: this.catMediosCompletos[i].platNombre,
            platImg: this.baseCMSUrl.slice(0, -1) + this.catMediosCompletos[i].platImg.urls[0],
            platProg: '', platLink: '/nuestra-oferta/plataforma/' + this.catMediosCompletos[i].platId + '/' + this.catMediosCompletos[i].platMediaId, numSecu: this.catMediosCompletos[i].numSecu
          });
      }
      this.canalesPlataforma.sort(this.ordenaArregloPropiedadNumSecu);
      //console.log('this.canalesPlataforma',this.canalesPlataforma);
    }
    
    this.initFilters(plataformaId);
    this.getProgramacion(plataformaId);
    //console.log('Plataforma con num imágenes: ', this.canalesPlataforma.length);
    this.isCanalesLoaded = true;
    this.cdr.markForCheck();
  }

  public getProgramacion(plataforma: string) {
    //console.log('En getProgramacion: ', plataforma, this.platMedia);
    /* Cargo los programas desde el servicio */
    //console.log('Entro a getProgramacion por plataforma', plataforma);
    this.cmsOfertaService.getOfertaContenidos().subscribe(response => {
      //console.log('Response de getOfertaContenidos:',response);
      this.datosProgramacion = new Array<OfertaContenido>();
      this.datosProgramacionServicio = response.data.ofertaContenidos;
      for(let i = 0; i < this.datosProgramacionServicio.length ; i++) {
        if(this.datosProgramacionServicio[i].canalTarjetaContenido?.contentItems[0]?.platId == plataforma) {
          //console.log('Coincide con plataforma: ', this.datosProgramacionServicio[i].canalTarjetaContenido?.contentItems[0]?.platId, this.datosProgramacionServicio[i]), 
          this.datosProgramacion.push(this.datosProgramacionServicio[i]);
        }
      }
      this.datosProgramacion.forEach(element => {
        element.idPrograma = element.contentItemId;
        element.linkTarjeta = '/nuestra-oferta/detalle-programa/' + element.contentItemId;
        if(element.canalTarjetaContenido)
        {
          element.idPlataformaTarjeta = element.canalTarjetaContenido.contentItems[0].platId;
          this.catPlataformas.filter(p => p.id == element.canalTarjetaContenido.contentItems[0].platId).forEach(plat => {
            element.cintilloTarjeta = plat.value;
          })
        }
      });
      this.datosProgramacionCompleta = this.datosProgramacion.slice();
      this.datosProgramacion = this.datosProgramacionCompleta.filter(programa => this.generoExiste(programa.genero?.contentItems[0]?.contentItemId.toString()));
      //console.log('Get Inicial despues de genero',this.datosProgramacion);
      this.datosProgramacion = this.datosProgramacion.filter(programa => this.canalExiste(programa.canalTarjetaContenido?.contentItems[0]?.platMediaId.toString()));
      //this.datosProgramacion.sort(CustomOrder.ordenaProgramacion);
      if(this.plataforma == '5'){
        this.datosProgramacion.sort(CustomOrder.ordenaNumOrden);
      } else {
        this.datosProgramacion.sort(CustomOrder.ordenaProgramacion);
      }
      //console.log('Get Inicial despues de canal',this.datosProgramacion);
      //console.log('Salgo de getProgramacion por plataforma', this.datosProgramacionCompleta, this.datosProgramacion);
      this.isProgramasLoaded = true;
      this.getGenerosDesdeContenido();
      if(this.plataformaMedia != '0')
      {
        this.catGeneros = [];
        
        this.getGenerosDesdeContenidoCanales();
        this.cdr.markForCheck();
      }
      this.cdr.markForCheck();
    });
    
  }

  public togglePlataformas() {
    if (this.isGenerosOpen) {
      this.toggleGeneros();
    }
    if (this.isProductosLoaded) {
      if (!this.isPlataformasOpen) {
        gsap.to(".tvs-combo-plat-menu", {
          height: 'auto',
          paddingTop: '30px',
          duration: 0.5
        });
        this.isPlataformasOpen = true;
      } else {
        gsap.to(".tvs-combo-plat-menu", {
          height: '0',
          paddingTop: '0',
          duration: 0.5
        });
        this.isPlataformasOpen = false;
      }
    }
    
  }

  public toggleGeneros() {
    if (this.isPlataformasOpen) {
      this.togglePlataformas();
    }
    if (!this.isGenerosOpen) {
      gsap.to(".tvs-combo-gen-menu", {
        height: 'auto',
        paddingTop: '30px',
        duration: 0.5
      });
      this.isGenerosOpen = true;
    } else {
      gsap.to(".tvs-combo-gen-menu", {
        height: '0',
        paddingTop: '0',
        duration: 0.5
      });
      this.isGenerosOpen = false;
    }
  }

  public openFilter() {
    if (!this.isFilterMenuOpen) {
      gsap.to(window, {
        scrollTo: 0,
        duration: 0.5
      });
      gsap.to(".tvs-mobile-filter", {
        width: '100%',
        padding: '20px',
        opacity: 1,
        duration: 0.5
      });
      this.isFilterMenuOpen = true;
    }
  }
  public closeFilter() {
    if (this.isFilterMenuOpen) {
      gsap.to(".tvs-mobile-filter", {
        width: 0,
        padding: '0px',
        opacity: 0,
        duration: 0.5
      });
      this.isFilterMenuOpen = false;
      this.toggleMobileAllFilters();
    }
  }
  public clearFilter() {
    this.borrarFiltros();
    this.canalesTodos();
  }

  public toggleMobilePlataforma() {
    if (!this.isPlataformaMobileOpen) {
      this.toggleMobileAllFilters();
      gsap.to(".tvs-mobile-filter-plat", {
        height: 'auto',
        duration: 0.5
      });
      this.isPlataformaMobileOpen = true;
    } else {
      gsap.to(".tvs-mobile-filter-plat", {
        height: '35px',
        duration: 0.5
      });
      this.isPlataformaMobileOpen = false;
    }
  }
  public toggleMobileCanales() {
    if (!this.isCanalesMobileOpen) {
      this.toggleMobileAllFilters();
      gsap.to(".tvs-mobile-filter-can", {
        height: 'auto',
        duration: 0.5
      });
      this.isCanalesMobileOpen = true;
    } else {
      gsap.to(".tvs-mobile-filter-can", {
        height: '35px',
        duration: 0.5
      });
      this.isCanalesMobileOpen = false
    }
  }
  public toggleMobileGeneros() {
    if (!this.isGenerosMobileOpen) {
      this.toggleMobileAllFilters();
      gsap.to(".tvs-mobile-filter-gen", {
        height: 'auto',
        duration: 0.5
      });
      this.isGenerosMobileOpen = true;
    } else {
      gsap.to(".tvs-mobile-filter-gen", {
        height: '35px',
        duration: 0.5
      });
      this.isGenerosMobileOpen = false;
    }
  }
  public toggleMobileBuscaGen() {
    if (!this.isBuscaGenMobileOpen) {
      this.toggleMobileAllFilters();
      gsap.to(".tvs-mobile-search-gen", {
        height: 'auto',
        duration: 0.5
      });
      this.isBuscaGenMobileOpen = true;
    } else {
      gsap.to(".tvs-mobile-search-gen", {
        height: '0',
        duration: 0.5
      });
      this.isBuscaGenMobileOpen = false;
    }
  }
  public toggleMobileAllFilters() {
    if (this.isPlataformaMobileOpen) { this.toggleMobilePlataforma(); }
    if (this.isCanalesMobileOpen) { this.toggleMobileCanales(); }
    if (this.isGenerosMobileOpen) { this.toggleMobileGeneros(); }
    if (this.isBuscaGenMobileOpen) { this.toggleMobileBuscaGen(); }

  }


  public navigateTo(path: string): void {
    this.router.navigateByUrl(path);
    gsap.to(window, {
      scrollTo: 0,
      duration: 0.5
    });
    if (this.isGenerosOpen) {
      this.toggleGeneros();
    }
    if (this.isPlataformasOpen) {
      this.togglePlataformas();
    }
    this.closeFilter();
  }

  public goBack() {
    this._location.back();
  }

  public navigateToExternal(path: string) {
    window.open(path, "_blank");
  }

  public goToProgram(path: string, nombre: string, plataforma: string, canal: string, genero: string, fecha: string, hora: string) {
    window.open(path, '_self');
    //console.log(path, nombre, plataforma, canal, genero);
    this.gtm.push({
      event: 'click-programa',
      type: 'Programa',
      from: 'Plataformas',
      platform: plataforma,
      channel: canal,
      genre: genero,
      name: nombre,
      date: fecha,
      schedule: hora
    });
  }

  customOptionsMobile: OwlOptions = {
    loop: false,
    rewind: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 500,
    navText: ['', ''],
    items: 1,
    nav: true,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplaySpeed: 1000,
  }

  public dynamicId(objeto: string, nombre: string) {
    return DynamicHelper.dynamicIdHelper(objeto, nombre);
  }

  public friendlyUrl(nombre: string) {
    return DynamicHelper.friendlyUrlHelper(nombre);
  }

}
